const POST_GET_ALL_LEADS_REQUEST = "POST_GET_ALL_LEADS_REQUEST";
const POST_GET_ALL_LEADS_REQUEST_SUCCESS = "POST_GET_ALL_LEADS_REQUEST_SUCCESS";
const POST_GET_ALL_LEADS_REQUEST_FAILURE = "POST_GET_ALL_LEADS_REQUEST_FAILURE";
const RESET_GET_ALL_LEADS_STATE = "RESET_GET_ALL_LEADS_STATE";

const getAllLeads = (formData) => {
  return {
    type: POST_GET_ALL_LEADS_REQUEST,
    formData
  };
};

const resetGetAllLeads = () => {
  return {
    type: RESET_GET_ALL_LEADS_STATE,
  };
};
export {
  getAllLeads,
  resetGetAllLeads,
  RESET_GET_ALL_LEADS_STATE,
  POST_GET_ALL_LEADS_REQUEST,
  POST_GET_ALL_LEADS_REQUEST_SUCCESS,
  POST_GET_ALL_LEADS_REQUEST_FAILURE,
};
