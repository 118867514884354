const POST_CHANGE_LANGUAGE_REQUEST = "POST_CHANGE_LANGUAGE_REQUEST";
const POST_CHANGE_LANGUAGE_REQUEST_SUCCESS = "POST_CHANGE_LANGUAGE_REQUEST_SUCCESS";
const POST_CHANGE_LANGUAGE_REQUEST_FAILURE = "POST_CHANGE_LANGUAGE_REQUEST_FAILURE";

const changeLanguage = (formData) => {
  return {
    type: POST_CHANGE_LANGUAGE_REQUEST,
    formData
  };
};


export {
  changeLanguage,
  POST_CHANGE_LANGUAGE_REQUEST,
  POST_CHANGE_LANGUAGE_REQUEST_SUCCESS,
  POST_CHANGE_LANGUAGE_REQUEST_FAILURE,
};
