const POST_GET_SITE_VISIT_REQUEST = 'POST_GET_SITE_VISIT_REQUEST';
const POST_GET_SITE_VISIT_REQUEST_SUCCESS = 'POST_GET_SITE_VISIT_REQUEST_SUCCESS';
const POST_GET_SITE_VISIT_REQUEST_FAILURE = 'POST_GET_SITE_VISIT_REQUEST_FAILURE';
const RESET_GET_SITE_VISIT_STATE = 'RESET_GET_SITE_VISIT_STATE'

const getSiteVisit = (formData) => {

    return {
        type: POST_GET_SITE_VISIT_REQUEST,
        formData
    }
}
const resetGetSiteVisit = () => {

    return {
        type: RESET_GET_SITE_VISIT_STATE,
    }
}
export {
    getSiteVisit,
    resetGetSiteVisit,
    RESET_GET_SITE_VISIT_STATE,
    POST_GET_SITE_VISIT_REQUEST,
    POST_GET_SITE_VISIT_REQUEST_SUCCESS,
    POST_GET_SITE_VISIT_REQUEST_FAILURE,
};