const POST_CONFIRM_PROJECT_COMPLETION_REQUEST = 'POST_CONFIRM_PROJECT_COMPLETION_REQUEST';
const POST_CONFIRM_PROJECT_COMPLETION_REQUEST_SUCCESS = 'POST_CONFIRM_PROJECT_COMPLETION_REQUEST_SUCCESS';
const POST_CONFIRM_PROJECT_COMPLETION_REQUEST_FAILURE = 'POST_CONFIRM_PROJECT_COMPLETION_REQUEST_FAILURE';
const RESET_CONFIRM_PROJECT_COMPLETION_STATE = 'RESET_CONFIRM_PROJECT_COMPLETION_STATE'

const confirnProjectCompletion = (formData) => {

    return {
        type: POST_CONFIRM_PROJECT_COMPLETION_REQUEST,
        formData
    }
}
const resetConfirnProject = () => {

    return {
        type: RESET_CONFIRM_PROJECT_COMPLETION_STATE,
    }
}
export {
    confirnProjectCompletion,
    resetConfirnProject,
    RESET_CONFIRM_PROJECT_COMPLETION_STATE,
    POST_CONFIRM_PROJECT_COMPLETION_REQUEST,
    POST_CONFIRM_PROJECT_COMPLETION_REQUEST_SUCCESS,
    POST_CONFIRM_PROJECT_COMPLETION_REQUEST_FAILURE,
};