const POST_EDIT_PROJECT_REQUEST = "POST_EDIT_PROJECT_REQUEST";
const POST_EDIT_PROJECT_REQUEST_SUCCESS = "POST_EDIT_PROJECT_REQUEST_SUCCESS";
const POST_EDIT_PROJECT_REQUEST_FAILURE = "POST_EDIT_PROJECT_REQUEST_FAILURE";
const RESET_EDIT_PROJECT_STATE = "RESET_EDIT_PROJECT_STATE";
const SET_IS_QUICK_QUOTE = "SET_IS_QUICK_QUOTE";

const updateProject = (formData) => {
  return {
    type: POST_EDIT_PROJECT_REQUEST,
    formData
  };
};
const resetUpdateProject = () => {
  return {
    type: RESET_EDIT_PROJECT_STATE,
  };
};
const setIsQuickQuote = (quickQuote) => {
  return {
    type: SET_IS_QUICK_QUOTE,
    quickQuote
  };
};


export {
  updateProject,
  resetUpdateProject,
  setIsQuickQuote,
  POST_EDIT_PROJECT_REQUEST,
  POST_EDIT_PROJECT_REQUEST_SUCCESS,
  POST_EDIT_PROJECT_REQUEST_FAILURE,
  RESET_EDIT_PROJECT_STATE,
  SET_IS_QUICK_QUOTE
};
