import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  Pressable,
  ImageBackground,
} from "react-native";
import React, { useEffect } from "react";
import Swiper from 'react-native-swiper/src';
import ConsumerBenefits from "./Components/ConsumerBenefits";
import StepCount from "./Components/StepCount";
import FreeOnline from "./Components/FreeOnline";
import FreeBusiness from "./Components/FreeBusiness";
import { useTranslation } from 'react-i18next'
import IndustryProfessional from "./Components/IndustryProfessional";
import { setItem } from "../../Utils/AsyncStore";
const Signup = ({ navigation }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setItem("languageSelected", true).catch(error => console.log(error))
  }, [])

  return (
    <View style={{ flex: 1 }}>
      <Pressable onPress={() => navigation.navigate("HomeScreen")} style={styles.skipBtn}>
        <Text style={styles.skipText}>{t("common:skip")}</Text>
      </Pressable>
      <Swiper
        loop={false}
        style={styles.wrapper}
        indicatorStyle="0"
        dotStyle={styles.dotStyle}
        activeDotStyle={styles.activeDotStyle}
      >
        <IndustryProfessional />
        <ConsumerBenefits />
        <StepCount />
        <FreeOnline />
        <FreeBusiness />
      </Swiper>
      <Pressable onPress={() => navigation.navigate("HomeScreen")} style={styles.endBtn}>
        <Text style={styles.signUpText}>{t("common:signup")}</Text>
      </Pressable>
    </View>
  )
}

export default Signup

const styles = StyleSheet.create({
  wrapper: {},
  slide1: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "#9DD6EB",
  },
  dotStyle: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginBottom: 85,
    backgroundColor: "#FFF",
  },
  activeDotStyle: {
    backgroundColor: "#F03B4B",
    width: 10,
    height: 10,
    borderRadius: 5,
    marginBottom: 85,
  },
  slide2: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#97CAE5",
  },
  slide3: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#92BBD9",
  },
  text: {
    color: "#fff",
    fontSize: 30,
    fontWeight: "bold",
  },
  endBtn: {
    position: "absolute",
    backgroundColor: "#FFF",
    padding: 15,
    paddingHorizontal: 120,
    bottom: 40,
    borderRadius: 25,
    alignSelf: "center",
    justifyContent: "center",
  },
  signUpText: {
    fontSize: 16,
    fontWeight: "400",
    color: "#EB2C3B",
    textTransform: "uppercase"
  },
  skipBtn: {
    position: "absolute",
    top: 60,
    right: 15,
    alignSelf: "flex-end",
    zIndex: 111,
  },
  skipText: {
    fontSize: 17,
    color: "#FFF",
    fontWeight: "600",
  },
});