import AsyncStorage from "@react-native-async-storage/async-storage";


const setItem = async (key, item) => {
    item = JSON.stringify(item)
    await AsyncStorage.setItem(key.toString(), item).catch(error => { throw Error(error) })

}

const getItem = async (key) => {
    let item = await AsyncStorage.getItem(key.toString()).catch(error => { throw Error(error) })
    return JSON.parse(item)
}
const removeItem = async (key) => {
    await AsyncStorage.removeItem(key).catch(error => { throw Error(error) })
}
export { setItem, getItem, removeItem }