const POST_REJECT_ON_SITE_VISIT_REQUEST = 'POST_REJECT_ON_SITE_VISIT_REQUEST';
const POST_REJECT_ON_SITE_VISIT_REQUEST_SUCCESS = 'POST_REJECT_ON_SITE_VISIT_REQUEST_SUCCESS';
const POST_REJECT_ON_SITE_VISIT_REQUEST_FAILURE = 'POST_REJECT_ON_SITE_VISIT_REQUEST_FAILURE';
const RESET_REJECT_ON_SITE_VISIT_STATE = 'RESET_REJECT_ON_SITE_VISIT_STATE'

const rejectOnSiteVisit = (formData) => {

    return {
        type: POST_REJECT_ON_SITE_VISIT_REQUEST,
        formData
    }
}
const resetRejectOnSiteVisit = () => {

    return {
        type: RESET_REJECT_ON_SITE_VISIT_STATE,

    }
}
export {
    rejectOnSiteVisit,
    resetRejectOnSiteVisit,
    RESET_REJECT_ON_SITE_VISIT_STATE,
    POST_REJECT_ON_SITE_VISIT_REQUEST,
    POST_REJECT_ON_SITE_VISIT_REQUEST_SUCCESS,
    POST_REJECT_ON_SITE_VISIT_REQUEST_FAILURE,
};