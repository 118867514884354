const POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST = 'POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST';
const POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST_SUCCESS = 'POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST_SUCCESS';
const POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST_FAILURE = 'POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST_FAILURE';
const RESET_ADD_PAYOUT_WITH_TOKEN_METHOD_STATE = 'RESET_ADD_PAYOUT_WITH_TOKEN_METHOD_STATE'
const SET_PAYMENT_WITH_TOKEN_METHOD = 'SET_PAYMENT_WITH_TOKEN_METHOD'


const addPayinMethod = (formData) => {

    return {
        type: POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST,
        formData
    }
}
const resetPayinMethod = () => {

    return {
        type: RESET_ADD_PAYOUT_WITH_TOKEN_METHOD_STATE,
    }
}
const setPaymentMethod = (paymentMethod) => {
    return {
        type: SET_PAYMENT_WITH_TOKEN_METHOD,
        paymentMethod
    };
};

export {
    addPayinMethod,
    resetPayinMethod,
    setPaymentMethod,
    SET_PAYMENT_WITH_TOKEN_METHOD,
    RESET_ADD_PAYOUT_WITH_TOKEN_METHOD_STATE,
    POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST,
    POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST_SUCCESS,
    POST_ADD_PAY_IN_METHOD_WITH_TOKEN_REQUEST_FAILURE,
};