const POST_REVIEW_SPECIAL_PRO_REQUEST = "POST_REVIEW_SPECIAL_PRO_REQUEST";
const POST_REVIEW_SPECIAL_PRO_REQUEST_SUCCESS = "POST_REVIEW_SPECIAL_PRO_REQUEST_SUCCESS";
const POST_REVIEW_SPECIAL_PRO_REQUEST_FAILURE = "POST_REVIEW_SPECIAL_PRO_REQUEST_FAILURE";
const RESET_REVIEW_SPECIAL_PRO_STATE = "RESET_REVIEW_SPECIAL_PRO_STATE";

const reviewSpecialPro = (formData) => {
  return {
    type: POST_REVIEW_SPECIAL_PRO_REQUEST,
    formData
  };
};
const resetReviewSpecialPro = () => {
  return {
    type: RESET_REVIEW_SPECIAL_PRO_STATE,

  };
};
export {
  reviewSpecialPro,
  resetReviewSpecialPro,
  RESET_REVIEW_SPECIAL_PRO_STATE,
  POST_REVIEW_SPECIAL_PRO_REQUEST,
  POST_REVIEW_SPECIAL_PRO_REQUEST_SUCCESS,
  POST_REVIEW_SPECIAL_PRO_REQUEST_FAILURE,
};
