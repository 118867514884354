import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, Image, Pressable, ImageBackground, SafeAreaView, BackHandler, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import Background from "../../assets/images/bg.png";
import LogoImage from "../../assets/images/ugo.png";
import ProImage from "../../assets/images/pro_img.png";
import UserImage from "../../assets/images/user_image.png";
import { basicStart, resetBasicStart } from "../../Redux/Models/BasicStart/Action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import { currentStack } from "../../Redux/Models/CurrentStack/Actions";
import { setItem, getItem, removeItem } from "../../Utils/AsyncStore";
import { setBasicStart } from "../../Redux/Models/BasicStart/Action";
import { setUser } from "../../Redux/Models/User/UserDetails/Actions";
import { hardwareBackPress, app_version } from "../../Utils/HelperFunctions";
import { getPermission } from "../../Utils/NotificationService";
import * as SplashScreen from "expo-splash-screen";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const HomeScreen = ({ navigation }) => {
  const insets = useSafeAreaInsets()
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const startUp = useSelector((state) => state.basicStart);
  useEffect(() => {
    (async () => {
      let startUpData = await getItem("startUp");
      // let authUser = await getItem("authUser")
      if (startUpData) {
        dispatch(setBasicStart(startUpData));
        // authUser && dispatch(setUser(authUser))
        return;
      }
      const formData = new FormData();
      const DeviceType = Platform.OS;
      formData.append("device_type", DeviceType);
      formData.append("app_version", app_version);
      dispatch(basicStart(formData));
    })();
    hardwareBackPress();
    getPermission();
    removeItem("authUser")
  }, []);
  useEffect(() => {
    if (startUp.error) {
      alert(startUp.message);
      dispatch(resetBasicStart());
      return;
    }
  }, [startUp]);
  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        return true;
      },
    );
    return () => backHandler.remove();
  }, [])
  return (
    <>
      <ImageBackground onLoad={async () => await SplashScreen.hideAsync()} style={styles.imageContainer} source={Background}>
        <View style={styles.container}>
          <Text style={styles.startText}>{t("common:start_your_connection_with")}</Text>
        </View>
        <View style={styles.container}>
          <Image source={LogoImage} style={styles.logoImage} />
        </View>
        <View style={styles.container}>
          <Text style={styles.connectingText}>{t("common:connecting_consumers_with")}</Text>
          <Text style={styles.connectingText}>{t("common:highly_skilled_industry_professionals_in_their_communities")}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.lookingText}>{t("common:are_you_looking_to_have_a_project_completed")}</Text>
          <Text style={styles.lookingText}>{t("common:or")}</Text>
          <Text style={styles.lookingText}>{t("common:sign_up_as_an_industry_professional")}</Text>
          <Text style={styles.versionText}>{Platform.OS !== 'web' && t("common:version_col") + app_version}</Text>
        </View>

        <View style={[styles.btnContainer,]}>
          <Pressable
            onPress={() => {
              dispatch(currentStack("UserStack"));
            }}
            style={[styles.userBtn,]}
          >
            <Image source={UserImage} style={styles.userImage} />
            <Text style={styles.userText}>{t("common:user")}</Text>
          </Pressable>
          <Pressable onPress={() => dispatch(currentStack("ProStack"))} style={[styles.proBtn,]}>
            <Image source={ProImage} style={styles.userImage} />
            <Text style={styles.userText}>{t("common:pro")}</Text>
          </Pressable>
        </View>
      </ImageBackground>
      {startUp.loading && <Loading />}
      <View style={{ height: insets.bottom, backgroundColor: "#EB2C3B" }}></View>

    </>
  );
};
export default HomeScreen;
const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
  },
  imageContainer: {
    flex: 1,
    resizeMode: "cover",
    backgroundColor: '#1F1E22'

  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  versionText: {
    marginVertical: 50,
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    color: "#FFF",
  },
  logoImage: {
    height: 125,
    width: "100%",
    resizeMode: "contain",
  },
  startText: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
    color: "#A8A8A8",
    marginTop: 40,
  },
  connectingText: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "400",
    color: "#DFE3E6",
    textAlign: "center",
  },
  lookingText: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    color: "#FFF",
    textAlign: "center",
  },
  btnContainer: {
    flexDirection: "row",
  },
  userBtn: {
    backgroundColor: "#EB2C3B",
    flex: 1,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  proBtn: {
    flex: 1,
    backgroundColor: "#EB2C3B",
    marginLeft: 1,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  userImage: {
    width: 50,
    height: 50,
    resizeMode: "contain",
  },
  userText: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    color: "#FFF",
  },
});
