const POST_PROJECT_NOT_COMPLETED_REQUEST = 'POST_PROJECT_NOT_COMPLETED_REQUEST';
const POST_PROJECT_NOT_COMPLETED_REQUEST_SUCCESS = 'POST_PROJECT_NOT_COMPLETED_REQUEST_SUCCESS';
const POST_PROJECT_NOT_COMPLETED_REQUEST_FAILURE = 'POST_PROJECT_NOT_COMPLETED_REQUEST_FAILURE';
const RESET_PROJECT_NOT_COMPLETED_STATE = 'RESET_PROJECT_NOT_COMPLETED_STATE'

const projectNotCompleted = (formData) => {

    return {
        type: POST_PROJECT_NOT_COMPLETED_REQUEST,
        formData
    }
}
const resetProjectNotCompleted = () => {

    return {
        type: RESET_PROJECT_NOT_COMPLETED_STATE,

    }
}

export {
    projectNotCompleted,
    resetProjectNotCompleted,
    RESET_PROJECT_NOT_COMPLETED_STATE,
    POST_PROJECT_NOT_COMPLETED_REQUEST,
    POST_PROJECT_NOT_COMPLETED_REQUEST_SUCCESS,
    POST_PROJECT_NOT_COMPLETED_REQUEST_FAILURE,
};