import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../../URLs";
import { queryApi } from "../../../QueryApi";
import {
  POST_GET_MY_LEADS_REQUEST,
  POST_GET_MY_LEADS_REQUEST_SUCCESS,
  POST_GET_MY_LEADS_REQUEST_FAILURE,
} from "./Action";

function* handler() {
  yield takeEvery(POST_GET_MY_LEADS_REQUEST, getMyLeads);
}

function* getMyLeads(action) {
  try {
    // API call
    console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.GET_MY_LEADS,
      method: "POST",
      body: action.formData
    });
    console.log("HIT", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_GET_MY_LEADS_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_GET_MY_LEADS_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_GET_MY_LEADS_REQUEST_FAILURE,
      payload: { error:{message: err} },
    });
  }
}

export { handler };
