const POST_CHANGE_PASSWORD_REQUEST = "POST_CHANGE_PASSWORD_REQUEST";
const POST_CHANGE_PASSWORD_REQUEST_SUCCESS = "POST_CHANGE_PASSWORD_REQUEST_SUCCESS";
const POST_CHANGE_PASSWORD_REQUEST_FAILURE = "POST_CHANGE_PASSWORD_REQUEST_FAILURE";
const CHANGE_PASSWORD_RESET_STATE = "CHANGE_PASSWORD_RESET_STATE";

const changePassword = (formData) => {
  return {
    type: POST_CHANGE_PASSWORD_REQUEST,
    formData
  };
};


export {
  changePassword,
  POST_CHANGE_PASSWORD_REQUEST,
  POST_CHANGE_PASSWORD_REQUEST_SUCCESS,
  POST_CHANGE_PASSWORD_REQUEST_FAILURE,
  CHANGE_PASSWORD_RESET_STATE
};
