const POST_REJECT_PROJECT_REQUEST = 'POST_REJECT_PROJECT_REQUEST';
const POST_REJECT_PROJECT_REQUEST_SUCCESS = 'POST_REJECT_PROJECT_REQUEST_SUCCESS';
const POST_REJECT_PROJECT_REQUEST_FAILURE = 'POST_REJECT_PROJECT_REQUEST_FAILURE';
const RESET_REJECT_PROJECT_STATE = 'RESET_REJECT_PROJECT_STATE'

const rejectProject = (formData) => {

    return {
        type: POST_REJECT_PROJECT_REQUEST,
        formData
    }
}
const resetRejectProject = () => {

    return {
        type: RESET_REJECT_PROJECT_STATE,

    }
}
export {
    rejectProject,
    resetRejectProject,
    RESET_REJECT_PROJECT_STATE,
    POST_REJECT_PROJECT_REQUEST,
    POST_REJECT_PROJECT_REQUEST_SUCCESS,
    POST_REJECT_PROJECT_REQUEST_FAILURE,
};