const POST_CONTACT_US_REQUEST = "POST_CONTACT_US_REQUEST";
const POST_CONTACT_US_REQUEST_SUCCESS = "POST_CONTACT_US_REQUEST_SUCCESS";
const POST_CONTACT_US_REQUEST_FAILURE = "POST_CONTACT_US_REQUEST_FAILURE";
const RESET_CONTACT_US_STATE = "RESET_CONTACT_US_STATE";

const contactUsRequest = (formData) => {
  return {
    type: POST_CONTACT_US_REQUEST,
    formData
  };
};
const resetContactUs = () => {
  return {
    type: RESET_CONTACT_US_STATE,
  };
};


export {
  contactUsRequest,
  resetContactUs,
  RESET_CONTACT_US_STATE,
  POST_CONTACT_US_REQUEST,
  POST_CONTACT_US_REQUEST_SUCCESS,
  POST_CONTACT_US_REQUEST_FAILURE,
};
