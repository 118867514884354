const POST_CANCEL_SUBSCRIBE_USER_REQUEST = 'POST_CANCEL_SUBSCRIBE_USER_REQUEST';
const POST_CANCEL_SUBSCRIBE_USER_REQUEST_SUCCESS = 'POST_CANCEL_SUBSCRIBE_USER_REQUEST_SUCCESS';
const POST_CANCEL_SUBSCRIBE_USER_REQUEST_FAILURE = 'POST_CANCEL_SUBSCRIBE_USER_REQUEST_FAILURE';
const RESET_CANCEL_SUBSCRIBE_USER_STATE = 'RESET_CANCEL_SUBSCRIBE_USER_STATE'

const cancelSubscribeUser = (formData) => {

    return {
        type: POST_CANCEL_SUBSCRIBE_USER_REQUEST,
        formData
    }
}
const resetCancelSubscribeUser = () => {

    return {
        type: RESET_CANCEL_SUBSCRIBE_USER_STATE,

    }
}
export {
    cancelSubscribeUser,
    resetCancelSubscribeUser,
    RESET_CANCEL_SUBSCRIBE_USER_STATE,
    POST_CANCEL_SUBSCRIBE_USER_REQUEST,
    POST_CANCEL_SUBSCRIBE_USER_REQUEST_SUCCESS,
    POST_CANCEL_SUBSCRIBE_USER_REQUEST_FAILURE,
};