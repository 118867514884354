const POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST = 'POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST';
const POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST_SUCCESS = 'POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST_SUCCESS';
const POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST_FAILURE = 'POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST_FAILURE';
const RESET_RETRY_ON_SITE_VISIT_PAYMENT_STATE = 'RESET_RETRY_ON_SITE_VISIT_PAYMENT_STATE'

const retryOnSiteVisitPayment = (formData) => {

    return {
        type: POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST,
        formData
    }
}
const resetRetryOnSiteVisitPayment = () => {

    return {
        type: RESET_RETRY_ON_SITE_VISIT_PAYMENT_STATE,
    }
}

export {
    retryOnSiteVisitPayment,
    resetRetryOnSiteVisitPayment,
    RESET_RETRY_ON_SITE_VISIT_PAYMENT_STATE,
    POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST,
    POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST_SUCCESS,
    POST_RETRY_ON_SITE_VISIT_PAYMENT_REQUEST_FAILURE,
};