const POST_QUICK_QUOTE_RESCHEDULE_REQUEST = 'POST_QUICK_QUOTE_RESCHEDULE_REQUEST';
const POST_QUICK_QUOTE_RESCHEDULE_REQUEST_SUCCESS = 'POST_QUICK_QUOTE_RESCHEDULE_REQUEST_SUCCESS';
const POST_QUICK_QUOTE_RESCHEDULE_REQUEST_FAILURE = 'POST_QUICK_QUOTE_RESCHEDULE_REQUEST_FAILURE';
const RESET_QUICK_QUOTE_RESCHEDULE_STATE = 'RESET_QUICK_QUOTE_RESCHEDULE_STATE'

const quickQuoteReschedule = (formData) => {

    return {
        type: POST_QUICK_QUOTE_RESCHEDULE_REQUEST,
        formData
    }
}
const resetQuickQuoteReschedule = () => {

    return {
        type: RESET_QUICK_QUOTE_RESCHEDULE_STATE,

    }
}
export {
    quickQuoteReschedule,
    resetQuickQuoteReschedule,
    RESET_QUICK_QUOTE_RESCHEDULE_STATE,
    POST_QUICK_QUOTE_RESCHEDULE_REQUEST,
    POST_QUICK_QUOTE_RESCHEDULE_REQUEST_SUCCESS,
    POST_QUICK_QUOTE_RESCHEDULE_REQUEST_FAILURE,
};