const POST_EMPLOYEE_END_JOB_REQUEST = "POST_EMPLOYEE_END_JOB_REQUEST";
const POST_EMPLOYEE_END_JOB_REQUEST_SUCCESS = "POST_EMPLOYEE_END_JOB_REQUEST_SUCCESS";
const POST_EMPLOYEE_END_JOB_REQUEST_FAILURE = "POST_EMPLOYEE_END_JOB_REQUEST_FAILURE";
const RESET_EMPLOYEE_END_JOB_STATE = "RESET_EMPLOYEE_END_JOB_STATE";

const employeeEndJob = (formData) => {
  return {
    type: POST_EMPLOYEE_END_JOB_REQUEST,
    formData,
  };
};

const resetEmployeeEndJob = () => {
  return {
    type: RESET_EMPLOYEE_END_JOB_STATE,
  };
};
export {
  employeeEndJob,
  resetEmployeeEndJob,
  RESET_EMPLOYEE_END_JOB_STATE,
  POST_EMPLOYEE_END_JOB_REQUEST,
  POST_EMPLOYEE_END_JOB_REQUEST_SUCCESS,
  POST_EMPLOYEE_END_JOB_REQUEST_FAILURE,
};
