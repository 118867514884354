const POST_GET_PROJECT_REQUEST = 'POST_GET_PROJECT_REQUEST';
const POST_GET_PROJECT_REQUEST_SUCCESS = 'POST_GET_PROJECT_REQUEST_SUCCESS';
const POST_GET_PROJECT_REQUEST_FAILURE = 'POST_GET_PROJECT_REQUEST_FAILURE';
const RESET_GET_PROJECT_STATE = 'RESET_GET_PROJECT_STATE'

const getProject = (formData) => {

    return {
        type: POST_GET_PROJECT_REQUEST,
        formData
    }
}
const resetGetProject = () => {

    return {
        type: RESET_GET_PROJECT_STATE,

    }
}

export {
    getProject,
    resetGetProject,
    RESET_GET_PROJECT_STATE,
    POST_GET_PROJECT_REQUEST,
    POST_GET_PROJECT_REQUEST_SUCCESS,
    POST_GET_PROJECT_REQUEST_FAILURE,
};