const POST_GET_PAYOUT_METHOD_REQUEST = "POST_GET_PAYOUT_METHOD_REQUEST";
const POST_GET_PAYOUT_METHOD_REQUEST_SUCCESS = "POST_GET_PAYOUT_METHOD_REQUEST_SUCCESS";
const POST_GET_PAYOUT_METHOD_REQUEST_FAILURE = "POST_GET_PAYOUT_METHOD_REQUEST_FAILURE";
const RESET_GET_PAYOUT_METHOD_STATE = "RESET_GET_PAYOUT_METHOD_STATE";

const getPayoutMethod = (formData) => {
  return {
    type: POST_GET_PAYOUT_METHOD_REQUEST,
    formData
  };
};
const resetGetPayoutMethod = () => {
  return {
    type: RESET_GET_PAYOUT_METHOD_STATE,

  };
};
export {
  getPayoutMethod,
  resetGetPayoutMethod,
  RESET_GET_PAYOUT_METHOD_STATE,
  POST_GET_PAYOUT_METHOD_REQUEST,
  POST_GET_PAYOUT_METHOD_REQUEST_SUCCESS,
  POST_GET_PAYOUT_METHOD_REQUEST_FAILURE,
};
