const POST_REJECTS_CANCEL_REQUEST = 'POST_REJECTS_CANCEL_REQUEST';
const POST_REJECTS_CANCEL_REQUEST_SUCCESS = 'POST_REJECTS_CANCEL_REQUEST_SUCCESS';
const POST_REJECTS_CANCEL_REQUEST_FAILURE = 'POST_REJECTS_CANCEL_REQUEST_FAILURE';
const RESET_REJECTS_CANCEL_STATE = 'RESET_REJECTS_CANCEL_STATE'

const rejectsCancel = (formData) => {

    return {
        type: POST_REJECTS_CANCEL_REQUEST,
        formData
    }
}
const resetRejectsCancel = () => {

    return {
        type: RESET_REJECTS_CANCEL_STATE,

    }
}
export {
    rejectsCancel,
    resetRejectsCancel,
    RESET_REJECTS_CANCEL_STATE,
    POST_REJECTS_CANCEL_REQUEST,
    POST_REJECTS_CANCEL_REQUEST_SUCCESS,
    POST_REJECTS_CANCEL_REQUEST_FAILURE,
};