const POST_SERVICE_CATEGORY_REQUEST = "POST_SERVICE_CATEGORY_REQUEST";
const POST_SERVICE_CATEGORY_REQUEST_SUCCESS = "POST_SERVICE_CATEGORY_REQUEST_SUCCESS";
const POST_SERVICE_CATEGORY_REQUEST_FAILURE = "POST_SERVICE_CATEGORY_REQUEST_FAILURE";
const RESER_SERVICE_CATEGORY_STATE = "RESER_SERVICE_CATEGORY_STATE";

const getServiceCategory = (formData) => {
  return {
    type: POST_SERVICE_CATEGORY_REQUEST,
    formData
  };
};
const resetServiceCategory = () => {
  return {
    type: RESER_SERVICE_CATEGORY_STATE,
  };
};
export {
  getServiceCategory,
  resetServiceCategory,
  RESER_SERVICE_CATEGORY_STATE,
  POST_SERVICE_CATEGORY_REQUEST,
  POST_SERVICE_CATEGORY_REQUEST_SUCCESS,
  POST_SERVICE_CATEGORY_REQUEST_FAILURE,
};
