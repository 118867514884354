const POST_UPDATE_PAYMENT_REQUEST = 'POST_UPDATE_PAYMENT_REQUEST';
const POST_UPDATE_PAYMENT_REQUEST_SUCCESS = 'POST_UPDATE_PAYMENT_REQUEST_SUCCESS';
const POST_UPDATE_PAYMENT_REQUEST_FAILURE = 'POST_UPDATE_PAYMENT_REQUEST_FAILURE';
const RESET_UPDATE_PAYMENT_STATE = 'RESET_UPDATE_PAYMENT_STATE'

const updatePayment = (formData) => {

    return {
        type: POST_UPDATE_PAYMENT_REQUEST,
        formData
    }
};

const resetUpdatePayment = () => {

    return {
        type: RESET_UPDATE_PAYMENT_STATE
    }
}

export {
    updatePayment,
    resetUpdatePayment,
    RESET_UPDATE_PAYMENT_STATE,
    POST_UPDATE_PAYMENT_REQUEST,
    POST_UPDATE_PAYMENT_REQUEST_SUCCESS,
    POST_UPDATE_PAYMENT_REQUEST_FAILURE,
};