const POST_GET_ALL_EMPLOYEES_REQUEST = "POST_GET_ALL_EMPLOYEES_REQUEST";
const POST_GET_ALL_EMPLOYEES_REQUEST_SUCCESS = "POST_GET_ALL_EMPLOYEES_REQUEST_SUCCESS";
const POST_GET_ALL_EMPLOYEES_REQUEST_FAILURE = "POST_GET_ALL_EMPLOYEES_REQUEST_FAILURE";
const RESET_GET_ALL_EMPLOYEES_STATE = "RESET_GET_ALL_EMPLOYEES_STATE";

const getAllEmployee = () => {
  return {
    type: POST_GET_ALL_EMPLOYEES_REQUEST,
  };
};

const resetGetAllEmployee = () => {
  return {
    type: RESET_GET_ALL_EMPLOYEES_STATE,
  };
};
export {
  getAllEmployee,
  resetGetAllEmployee,
  RESET_GET_ALL_EMPLOYEES_STATE,
  POST_GET_ALL_EMPLOYEES_REQUEST,
  POST_GET_ALL_EMPLOYEES_REQUEST_SUCCESS,
  POST_GET_ALL_EMPLOYEES_REQUEST_FAILURE,
};
