const POST_RESEND_EMAIL_REQUEST = "POST_RESEND_EMAIL_REQUEST";
const POST_RESEND_EMAIL_REQUEST_SUCCESS = "POST_RESEND_EMAIL_REQUEST_SUCCESS";
const POST_RESEND_EMAIL_REQUEST_FAILURE = "POST_RESEND_EMAIL_REQUEST_FAILURE";
const RESET_RESEND_EMAIL_STATE = "RESET_RESEND_EMAIL_STATE";

const resendEmail = (formData) => {
  return {
    type: POST_RESEND_EMAIL_REQUEST,
    formData
  };
};
const resetResendndEmail = () => {
  return {
    type: RESET_RESEND_EMAIL_STATE,
  };
};

export {
  resendEmail,
  resetResendndEmail,
  RESET_RESEND_EMAIL_STATE,
  POST_RESEND_EMAIL_REQUEST,
  POST_RESEND_EMAIL_REQUEST_SUCCESS,
  POST_RESEND_EMAIL_REQUEST_FAILURE,
};
