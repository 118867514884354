const POST_GET_NOTIFICATIONS_REQUEST = "POST_GET_NOTIFICATIONS_REQUEST";
const POST_GET_NOTIFICATIONS_REQUEST_SUCCESS = "POST_GET_NOTIFICATIONS_REQUEST_SUCCESS";
const POST_GET_NOTIFICATIONS_REQUEST_FAILURE = "POST_GET_NOTIFICATIONS_REQUEST_FAILURE";
const RESET_GET_NOTIFICATIONS_STATE = "RESET_GET_NOTIFICATIONS_STATE";

const getNotifications = (formData) => {
  return {
    type: POST_GET_NOTIFICATIONS_REQUEST,
    formData
  };
};
const resetNotifications = () => {
  return {
    type: RESET_GET_NOTIFICATIONS_STATE,
  };
};


export {
  getNotifications,
  resetNotifications,
  RESET_GET_NOTIFICATIONS_STATE,
  POST_GET_NOTIFICATIONS_REQUEST,
  POST_GET_NOTIFICATIONS_REQUEST_SUCCESS,
  POST_GET_NOTIFICATIONS_REQUEST_FAILURE,
};
