const POST_PRO_CHAT_REQUEST = "POST_PRO_CHAT_REQUEST";
const POST_PRO_CHAT_REQUEST_SUCCESS = "POST_PRO_CHAT_REQUEST_SUCCESS";
const POST_PRO_CHAT_REQUEST_FAILURE = "POST_PRO_CHAT_REQUEST_FAILURE";
const RESET_PRO_CHAT_REQUEST_STATE = "RESET_PRO_CHAT_REQUEST_STATE";

const getProChat = (formData) => {
  return {
    type: POST_PRO_CHAT_REQUEST,
    formData,
  };
};
const resetGetProChat = () => {
  return {
    type: RESET_PRO_CHAT_REQUEST_STATE,
  };
};
export {
  getProChat,
  resetGetProChat,
  RESET_PRO_CHAT_REQUEST_STATE,
  POST_PRO_CHAT_REQUEST,
  POST_PRO_CHAT_REQUEST_SUCCESS,
  POST_PRO_CHAT_REQUEST_FAILURE,
};
