import { setItem } from "../../../../Utils/AsyncStore";
import {
  POST_USER_SOCIAL_REGISTER_REQUEST,
  POST_USER_SOCIAL_REGISTER_REQUEST_SUCCESS,
  POST_USER_SOCIAL_REGISTER_REQUEST_FAILURE,
  RESET_SOCIAL_REGISTER_STATE
} from "./Actions";

const initialState = {
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_SOCIAL_REGISTER_REQUEST: {
      console.log(POST_USER_SOCIAL_REGISTER_REQUEST);

      return {
        ...state,
        error: false,
        loading: true,
        success: false,
      };
    }
    case POST_USER_SOCIAL_REGISTER_REQUEST_SUCCESS: {
      const data = action.payload;
      console.log(POST_USER_SOCIAL_REGISTER_REQUEST_SUCCESS);
      setItem("registerUser",data)

      return {
        ...state,
        loading: false,
        success:true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case POST_USER_SOCIAL_REGISTER_REQUEST_FAILURE: {
      console.log(POST_USER_SOCIAL_REGISTER_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message:error?.message,
        errorData:error
      };
    }

    case RESET_SOCIAL_REGISTER_STATE: {
      console.log(RESET_SOCIAL_REGISTER_STATE);
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

export { reducer };
