const POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST = 'POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST';
const POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST_SUCCESS = 'POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST_SUCCESS';
const POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST_FAILURE = 'POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST_FAILURE';
const RESET_DELETE_CLOSED_PROJECT_BY_PRO_STATE = 'RESET_DELETE_CLOSED_PROJECT_BY_PRO_STATE'

const deleteClosedProject = (formData) => {

    return {
        type: POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST,
        formData
    }
}
const resetDeleteClosedProject = () => {

    return {
        type: RESET_DELETE_CLOSED_PROJECT_BY_PRO_STATE,

    }
}
export {
    deleteClosedProject,
    resetDeleteClosedProject,
    RESET_DELETE_CLOSED_PROJECT_BY_PRO_STATE,
    POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST,
    POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST_SUCCESS,
    POST_DELETE_CLOSED_PROJECT_BY_PRO_REQUEST_FAILURE,
};