import {
  PRO_DETAIL_REQUEST,
  PRO_DETAIL_REQUEST_SUCCESS,
  PRO_DETAIL_REQUEST_FAILURE,
  RESET_PRO_DETAIL_STATE
} from "./Action"

const initialState = {
  data: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRO_DETAIL_REQUEST: {
      console.log(PRO_DETAIL_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case PRO_DETAIL_REQUEST_SUCCESS: {
      console.log(PRO_DETAIL_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case PRO_DETAIL_REQUEST_FAILURE: {
      console.log(PRO_DETAIL_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: error?.message,
        errorData: error
      }
    }
    case RESET_PRO_DETAIL_STATE: {
      console.log(RESET_PRO_DETAIL_STATE);
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
