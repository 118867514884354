export default {


  app_name: 'U’GO Pros',
  facebook_app_id: '1464655393624471',
  facebook_client_token: 'f93f25880f86c822b0eb4da7147c18c6',
  fb_login_protocol_scheme: 'fb1464655393624471',
  Continue_with_Facebook: 'फेसबुक के साथ जारी रखें',
  Sign_in_with_Google: 'Google से साइन इन करें',
  create_account: 'अभी खाता बनाएं',
  create_account_: 'अभी खाता बनाएं',
  user_privacy: 'उपयोगकर्ता गोपनीयता बयान',
  terms_conditions: 'नियम और शर्तें',
  forgot_pass: 'पासवर्ड भूल गए?',
  terms: 'नियम और शर्तें',
  validationErrors: 'मान्यता त्रुटियां',
  meeting_note: '<b>ध्यान दें:</b> एक बार अनुमान आपके (उपयोगकर्ता) द्वारा स्वीकार कर लिए जाने के बाद, यह राशि फ़ाइल पर आपके भुगतान विधि से ली जाएगी। आप किसी भी समय किसी परियोजना को रद्द कर सकते हैं, लेकिन एक बार जब कोई अनुमान स्वीकार कर लिया जाता है और आप परियोजना को रद्द कर देते हैं तो आपसे उस अनुमान का 5% प्रसंस्करण शुल्क लिया जाएगा। परियोजना के अंत में भुगतान को अंतिम लागत के अनुसार समायोजित किया जाएगा।',
  bsb_number: 'राउटिंग नम्बर',
  account_number: 'खाता संख्या',
  account_name: 'खाते का नाम',
  txt_response_rate: 'आपने पिछले वर्ष में प्राप्त किए गए 0 बुकिंग अनुरोध में से 24 घंटे में से 0 में जवाब दिया।',
  below_are_the_cities: 'Below are the cities you selected, wish to continue?\n\n',
  sunday_text: 'रवि',
  monday_text: 'सोमवार',
  tuesday_text: 'मंगल',
  wednesday_text: 'बुध',
  thursday_text: 'गुरु',
  friday_text: 'शुक्र',
  saturday_text: 'बैठ गया',
  no_events: 'कोई आयोजन नहीं',
  call_failure: 'Failure!',

  invalid_attribute: 'कैलेंडर बनाने में विफल, गुण का \n\n अमान्य मान!, \n कृपया समीक्षा करें &amp; पुनः प्रयास करें!',
  your_earnings: 'आपकी कमाई तुरंत आपको मिल जाती है। साप्ताहिक भुगतान के लिए प्रतीक्षा करने की कोई आवश्यकता नहीं है। \n\n अपनी कमाई तब करें जब आप उन्हें चाहते हैं। इंस्टेंट पे के साथ कैश आउट प्रति दिन 5 बार तक।\n ',
  reviews_detail: 'समीक्षा विवरण',
  oops_enter_payout_method_: 'भुगतान विधि अनुपस्थित है। भुगतान पाने के लिए पेआउट विधि दर्ज करें। चिंता मत करो, आपकी जानकारी हमारे तीसरे पक्ष के भुगतान प्रसंस्करण के माध्यम से सुरक्षित है।',
  start_your_connection_with: 'अपने कनेक्शन के साथ शुरू करें',
  about_business: 'व्यापार के बारे में',
  done: 'किया हुआ',
  account_type: 'खाते का प्रकार',
  checking: 'चेकिंग',
  saving: 'सहेजा जा रहा है',
  business_checking: 'व्यवसाय की जाँच',
  save: 'सहेजें',
  ach_payment: 'ACH भुगतान',
  set_as_default: 'डिफाल्ट के रूप में सेट',
  feedback: 'प्रतिपुष्टि',
  tell_me_how_so_we_can_match_you_with_better_jobs: 'मुझे बताओ कि हम बेहतर जॉब्स के साथ मिल सकते हैं।',
  i_am_busy: 'मैं व्यस्त हूँ',
  wrong_area: 'गलत क्षेत्र',
  not_my_type_of_work: 'मेरे काम का प्रकार नहीं',
  submit: 'प्रस्तुत',
  actions: 'कार्रवाई',
  accept_amp_schedule: 'सीडीएटीए [स्वीकार और अनुसूची',
  reject: 'अस्वीकार',
  save_to_another: 'दूसरे को बचाओ',
  date: 'तारीख',
  request_details: 'अनुरोध विवरण',
  description: 'विवरण',
  media: 'मीडिया',
  time: 'समय',
  start_job: 'स्टार्ट जोब',
  enter_final_cost_optional: 'अंतिम लागत दर्ज करें (ऐच्छिक)',
  upload_work_photo: 'कार्य फोटो अपलोड करें',
  project_amount: 'प्रोजेक्ट राशि :',
  u_go_pros_fee: 'यू \' जाओ पेशेवरों शुल्क:',
  u_go_pros_fee_structure: 'U\'GO Pros शुल्क संरचना',
  you_will_receive: 'आप प्राप्त करेंगे:',
  If_these_times_are_not_convenient: 'यदि ये समय आपके लिए उपयुक्त नहीं हैं, तो उपभोक्ता के साथ चैट करें और ऐसा समय बताएं जो आप दोनों के लिए अच्छा हो',
  _0: '$0',
  _10: '10%',
  submit_when_completed: 'जब पूरा किया गया',
  chat: 'चैट',
  uploaded_pics: 'अपलोड की गई पिक्स',
  view_video: 'देखें वीडियो',
  contractor_note: 'ठेकेदार नोट',
  contractor_uploaded_pics: 'ठेकेदार अपलोड की गई पिक्स',
  location: 'स्थान',
  assign_job: 'सौंपना काम',
  change_employee: 'परिवर्तन कर्मचारी',
  project_already_assign: "परियोजना पहले से ही इस कर्मचारी को सौंपी गई है",
  send_official_cost: 'भेजने  सरकारी COST',
  new_project: 'नवीन व  परियोजना',
  add_document: 'दस्तावेज़  जोड़ें',
  max_limit_1: '(अधिकतम सीमा 1)',
  next: 'अगला',
  add_employee: 'जोड़ें  कर्मचारी',
  this_information_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'यह जानकारी कुछ सेकंड में सहेज ली जाएगी और उपर्युक्त रूप में प्रदान किए गए उपयोगकर्ता ईमेल पर भेज दी जाएगी।',
  show_us_more_about_your_project: 'हमें अपने प्रोजेक्ट के बारे में और दिखाएं',
  add_photo: 'तस्वीर जोड़ो',
  max_limit_5: '(अधिकतम सीमा 5)',
  add_video: 'वीडियो जोड़ें',
  add_work_video: ' काम वीडियो जोड़ें',
  max_duration_30sec: 'अधिकतम वीडियो अवधि 30 सेकंड',
  where_is_the_service_location: 'सेवा स्थान कहां है?',
  i_m_ready_for_quotes: 'मैं उद्धरणों के लिए बहुत खुश हूँ',
  review: 'समीक्षा',
  close: 'बंद करे',
  select_any_option: 'कोई भी विकल्प चुनें',
  view_photo: 'फ़ोटो देखें',
  view_photos: 'फोटो देखें',
  already_pay_out: 'पहले से ही पे आउट',
  _0_usd: '$0 USD',
  expected_earnings: 'प्रत्याशित आय',
  total: 'संपूर्ण',
  _0_project: '0 परियोजना',
  total_complete_project: 'कुल पूर्ण परियोजना',
  total_active_project: 'कुल सक्रिय परियोजना',
  _0_response_rate: '0% प्रतिक्रिया की दर',
  response_rate: 'प्रतिक्रिया की दर',
  send: 'भेजने',
  auto_services: 'ऑटो सेवा',
  project_details: 'परियोजना विवरण',
  hourly_price: 'प्रति घंटा मूल्य',
  n_a: 'एन / ए',
  portfolio: 'पोर्टफोलियो',
  default_album: 'डिफ़ॉल्ट एल्बम',
  recent_activities: 'हाल की गतिविधियां',
  _15_online_bids_100_response_rate: '15 ऑनलाइन बोलियां, 100% प्रतिक्रिया दर।',
  business_detail: 'व्यापार विस्तार',
  add_review: 'समीक्षा जोड़ें',
  reviews: 'समीक्षा',
  select_your_service_category: 'अपनी सेवा श्रेणी चुनें',
  change_password: 'पासवर्ड बदलें',
  change_password_mark: 'पासवर्ड बदलें?',
  please_enter_the_code_you_were_sent_by_email_and_reset_your_new_password: 'कृपया वह कोड दर्ज करें जो आपको ईमेल द्वारा भेजा गया था और अपना नया पासवर्ड रीसेट करें।',
  messages: 'संदेशों',
  contact_info: 'संपर्क सूचना',
  project_info: 'परियोजना की जानकारी',
  category: 'वर्ग',
  view_document: 'दस्तावेज़ देखें',
  budget_dolar: 'बजट $',
  min_budget: 'मिन बजट $',
  max_budget: 'अधिकतम बजट $',
  assigning_detail: 'विवरण सौंपना',
  bid_detail: 'बोली विस्तार से',
  notes: 'टिप्पणियाँ',
  contact_us: 'संपर्क करें',
  send_us_an_email: 'हमें एक ईमेल भेजो',
  let_us_know_what_you_think_don_t_hesitate_to_send_us_questions_or_comments_we_value_your_thoughts_and_will_respond_in_a_timely_manner: 'मुझे बताओ कि आप क्या सोचते हो! हमें सवाल या टिप्पणी भेजने में संकोच न करें। हम आपके विचारों को महत्व देते हैं, और समय पर जवाब देंगे!',
  bonded_insured: 'बंधुआ / बीमित',
  accredited: 'मान्यता प्राप्त',
  nari_member: 'नारी  सदस्य',
  service_area: 'सेवा क्षेत्र',
  speciality_services: 'विशेषता सेवाएँ',
  categories: 'श्रेणियाँ',
  add_utility_bill: 'उपयोगिता बिल जोड़ें',
  portfolio_optional: 'पोर्टफोलियो (ऐच्छिक)',
  by_creating_account_you_agree_to_our: 'खाता बनाकर आप हमारे लिए सहमत हैं',
  register: 'रजिस्टर करें',
  create_profile: 'प्रोफ़ाइल बनाने',
  date_of_birth: 'जन्म की तारीख',
  credits: 'क्रेडिट',
  u_go_reward_credits: 'यूगो रिवॉर्ड क्रेडिट्स',
  friend_referral: 'मित्र रेफरल',
  rate_contractor: 'दर ठेकेदार',
  _20_gift_card: '$ 20 उपहार कार्ड',
  _20_instantly_for_your_friend_and_20_for_you_once_their_project_completed: 'अपने दोस्त के लिए $ 20 तुरन्त और आपके प्रोजेक्ट के पूरा होने पर आपके लिए $ 20',
  redeem_credits: 'क्रेडिट को भुनाएं',
  add: 'जोड़ें',
  default_payment: 'डिफ़ॉल्ट भुगतान',
  documents: 'दस्तावेजों',
  edit_profile: 'प्रोफ़ाइल संपादित करें',
  add_view: 'जोड़ें / देखें',
  service: 'सर्विस',
  state: 'राज्य',
  city: 'City',
  update: 'अपडेट करें',
  employee: 'कर्मचारी',
  record_not_found: 'Record not found',
  employee_info: 'कर्मचारी  जानकारी',
  mobile_no: 'मोबाइल न',
  email: 'ईमेल',
  address: 'पता',
  delete: 'हटाएं',
  tell_us_more_about_your_project: 'हमें अपने प्रोजेक्ट के बारे में और बताएं',
  project_description: 'परियोजना विवरण',
  what_is_your_budget: 'आपका बजट क्या है?',
  what_is_your_contact_info: 'आपकी संपर्क जानकारी क्या है?',
  what_is_your_availability: 'आपकी उपलब्धता क्या है?',
  _1st_option_date: 'पहली विकल्प तिथि',
  _2nd_option_date: 'दूसरा विकल्प दिनांक',
  estimates: 'अनुमान',
  your_available_time: 'आपका उपलब्ध समय',
  best_case_scenario: 'बेहतरीन परिदृश्य',
  worst_case_scenario: 'सबसे खराब मामले की पृष्ठभूमि',
  exclusive_services: 'विशिष्ट सेवाएं',
  forgot_password: 'पासवर्ड भूल गए',
  forgot_password_mark: 'पासवर्ड भूल गए?',
  enter_your_email_below_to_receive_your_password_reset_instructions: 'अपना पासवर्ड रीसेट निर्देश प्राप्त करने के लिए अपना ईमेल नीचे दर्ज करें।',
  send_password: 'पासवर्ड भेजें',
  friends_referral: 'दोस्तों की राय',
  enter_friend_email_below_to_refer_your_friend: 'अपने मित्र को संदर्भित करने के लिए नीचे मित्र ईमेल दर्ज करें।',
  _20_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_home_user_n_n_50_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_pro_user: 'यदि आपका दोस्त होम यूज़र है, तो 20% आपके पहले प्रोजेक्ट के पूरा होने के बाद। \ n \ n $ 50 आपके दोस्त के लिए तुरंत, अपने पहले प्रोजेक्ट को पूरा करने के लिए अगर संदर्भित दोस्त प्रो यूजर है।',
  help: 'हाथ बटाना',
  faq: 'सामान्य प्रश्न',
  send_feedback: 'प्रतिक्रिया भेजें',
  home_services: 'गृह सेवा',
  status: 'स्थिति',
  final_amount: 'अंतिम राशि',
  note: 'ध्यान दें',
  login: 'लॉग इन करें',
  user: 'उपयोगकर्ता',
  remember_me: 'पहचाना की नहीं?',
  connect_with_facebook: 'फेस्बूक से जूडो',
  connect_with_google: 'के साथ कनेक्ट  गूगल',
  pro: 'समर्थक',
  map: 'नक्शा',
  time_availability: 'समय की उपलब्धता',
  accept: 'स्वीकार करते हैं',
  budget: 'बजट',
  click_to_request_on_site_visit: 'साइट यात्रा के लिए अनुरोध करने के लिए क्लिक करें',
  action: 'कार्य',
  i_am_not_interested: 'मुझे कोई दिलचस्पी नहीं है',
  i_am_interested: 'मुझे दिलचस्पी है',
  my_rewards: 'मेरी खबर',
  official_cost: 'सरकारी  लागत',
  your_official_price_can_be_set_by_you_when_you_arrive_before_project_starts_from_here_client_can_still_refuse_service_it_is_recommended_to_stay_within_the_range_you_set: 'प्रोजेक्ट शुरू होने से पहले पहुंचने पर आपकी आधिकारिक कीमत आपके द्वारा निर्धारित की जा सकती है। यहां से क्लाइंट अभी भी सेवा से इनकार कर सकता है। आपके द्वारा निर्धारित सीमा के भीतर रहने की सिफारिश की जाती है।',
  on_site_visit_requests: 'ON-SITE VISIT REQUESTS',
  pay_with_card: 'कार्ड के साथ भुगतान करें',
  paypal: 'paypal',
  free_transaction: 'निःशुल्क लेन-देन',
  no_minimum_transfer: 'कोई न्यूनतम स्थानांतरण नहीं',
  delete_project: 'हटाएँ  परियोजना',
  user_privacy_statement: 'उपयोगकर्ता  एकांत  बयान',
  credit_card: 'क्रेडिट कार्ड',
  bank_transfer: 'बैंक ट्रांसफर',
  country: 'देश',
  usa: 'अमेरीका',
  currency: 'मुद्रा',
  usd: 'USD',
  add_payout: 'पेआउट जोड़ें',
  schedules: 'अनुसूची',
  pro_detail: 'प्रो विस्तार',
  payout_detail: 'पेआउट डिटेल',
  account_info: 'खाता जानकारी',
  instant_payment: 'तुरंत भुगतान',
  routing_number: 'राउटिंग नम्बर',
  auto_renew_your_subscription: 'ऑटो आपकी सदस्यता को नवीनीकृत करता है',
  profile: 'प्रोफ़ाइल',
  projects: 'परियोजनाओं',
  reward: 'इनाम',
  credits_points: 'क्रेडिट अंक',
  enter_Pin: 'पिन दर्ज करें',
  view_detail: 'विस्तार से देखें',
  cancel_project: 'रद्द परियोजना',
  edit_project: 'परियोजना संपादित करें',
  reschedule_project: 'परिणाम परियोजना',
  my_project: 'मेरी परियोजना',
  you_have_not_completed_any_project_yet: 'आपने अभी तक कोई प्रोजेक्ट पूरा नहीं किया है',
  speciality_services_pro: 'स्पेशलिटी सर्विसेज प्रो',
  available_time: 'उपलब्ध समय',
  decline: 'पतन',
  min_budget_normal: 'न्यूनतम बजट',
  max_budget_normal: 'अधिकतम बजट',
  review_detail: 'विस्तार से समीक्षा करें',
  use_amount_from_credits: 'क्रेडिट से राशि का उपयोग करें',
  your_availability_time_preference: 'आपकी उपलब्धता - समय वरीयता',
  specialty_service_partners: 'विशेषता सेवा पार्टनर',
  special_pro_detail: 'विशेष  प्रो  विवरण',
  email_col: 'ईमेल:',
  mobile_no_col: 'मोबाइल नहीं है:',
  service_col: 'सर्विस:',
  state_col: 'राज्य:',
  cities_col: 'शहरों:',
  detail: 'विवरण',
  request_info: 'अनुरोध जानकारी',
  phone: 'फ़ोन',
  select_cities_you_offer_your_services: 'उन शहरों का चयन करें जो आप अपनी सेवाओं की पेशकश करते हैं',
  specialty_service: 'विशेषता सेवा:',
  connecting_consumers_with: 'उपभोक्ताओं को साथ जोड़ना',
  highly_skilled_industry_professionals_in_their_communities: 'अपने समुदायों में अत्यधिक कुशल उद्योग पेशेवर।',
  are_you_looking_to_have_a_project_completed: 'क्या आप कोई प्रोजेक्ट पूरा करना चाहते हैं',
  or: 'या',
  sign_up_as_an_industry_professional: 'एक उद्योग पेशेवर के रूप में साइन अप करें?',
  select_all_categories_below: 'नीचे सभी श्रेणियों का चयन करें',
  employee_contact_info: 'कर्मचारी संपर्क जानकारी',
  name: 'नाम',
  company: 'कंपनी',
  min_bid_amount: 'न्यूनतम बोली राशि',
  max_bid_amount: 'अधिकतम बोली राशि',
  confirm_project: 'प्रोजेक्ट की पुष्टि करें',
  send_issue: 'भेजने  समस्या',
  accept_official_cost: 'स्वीकार करते हैं सरकारी  लागत',
  subscription: 'अंशदान',
  cancel: 'रद्द करना',
  terms_and_conditions: 'नियम और शर्तें',
  skip: 'छोड़ें',
  signup: 'साइन अप करें',
  edit_info: 'संपादित करें  जानकारी',
  this_inform_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'यह सूचना कुछ सेकंड में सहेज ली जाएगी और उपर्युक्त रूप में प्रदान किए गए उपयोगकर्ता ईमेल पर भेज दी जाएगी.',
  upload_pictures: 'डालना  चित्रों',
  upload: 'डालना',
  video: 'वीडियो',
  wire: 'वायर',
  _20_transaction_fee: '$20 लेनदेन शुल्क.',
  minimum_transaction_250: 'न्यूनतम लेनदेन $250',
  account_holder: 'खाता धारक',
  bank_address: 'बैंक का पता',
  withdraw: 'हटें',
  available_balance: 'उपलब्ध शेष राशि',
  withdraw_amount: 'निकासी की राशि',
  services_offered: 'सेवाएं दी गईं',
  become_a_pro: 'एक समर्थक प्रो',
  add_event: 'कार्यक्रम जोड़ें',
  get_free_quotes: 'मुफ़्त उद्धरण प्राप्त करें',
  tell_us_about_the_project_you_would_like_completed_and_we_will_connect_you_with_several_industry_professionals_in_your_community_you_can_then_read_through_their_reviews_and_select_the_professional_to_work_on_your_project: 'हमें उस परियोजना के बारे में बताएं जिसे आप पूरा करना चाहते हैं और हम आपको अपने समुदाय के कई उद्योग पेशेवरों से जोड़ेंगे। फिर आप उनकी समीक्षाओं को पढ़ सकते हैं और अपनी परियोजना पर काम करने के लिए पेशेवर का चयन कर सकते हैं।',
  use_instant_request: 'उपयोग की आवश्यकता है',
  in_a_hurry_or_have_an_emergency_project_tell_us_more_about_the_project_and_we_will_quickly_connect_you_to_the_first_available_professional_usually_within_2_hours: 'जल्दी में या एक आपातकालीन परियोजना है? हमें प्रोजेक्ट के बारे में अधिक बताएं और हम आपको पहले उपलब्ध पेशेवर (आमतौर पर 2 घंटे के भीतर) से कनेक्ट करेंगे।',
  select_this_option_for_a_directory_list_of_our_specialty_professional_partners_in_your_city_find_your_lawyers_realtors_insurance_agents_doctors_etc: 'अपने शहर में हमारे विशेष व्यावसायिक भागीदारों की निर्देशिका सूची के लिए इस विकल्प का चयन करें। अपने वकीलों, Realtors, बीमा एजेंटों, डॉक्टरों आदि का पता लगाएं।',
  instant_requests: 'INSTANT REQUESTS',
  leads: 'नेतृत्व',
  invoices: 'चालान',
  settings: 'समायोजन',
  downloading: 'डाउनलोड हो रहा है ...',
  select_cities: 'शहरों का चयन करें',
  are_you_sure_you_want_to_move_back: 'क्या आप वाकई वापस जाना चाहते हैं?',
  you_will_lose_your_progress: 'आप अपनी प्रगति खो देंगे।',
  no: 'नहीं न',
  yes: 'हाँ',
  your_account_is_under_review_please_allow_24_28: 'आपके खाते की समीक्षा चल रही है। कृपया पुष्टि ईमेल प्राप्त करने के लिए 24-28 घंटे का समय दें। किसी भी खाते के सवालों के साथ support@ugopros.com से संपर्क करें।',
  payout_detail_confirmation: 'पेआउट डिटेल कन्फर्मेशन',
  please_confirm_all_info_entered_is_correct_we_want_to_make_sure_you_get_paid_for_the_projects_you_complete: 'कृपया पुष्टि करें कि दर्ज की गई सभी जानकारी सही है। हम यह सुनिश्चित करना चाहते हैं कि आपके द्वारा पूरी की जाने वाली परियोजनाओं के लिए आपको भुगतान किया जाए।',
  rate_user: 'उपयोगकर्ता दर',
  _20_instantly_for_you_and_your_friend_on_first_project_completion_if_the_referred_friend_is_home_user_n_50_instantly_for_you_on_first_project_completion_if_the_referred_friend_is_pro_user: '$ 20 आप और आपके दोस्त के लिए तुरन्त पहली परियोजना के पूरा होने पर अगर निर्दिष्ट दोस्त होम उपयोगकर्ता है। \ n $ 50 आपके लिए पहले प्रोजेक्ट के पूरा होने पर तुरंत यदि संदर्भित दोस्त प्रो उपयोगकर्ता है।',
  add_credit_for_the_bigger_jobs_pay_now_for_jobs_later: 'बड़ी नौकरियों के लिए क्रेडिट जोड़ें, बाद में नौकरियों के लिए भुगतान करें।',
  add_credits: 'जोड़ें क्रेडिट',
  no_default_card: 'कोई डिफ़ॉल्ट कार्ड नहीं',
  add_payment_method: 'भुगतान विधि जोड़ें',
  payment_method: 'भुगतान विधि',
  payment_history: 'भुगतान इतिहास',
  add_credit_card: 'क्रेडिट कार्ड जोड़ें',
  add_credit_gift_code: 'क्रेडिट / उपहार कोड जोड़ें',
  apply: 'लागू',
  payment_defaults: 'भुगतान की कमी',
  set_payment_default: 'डिफ़ॉल्ट भुगतान सेट करें',
  default_payment_method: 'डिफ़ॉल्ट भुगतान विधि',
  amount: 'रकम',
  view: 'रे',
  revenue_detail: 'समीक्षा विवरण',
  total_revenue: 'कुल मुनाफा',
  total_withdraw: 'संपूर्ण  हटें',
  payable_balance: 'देय शेष राशि',
  pending_withdraw: 'वापस लेना',
  new_: 'नवीन व',
  active: 'सक्रिय',
  completed: 'पूरा किया हुआ',
  notification: 'अधिसूचना',
  message: 'संदेश',
  write_your_message: 'अपना संदेश लिखें',
  sign_out: 'प्रस्थान करें',
  my_projects: 'मेरी परियोजनाएं',
  site_visit_request: 'साइट विज़ट रिपोर्ट',
  archived: 'संग्रहीत',
  canceled: 'रद्द',
  consumer_benefits: 'उपभोक्ता लाभ',
  normal: 'साधारण',
  specialty: 'स्पेशलिटी',
  invoiced: 'चालान की गई',
  paid: 'भुगतान किया है',
  enter_your_issue_detail: 'अपनी समस्या विस्तार से दर्ज करें',
  add_new_event: 'नई घटना जोड़ें',
  tap_on_date_to_view_schedule: 'शेड्यूल देखने के लिए दिनांक पर टैप करें।',
  my_earnings: 'मेरी कमाई',
  withdraw_history: 'इतिहास वापस लेना',
  waiting_for_response: 'प्रतिक्रिया के लिए प्रतीक्षा कर रहा हूँ',
  confirm: 'पुष्टि करें',
  would_you_like_to_create_a: 'क्या आप एक बनाना चाहेंगे',
  Pin: 'नत्थी करना?',
  enter_pin: 'पिन दर्ज करें',
  enter_password: 'पास वर्ड दर्ज करें',
  use_different_account: 'USE DIFFERENT ACCOUNT',
  u_go_pros_inc: 'U\'GO Pros Inc',

  pay: 'भुगतान कर',
  next_payment_schedule: 'अगला भुगतान अनुसूची',
  credit: 'श्रेय',
  debit: 'डेबिट',
  continue_: 'जारी रखें',
  schedule: 'अनुसूची',
  payment: 'भुगतान',
  change_pin: 'पिन बदलिए',
  notifications: 'सूचनाएं',
  pin: 'पिन',
  online_status: 'ऑनलाइन स्थिति',
  email_message: 'विद्युतडाक संदेश',
  add_edit: 'जोड़ें  / संपादित करें',
  selected_categories: 'चयनित श्रेणियों',
  dismiss: 'खारिज',
  add_more: 'अधिक जोड़ें',
  project: 'परियोजना',
  earning: 'कमाई',
  withdraw_status: 'हटें  स्थिति',
  project_leads: 'परियोजना का नेतृत्व किया',
  offline_mode: 'ऑफ़लाइन मोड',
  ugo_to: '$ तक',
  pay_dol: '$ का भुगतान करें',
  are_you_sure_you_want_to_credit: 'क्या आप सुनिश्चित हैं कि आप $ क्रेडिट करना चाहते हैं',
  are_you_sure_you_want_to_delete: 'क्या आप वाकई इस प्रोजेक्ट को सूची से हटाना चाहते हैं?',
  logout: 'लॉग आउट',
  complete: 'पूर्ण',
  please_complete_your_profile: 'कृपया अपना प्रोफ़ाइल पूरा करें।',
  ready_for_quotes: 'उद्धरण के लिए तैयार है',
  estimates_received: 'प्राप्त अनुमान',
  pending_for_official_cost: 'आधिकारिक लागत के लिए लंबित',
  project_started: 'प्रोजेक्ट शुरू हुआ',
  please_confirm_project: 'कृपया प्रोजेक्ट की पुष्टि करें',
  successfully_completed: 'सफलतापूर्वक Completed',
  before_payment: 'भुगतान से पहले',
  after_payment: 'भुगतान के बाद',
  issue_reported: 'रिपोर्ट जारी की',
  refunded_by_admin: 'व्यवस्थापक द्वारा वापसी की गई',
  official_cost_recieved: 'आधिकारिक लागत प्राप्त हुई',
  waiting_for_pro_to_start: 'प्रो से शुरू होने का इंतजार है',
  please_wait: 'कृपया प्रतीक्षा करें...',
  waiting_for_pros_response: 'प्रतिसाद के लिए प्रतीक्षा कर रहा है',
  started_by_pro: 'प्रो द्वारा शुरू किया गया',
  need_for_rechedule: 'पुनर्निर्धारित की आवश्यकता',
  project_canceled: 'प्रोजेक्ट रद्द कर दिया गया',
  create_your_pin: 'अपना पिन बनाएं',
  please_select_what_you_want_to_do: 'कृपया चुनें कि आप क्या करना चाहते हैं',
  remove_change_pin: 'पपिन हटाएं/बदलें?',
  remove_pin: 'पिन निकालें',
  successfully_scheduled: 'v',
  project_accepted: 'परियोजना  को स्वीकृत',
  accepted_by_pro: 'प्रो द्वारा स्वीकार किए जाते हैं',
  image_attched: 'छवि संलग्न',
  video_attached: 'वीडियो संलग्न है',
  valid_till: 'तक मान्य : ',
  rejected: 'ठुकरा दिया',
  confirmed: 'की पुष्टि की',
  are_you_sure_you_want_to_reject: 'क्या आप सुनिश्चित हैं कि आप ऑन-साइट यात्रा अनुरोध को अस्वीकार करना चाहते हैं',
  are_you_sure_you_want_confirmed: 'क्या आप सुनिश्चित हैं कि आप साइट पर साइट के अनुरोध की पुष्टि करना चाहते हैं',
  attached: '\" Attached\"',
  cannot_upload_file_to_server: 'फ़ाइल को सर्वर पर अपलोड नहीं किया जा सकता',
  update_review: 'अद्यतन समीक्षा',
  response_rate_per: '% प्रतिक्रिया की दर',
  add_debit_card: 'डेबिट कार्ड जोड़ें',
  add_payout_method: 'पेआउट विधि जोड़ें',
  update_video: 'वीडियो अपडेट करें',
  enter_friend_email_user: 'अपने मित्र को उपयोगकर्ता के रूप में संदर्भित करने के लिए नीचे मित्र ईमेल दर्ज करें।',
  enter_friend_email_pro: 'अपने मित्र को प्रो के रूप में संदर्भित करने के लिए नीचे मित्र ईमेल दर्ज करें।',
  credit_dol: 'क्रेडिट: $',
  free: 'नि: शुल्क',
  are_you_sure_you_want_delete: 'क्या आप वाकई परियोजना को हटाना चाहते हैं?',
  are_you_sure_too_cancel: 'क्या आप वाकई इस प्रोजेक्ट को रद्द करना चाहते हैं?',
  rating: 'रेटिंग',
  total_credits: 'कुल क्रेडिट:',
  cities: 'शहरों',
  the_amount_you_have_submitted: 'आपने जो राशि जमा की है ($',
  if_you_agree_to_the_official: ') सरकारी लागत से कम है। यदि आप आधिकारिक लागत से सहमत हैं तो कृपया वेतन विकल्प दबाएं अन्यथा कोई समस्या होने पर हमें अस्वीकार या संपर्क करें।',
  refund_dol: 'वापसी $',
  the_amount_you_have: 'राशि जो आपने $ जमा की है',
  otherwise_rejected_or_contact_us: '\"आधिकारिक लागत से अधिक है। यदि आप सहमत हैं तो REFUND विकल्प दबाएं, अन्यथा कोई प्रश्न होने पर हमें अस्वीकार या संपर्क करें \"',
  if_there_are_any_problems: 'आपके द्वारा जमा की गई राशि परियोजना की आधिकारिक लागत के बराबर है। अगर सब कुछ ठीक है तो कृपया नीचे पुष्टि करें, अन्यथा कोई समस्या होने पर हमसे संपर्क करें।',
  are_you_sure_you_want_cancel: 'क्या आप वाकई प्रोजेक्ट को रद्द करना चाहते हैं?',
  if_you_submit_you_will_refund: 'यदि आप जमा करते हैं तो आपको अपने धनवापसी से 5% प्रोसेसिंग शुल्क लिया जाएगा।',
  are_you_sure_you_want_to_confirm: 'क्या आप वाकई प्रोजेक्ट की पुष्टि करना चाहते हैं?',
  pro_will_be_paid_for_this: 'इसके लिए प्रो को भुगतान किया जाएगा।',
  pending: 'विचाराधीन',
  accepted: 'को स्वीकृत',
  referral_code: 'रेफरल कोड',
  expiry_col: 'समाप्ति:',
  next_payment_schedule_col: '"अगला भुगतान अनुसूची: "',
  status_active: 'स्थिति: सक्रिय',
  status_cancelled: 'स्थिति: रद्द की गई',
  update_payout: 'पेआउट अपडेट करें',
  please_add_it_by_pressing: 'आपने अभी तक भुगतान विधि नहीं जोड़ी है। कृपया नीचे में बटन दबाकर इसे जोड़ें.',
  onsite_visit_request_sent: 'साइट पर जाएँ अनुरोध भेजा गया',
  onsite_visit_request_rejected: 'साइट पर जाएँ अनुरोध अस्वीकृत',
  onsite_visit_scheduled: 'साइट पर अनुसूचित यात्रा',
  waiting_for_acceptance: 'स्वीकृति की प्रतीक्षा है',
  qoute_and_onsite_visit: 'उद्धरण और साइट पर यात्रा अनुरोध भेजा गया',
  qoute_sent: 'भाव भेजा गया',
  waiting_for_official_cost: 'आधिकारिक लागत की प्रतीक्षा की जा रही है',
  assigned_to_employee: 'कर्मचारी को सौंपा',
  rejected_by_employee: 'कर्मचारी द्वारा अस्वीकृत',
  job_started: 'नौकरी शुरू हुई',
  waiting_for_confirmation: 'पुष्टि के लिए प्रतीक्षा कर रहा है',
  official_cost_sent: 'आधिकारिक लागत भेजी गई',
  project_awarded: 'प्रोजेक्ट से सम्मानित किया गया',
  in_progress: 'चालू',
  closed: 'बंद किया हुआ',
  bid_amount: 'बोली की रक़म: $',
  exit: 'बाहर जाएं',
  enter_old_pin: 'पुराना पिन दर्ज करें',
  confirm_pin: 'पिन की पुष्टि करें',
  back: 'वापस',
  wrong_pin_keyboard: 'गलत पिन कीबोर्ड',
  enter_new_pin: 'नया पिन दर्ज करें',
  wrong_pin_locked: 'गलत पिन लॉक',
  user_guide: 'उपयोगकर्ता गाइड',
  job_no: 'काम #',
  project_col: 'Project :',
  posted_by_col: '"द्वारा प्रकाशित किया गया था : "',
  online_mode: 'ऑनलाइन मोड',
  are_you_sure_accept: 'क्या आप वाकई स्वीकार करना चाहते हैं?',
  are_you_sure_want_reject: 'क्या आप सुनिश्चित हैं कि आप अस्वीकार करना चाहते हैं',
  assign_to_col: '" के लिए आवंटित:  "',
  status_assigned: 'स्थिति: सौंपी गई',
  status_rejected: 'स्थिति: अस्वीकृत',
  status_waiting: 'स्थिति: पुष्टि की प्रतीक्षा है',
  final_amount_col: 'अंतिम राशि: $',
  issue_detail: 'विस्तार से जारी करें',
  official_cost_dol: 'आधिकारिक लागत : $',
  project_title_col: '"परियोजना का शीर्षक: "',
  photos_attached: 'फ़ोटो जुड़ी हुई',
  video_attached: 'वीडियो जोड़ा गया',
  document_attached: 'दस्तावेज़ जोड़ा गया',
  please_confirm_final_amount: 'कृपया $ की अंतिम राशि की पुष्टि करें',
  once_you_tap_confirm_you_will: '. एक बार जब आप "पुष्टि" पर टैप करते हैं, तो आप अंतिम लागत को बदलने में असमर्थ होंगे',
  employee_note: 'कर्मचारी नोट',
  employee_upload_pics: 'कर्मचारी अपलोड की गई पिक्स',
  official_document: 'सरकारी दस्तावेज़',
  are_you_sure_you_want_to_delete_emp: 'क्या आप वाकई इस कर्मचारी को हटाना चाहते हैं?',
  employee_since: '"के बाद से कर्मचारी "',
  are_you_sure_to_assign: 'क्या आप वाकई प्रोजेक्ट को असाइन करना चाहते हैं?',
  i_am_intrested: 'क्या आप वाकई साइट विज़िट पर $ 10 का भुगतान करना चाहते हैं? वह शुल्क तभी वापस किया जाएगा जब ग्राहक आपकी बोली स्वीकार करेगा। \ n \ n यदि आप साइट की यात्रा के बिना मुफ्त में अनुमान भेजना चाहते हैं तो रद्द करें पर टैप करें और स्क्रीन के नीचे "I AM INTERESTED" चुनें।',
  pay_again: 'फिर से भुगतान करें',
  please_recharge_your_account: 'आपके खाते में अपर्याप्त शेष राशि के कारण आपका ऑन-साइट विज़िट अनुरोध अस्वीकार कर दिया गया था। कृपया अपना खाता रिचार्ज करें और पुनः प्रयास करें।',
  your_on_site_visit: 'आपका ऑन-साइट विज़िट अनुरोध अस्वीकार कर दिया गया था और आपसे $ 10 का शुल्क नहीं लिया जाएगा.',
  budget_dol: 'बजट: $',
  please_confirm_if_you_will: 'उपयोगकर्ता की आवश्यकता है कि परियोजना 2 घंटे के भीतर शुरू हो जाए। कृपया "पुष्टि करें" यदि आप उपलब्ध होंगे',
  debit_card: 'डेबिट कार्ड',
  resubscribe: 'पुन: सदस्यता',
  project_no: '"प्रोजेक्ट # "',
  i_am_ready: 'मैं एक प्रो के साथ जुड़ने के लिए तैयार हूँ',
  edit: 'संपादित करें',
  language: 'भाषा: हिन्दी',
  english: 'English',
  spanish: 'Spanish',
  tamil: 'Tamil',
  hindi: 'Hindi',
  arabic: 'Arabic',
  contractor_notes_n_if_project: 'कांट्रेक्टर नोट्स \ n (यदि प्रोजेक्ट अभी पूरा नहीं हुआ है जब आप फिर से काम करेंगे तो बाद में फिर से काम शुरू करेंगे)',
  first_name: 'नाम',
  last_name: 'उपनाम',
  password: 'कुंजिका',
  confirm_password: 'पासवर्ड की पुष्टि कीजिये',
  confirm_password_optional: 'पासवर्ड की पुष्टि करें (वैकल्पिक)',
  verification_code: 'पुष्टि संख्या',
  verify_pin: 'पिन सत्यापित करें',
  new_password: 'नया पासवर्ड',
  your_name: 'तुम्हारा नाम',
  contact_person_first_name: 'संपर्क व्यक्ति का पहला नाम',
  contact_person_last_name: 'संपर्क व्यक्ति का अंतिम नाम',
  company_name: 'कंपनी का नाम',
  company_ein_tax_id: 'कंपनी EIN / टैक्स आईडी',
  licence_no_optional: 'लाइसेंस संख्या (वैकल्पिक)',
  tap_to_create_project: 'प्रोजेक्ट बनाने के लिए टैप करें',
  home_auto_or_exclusive_services: 'गृह, ऑटो या विशेष सेवाएं',
  friend_pro_referral: 'मित्र / प्रो रेफरल',
  enter_title: 'शीर्षक दर्ज करें',
  account_iban: 'Account/ IBAN',
  swift_bic_code: 'Swift/ Bic code',
  new_password_optional: 'नया पासवर्ड (वैकल्पिक)',
  reach_out_directly_with_this: 'यहाँ चैट करके सीधे इस प्रो के साथ पहुँचें। बस इस बॉक्स में टाइप करना शुरू करें और काम पूरा होने पर पुष्टि करें',
  company_phone_number: 'कंपनी का फोन नंबर',
  paypal_account: 'Paypal Account',
  card_number: 'कार्ड संख्या',
  enter_project_description: 'प्रोजेक्ट विवरण दर्ज करें',
  please_enter_project_description: 'कृपया परियोजना विवरण दर्ज करें।',
  please_provide_information: 'कृपया पिछली परियोजनाओं और अपनी कंपनी के बारे में जानकारी प्रदान करें। आपके पास कोई भी प्रश्न शामिल हो सकता है।',
  hourly_estimate_optional: 'प्रति घंटा अनुमानित $ (वैकल्पिक)',
  total_min_estimate_best_case_scenario: 'कुल न्यूनतम अनुमान $ (सर्वश्रेष्ठ मामला परिदृश्य)',
  total_max_estimate_worst_case_scenario: 'कुल अधिकतम अनुमानित $ (सबसे खराब मामला परिदृश्य)',
  please_name_your_project: 'कृपया अपने प्रोजेक्ट का नाम बताएं',
  social_security_number: 'सामाजिक सुरक्षा संख्या',
  years_in_business: 'सालो से वयव्साय में',
  old_password_optional: 'पुराना पासवर्ड (वैकल्पिक)',
  other_category_optional: 'अन्य श्रेणी (वैकल्पिक)',
  wrong_email_password: 'गलत ईमेल / पासवर्ड',
  please_enter_your_email: 'अपना ईमेल दर्ज करें',
  please_enter_your_name: 'अपना नाम दर्ज करें',
  please_enter_your_project_name: 'कृपया अपना प्रोजेक्ट नाम दर्ज करें',
  please_enter_discription: 'कृपया प्रोजेक्ट विवरण दर्ज करें',
  please_enter_your_min: 'कृपया अपना न्यूनतम बजट दर्ज करें $',
  please_enter_your_max_budget: 'कृपया अपना अधिकतम बजट दर्ज करें $',
  please_enter_your_valid_min: 'कृपया अपने वैध न्यूनतम बजट $ दर्ज करें।',
  please_enter_your_max_valid_budget: 'कृपया अपने वैध अधिकतम बजट $ दर्ज करें।',
  min_budget_should_less_than_max: 'न्यूनतम बजट अधिकतम बजट से कम होना चाहिए',
  please_enter_your_budget: 'कृपया अपना बजट दर्ज करें $',
  please_enter_your_budget_at_least_1: 'कृपया अपना बजट कम से कम दर्ज करें $1',
  please_select_date_before_time: 'कृपया निर्धारित समय से पहले तारीख का चयन करें',
  no_user_found: 'कोई उपयोगकर्ता नहीं मिला',
  please_enter_your_message: 'कृपया अपना संदेश लिखें',
  please_enter_valid_email: 'कृपया वैध ईमेल दर्ज़ करें',
  please_enter_your_card_no: 'कृपया अपना कार्ड नंबर दर्ज करें',
  please_enter_cvc: 'कृपया अपना सीवीसी दर्ज करें',
  please_select_future_date: 'कृपया भविष्य की तारीख चुनें',
  pics_not_available: 'चित्र उपलब्ध नहीं है!',
  document_not_available: 'दस्तावेज़ उपलब्ध नहीं है!',
  video_not_available: 'वीडियो उपलब्ध नहीं है!',
  please_enter_official_cost: 'कृपया आधिकारिक लागत जोड़ें।',
  please_add_official_cost: 'कृपया कम से कम आधिकारिक लागत जोड़ें',
  please_enter_your_password: 'अपना पासवर्ड दर्ज करें',
  password_dose_not_matched: 'पासवर्ड मैच नहीं कर रहा है!',
  please_enter_your_first_name: 'कृपया अपना पहला नाम दें',
  please_enter_your_last_name: 'कृपया अपना अंतिम नाम दर्ज करें',
  please_enter_your_phone: 'कृपया अपना फ़ोन दर्ज करें',
  please_enter_password: 'Please Enter Your Password',
  password_must_contain_a_minimum: 'पासवर्ड में न्यूनतम 8 वर्ण होने चाहिए',
  pin_successfully_change: 'पिन सफलतापूर्वक बदल गया',
  please_enter_your_confirm_password: 'कृपया अपना पासवर्ड दर्ज करें',
  please_enter_your_address: 'कृपया अपना पता दर्ज करें',
  phone_length_10: 'फोन # लंबाई 10 होनी चाहिए',
  please_enter_contact_first_name: 'कृपया संपर्क व्यक्ति का पहला नाम दर्ज करें',
  please_enter_contact_lastname: 'कृपया संपर्क व्यक्ति का अंतिम नाम दर्ज करें',
  password_dose_not_match: 'पासवर्ड की पुष्टि नहीं है',
  please_enter_dob: 'कृपया जन्म तिथि दर्ज करें।',
  please_enter_company_name: 'कृपया कंपनी का नाम दर्ज करें',
  please_enter_mobile_number: 'कृपया मोबाइल नंबर दर्ज करें',
  please_enter_address: 'कृपया पता दर्ज करें',
  please_enter_contractor_notes: 'कृपया ठेकेदार नोट दर्ज करें',
  please_enter_at_least_one_work_photo: 'कृपया कम से कम एक कार्य फ़ोटो अपलोड करें',
  you_must_be_18: 'हमारी सेवाओं का उपयोग करने के लिए आपकी आयु 18 वर्ष या उससे अधिक होनी चाहिए। यदि आपके पास कोई समस्या है, तो कृपया हमें support@ugopros.com पर संपर्क करें',
  please_agree_to_our: 'कृपया हमारे नियम और शर्तों से सहमत हों',
  please_select_your_available: 'कृपया अपने उपलब्ध समय का चयन करें',
  please_enter_issue_detail: 'कृपया समस्या विस्तार से दर्ज करें',
  please_add_profile_photo: 'कृपया प्रोफाइल फोटो जोड़ें',
  please_set_your_loaction: 'कृपया अपना स्थान निर्धारित करें',
  please_select_address: 'कृपया पता चुनें',
  please_select_address_from: 'कृपया ड्रॉप डाउन सूची से पता चुनें',
  please_add_availability_date: 'कृपया उपलब्धता की तारीख जोड़ें',
  please_add_available_time: 'कृपया उपलब्धता समय जोड़ें',
  enter_address_or_single_press_on_map: 'मानचित्र पर पता या एकल प्रेस दर्ज करें',
  type_to_search: 'खोजने के लिए लिखें',
  _3rd_option_date: 'तीसरा विकल्प दिनांक',
  you_cannot_select_past_date: 'आप पिछली तारीख का चयन नहीं कर सकते',
  thank_you_for_uploading_document: 'दस्तावेज़ अपलोड करने के लिए धन्यवाद। कृपया खाता सक्रिय होने के लिए समीक्षा की प्रतीक्षा करें।',
  reason: 'कारण',
  enter_amount: 'राशी डालें',
  please_enter_amount: 'कृपया राशि दर्ज करें!',
  home: 'घर',
  track_earning: 'ट्रेक EARNING',
  manage_employees: 'मंच का उपयोग करें',
  payout_method: 'पैथ के बारे में',
  payments: 'भुगतान',
  offers: 'ऑफ़र',
  special_requests: 'विशेष आग्रह',
  first_project_completion: '$20 पहला प्रोजेक्ट पूरा होने पर यदि संदर्भित मित्र होम उपयोगकर्ता है. \n$50 यदि संदर्भित मित्र प्रो उपयोगकर्ता है तो पहले प्रोजेक्ट के पूरा होने पर आपके लिए तुरंत.',
  confirmation: 'पुष्टीकरण',
  refer_a_pro: 'एक प्रो देखें',
  refer_your_friend: 'अपने मित्र को देखें',
  loading_payment_method: 'लोड हो रहा है भुगतान के तरीके ...',
  enabling_instant_payment: 'त्वरित भुगतान विधियों को सक्षम करना ...',
  loading_data: 'डेटा लोड हो रहा है...',
  loading_payment_detail: 'पेआउट विवरण लोड हो रहा है ...',
  it_will_take_some_time: 'इसमें थोड़ा वक्त लगेगा....',
  sending: 'भेजना...',
  updating: 'Updating...',
  setting_default_payment_method: 'डिफ़ॉल्ट भुगतान विधि सेट करना ...',
  compressing_the_size: 'वीडियो के आकार को संकुचित करना.',
  uploading_data: 'डेटा अपलोड करना',
  your_account_suspended: 'आपका खाता निलंबित कर दिया गया है क्योंकि आपकी कंपनी का बीमा समाप्त हो चुका है। कृपया नई बीमा जानकारी अपलोड करें।',
  profile_incomplete: 'प्रोफ़ाइल अपूर्ण',
  please_complete_profile: 'कृपया अपना प्रोफ़ाइल पूरा करें',
  please_enter_your_date_of_birth: 'कृपया अपने खाते की सक्रियता के लिए अपनी जन्म तिथि दर्ज करें।',
  please_enter_account_holder_name: 'कृपया अपना खाता धारक नाम दर्ज करें.',
  please_enter_your_routing_number: 'कृपया अपना रूटिंग नंबर दर्ज करें',
  please_enter_your_account_number: 'कृपया अपना खाता नंबर दर्ज करें',
  sesson_has_been_expire: 'आपका सत्र समाप्त हो गया है कृपया पुनः साइन इन करें',
  internet_connection_issue: 'इंटरनेट कनेक्शन समस्या। कृपया बाद में पुनः प्रयास करें',
  please_check_your_email_for_our: 'कृपया हमारी प्रतिक्रिया के लिए अपना ईमेल देखें। हम आपकी प्रतिक्रिया को महत्व देते हैं। आपको 24 घंटे के भीतर हमसे वापस सुनना चाहिए।',
  account_has_been_updated: 'खाता सफलतापूर्वक अपडेट कर दिया गया है। कृपया खाता सक्रिय होने के लिए समीक्षा की प्रतीक्षा करें।',
  project_has_been_completed: 'परियोजना पूरी हो गई है कृपया उपयोगकर्ता से पुष्टि की प्रतीक्षा करें।',
  you_have_not_added_a_payout: 'आपने अभी तक भुगतान विधि नहीं जोड़ी है। कृपया इसे पहले जोड़ें।',
  information: 'जानकारी!',
  if_profile_needs_updated: 'यदि प्रोफ़ाइल अपडेट की आवश्यकता है तो कृपया ऐसा करने के लिए स्वतंत्र महसूस करें.',
  please_add_your_credit: 'कृपया पहले अपना क्रेडिट कार्ड जोड़ें।',
  for_sercurity_and_payout: 'सुरक्षा और भुगतान सुविधाओं के लिए अधिक जानकारी की आवश्यकता है।',
  update_app: 'UPDATE APP',
  not_now: 'अब मत',
  your_official_price_can_be: 'प्रोजेक्ट शुरू होने से पहले पहुंचने पर आपकी आधिकारिक कीमत आपके द्वारा निर्धारित की जा सकती है। यहां से क्लाइंट अभी भी सेवा से इनकार कर सकता है। आपके द्वारा निर्धारित सीमा के भीतर रहने की सिफारिश की जाती है.',
  thank_you_for_your_payment: 'आपके भुगतान के लिए धन्यवाद। अब आप साइट पर जा सकते हैं.',
  request_sent_successfully: 'अनुरोध सफलतापूर्वक भेजा गया। जब ग्राहक आपके ऑन-साइट विज़िट के अनुरोध को स्वीकार करेगा तो आपको सूचित किया जाएगा.',
  credit_card_is_valid: 'क्रेडिट कार्ड अमान्य है',
  are_your_sure_you_want_to_delete: 'क्या आप वाकई इस कार्ड को हटाना चाहते हैं?',
  would_you_like_to_auto: 'क्या आप अपनी सदस्यता को स्वतः नवीनीकृत करना चाहेंगे?',
  please_select_your_subscription: 'कृपया अपनी सदस्यता का चयन करें.',
  alert: 'चेतावनी!',
  we_hate_to: 'हम आपको जाते हुए देखने से घृणा करते हैं, यदि आपके पास कोई भी समस्या है, तो आप हमारे लिए 877-733-2425 या info@ugopros.com पर पहुंचें।.',
  yes_cancel: 'हाँ रद्द करें',
  no_i_will_stay: 'नहीं, मैं रहूँगा',
  you_have_not_added: 'आपने अभी तक अपना डेबिट कार्ड नहीं जोड़ा है।',
  please_select_at_least: 'कृपया कम से कम एक उप श्रेणी का चयन करें.',
  you_have_selected_to: 'आपने ऑटो भुगतान चालू करने के लिए चुना है। यदि आप जारी रखना चाहते हैं तो पुष्टि करें?',
  turn_off: 'बंद करें',
  are_you_sure_you_want_to_delete_this: 'क्या आप वाकई इस भुगतान विधि को हटाना चाहते हैं?',
  if_project_has_not: 'यदि 14 दिनों के भीतर प्रोजेक्ट की पुष्टि नहीं हुई है, तो आपके अनुमानित कुल आपके खाते में जारी कर दिए जाएंगे।',
  video_lenght_should_30: 'वीडियो की लंबाई अधिकतम 30 सेकंड होनी चाहिए।',
  you_can_not_uodalog_5: 'आप 5 से अधिक फ़ोटो अपलोड नहीं कर सकते।',
  please_enter_date_of_birth: 'कृपया अपने खाते की सक्रियता के लिए जन्म तिथि दर्ज करें।',
  congratulation_your_account: 'बधाई! अब आप सक्रिय हैं।.',
  not_availeable: 'अनुपलब्ध। अधिक विस्तार के लिए हमसे संपर्क करें.',
  please_enter_account_iban: 'कृपया खाता / IBAN दर्ज करें',
  please_enter_swift: 'कृपया स्विफ्ट / बीआईसी कोड दर्ज करें',
  please_enter_aba: 'कृपया एबीए / रूटिंग नंबर दर्ज करें',
  please_enter_account_holder: 'कृपया खाता धारक का नाम दर्ज करें',
  media_not_available: 'मीडिया उपलब्ध नहीं है!',
  please_enter_aba_routing: 'कृपया एबीए / रूटिंग नंबर दर्ज करें',
  please_enter_bank_account_number: 'कृपया बैंक खाता नंबर दर्ज करें',
  please_enter_back_name: 'कृपया बैंक का नाम दर्ज करें',
  please_add_account: 'कृपया खाता प्रकार जोड़ें',
  please_enter_bank_address: 'कृपया बैंक का पता दर्ज करें',
  instant_request: 'सबसे पहले',
  free_online_leads: 'मुफ्त ऑनलाइन बिक्रीसूत्र',
  manage_all_your: '•अपने सभी कर्मचारियों या टीमों का प्रबंधन करें\n',
  here_you_will_see: 'यहां आपको नए प्रोजेक्ट लीड्स दिखाई देंगे ताकि आप फ्री कोट्स भेज सकें।',
  here_you_are_able_to_chat: 'यहाँ आप अपने ग्राहकों के साथ पूरे प्रोजेक्ट में एक ही पेज पर चैट कर सकते हैं।',
  we_are_always_here: 'हम हमेशा आपके लिए यहाँ मौजूद हैं। हम आपके विचारों को महत्व देते हैं। यहां आप अपने विचारों या चिंताओं को हमारे साथ साझा कर सकते हैं।',
  here_is_you_able_to_chat: 'यहाँ आप अपने ग्राहकों के साथ पूरे प्रोजेक्ट में एक ही पेज पर चैट कर सकते हैं।',
  we_are_always_here_for_you: 'हम हमेशा आपके लिए यहाँ मौजूद हैं। हम आपके विचारों को महत्व देते हैं। यहां आप अपने विचारों या चिंताओं को हमारे साथ साझा कर सकते हैं।',
  here_you_can_make: 'यहां आप अधिक जरूरी अनुरोधों के लिए खुद को उपलब्ध करा सकते हैं। ऑनलाइन मोड विकल्प कहता है कि आप अनुरोध के 2 घंटे के भीतर परियोजनाओं को शुरू करने के लिए उपलब्ध होंगे।',
  there_is_nothing: 'डबल बुकिंग से कुछ भी ख़ास नहीं है। यहां आप अपना कार्यक्रम बनाए रख सकते हैं ताकि आपको पता चल सके कि आगे क्या है। इस सुविधा के साथ अपनी परियोजनाओं की सटीक समीक्षा करें।',
  why_not_grow: 'कई कर्मचारियों या टीमों के लिए अपने व्यवसाय को क्यों नहीं बढ़ाएं? यहां आप अपने कर्मचारियों को प्रबंधित कर सकते हैं ताकि आप उन परियोजनाओं को असाइन कर सकें जिन्हें आपने सम्मानित किया है!',
  back_button: 'पिछला बटन',
  loagin_button: 'लॉगिन बटन',
  here_is_where_you_see: 'यहां आप अपने उपलब्ध क्रेडिट को देखते हैं। न केवल आप ईएआरएन क्रेडिट कर सकते हैं, बल्कि उन अधिक महंगी परियोजनाओं की दिशा में भुगतान करने के लिए आप एडीडी क्रेडिट भी कर सकते हैं।',
  we_are_here_to_help: 'आपकी परियोजनाओं के प्रबंधन में मदद के लिए हम यहां हैं। चाहे आपके पास एक तत्काल अनुरोध हो या एक साधारण परियोजना, यह वह जगह है जहां आप एक पेशेवर खोजने के लिए यात्रा शुरू करते हैं। कई एक उद्धरण भेजेंगे, लेकिन आप चुनते हैं कि कौन कार्य पूरा करेगा।',
  here_you_will_see_the_status: 'यहां आप उन परियोजनाओं की स्थिति देखेंगे जो आपने दर्ज की हैं। आप देखेंगे कि क्या किसी परियोजना को एक उद्धरण भेजा गया था, या यदि परियोजना शुरू की गई थी। हम आपके साथ तब तक चलते हैं जब तक आप अपनी तैयार परियोजना से पूरी तरह से संतुष्ट नहीं हो जाते।',
  this_is_where_you: 'यह वह जगह है जहां आप उन अधिक जरूरी परियोजनाओं पर स्थिति की जांच करेंगे। इसलिए अगर ज्यादातर मामलों में आपातकाल है, तो हमारे पास अनुरोधों के 2 घंटे के भीतर मदद करने के लिए उपलब्ध है।',
  here_you_are_able: 'यहाँ आप परियोजना के पूरे होने के दौरान आपको उसी पृष्ठ पर रखते हुए अपनी सेवा पेशेवर से चैट करने में सक्षम हैं।',
  we_are_always_here_for: 'हम हमेशा आपके लिए यहाँ मौजूद हैं। हम आपके विचारों को महत्व देते हैं। यहां आप अपने विचारों या चिंताओं को हमारे साथ साझा कर सकते हैं।',
  send_free_qoutes: '• मुफ्त उद्धरण भेजें',
  grow_your_business: '• अपना व्यवसाय बढ़ाएं',
  manage_your_employee: '• अपने कर्मचारियों को प्रबंधित करें',
  track_clients: '• ट्रैक ग्राहकों, नौकरियों, और चालान',
  utilize_the_free: '•मुफ्त सीआरएम सॉफ्टवेयर का उपयोग करें',
  connect_directly_with: '• परियोजनाओं के बारे में उपभोक्ताओं के साथ सीधे जुड़ें',
  select_your_services: '• अपना सेवा स्थान चुनें',
  no_annual_fee: '•कोई वार्षिक शुल्क नहीं',
  industry_professional: 'उद्योग व्यावसायिक लाभ',
  connect_with_a_vetted_pro: '• अपने समुदाय में एक veted समर्थक के साथ कनेक्ट करें',
  recieve_multiple_qoute: '• परियोजनाओं के लिए कई उद्धरण प्राप्त करें',
  read_reviews_of_pros: '• पेशेवरों की समीक्षा पढ़ें और तुलना करें',
  manage_all_projects: '• एप्लिकेशन में सभी परियोजनाओं का प्रबंधन',
  communicate_with_your: '• अपने सेवा प्रदाता के साथ संवाद करें',
  send_payment_once_project: '•प्रोजेक्ट पूरा होने के बाद भुगतान भेजें',
  refer_a_friend_and: '• किसी मित्र को देखें और खाता क्रेडिट प्राप्त करें',
  provide_accurate_qoute: '• चित्रों और चैट के आधार पर सटीक उद्धरण प्रदान करें',
  exposure_for_your: '• आपकी कंपनी के लिए एक्सपोजर',
  live_mode_for_instant: '• तत्काल / तत्काल या अनुसूची परियोजनाओं के लिए लाइव मोड',
  choose_the_jobs: '• मनचाहा रोजगार चुनें',
  crm_platform_for_great: '• महान ग्राहक संतुष्टि के लिए सीआरएम मंच',
  professional_chat_funtion: '• पेशेवर चैट समारोह',
  keep_track_of_all: '• अपनी सभी परियोजनाओं और चालानों पर नज़र रखें',
  free_business_tools: 'मुक्त व्यापार उपकरण',
  please_enter_your_project: 'कृपया चैट के साथ जारी रखने के लिए अपना प्रोजेक्ट विवरण दर्ज करें।',
  ok: 'ठीक है',
  jobs: 'नौकरियां',
  requests: 'अनुरोध',
  got_it: 'समझ गया',

  please_enter_ein: 'कृपया EIN / टैक्स आईडी दर्ज करें',
  please_enter_social_number: 'कृपया सामाजिक सुरक्षा नंबर दर्ज करें।',
  please_enter_correct_ssn: 'कृपया सही सामाजिक सुरक्षा नंबर दर्ज करें।',
  please_check_the_box: 'कृपया बॉन्ड / बीमा के लिए बॉक्स की जांच करें और बॉन्ड / बीमा का प्रमाण दिखाएं',
  please_check_the_box_only: 'कृपया बॉन्ड / बीमा के लिए बॉक्स की जाँच करें',
  please_show_proof_of_bond: 'कृपया बॉन्ड / बीमा का प्रमाण दिखाएं',
  please_select_bounded: 'कृपया बंधुआ / बीमित का चयन करें',
  please_enter_about_business: 'कृपया व्यवसाय के बारे में दर्ज करें',
  please_enter_new_password: 'कृपया नया पासवर्ड दर्ज करें',
  please_enter_old_password: 'कृपया पुराना पासवर्ड डालें',
  please_enter_your_correct: 'कृपया सही स्थान दर्ज करें',
  please_add_service_area: 'कृपया सेवा क्षेत्र जोड़ें',
  please_add_categories: 'कृपया श्रेणियाँ जोड़ें',
  please_enter_year_of: 'कृपया व्यवसाय में वर्ष दर्ज करें',
  pleaes_check_the_box_for_ar: 'कृपया मान्यता प्राप्त बॉक्स के लिए जाँच करें',
  please_check_the_box_nari: 'कृपया NARI सदस्य के लिए बॉक्स देखें',
  please_enter_company_ein: 'कृपया कंपनी EIN / टैक्स आईडी दर्ज करें',
  please_add_company_ein_id: 'कृपया कंपनी EIN / टैक्स आईडी फोटो जोड़ें',
  tell_us_about_the_project_using_chat_picture_and_or_videos: 'हमें चैट, चित्र और / या वीडियो का उपयोग करके परियोजना के बारे में बताएं.',
  obtain_multiple_quotes_from_ready_and_available_pros: 'तैयार और उपलब्ध पेशेवरों से कई उद्धरण प्राप्त करें।',
  book_your_fully_vetted_pro_for_same_day_repair_or_schedule_them_at_your_convenience: 'उसी दिन मरम्मत के लिए अपने पूरी तरह से वीटो प्रो बुक करें या अपनी सुविधानुसार उन्हें शेड्यूल करें।',
  schedule_pay_and_rate_your_pro_while_keeping_track_of_all_your_jobs_with_the_u_go_pros_app: 'U \'GO Pros ऐप के साथ अपने सभी कार्यों का ट्रैक रखते हुए शेड्यूल करें, भुगतान करें, और अपने प्रो को रेट करें।',
  you_will_be_charged: 'आपसे $ . का सर्वोत्तम केस परिदृश्य शुल्क लिया जाएगा',
  before_pro_starts_job: '! प्रो काम शुरू करने से पहले, मूल्यांकन के बाद आपको वास्तविक लागत के बारे में सूचित किया जाएगा। यहां से आप मना या स्वीकार कर सकते हैं',
  please_enter_title_of_your_event: 'कृपया अपने ईवेंट का शीर्षक दर्ज करें.',
  please_enter_detail_of_your_event: 'कृपया अपने ईवेंट का विवरण दर्ज करें.',
  please_select_time_of_your_event: 'कृपया अपने आयोजन का समय चुनें।',
  please_select_date_of_your_event: 'कृपया अपने आयोजन की तिथि चुनें।',
  please_enter_the_reason_for_cancel_to_continue: 'कृपया जारी रखने के लिए रद्द करने का कारण दर्ज करें।',
  you_havent_enough_credits: 'आपके पास पर्याप्त क्रेडिट नहीं हैं।',
  please_select_month_from_list: 'कृपया सूची से महीने का चयन करें',
  please_select_year_from_list: 'कृपया सूची से वर्ष चुनें',
  google_play_services_required: 'Google Play सेवाएं आवश्यक: ',
  after_installing_close_and_relaunch_this_app: 'इंस्टॉल करने के बाद, इस ऐप को बंद करें और फिर से लॉन्च करें।',
  no_network_connection_available: 'कोई नेटवर्क कनेक्शन उपलब्ध नहीं है।',
  cancelling_required_permissions_are_not_granted: 'रद्द करना, आवश्यक अनुमतियाँ नहीं दी जाती हैं',
  connection_problem: 'कनेक्शन समस्या, कृपया अपना इंटरनेट जांचें और बाद में पुन: प्रयास करें',
  oh_no: 'नहीं ओ! ',
  microphone_permission: 'रिकॉर्डिंग के लिए माइक्रोफ़ोन की अनुमति ज़रूरी है. कृपया अतिरिक्त कार्यक्षमता के लिए ऐप सेटिंग में अनुमति दें।',
  external_storage_permission: 'बाहरी संग्रहण अनुमति की आवश्यकता है। कृपया अतिरिक्त कार्यक्षमता के लिए ऐप सेटिंग में अनुमति दें।',
  camera_permission: 'कैमरा अनुमति की आवश्यकता है। कृपया अतिरिक्त कार्यक्षमता के लिए ऐप सेटिंग में अनुमति दें।',
  please_provide_personal_past_experience_and_company_info: 'कृपया व्यक्तिगत, पिछला अनुभव और कंपनी की जानकारी प्रदान करें',
  please_add_total_minimum_estimate: 'कृपया कुल न्यूनतम अनुमान जोड़ें? $',
  please_add_total_minimum_estimate_at_least_1: 'कृपया कुल न्यूनतम अनुमान कम से कम $1 . जोड़ें',
  please_add_total_maximum_estimate_at_least_1: 'कृपया कुल अधिकतम अनुमान कम से कम $1 . जोड़ें',
  please_add_total_maximum_estimate: 'कृपया कुल अधिकतम अनुमान जोड़ें? $',
  total_minimum_estimate_should_be_less_than_total_maximum_estimate: 'कुल न्यूनतम अनुमान कुल अधिकतम अनुमान से कम होना चाहिए।',
  please_select_your_available_time: 'कृपया अपना उपलब्ध समय चुनें',
  permission_deny: 'अनुमति अस्वीकार',
  mediaActivity_not_available: 'मीडियाएक्टिविटी उपलब्ध नहीं है!',
  portfolio_not_found: 'पोर्टफोलियो नहीं मिला',
  select_second_time: 'कृपया अपना दूसरा उपलब्ध समय फिर से चुनें',
  select_third_time: 'कृपया अपना तीसरा उपलब्ध समय फिर से चुनें',
  commissioned_base_services: 'कमीशन आधार सेवाओं के लिए। उदाहरण: रियल्टी, वकील, बीमा एजेंट आदि।\n\n U\'GO के पेशेवरों में आपकी रुचि के लिए धन्यवाद। हम आपको अधिक ग्राहकों से जोड़ने के लिए समर्पित हैं। हमारे साथ साझेदारी करने से आप उपभोक्ताओं के लिए एक निर्देशिका में आ जाएंगे। वे आपको हमारे मंच पर चैट के माध्यम से, या आपके व्यक्तिगत संपर्क के माध्यम से सूचित करने में सक्षम होंगे।\n\n सेवा लागत: कोई छिपी हुई फीस नहीं\n',
  yearly: 'वार्षिक:     ',
  monthly: 'महीने के::     ',
  these_fees_are_subject: 'ये शुल्क परिवर्तन के अधीन हैं, लेकिन ऐसा होने पर आपको अग्रिम रूप से सूचित किया जाएगा।\n\n यदि आप हमारे साथ साझेदारी करने में रुचि रखते हैं तो साइन अप प्रक्रिया जारी रखें। यदि आपके कोई प्रश्न हैं, तो बेझिझक 877-733-2425 पर संपर्क करें या info@ugopros.com',
  chose_to_resubscribe: 'बहुत खुशी है कि आपने फिर से सदस्यता लेना चुना, कृपया चुनें कि आप अपनी सदस्यता का भुगतान मासिक या वार्षिक कैसे करना चाहते हैं।',
  plumber_request: 'प्लंबर अनुरोध',
  schedule_meeting: 'सुनियोजित बैठक',
  error_pdf_docs: 'त्रुटि, दस्तावेज़ में केवल पीडीएफ़ और दस्तावेज़ फ़ाइल की अनुमति है',
  please_enter_paypall_account: 'कृपया Paypal खाता दर्ज करेंं',
  please_enter_verify_code: 'कृपया सत्यापन कोड दर्ज करें',
  please_enter_your_new_password: 'कृपया अपना नया पासवर्ड दर्ज करें',
  choose_service_distance: 'कृपया सेवा क्षेत्र की दूरी चुनें',
  unable_to_get_info: 'हम आपके खाते से आवश्यक जानकारी प्राप्त करने में असमर्थ रहे। कृपया अनुमति दें/एप्लिकेशन रीफ़्रेश करें और पुनः प्रयास करें',
  please_enter_service_location: 'कृपया सेवा स्थान दर्ज करें',
  no_data_found: 'डाटा प्राप्त नहीं हुआ',
  add_portfolio_picture: 'कृपया पोर्टफोलियो चित्र जोड़ें',
  cannot_access_location: 'अनुमति! आप स्थान डेटा तक नहीं पहुंच सकते',
  information_not_found: 'जानकारी नहीं मिली',
  you_cannot_select_past_time: 'आप पिछले समय का चयन नहीं कर सकते',
  error_occurs: 'त्रुटि हुई',
  select_special_service: 'कृपया विशेष सेवा का चयन करें',
  select_state: 'कृपया राज्य का चयन करें',
  select_city: 'कृपया शहर का चयन करें',
  enter_your_withdraw_amount: 'कृपया अपनी निकासी राशि दर्ज करें',
  less_than_total_balance: 'राशि कुल शेष राशि से कम होनी चाहिए',
  enter_business_description: 'व्यवसाय विवरण दर्ज करें',
  please_show_proof_of_register_certificate: 'कृपया रजिस्टर प्रमाण पत्र का प्रमाण दिखाएं',
  please_show_proof_of_pen_card: 'कृपया पेन कार्ड का प्रमाण दिखाएं',
  please_show_proof_of_aadhar_card: 'कृपया आधार कार्ड का प्रमाण दिखाएं',
  please_show_proof_of_voter_identity_card: 'कृपया मतदाता पहचान पत्र का प्रमाण दिखाएं',
  please_show_proof_of_gst: 'कृपया जीएसटी का प्रमाण दिखाएं',
  please_show_proof_of_licese: 'कृपया ड्राइविंग लाइसेंस का प्रमाण दिखाएं',
  please_check_the_box_for_register_certificate_and_show_poof: 'कृपया रजिस्टर प्रमाणपत्र के लिए बॉक्स को चेक करें और रजिस्टर प्रमाणपत्र का प्रमाण दिखाएं',
  please_check_the_for_register_certificate: 'कृपया रजिस्टर प्रमाणपत्र के लिए बॉक्स को चेक करें',
  please_check_the_box_for_pen_card_and_show_poof: 'कृपया पेन कार्ड के लिए बॉक्स चेक करें और पेन कार्ड का प्रमाण दिखाएं',
  please_check_the_box_only_for_pen_card: 'कृपया पेन कार्ड के बॉक्स को चेक करें',
  please_check_the_box_for_adhar_card_and_show_poof: 'कृपया आधार कार्ड के लिए बॉक्स को चेक करें और आधार कार्ड का प्रमाण दिखाएं',
  please_check_the_box_only_for_adhar_card: 'कृपया आधार कार्ड के लिए बॉक्स को चेक करें',
  please_check_the_box_for_adhar_card: 'कृपया आधार कार्ड के लिए बॉक्स को चेक करें',
  please_check_the_box_for_voter_identityr_card_and_show_poof: 'कृपया मतदाता पहचान पत्र के लिए बॉक्स को चेक करें और पेन कार्ड का प्रमाण दिखाएं',
  please_check_the_box_for_voter_identity_card: 'कृपया मतदाता पहचान पत्र के लिए बॉक्स को चेक करें',
  please_check_the_box_for_gst_and_show_poof: 'कृपया जीएसटी के लिए बॉक्स को चेक करें और पेन कार्ड का प्रमाण दिखाएं',
  please_check_the_box_for_gst: 'कृपया जीएसटी के लिए बॉक्स को चेक करें',
  please_check_the_box_for_driving_license_and_show_poof: 'कृपया ड्राइविंग लाइसेंस के लिए बॉक्स को चेक करें और पेन कार्ड का प्रमाण दिखाएं',
  please_check_the_box_for_driving_License: 'कृपया ड्राइविंग लाइसेंस के लिए बॉक्स को चेक करें',
  you_must_be_18_: 'हमारी सेवाओं का उपयोग करने के लिए आपकी आयु 18 वर्ष या उससे अधिक होनी चाहिए।',
  select_payment_method: 'भुगतान का तरीका चुनें',
  google_payment_is_not_available_in_this_device: 'इस उपकरण में Google भुगतान उपलब्ध नहीं है',
  us: 'हम',
  _5_credit_for_reviews_are_for_jobs_that_are_complete_over_10: 'समीक्षाओं के लिए $5 का क्रेडिट उन नौकरियों के लिए है जो $10 . से अधिक की हैं',

  //employee

  login_in: 'लॉग इन करें',

  mobile: 'मोबाइल#',

  please_enter_valid_email_address: 'कृपया मान्य ईमेल पता दर्ज करें।',

  minimum_password_length_should_be: 'न्यूनतम पासवर्ड लंबाई होनी चाहिए',

  don_t_have_an_account: 'खाता नहीं है?',

  agree_to_terms_conditions: 'मामले &amp; शर्तेँ',
  privacy_policy: 'गोपनीयता नीति',

  google_maps_key: 'AIzaSyBb2sUywacICcNUEmkhGsMUXIArFSoWUfc',

  please_check_network_connection: 'कृपया अपना नेटवर्क कनेक्शन जांचें',
  add_lead: 'सीसा जोड़ें',
  write_your_message_here: 'यहां अपना संदेश लिखें ...',
  lead_detail: 'सीसा विस्तार',
  superviser: 'पर्यवेक्षक',
  followed_up: 'पीछा',
  in_person_schedule: 'व्यक्ति अनुसूचित में',

  refused: 'मना कर दिया',
  requested_callback: 'आवश्यक कॉल बैक',

  all_employees: 'सभी कर्मचारी',

  manage: 'प्रबंधित',
  employees: 'कर्मचारियों',
  please_enter_user_name: 'कृपया उपयोगकर्ता नाम दर्ज करें',

  oops: 'उफ़!',
  task_list: 'कार्य सूची',
  setting: 'स्थापना',

  update_employee: 'अद्यतन करें',

  please_enter_phone_number: 'कृपया फ़ोन नंबर दर्ज करें',

  please_enter_country: 'कृपया देश दर्ज करें',
  please_enter_city: 'कृपया शहर दर्ज करें',

  please_Enter_body: '\"कृपया बॉडी दर्ज करें\"',
  please_enter_postal_code: '\"कृपया पोस्टल कोड दर्ज करें \"',

  please_enter_First_name: 'कृपया पहला नाम दर्ज करें',
  please_enter_last_name: 'कृपया अंतिम नाम दर्ज करें',
  plese_select_the_team: 'कृपया टीम का चयन करें',

  this_app_is_designed_for_internal_staff_members_only: 'इस एप्लिकेशन को आंतरिक स्टाफ के सदस्यों के लिए ही बनाया गया है।',
  follow_ugo_pros_to_utilize_pros_features: 'पेशेवरों की सुविधाओं का उपयोग करने के लिए केवल U\'GO पेशेवरों',

  signup_as_an_industry_professional: 'साइन अप एक उद्योग पेशेवर के रूप में?',
  supervisor: 'पर्यवेक्षक',

  user_login: 'उपयोगकर्ता लॉगिन',
  user_name: 'उपयोगकर्ता नाम',

  team_id: 'टीम आईडी',

  sale: 'बिक्री',
  street: 'सड़क',
  phone_number: 'फ़ोन नंबर',
  body: 'तन',
  postal_code: 'डाक कोड',

  new_task_s: 'नए कार्य',
  current_task: 'तत्कालीन काम',

  task_num: 'टास्क # ',
  ready_to_start: 'शुरू करने के लिए तैयार',

  start: 'शुरू',

  end_tracking: 'अंत ट्रैकिंग',
  task_detail: 'कार्य विस्तार',

  upload_images: 'तश्वीरें अपलोड करो',
  write_your_feedback_here: 'अपनी प्रतिक्रिया यहाँ लिखें',

  followed: 'पीछा किया',
  lead_name: 'लीड नाम',

  name_: 'नाम:',
  address_: 'पता:',
  country_: 'देश:',
  state_: 'राज्य:',
  city_: 'Faridabad:',
  meeting_detail: 'बैठक का विवरण:',

  start_tracking: 'स्टार्ट कर रहा है',
  meet_with_pro_to_get_information: 'जानकारी प्राप्त करने के लिए प्रो के साथ मिलो',
  choose_option: 'विकल्प चुनें',

  permission: 'अनुमति',
  allow_to_access_your_location: 'अपने स्थान तक पहुँचने की अनुमति दें?',
  allow: 'अनुमति',
  never: 'कभी नहीं',

  days_ago: 'दिन पहले',

  assign: 'असाइन',
  update_successfully: 'सफलतापूर्वक अपडेट करें',

  turn_on_location_on_device: 'डिवाइस पर अपना स्थान चालू करें',

  welcome_to_u_go_employee_staff: 'U\'GO कर्मचारी / कर्मचारी में आपका स्वागत है',
  account_deletion: 'खाता हटाना',
  delete_my_account: 'मेरा एकाउंट हटा दो',
  delete_account: 'खाता हटा दो',
  importent_note: 'महत्वपूर्ण लेख',
  your_account_should_not_have_any_active_pending_project_n_your_account_should_not_have_any_active_payouts_n_after_successful_deletion_of_your_account_you_will_not_be_able_to_log_in_to_the_account_account_deletion_is_permanent_and_will_not_be_reversible: '• आपके खाते में कोई सक्रिय लंबित परियोजना नहीं होनी चाहिए\n• आपके खाते में कोई सक्रिय पेआउट नहीं होना चाहिए\n• अपने खाते को सफलतापूर्वक हटाने के बाद, आप खाते में लॉग इन नहीं कर पाएंगे। खाता विलोपन स्थायी है और पूर्ववत नहीं होगा।',
  please_acknowledge_that_you_have_read_the_above_note: 'कृपया स्वीकार करें कि आपने उपरोक्त टिप्पणी पढ़ ली है',
  acknowledged: 'स्वीकार किया',
  are_you_sure_you_want_to_delete_account: 'क्या आप वाकई खाता हटाना चाहते हैं?',
  please_confirm_your_account_deletion_once_you_confirm_it_you_will_not_be_able_to_use_your_account_and_we_will_delete_all_the_data_associated_with_your_account: 'कृपया अपने खाते को हटाने की पुष्टि करें, इसकी पुष्टि करने के बाद आप अपने खाते का उपयोग नहीं कर पाएंगे और हम आपके खाते से संबद्ध सभी डेटा हटा देंगे।',
  please_acknowledge: 'कृपया मान लें',
  delete_accounts: 'खाता हटा दो',
  your_profile_is_incomplete_if_you_want_edit_profile_you_can_do_that_by_pressing_edit_profile_button: 'आपकी प्रोफ़ाइल अधूरी है यदि आप प्रोफ़ाइल संपादित करना चाहते हैं तो आप प्रोफ़ाइल संपादित करें बटन दबाकर ऐसा कर सकते हैं।',
  sorry_you_have_not_yet_been_approved_by_admin: 'क्षमा करें, आपको अभी तक व्यवस्थापक द्वारा अनुमोदित नहीं किया गया है।',
  confirm_that_you_are: 'पुष्टि करें कि आप 18+ हैं',
  please_check_the_box_confirm_that_you_are: 'कृपया पुष्टि करने के लिए बॉक्स को चेक करें कि आप 18+ हैं',
  resend_link: 'இணைப்பை மீண்டும் அனுப்பு',
  resend_in: ' पुन: भेजें',
  create_pin: 'पिन बनाएं',
  your_authentication_code: 'आपका प्रमाणीकरण कोड',
  please_enter_your_authentication_code: 'कृपया अपना प्रमाणीकरण कोड दर्ज करें',
  sign_in_securely: 'सुरक्षित रूप से साइन इन करें',
  resend_authentication_code: 'प्रमाणीकरण कोड पुनः भेजें',
  two_setup_authentication: 'दो-सेटअप प्रमाणीकरण',
  thanks_for_keeping_your_account_secure: 'अपना खाता सुरक्षित रखने के लिए धन्यवाद।',
  please_check_your_email: 'कृपया अपनी ईमेल देखें',
  enter_old_password: 'पुराना पासवर्ड डालें',
  please_proceed_to_your_email_for_the_purpose_of_verification_in_order_to_proceed_to_the_next_step: 'अगले चरण पर जाने के लिए कृपया सत्यापन के उद्देश्य से अपने ईमेल पर जाएं।',
  please_enter_social_number_or_ein: 'कृपया सामाजिक सुरक्षा या ईआईएन नंबर दर्ज करें।',



};
