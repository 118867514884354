const POST_CREATE_EMPLOYEE_REQUEST = "POST_CREATE_EMPLOYEE_REQUEST";
const POST_CREATE_EMPLOYEE_REQUEST_SUCCESS = "POST_CREATE_EMPLOYEE_REQUEST_SUCCESS";
const POST_CREATE_EMPLOYEE_REQUEST_FAILURE = "POST_CREATE_EMPLOYEE_REQUEST_FAILURE";
const RESET_CREATE_EMPLOYEE_STATE = "RESET_CREATE_EMPLOYEE_STATE";

const createEmployee = (formData) => {
  return {
    type: POST_CREATE_EMPLOYEE_REQUEST,
    formData
  };
};

const resetCreateEmployee = () => {
  return {
    type: RESET_CREATE_EMPLOYEE_STATE,
  };
};
export {
  createEmployee,
  resetCreateEmployee,
  RESET_CREATE_EMPLOYEE_STATE,
  POST_CREATE_EMPLOYEE_REQUEST,
  POST_CREATE_EMPLOYEE_REQUEST_SUCCESS,
  POST_CREATE_EMPLOYEE_REQUEST_FAILURE,
};
