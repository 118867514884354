const POST_EMPLOYEE_END_TASK_REQUEST = "POST_EMPLOYEE_END_TASK_REQUEST";
const POST_EMPLOYEE_END_TASK_REQUEST_SUCCESS = "POST_EMPLOYEE_END_TASK_REQUEST_SUCCESS";
const POST_EMPLOYEE_END_TASK_REQUEST_FAILURE = "POST_EMPLOYEE_END_TASK_REQUEST_FAILURE";
const RESET_EMPLOYEE_END_TASK_STATE = "RESET_EMPLOYEE_END_TASK_STATE";

const endTask = (formData) => {
  return {
    type: POST_EMPLOYEE_END_TASK_REQUEST,
    formData
  };
};

const resetEndTask = () => {
  return {
    type: RESET_EMPLOYEE_END_TASK_STATE,
  };
};
export {
  endTask,
  resetEndTask,
  RESET_EMPLOYEE_END_TASK_STATE,
  POST_EMPLOYEE_END_TASK_REQUEST,
  POST_EMPLOYEE_END_TASK_REQUEST_SUCCESS,
  POST_EMPLOYEE_END_TASK_REQUEST_FAILURE,
};
