const POST_CAPTURE_BID_AMOUNT_REQUEST = 'POST_CAPTURE_BID_AMOUNT_REQUEST';
const POST_CAPTURE_BID_AMOUNT_REQUEST_SUCCESS = 'POST_CAPTURE_BID_AMOUNT_REQUEST_SUCCESS';
const POST_CAPTURE_BID_AMOUNT_REQUEST_FAILURE = 'POST_CAPTURE_BID_AMOUNT_REQUEST_FAILURE';
const RESET_CAPTURE_BID_AMOUNT_STATE = 'RESET_CAPTURE_BID_AMOUNT_STATE'

const captureBidAmount = (formData) => {

    return {
        type: POST_CAPTURE_BID_AMOUNT_REQUEST,
        formData
    }
}
const resetCaptureBidAmount = () => {

    return {
        type: RESET_CAPTURE_BID_AMOUNT_STATE,

    }
}
export {
    captureBidAmount,
    resetCaptureBidAmount,
    RESET_CAPTURE_BID_AMOUNT_STATE,
    POST_CAPTURE_BID_AMOUNT_REQUEST,
    POST_CAPTURE_BID_AMOUNT_REQUEST_SUCCESS,
    POST_CAPTURE_BID_AMOUNT_REQUEST_FAILURE,
};