const POST_UPDATE_PROFILE_REQUEST = "POST_UPDATE_PROFILE_REQUEST";
const POST_UPDATE_PROFILE_REQUEST_SUCCESS = "POST_UPDATE_PROFILE_REQUEST_SUCCESS";
const POST_UPDATE_PROFILE_REQUEST_FAILURE = "POST_UPDATE_PROFILE_REQUEST_FAILURE";
const RESET_UPDATE_PROFILE_STATE = "RESET_UPDATE_PROFILE_STATE";

const updateProfile = (formData) => {
  return {
    type: POST_UPDATE_PROFILE_REQUEST,
    formData
  };
};

const resetUpdateProfile = () => {
  return {
    type: RESET_UPDATE_PROFILE_STATE,
  };
};
export {
  updateProfile,
  resetUpdateProfile,
  RESET_UPDATE_PROFILE_STATE,
  POST_UPDATE_PROFILE_REQUEST,
  POST_UPDATE_PROFILE_REQUEST_SUCCESS,
  POST_UPDATE_PROFILE_REQUEST_FAILURE,
};
