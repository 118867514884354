const POST_UPLOAD_MESSAGE_FILE_REQUEST = "POST_UPLOAD_MESSAGE_FILE_REQUEST";
const POST_UPLOAD_MESSAGE_FILE_REQUEST_SUCCESS =
  "POST_UPLOAD_MESSAGE_FILE_REQUEST_SUCCESS";
const POST_UPLOAD_MESSAGE_FILE_REQUEST_FAILURE =
  "POST_UPLOAD_MESSAGE_FILE_REQUEST_FAILURE";
const RESET_UPLOAD_MESSAGE_FILE_STATE = "RESET_UPLOAD_MESSAGE_FILE_STATE";

const uploadMessageFile = (formData) => {
  return {
    type: POST_UPLOAD_MESSAGE_FILE_REQUEST,
    formData,
  };
};

const resetMessageFileState = () => {
  return {
    type: RESET_UPLOAD_MESSAGE_FILE_STATE,
  };
};

export {
  uploadMessageFile,
  resetMessageFileState,
  RESET_UPLOAD_MESSAGE_FILE_STATE,
  POST_UPLOAD_MESSAGE_FILE_REQUEST,
  POST_UPLOAD_MESSAGE_FILE_REQUEST_SUCCESS,
  POST_UPLOAD_MESSAGE_FILE_REQUEST_FAILURE,
};
