import { call, put, select } from "redux-saga/effects";
import { getItem, removeItem } from "../../Utils/AsyncStore";
import { atob } from "js-base64";
import CryptoJS from "crypto-js";
import ToastAndroid from "react-native-root-toast";
import { setUser } from "./User/UserDetails/Actions";
function* queryApi({ endpoint, method, body = null }) {
  const userJson = yield getItem("authUser");
  if (userJson?.token && endpoint.includes("?")) {
    console.log("TOKEN", userJson.token);
    endpoint = endpoint + userJson.token;
  }
  var res = yield call(makeRequest, {
    endpoint: endpoint,
    method,
    headers: {
      // 'Content-Type': 'application/json',
      Accept: "application/json",
      token: userJson?.token,
    },
    body: body,
  });

  if (res.error?.error?.noInternet) {
    return res;
  }

  if (res.status === 401) {
    removeItem("authUser")
      .then((val) => console.log(val))
      .catch((err) => console.log(err));
    const userData = {
      token: null,
      user: {},
    };
    yield put(setUser({ ...userData }));
    ToastAndroid.show("Session Expired Logout", ToastAndroid.LONG);
  }

  const parsedResponse = yield call(parseResponse, res);
  if (!res.ok) {
    if (parsedResponse.exception) {
      let { exception, file, message, line } = parsedResponse;
      message = "Sorry, SomeThing went worng please contact support";
      // alert(message);
      return { error: { error: { exception, file, message, line } } };
    }
    return { error: parsedResponse };
  }

  return parsedResponse;
}

const makeRequest = async ({ endpoint, method, headers, body = null }) => {
  let retryCount= 3
  while (retryCount > 0) {
    try {
      return await fetch(endpoint, {
        method,
        headers,
        credentials: "include",
        body: body === "{}" ? undefined : body,
      });
    } catch (error) {
      console.log(" catch");
      await new Promise((resolve) => setTimeout(resolve, 700));
    } finally {
      retryCount -= 1;
    }
  }
  return { error: { error: { message: "Please check your internet connection",noInternet:true } } };
};

const parseResponse = async (response) => {
  let parsedResponse;
  try {
    parsedResponse = await response.clone().json();
    const userJson = await getItem("authUser");

    if (
      parsedResponse.data &&
      (parsedResponse.data instanceof String ||
        typeof parsedResponse.data === "string") &&
      parsedResponse.data !== "" &&
      userJson?.user?.secret_key !== ""
    ) {
      let values = parsedResponse.data.split(":");
      let key = CryptoJS.enc.Utf8.parse(userJson.user.secret_key); // Secret key
      let iv = CryptoJS.enc.Utf8.parse(atob(values[1])); //Vector iv
      let decrypted = CryptoJS.AES.decrypt(values[0], key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
      });
      parsedResponse = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    }
    if (parsedResponse.error) {
      parsedResponse = { error: parsedResponse };
    }
    if (parsedResponse.data?.error) {
      parsedResponse = { error: parsedResponse.data };
    }
  } catch (error) {
    console.log("catch", error);
    parsedResponse = { error };

    // parsedResponse = await response.text();
  }

  return parsedResponse;
};

export { queryApi };
