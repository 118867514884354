const POST_USER_LOGIN_REQUEST = 'POST_USER_LOGIN_REQUEST';
const POST_USER_LOGIN_REQUEST_SUCCESS = 'POST_USER_LOGIN_REQUEST_SUCCESS';
const POST_USER_LOGIN_REQUEST_FAILURE = 'POST_USER_LOGIN_REQUEST_FAILURE';
const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE'

const login = (formData) => {

    return {
        type: POST_USER_LOGIN_REQUEST,
        formData
    }
}
const resetLogin = () => {

    return {
        type: RESET_LOGIN_STATE,
    }
}
export {
    login,
    resetLogin,
    RESET_LOGIN_STATE,
    POST_USER_LOGIN_REQUEST,
    POST_USER_LOGIN_REQUEST_SUCCESS,
    POST_USER_LOGIN_REQUEST_FAILURE,
};