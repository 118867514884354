const POST_SUPPERVISOR_LOGIN_REQUEST = "POST_SUPPERVISOR_LOGIN_REQUEST";
const POST_SUPPERVISOR_LOGIN_REQUEST_SUCCESS = "POST_SUPPERVISOR_LOGIN_REQUEST_SUCCESS";
const POST_SUPPERVISOR_LOGIN_REQUEST_FAILURE = "POST_SUPPERVISOR_LOGIN_REQUEST_FAILURE";
const RESET_SUPPERVISOR_LOGIN_STATE = "RESET_SUPPERVISOR_LOGIN_STATE";

const supervisorLogin = (formData) => {
  return {
    type: POST_SUPPERVISOR_LOGIN_REQUEST,
    formData
  };
};

const resetSupervisorLogin = () => {
  return {
    type: RESET_SUPPERVISOR_LOGIN_STATE,
  };
};
export {
  supervisorLogin,
  resetSupervisorLogin,
  RESET_SUPPERVISOR_LOGIN_STATE,
  POST_SUPPERVISOR_LOGIN_REQUEST,
  POST_SUPPERVISOR_LOGIN_REQUEST_SUCCESS,
  POST_SUPPERVISOR_LOGIN_REQUEST_FAILURE,
};
