import {
  SET_USER,
  RESET_USER,
} from "./Actions";
import { setItem } from "../../../../Utils/AsyncStore"
const initialState = {
  token: null,
  user: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      const userData = action.user
      if (userData.data?.user_type) {
        state.user = userData.data
      }
      if (state.user.user_type == 3 && state.user.id) {
        state.user.employee_id = state.user.id
      }
      setItem("authUser", userData)
      setItem("registerUser", {success : {...userData}})
      console.log(SET_USER);

      return {
        ...userData
      };
    }
    case RESET_USER: {
      console.log(RESET_USER);
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
