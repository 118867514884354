const POST_GET_PAYMENT_INTENT_REQUEST = 'POST_GET_PAYMENT_INTENT_REQUEST';
const POST_GET_PAYMENT_INTENT_REQUEST_SUCCESS =  'POST_GET_PAYMENT_INTENT_REQUEST_SUCCESS';
const POST_GET_PAYMENT_INTENT_REQUEST_FAILURE =  'POST_GET_PAYMENT_INTENT_REQUEST_FAILURE';
const RESET_GET_PAYMENT_INTENT_STATE ='RESET_GET_PAYMENT_INTENT_STATE'

const getPaymentIntent = (formData)=>{

    return {
        type: POST_GET_PAYMENT_INTENT_REQUEST,
        formData
    }
}

export {
    getPaymentIntent,
    RESET_GET_PAYMENT_INTENT_STATE,
    POST_GET_PAYMENT_INTENT_REQUEST,
    POST_GET_PAYMENT_INTENT_REQUEST_SUCCESS,
    POST_GET_PAYMENT_INTENT_REQUEST_FAILURE,
};