const POST_SET_AUTO_PAYMENT_REQUEST = 'POST_SET_AUTO_PAYMENT_REQUEST';
const POST_SET_AUTO_PAYMENT_REQUEST_SUCCESS = 'POST_SET_AUTO_PAYMENT_REQUEST_SUCCESS';
const POST_SET_AUTO_PAYMENT_REQUEST_FAILURE = 'POST_SET_AUTO_PAYMENT_REQUEST_FAILURE';
const RESET_SET_AUTO_PAYMENT_STATE = 'RESET_SET_AUTO_PAYMENT_STATE'

const autoPayment = (formData) => {

    return {
        type: POST_SET_AUTO_PAYMENT_REQUEST,
        formData
    }
}
const resetAutoPayment = () => {

    return {
        type: RESET_SET_AUTO_PAYMENT_STATE,

    }
}
export {
    autoPayment,
    resetAutoPayment,
    RESET_SET_AUTO_PAYMENT_STATE,
    POST_SET_AUTO_PAYMENT_REQUEST,
    POST_SET_AUTO_PAYMENT_REQUEST_SUCCESS,
    POST_SET_AUTO_PAYMENT_REQUEST_FAILURE,
};