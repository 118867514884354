import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  SafeAreaView,
  Pressable,
  ImageBackground,
} from "react-native";
import BackgroundImage from "../../../assets/images/bg_pro.png";
import HeaderLogo from "../../../assets/images/tutorial_logo.png";
import CenterImage from "../../../assets/images/s1_logo.png";
import { useTranslation } from 'react-i18next'

const IndustryProfessional = () => {
  const { t } = useTranslation();
  return (
    <ImageBackground
      source={BackgroundImage}
      style={styles.imageBack}
    >
      <View style={styles.header}>
        <Image
          source={HeaderLogo}
          resizeMode="contain"
          style={styles.headerLogo}
        />
      </View>
      <Image
        source={CenterImage}
        resizeMode="contain"
        style={styles.centerImage}
      />
      <Text style={styles.industryText}>{t("common:industry_professional")}</Text>
      <View style={styles.pointsContainer}>
        <Text style={styles.pointText}>{t("common:send_free_qoutes")}</Text>
        <Text style={styles.pointText}>{t("common:grow_your_business")}</Text>
        <Text style={styles.pointText}>{t("common:manage_your_employee")}</Text>
        <Text style={styles.pointText}>{t("common:track_clients")}</Text>
        <Text style={styles.pointText}>{t("common:utilize_the_free")}</Text>
        <Text style={styles.pointText}>{t("common:connect_directly_with")}</Text>
        <Text style={styles.pointText}>{t("common:select_your_services")}</Text>
        <Text style={styles.pointText}>{t("common:no_annual_fee")}</Text>
      </View>
    </ImageBackground>
  )
}

export default IndustryProfessional

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
  },
  imageBack: {
    flex: 1,
    resizeMode: "contain"
  },
  header: {
    paddingHorizontal: 15,
    height: 80,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 40
  },
  headerLogo: {
    height: 50,
    width: 150,
  },
  skipText: {
    fontSize: 17,
    color: "#FFF",
    fontWeight: "600",
  },
  centerImage: {
    marginVertical: 20,
    alignSelf: "center",
    height: 110,
    width: 110,
  },
  industryText: {
    alignSelf: "center",
    fontSize: 22,
    color: "#FFFFFF",
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center"
  },
  pointsContainer: {
    marginTop: 15,
    backgroundColor: "#760001",
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  pointText: {
    color: "#FFF",
    fontWeight: "300",
    fontSize: 14,
    paddingVertical: 5
  },
})