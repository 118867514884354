const POST_START_PROJECT_REQUEST = 'POST_START_PROJECT_REQUEST';
const POST_START_PROJECT_REQUEST_SUCCESS = 'POST_START_PROJECT_REQUEST_SUCCESS';
const POST_START_PROJECT_REQUEST_FAILURE = 'POST_START_PROJECT_REQUEST_FAILURE';
const RESET_START_PROJECT_STATE = 'RESET_START_PROJECT_STATE'

const startProject = (formData) => {

    return {
        type: POST_START_PROJECT_REQUEST,
        formData
    }
}
const resetStartProject = () => {

    return {
        type: RESET_START_PROJECT_STATE,
    }
}

export {
    startProject,
    resetStartProject,
    RESET_START_PROJECT_STATE,
    POST_START_PROJECT_REQUEST,
    POST_START_PROJECT_REQUEST_SUCCESS,
    POST_START_PROJECT_REQUEST_FAILURE,
};