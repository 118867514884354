import React, { useEffect } from "react";
import * as SplashScreen from "expo-splash-screen";
import * as Font from "expo-font";
import "./Constants/IMLocalize";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import RootStack from "./Navigation/RootStack";
import { StatusBar } from "expo-status-bar";
import { getItem } from "./Utils/AsyncStore";
import { useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Image, Keyboard, Pressable } from "react-native";
import Background from "./assets/images/bg.png";
import { ActivityIndicator } from "react-native";
import { Platform } from "react-native";

SplashScreen.preventAutoHideAsync();
let languageSelected;

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await Font.loadAsync({
          // Inter: require("./assets/fonts/Inter-Regular.ttf"),
          // Roboto: require("./assets/fonts/Roboto-Regular.ttf"),
          // RobotoMedium: require("./assets/fonts/Roboto-Medium.ttf"),
          // OpenSansLight: require("./assets/fonts/OpenSans-Light.ttf"),
          // "Open Sans": require("./assets/fonts/OpenSans-Regular.ttf"),
          // OpenSansBold: require("./assets/fonts/OpenSans-Bold.ttf"),
          // Gilroy: require("./assets/fonts/Gilroy-Light.otf"),
          // Montserrat: require("./assets/fonts/Montserrat-Regular.ttf"),
          // Poppins: require("./assets/fonts/Poppins-Regular.ttf"),
          // Silkscreen: require("./assets/fonts/Silkscreen-Regular.ttf"),
        });
        let bg = {
          ...Image.resolveAssetSource(require('./assets/images/bg.png')),
          cache: 'force-cache'
        }
        // await Image.prefetch(bg)
        languageSelected = await getItem("languageSelected");
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }
    prepare();
  }, []);

  if (!appIsReady) {
    if(Platform.OS === 'web') {
      return <ActivityIndicator size='large' color='red' />;
    }
    return;
  }

  return (
    <Pressable style={{ flex: 1 }} onPress={() => Keyboard.dismiss()}>
      <Provider store={store}>
        <SafeAreaProvider>
          <StatusBar style="light" backgroundColor="#EB2C3B" />
          <RootStack languageSelected={languageSelected} />
        </SafeAreaProvider>
      </Provider>
    </Pressable>
  );
}
