const POST_SUPERVISOR_UPDATE_PROFILE_REQUEST = "POST_SUPERVISOR_UPDATE_PROFILE_REQUEST";
const POST_SUPERVISOR_UPDATE_PROFILE_REQUEST_SUCCESS = "POST_SUPERVISOR_UPDATE_PROFILE_REQUEST_SUCCESS";
const POST_SUPERVISOR_UPDATE_PROFILE_REQUEST_FAILURE = "POST_SUPERVISOR_UPDATE_PROFILE_REQUEST_FAILURE";
const RESET_SUPERVISOR_UPDATE_PROFILE_STATE = "RESET_SUPERVISOR_UPDATE_PROFILE_STATE";

const supervisorUpdateProfile = (formData) => {
  return {
    type: POST_SUPERVISOR_UPDATE_PROFILE_REQUEST,
    formData
  };
};

const resetSupervisorUpdateProfile = () => {
  return {
    type: RESET_SUPERVISOR_UPDATE_PROFILE_STATE,
  };
};
export {
  supervisorUpdateProfile,
  resetSupervisorUpdateProfile,
  RESET_SUPERVISOR_UPDATE_PROFILE_STATE,
  POST_SUPERVISOR_UPDATE_PROFILE_REQUEST,
  POST_SUPERVISOR_UPDATE_PROFILE_REQUEST_SUCCESS,
  POST_SUPERVISOR_UPDATE_PROFILE_REQUEST_FAILURE,
};
