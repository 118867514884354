const POST_REMOVE_PIN_REQUEST = 'POST_REMOVE_PIN_REQUEST';
const POST_REMOVE_PIN_REQUEST_SUCCESS =  'POST_REMOVE_PIN_REQUEST_SUCCESS';
const POST_REMOVE_PIN_REQUEST_FAILURE =  'POST_REMOVE_PIN_REQUEST_FAILURE';
const RESET_REMOVE_PIN_STATE ='RESET_REMOVE_PIN_STATE'

const removePin = (formData)=>{

    return {
        type: POST_REMOVE_PIN_REQUEST,
        formData
    }
}

export {
    removePin,
    RESET_REMOVE_PIN_STATE,
    POST_REMOVE_PIN_REQUEST,
    POST_REMOVE_PIN_REQUEST_SUCCESS,
    POST_REMOVE_PIN_REQUEST_FAILURE,
};