const POST_UPDATE_NOTIFICATION_SETTING_REQUEST = "POST_UPDATE_NOTIFICATION_SETTING_REQUEST";
const POST_UPDATE_NOTIFICATION_SETTING_REQUEST_SUCCESS = "POST_UPDATE_NOTIFICATION_SETTING_REQUEST_SUCCESS";
const POST_UPDATE_NOTIFICATION_SETTING_REQUEST_FAILURE = "POST_UPDATE_NOTIFICATION_SETTING_REQUEST_FAILURE";
const RESET_UPDATE_NOTIFICATION_SETTING_STATE = "RESET_UPDATE_NOTIFICATION_SETTING_STATE";

const updateNotification = (formData) => {
  return {
    type: POST_UPDATE_NOTIFICATION_SETTING_REQUEST,
    formData
  };
};

const resetUpdateNotification = () => {
  return {
    type: RESET_UPDATE_NOTIFICATION_SETTING_STATE,
  };
};
export {
  updateNotification,
  resetUpdateNotification,
  RESET_UPDATE_NOTIFICATION_SETTING_STATE,
  POST_UPDATE_NOTIFICATION_SETTING_REQUEST,
  POST_UPDATE_NOTIFICATION_SETTING_REQUEST_SUCCESS,
  POST_UPDATE_NOTIFICATION_SETTING_REQUEST_FAILURE,
};
