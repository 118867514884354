const POST_DELETE_EMPLOYEE_REQUEST = "POST_DELETE_EMPLOYEE_REQUEST";
const POST_DELETE_EMPLOYEE_REQUEST_SUCCESS = "POST_DELETE_EMPLOYEE_REQUEST_SUCCESS";
const POST_DELETE_EMPLOYEE_REQUEST_FAILURE = "POST_DELETE_EMPLOYEE_REQUEST_FAILURE";

const deleteEmployee = (formData) => {
  return {
    type: POST_DELETE_EMPLOYEE_REQUEST,
    formData,
  };
};

export {
  deleteEmployee,
  POST_DELETE_EMPLOYEE_REQUEST,
  POST_DELETE_EMPLOYEE_REQUEST_SUCCESS,
  POST_DELETE_EMPLOYEE_REQUEST_FAILURE,
};
