const SET_CURRENT_STACK = "SET_CURRENT_STACK";
const RESET_CURRENT_STACK = "RESET_CURRENT_STACK";

const currentStack = (stack) => {
  return {
    type: SET_CURRENT_STACK,
    stack
  }
}
export {
  currentStack,
  SET_CURRENT_STACK,
  RESET_CURRENT_STACK,
};
