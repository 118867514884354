const POST_DELETE_ACCOUNT_REQUEST = "POST_DELETE_ACCOUNT_REQUEST";
const POST_DELETE_ACCOUNT_REQUEST_SUCCESS = "POST_DELETE_ACCOUNT_REQUEST_SUCCESS";
const POST_DELETE_ACCOUNT_REQUEST_FAILURE = "POST_DELETE_ACCOUNT_REQUEST_FAILURE";
const RESET_DELETE_ACCOUNT_STATE = "RESET_DELETE_ACCOUNT_STATE";

const deleteAccount = (formData) => {
  return {
    type: POST_DELETE_ACCOUNT_REQUEST,
    formData
  };
};
const resetDeleteAccount = () => {
  return {
    type: RESET_DELETE_ACCOUNT_STATE,
  };
};
export {
  deleteAccount,
  resetDeleteAccount,
  RESET_DELETE_ACCOUNT_STATE,
  POST_DELETE_ACCOUNT_REQUEST,
  POST_DELETE_ACCOUNT_REQUEST_SUCCESS,
  POST_DELETE_ACCOUNT_REQUEST_FAILURE,
};
