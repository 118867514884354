const POST_GET_PAYMENT_HISTORY_REQUEST = 'POST_GET_PAYMENT_HISTORY_REQUEST';
const POST_GET_PAYMENT_HISTORY_REQUEST_SUCCESS = 'POST_GET_PAYMENT_HISTORY_REQUEST_SUCCESS';
const POST_GET_PAYMENT_HISTORY_REQUEST_FAILURE = 'POST_GET_PAYMENT_HISTORY_REQUEST_FAILURE';
const RESET_GET_PAYMENT_HISTORY_STATE = 'RESET_GET_PAYMENT_HISTORY_STATE'

const getPaymentHistory = (formData) => {

    return {
        type: POST_GET_PAYMENT_HISTORY_REQUEST,
        formData
    }
}
const resetPaymentHistory = () => {

    return {
        type: RESET_GET_PAYMENT_HISTORY_STATE,
    }
}
export {
    getPaymentHistory,
    resetPaymentHistory,
    RESET_GET_PAYMENT_HISTORY_STATE,
    POST_GET_PAYMENT_HISTORY_REQUEST,
    POST_GET_PAYMENT_HISTORY_REQUEST_SUCCESS,
    POST_GET_PAYMENT_HISTORY_REQUEST_FAILURE,
};