const POST_REMOVE_DEBIT_CARD_REQUEST = 'POST_REMOVE_DEBIT_CARD_REQUEST';
const POST_REMOVE_DEBIT_CARD_REQUEST_SUCCESS = 'POST_REMOVE_DEBIT_CARD_REQUEST_SUCCESS';
const POST_REMOVE_DEBIT_CARD_REQUEST_FAILURE = 'POST_REMOVE_DEBIT_CARD_REQUEST_FAILURE';
const RESET_REMOVE_DEBIT_CARD_STATE = 'RESET_REMOVE_DEBIT_CARD_STATE'

const removeDebitCard = (formData) => {

    return {
        type: POST_REMOVE_DEBIT_CARD_REQUEST,
        formData
    }
}
const resetRemoveDebitCard = () => {

    return {
        type: RESET_REMOVE_DEBIT_CARD_STATE,
    }
}

export {
    removeDebitCard,
    resetRemoveDebitCard,
    RESET_REMOVE_DEBIT_CARD_STATE,
    POST_REMOVE_DEBIT_CARD_REQUEST,
    POST_REMOVE_DEBIT_CARD_REQUEST_SUCCESS,
    POST_REMOVE_DEBIT_CARD_REQUEST_FAILURE,
};