const POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST = "POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST";
const POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST_SUCCESS = "POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST_SUCCESS";
const POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST_FAILURE = "POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST_FAILURE";
const RESET_EMPLOYEE_ASSIGN_TOKEN_STATE = "RESET_EMPLOYEE_ASSIGN_TOKEN_STATE";

const assignEmployee = (formData) => {
  return {
    type: POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST,
    formData,
  };
};
const resetAssignEmployee = () => {
  return {
    type: RESET_EMPLOYEE_ASSIGN_TOKEN_STATE,
  };
};
export {
  assignEmployee,
  resetAssignEmployee,
  RESET_EMPLOYEE_ASSIGN_TOKEN_STATE,
  POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST,
  POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST_SUCCESS,
  POST_EMPLOYEE_ASSIGN_TOKEN_REQUEST_FAILURE,
};
