import {
  POST_SERVICE_CATEGORY_REQUEST,
  POST_SERVICE_CATEGORY_REQUEST_SUCCESS,
  POST_SERVICE_CATEGORY_REQUEST_FAILURE,
  RESER_SERVICE_CATEGORY_STATE
} from "./Action"

const initialState = {
  message: null,
  data: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SERVICE_CATEGORY_REQUEST: {
      console.log(POST_SERVICE_CATEGORY_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_SERVICE_CATEGORY_REQUEST_SUCCESS: {
      const { success } = action.payload;
      console.log(POST_SERVICE_CATEGORY_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: success.data,
        message: success.message,
      };
    }
    case POST_SERVICE_CATEGORY_REQUEST_FAILURE: {
      console.log(POST_SERVICE_CATEGORY_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: error.message,
      };
    }
    case RESER_SERVICE_CATEGORY_STATE: {
      console.log(RESER_SERVICE_CATEGORY_STATE);
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
