const POST_USER_LOGOUT_REQUEST = "POST_USER_LOGOUT_REQUEST";
const POST_USER_LOGOUT_REQUEST_SUCCESS = "POST_USER_LOGOUT_REQUEST_SUCCESS";
const POST_USER_LOGOUT_REQUEST_FAILURE = "POST_USER_LOGOUT_REQUEST_FAILURE";
const RESET_USER_LOGOUT_STATE = "RESET_USER_LOGOUT_STATE";

const logoutUser = (formData) => {
  return {
    type: POST_USER_LOGOUT_REQUEST,
    formData
  };
};
const resetLogoutUser = () => {
  return {
    type: RESET_USER_LOGOUT_STATE,
  };
};


export {
  logoutUser,
  resetLogoutUser,
  RESET_USER_LOGOUT_STATE,
  POST_USER_LOGOUT_REQUEST,
  POST_USER_LOGOUT_REQUEST_SUCCESS,
  POST_USER_LOGOUT_REQUEST_FAILURE,
};
