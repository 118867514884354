const POST_REVIEW_USER_REQUEST = "POST_REVIEW_USER_REQUEST";
const POST_REVIEW_USER_REQUEST_SUCCESS = "POST_REVIEW_USER_REQUEST_SUCCESS";
const POST_REVIEW_USER_REQUEST_FAILURE = "POST_REVIEW_USER_REQUEST_FAILURE";
const RESET_REVIEW_USER_STATE = "RESET_REVIEW_USER_STATE";

const reviewUser = (formData) => {
  return {
    type: POST_REVIEW_USER_REQUEST,
    formData
  };
};
const resetReviewUser = () => {
  return {
    type: RESET_REVIEW_USER_STATE,
  };
};

export {
  reviewUser,
  resetReviewUser,
  RESET_REVIEW_USER_STATE,
  POST_REVIEW_USER_REQUEST,
  POST_REVIEW_USER_REQUEST_SUCCESS,
  POST_REVIEW_USER_REQUEST_FAILURE,
};
