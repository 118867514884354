import { takeEvery, put, call } from 'redux-saga/effects';
import BASEURL from '../../URLs'
import { queryApi } from '../../QueryApi'
import {
  POST_PROJECT_DELETE_BY_PRO_REQUEST,
  POST_PROJECT_DELETE_BY_PRO_REQUEST_SUCCESS,
  POST_PROJECT_DELETE_BY_PRO_REQUEST_FAILURE,
} from './Actions';

function* handler() {
  yield takeEvery(POST_PROJECT_DELETE_BY_PRO_REQUEST, projectDeleteByPro);
}
function* projectDeleteByPro(action) {
  try {
    // API call
    console.log(action.formData)
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.API_PROJECT_DELETE_BY_PRO,
      method: 'POST',
      body: action.formData
    });
    //console.log('HIT',apiResponse)
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_PROJECT_DELETE_BY_PRO_REQUEST_SUCCESS,
        payload: apiResponse
      });

    } else {
      yield put({
        type: POST_PROJECT_DELETE_BY_PRO_REQUEST_FAILURE,
        payload: apiResponse.error
      });
    }


  } catch (err) {
    console.log(err);
    //alert(err)
    yield put({
      type: POST_PROJECT_DELETE_BY_PRO_REQUEST_FAILURE,
      payload: { error:{message: err} }
    });
  }
}

export { handler };