import {
  POST_REGISTER_PRO_USER_REQUEST,
  POST_REGISTER_PRO_USER_REQUEST_SUCCESS,
  POST_REGISTER_PRO_USER_REQUEST_FAILURE,
  RESET_REGISTER_STATE
} from "./Action"
import AsyncStorage from "@react-native-async-storage/async-storage";

const initialState = {
  message: null,
  data: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_REGISTER_PRO_USER_REQUEST: {
      console.log(POST_REGISTER_PRO_USER_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_REGISTER_PRO_USER_REQUEST_SUCCESS: {
      console.log(POST_REGISTER_PRO_USER_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case POST_REGISTER_PRO_USER_REQUEST_FAILURE: {
      console.log(POST_REGISTER_PRO_USER_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: error?.message,
        errorData: error
      }
    }
    case RESET_REGISTER_STATE: {
      console.log(RESET_REGISTER_STATE);
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export { reducer };
