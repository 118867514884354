const POST_GET_UGO_MAIN_SERVICES_REQUEST = 'POST_GET_UGO_MAIN_SERVICES_REQUEST';
const POST_GET_UGO_MAIN_SERVICES_REQUEST_SUCCESS =  'POST_GET_UGO_MAIN_SERVICES_REQUEST_SUCCESS';
const POST_GET_UGO_MAIN_SERVICES_REQUEST_FAILURE =  'POST_GET_UGO_MAIN_SERVICES_REQUEST_FAILURE';
const RESET_GET_UGO_MAIN_SERVICES_STATE ='RESET_GET_UGO_MAIN_SERVICES_STATE'

const getUgoMainServices = (formData)=>{

    return {
        type: POST_GET_UGO_MAIN_SERVICES_REQUEST,
        formData
    }
}

export {
    getUgoMainServices,
    RESET_GET_UGO_MAIN_SERVICES_STATE,
    POST_GET_UGO_MAIN_SERVICES_REQUEST,
    POST_GET_UGO_MAIN_SERVICES_REQUEST_SUCCESS,
    POST_GET_UGO_MAIN_SERVICES_REQUEST_FAILURE,
};