const POST_SEND_ACTUAL_PRICE_REQUEST = 'POST_SEND_ACTUAL_PRICE_REQUEST';
const POST_SEND_ACTUAL_PRICE_REQUEST_SUCCESS = 'POST_SEND_ACTUAL_PRICE_REQUEST_SUCCESS';
const POST_SEND_ACTUAL_PRICE_REQUEST_FAILURE = 'POST_SEND_ACTUAL_PRICE_REQUEST_FAILURE';
const RESET_SEND_ACTUAL_PRICE_STATE = 'RESET_SEND_ACTUAL_PRICE_STATE'

const sendActualPrice = (formData) => {

    return {
        type: POST_SEND_ACTUAL_PRICE_REQUEST,
        formData
    }
}
const resetSendActualPrice = () => {

    return {
        type: RESET_SEND_ACTUAL_PRICE_STATE,
    }
}
export {
    sendActualPrice,
    resetSendActualPrice,
    RESET_SEND_ACTUAL_PRICE_STATE,
    POST_SEND_ACTUAL_PRICE_REQUEST,
    POST_SEND_ACTUAL_PRICE_REQUEST_SUCCESS,
    POST_SEND_ACTUAL_PRICE_REQUEST_FAILURE,
};