const POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST = 'POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST';
const POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST_SUCCESS = 'POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST_SUCCESS';
const POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST_FAILURE = 'POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST_FAILURE';
const RESET_GET_SPECIALITY_SERVICE_CHAT_DETAIL_STATE = 'RESET_GET_SPECIALITY_SERVICE_CHAT_DETAIL_STATE'
const ADD_GET_SPECIALITY_SERVICE_CHAT_DETAIL = 'ADD_GET_SPECIALITY_SERVICE_CHAT_DETAIL'

const getSpecialityServiceChatDetail = (formData) => {

    return {
        type: POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST,
        formData
    }
}
const resetGetSpecialityServiceChatDetail = () => {

    return {
        type: RESET_GET_SPECIALITY_SERVICE_CHAT_DETAIL_STATE,

    }
}

const addSpecialityChat = (message) => {
    return {
        type: ADD_GET_SPECIALITY_SERVICE_CHAT_DETAIL,
        message,
    };
};
export {
    getSpecialityServiceChatDetail,
    resetGetSpecialityServiceChatDetail,
    addSpecialityChat,
    ADD_GET_SPECIALITY_SERVICE_CHAT_DETAIL,
    RESET_GET_SPECIALITY_SERVICE_CHAT_DETAIL_STATE,
    POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST,
    POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST_SUCCESS,
    POST_GET_SPECIALITY_SERVICE_CHAT_DETAIL_REQUEST_FAILURE,
};