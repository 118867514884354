export default {

  app_name: 'U’GO Pros',
  facebook_app_id: '1464655393624471',
  facebook_client_token: 'f93f25880f86c822b0eb4da7147c18c6',
  fb_login_protocol_scheme: 'fb1464655393624471',
  Continue_with_Facebook: 'تابع مع فيسبوك',
  Sign_in_with_Google: 'تسجيل الدخول بواسطة جوجل',
  create_account: 'إنشاء حساب الآن',
  create_account_: 'إنشاء حساب الآن',
  user_privacy: 'بيان خصوصية المستخدم',
  terms_conditions: 'الأحكام والشروط',
  forgot_pass: 'هل نسيت كلمة السر؟',
  terms: 'الأحكام والشروط',
  validationErrors: 'أخطاء التحقق من الصحة',
  meeting_note: '<b>ملحوظة:</b> بمجرد قبولك (المستخدم) التقدير ، سيتم أخذ هذا المبلغ من طريقة الدفع المسجلة في الملف. يمكنك إلغاء مشروع في أي وقت ، ولكن بمجرد قبول تقدير وإلغاء المشروع ، سيتم تحصيل 5٪ من هذا التقدير لرسوم المعالجة. في نهاية المشروع ، سيتم تعديل الدفعة وفقًا للتكلفة النهائية.',
  bsb_number: 'رقم التوصيل',
  account_number: 'رقم حساب',
  account_name: 'إسم الحساب',
  txt_response_rate: 'لقد قمت بالرد في غضون 24 ساعة على 0 من 0 طلب الحجز الذي تلقيته في العام الماضي.',
  sunday_text: 'شمس',
  monday_text: 'الإثنين',
  tuesday_text: 'الثلاثاء',
  wednesday_text: 'الأربعاء',
  thursday_text: 'خميس',
  friday_text: 'الجمعة',
  saturday_text: 'جلس',
  no_events: 'لا أحداث',
  call_failure: 'Failure!',

  invalid_attribute: 'فشل إنشاء التقويم ، \n\n قيمة غير صالحة للسمات! ، \n يرجى مراجعة &amp; ؛ حاول مرة أخرى!',
  your_earnings: 'أرباحك متاحة لك على الفور. لا داعي لانتظار دفعة أسبوعية. \n\n احصل على أرباحك عندما تريدها. صرف الأموال من خلال الدفع الفوري حتى 5 مرات في اليوم. \n',
  reviews_detail: 'يستعرض بالتفصيل',
  oops_enter_payout_method_: 'طريقة الدفع مفقودة. أدخل طريقة الدفع للحصول على أموال. لا تقلق ، معلوماتك آمنة من خلال معالجة مدفوعات الطرف الثالث.',
  start_your_connection_with: 'ابدأ اتصالك بـ',
  about_business: 'حول الأعمال',
  done: 'انتهى',
  account_type: 'نوع الحساب',
  checking: 'تدقيق',
  saving: 'إنقاذ',
  business_checking: 'فحص الأعمال',
  save: 'حفظ',
  ach_payment: 'ACH دفع',
  set_as_default: 'تعيين كافتراضي',
  feedback: 'استجابة',
  tell_me_how_so_we_can_match_you_with_better_jobs: 'أخبرني كيف يمكننا أن نطابقك مع وظائف أفضل.',
  i_am_busy: 'أنا مشغول',
  wrong_area: 'منطقة خاطئة',
  not_my_type_of_work: 'ليس نوع عملي',
  submit: 'إرسال',
  actions: 'أجراءات',
  accept_amp_schedule: 'كداتاقبول وجدولة',
  reject: 'يرفض',
  save_to_another: 'حفظ لآخر',
  date: 'تاريخ',
  request_details: 'طلب تفاصيل',
  description: 'وصف',
  media: 'وسائط',
  time: 'وقت',
  start_job: 'ابدأ الوظيفة',
  enter_final_cost_optional: 'أدخل التكلفة النهائية (اختياري)',
  upload_work_photo: 'تحميل صورة العمل',
  project_amount: 'مبلغ المشروع:',
  u_go_pros_fee: 'رسوم U\'GO Pros :',
  u_go_pros_fee_structure: 'هيكل رسوم U\'GO Pros',
  you_will_receive: 'ستستلم :',
  If_these_times_are_not_convenient: 'إذا كانت هذه الأوقات غير مناسبة بالنسبة لك، فلا تتردد في الدردشة مع المستخدم للعثور على وقت أفضل يعمل لكما.',
  _0: '$0',
  _10: '10%',
  submit_when_completed: 'قدم عند اكتماله',
  chat: 'محادثة',
  uploaded_pics: 'تم تحميل الصور',
  view_video: 'عرض الفيديو',
  contractor_note: 'ملاحظة المقاول',
  contractor_uploaded_pics: 'المقاول تحميل صور',
  location: 'موقع',
  assign_job: 'تعيين الوظيفة',
  change_employee: 'تغيير الموظف',
  project_already_assign: "تم تعيين المشروع بالفعل لهذا الموظف",
  send_official_cost: 'أرسل التكلفة الرسمية',
  new_project: 'مشروع جديد',
  add_document: 'أضف وثيقة',
  max_limit_1: '(الحد الأقصى 1)',
  next: 'التالي',
  add_employee: 'إضافة موظف',
  this_information_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'سيتم حفظ هذه المعلومات في بضع ثوانٍ وإرسالها إلى البريد الإلكتروني للمستخدم المقدم في النموذج أعلاه.',
  show_us_more_about_your_project: 'أظهر لنا المزيد عن مشروعك',
  add_photo: 'إضافة صورة',
  max_limit_5: '(الحد الأقصى 5)',
  add_video: 'أضف فيديو',
  add_work_video: 'إضافة فيديو العمل',
  max_duration_30sec: 'الحد الأقصى لمدة الفيديو 30 ثانية',
  where_is_the_service_location: 'أين موقع الخدمة؟',
  i_m_ready_for_quotes: 'أنا جاهز للاقتباسات',
  review: 'إعادة النظر',
  close: 'أغلق',
  select_any_option: 'حدد أي خيار',
  view_photo: 'عرض الصورة',
  view_photos: 'عرض الصور',
  already_pay_out: 'تدفع بالفعل',
  _0_usd: '$0 USD',
  expected_earnings: 'الأرباح المتوقعة',
  total: 'مجموع',
  _0_project: '0 المشروع',
  total_complete_project: 'إجمالي المشروع الكامل',
  total_active_project: 'إجمالي المشروع النشط',
  _0_response_rate: '0% معدل الاستجابة',
  response_rate: 'معدل الاستجابة',
  send: 'إرسال',
  auto_services: 'خدمات السيارات',
  project_details: 'خدمات السيارات',
  hourly_price: 'السعر بالساعة',
  n_a: 'غير متاح',
  portfolio: 'ملف',
  default_album: 'الألبوم الافتراضي',
  recent_activities: 'أنشطة حالية',
  _15_online_bids_100_response_rate: '15 عطاءات عبر الإنترنت ، معدل استجابة 100٪.',
  business_detail: 'تفاصيل العمل',
  add_review: 'إضافة مراجعة',
  reviews: 'المراجعات',
  select_your_service_category: 'حدد فئة الخدمة الخاصة بك',
  change_password: 'تغيير كلمة المرور',
  change_password_mark: 'تغيير كلمة المرور؟',
  please_enter_the_code_you_were_sent_by_email_and_reset_your_new_password: 'الرجاء إدخال الرمز الذي تم إرساله إليك عبر البريد الإلكتروني وإعادة تعيين كلمة المرور الجديدة.',
  messages: 'رسائل',
  contact_info: 'معلومات الاتصال',
  project_info: 'معلومات المشروع',
  category: 'فئة',
  view_document: 'عرض المستند',
  budget_dolar: 'الميزانية $',
  min_budget: 'الحد الأدنى للميزانية $',
  max_budget: 'الحد الأقصى للميزانية $',
  assigning_detail: 'تعيين التفاصيل',
  bid_detail: 'تفاصيل العطاء',
  notes: 'ملاحظات',
  contact_us: 'اتصل بنا',
  send_us_an_email: 'مراسلتنا على البريد الاليكتروني',
  let_us_know_what_you_think_don_t_hesitate_to_send_us_questions_or_comments_we_value_your_thoughts_and_will_respond_in_a_timely_manner: 'اسمحوا لنا أن نعرف ما هو رأيك! لا تتردد في إرسال أسئلة أو تعليقات إلينا. نحن نقدر أفكارك ، وسنرد في الوقت المناسب!',
  bonded_insured: 'مرهون / مؤمن عليه',
  accredited: 'معتمد',
  nari_member: 'عضو NARI',
  service_area: 'منطقة الخدمة',
  speciality_services: 'الخدمات المتخصصة',
  categories: 'فئات',
  add_utility_bill: 'إضافة فاتورة المرافق',
  portfolio_optional: 'ملف (خياري)',
  by_creating_account_you_agree_to_our: 'من خلال إنشاء حساب فإنك توافق على',
  register: 'تسجيل',
  create_profile: 'إنشاء ملف تعريف',
  date_of_birth: 'تاريخ الولادة',
  credits: 'الاعتمادات',
  u_go_reward_credits: 'يو’يذهب ائتمانات المكافآت',
  friend_referral: 'إحالة صديق',
  rate_contractor: 'معدل المقاول',
  _20_gift_card: '$20 كرت هدية',
  _20_instantly_for_your_friend_and_20_for_you_once_their_project_completed: '$20 على الفور لصديقك و 20 $ًا لك بمجرد اكتمال مشروعهم',
  redeem_credits: 'استرداد الاعتمادات',
  add: 'يضيف',
  default_payment: 'الدفع الافتراضي',
  documents: 'مستندات',
  edit_profile: 'تعديل الملف الشخصي',
  add_view: 'إضافة / عرض',
  service: 'خدمة',
  state: 'حالة',
  city: 'مدينة',
  update: 'تحديث',
  employee: 'الموظف',
  record_not_found: 'لم يتم العثور على السجل',
  employee_info: 'معلومات الموظف',
  mobile_no: 'رقم المحمول',
  email: 'البريد الإلكتروني',
  address: 'تبوك',
  delete: 'حذف',
  tell_us_more_about_your_project: 'أخبرنا المزيد عن مشروعك',
  project_description: 'وصف المشروع',
  what_is_your_budget: 'ما هي ميزانيتك؟',
  what_is_your_contact_info: 'ما هي معلومات الاتصال الخاصة بك؟',
  what_is_your_availability: 'ما هو تواجدك؟',
  _1st_option_date: 'تاريخ الخيار الأول',
  _2nd_option_date: 'تاريخ الخيار الثاني',
  estimates: 'تقديرات',
  your_available_time: 'وقتك المتاح',
  best_case_scenario: 'أفضل سيناريو',
  worst_case_scenario: 'السيناريو الأسوأ',
  exclusive_services: 'خدمات حصرية',
  forgot_password: 'هل نسيت كلمة السر',
  forgot_password_mark: 'هل نسيت كلمة السر؟',
  enter_your_email_below_to_receive_your_password_reset_instructions: 'أدخل بريدك الإلكتروني أدناه لتلقي تعليمات إعادة تعيين كلمة المرور الخاصة بك.',
  send_password: 'إرسال كلمة المرور',
  friends_referral: 'إحالة الأصدقاء',
  enter_friend_email_below_to_refer_your_friend: 'أدخل البريد الإلكتروني لصديق أدناه لإحالة صديقك.',
  _20_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_home_user_n_n_50_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_pro_user: '20 $ًا على الفور لصديقك الذي أكمل مشروعه الأول إذا كان الصديق المُحال هو مستخدم منزلي. \ n \ n 50 $ًا على الفور لصديقك الذي أكمل مشروعه الأول إذا كان الصديق المُحال هو مستخدم محترف.',
  help: 'مساعدة',
  faq: 'التعليمات',
  send_feedback: 'ارسل رأيك',
  home_services: 'خدمات منزلية',
  status: 'حالة',
  final_amount: 'القيمة النهائية',
  note: 'ملحوظة',
  login: 'تسجيل الدخول',
  user: 'المستعمل',
  remember_me: 'تذكرنى؟',
  connect_with_facebook: 'تواصل مع الفيسبوك',
  connect_with_google: 'متصل مع غوغل',
  pro: 'طليعة',
  map: 'خريطة',
  time_availability: 'توافر الوقت',
  accept: 'قبول',
  budget: 'ميزانية',
  click_to_request_on_site_visit: 'انقر لطلب زيارة الموقع',
  action: 'فعل',
  i_am_not_interested: 'لست مهتما',
  i_am_interested: 'أنا مهتم',
  my_rewards: 'مكافآتي',
  official_cost: 'التكلفة الرسمية',
  your_official_price_can_be_set_by_you_when_you_arrive_before_project_starts_from_here_client_can_still_refuse_service_it_is_recommended_to_stay_within_the_range_you_set: 'يمكنك تحديد سعرك الرسمي عند وصولك قبل بدء المشروع. من هنا لا يزال بإمكان العميل رفض الخدمة. يوصى بالبقاء ضمن النطاق الذي تحدده.',
  on_site_visit_requests: 'طلبات الزيارة في الموقع',
  pay_with_card: 'الدفع بالبطاقة ',
  paypal: 'باي بال',
  free_transaction: 'معاملة مجانية',
  no_minimum_transfer: 'لا يوجد حد أدنى للتحويل',
  delete_project: 'حذف المشروع',
  user_privacy_statement: 'بيان خصوصية المستخدم',
  credit_card: 'بطاقة ائتمان',
  bank_transfer: 'حوالة بنكية',
  country: 'دولة',
  usa: 'الولايات المتحدة الأمريكية',
  currency: 'عملة',
  usd: 'USD',
  add_payout: 'إضافة المدفوعات',
  schedules: 'جداول',
  pro_detail: 'طليعة التفاصيل',
  payout_detail: 'تفاصيل الدفع',
  account_info: 'معلومات الحساب',
  instant_payment: 'الدفع الفوري',
  routing_number: 'رقم التوصيل',
  auto_renew_your_subscription: 'تجديد تلقائي لاشتراكك',
  profile: 'الملف الشخصي',
  projects: 'المشاريع',
  reward: 'مكافأة',
  credits_points: 'نقاط الائتمان',
  enter_Pin: 'أدخل رقم التعريف الشخصي',
  view_detail: 'عرض التفاصيل',
  cancel_project: 'مشروع الغاء',
  edit_project: 'تحرير المشروع',
  reschedule_project: 'مشروع إعادة التشكيل',
  my_project: 'مشروعي',
  you_have_not_completed_any_project_yet: 'لم تكمل أي مشروع حتى الآن',
  speciality_services_pro: 'الخدمات المتخصصة برو',
  available_time: 'الوقت المتاح',
  decline: 'انخفاض',
  min_budget_normal: 'الحد الأدنى للميزانية',
  max_budget_normal: 'الحد الأقصى للميزانية',
  review_detail: 'مراجعة التفاصيل',
  use_amount_from_credits: 'استخدم المبلغ من الاعتمادات',
  your_availability_time_preference: 'التوفر الخاص بك - تفضيل الوقت',
  specialty_service_partners: 'شركاء الخدمة المتخصصة',
  special_pro_detail: 'تفاصيل PRO الخاصة',
  email_col: 'بريد الالكتروني:',
  mobile_no_col: 'رقم المحمول:',
  service_col: 'خدمة:',
  state_col: 'حالة:',
  cities_col: 'مدن:',
  detail: 'التفاصيل',
  request_info: 'اطلب معلومات',
  phone: 'هاتف',
  select_cities_you_offer_your_services: 'حدد المدن التي تقدم خدماتك فيها',
  specialty_service: 'خدمة متخصصة:',
  connecting_consumers_with: 'ربط المستهلكين بـ',
  highly_skilled_industry_professionals_in_their_communities: 'المتخصصين في الصناعة ذوي المهارات العالية في مجتمعاتهم.',
  are_you_looking_to_have_a_project_completed: 'هل تتطلع إلى إنجاز مشروع',
  or: 'أو',
  sign_up_as_an_industry_professional: 'قم بالتسجيل كمحترف في الصناعة?',
  select_all_categories_below: 'حدد جميع الفئات أدناه',
  employee_contact_info: 'معلومات الاتصال بالموظف',
  name: 'اسم',
  company: 'شركة',
  min_bid_amount: 'مبلغ العطاء الأدنى',
  max_bid_amount: 'أقصى مبلغ مزايدة',
  confirm_project: 'تأكيد المشروع',
  send_issue: 'إرسال المشكلة',
  accept_official_cost: 'قبول التكلفة الرسمية',
  subscription: 'الاشتراك',
  cancel: 'إلغاء',
  terms_and_conditions: 'الأحكام والشروط',
  skip: 'تخطى',
  signup: 'اشتراك',
  edit_info: 'تحرير المعلومات',
  this_inform_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: '.سيتم حفظ هذه المعلومات في بضع ثوانٍ وإرسالها إلى البريد الإلكتروني للمستخدم الوارد في النموذج أعلاه',
  upload_pictures: 'تحميل الصور',
  upload: 'تحميل',
  video: 'فيديو',
  wire: 'الأسلاك',
  _20_transaction_fee: '20 $ رسوم المعاملات.',
  minimum_transaction_250: 'الحد الأدنى للمعاملة 250 $',
  account_holder: 'مالك الحساب',
  bank_address: 'عنوان البنك',
  withdraw: 'ينسحب',
  available_balance: 'الرصيد المتوفر',
  withdraw_amount: 'سحب المبلغ',
  services_offered: 'الخدمات المقدمة',
  become_a_pro: 'كن محترفًا',
  add_event: 'أضف حدث',
  get_free_quotes: 'احصل على عروض أسعار مجانية',
  tell_us_about_the_project_you_would_like_completed_and_we_will_connect_you_with_several_industry_professionals_in_your_community_you_can_then_read_through_their_reviews_and_select_the_professional_to_work_on_your_project: 'أخبرنا عن المشروع الذي ترغب في استكماله وسنقوم بتوصيلك بالعديد من المتخصصين في هذا المجال في مجتمعك. يمكنك بعد ذلك قراءة مراجعاتهم واختيار المحترف للعمل في مشروعك.',
  use_instant_request: 'استخدم الطلب الفوري',
  in_a_hurry_or_have_an_emergency_project_tell_us_more_about_the_project_and_we_will_quickly_connect_you_to_the_first_available_professional_usually_within_2_hours: 'في عجلة من أمرك أو لديك مشروع طارئ؟ أخبرنا بالمزيد عن المشروع وسنقوم بتوصيلك بسرعة بأول محترف متاح (عادة في غضون ساعتين).',
  select_this_option_for_a_directory_list_of_our_specialty_professional_partners_in_your_city_find_your_lawyers_realtors_insurance_agents_doctors_etc: 'حدد هذا الخيار للحصول على قائمة دليل لشركائنا المحترفين المتخصصين في مدينتك. ابحث عن المحامين والوسطاء العقاريين ووكلاء التأمين والأطباء وما إلى ذلك.',
  instant_requests: 'طلبات فورية',
  leads: 'يؤدي',
  invoices: 'الفواتير',
  settings: 'إعدادات',
  downloading: 'جارى التحميل...',
  select_cities: 'حدد المدن',
  are_you_sure_you_want_to_move_back: 'هل أنت متأكد أنك تريد العودة.',
  you_will_lose_your_progress: 'سوف تفقد تقدمك.',
  no: 'لا',
  yes: 'نعم',
  your_account_is_under_review_please_allow_24_28: 'حسابك قيد المراجعة. يُرجى الانتظار 24-28 ساعة لتلقي رسالة تأكيد بالبريد الإلكتروني. تواصل مع support@ugopros.com لطرح أي أسئلة تتعلق بالحساب.',
  payout_detail_confirmation: 'تأكيد تفاصيل الدفع',
  please_confirm_all_info_entered_is_correct_we_want_to_make_sure_you_get_paid_for_the_projects_you_complete: 'يرجى التأكد من صحة جميع المعلومات المدخلة. نريد التأكد من حصولك على أموال مقابل المشاريع التي تكملها.',
  rate_user: 'قيم المستخدم',
  _20_instantly_for_you_and_your_friend_on_first_project_completion_if_the_referred_friend_is_home_user_n_50_instantly_for_you_on_first_project_completion_if_the_referred_friend_is_pro_user: '20 $ًا على الفور لك ولصديقك عند اكتمال المشروع الأول إذا كان الصديق المُحال هو المستخدم المنزلي. \ n 50 $ًا أمريكيًا على الفور عند اكتمال المشروع الأول إذا كان الصديق المُحال هو مستخدم محترف.',
  add_credit_for_the_bigger_jobs_pay_now_for_jobs_later: 'أضف رصيدًا للوظائف الأكبر ، وادفع الآن مقابل الوظائف لاحقًا.',
  add_credits: 'لا بطاقة افتراضية',
  no_default_card: 'لا بطاقة افتراضية',
  add_payment_method: 'إضافة طريقة دفع',
  payment_method: 'طريقة الدفع',
  payment_history: 'تاريخ الدفعات',
  add_credit_card: 'إضافة بطاقة ائتمان',
  add_credit_gift_code: 'أضف رصيدًا / رمز هدية',
  apply: 'يتقدم',
  payment_defaults: 'افتراضات الدفع',
  set_payment_default: 'تعيين الدفع الافتراضي ',
  default_payment_method: 'الطريقة الافتراضية للدفع',
  amount: 'كمية',
  view: 'رأي',
  revenue_detail: 'تفاصيل الإيرادات',
  total_revenue: 'إجمالي الإيرادات',
  total_withdraw: 'إجمالي الانسحاب',
  payable_balance: 'الرصيد الدائن',
  pending_withdraw: 'في انتظار سحب',
  new_: 'الجديد',
  active: 'نشيط',
  completed: 'منجز',
  notification: 'تنبيه',
  message: 'رسالة',
  write_your_message: 'اكتب رسالتك',
  sign_out: 'خروج',
  my_projects: 'مشاريعي',
  site_visit_request: 'طلب زيارة الموقع',
  archived: 'مؤرشف',
  canceled: 'ألغيت',
  consumer_benefits: 'فوائد المستهلك',
  normal: 'عادي',
  specialty: 'تخصص',
  invoiced: 'مفوترة',
  paid: 'دفع',
  enter_your_issue_detail: 'أدخل تفاصيل مشكلتك',
  add_new_event: 'أضف حدثًا جديدًا',
  tap_on_date_to_view_schedule: 'اضغط على التاريخ لعرض الجدول الزمني.',
  my_earnings: 'أجوري',
  withdraw_history: 'سحب التاريخ',
  waiting_for_response: 'بإنتظار الرد',
  confirm: 'تؤكد',
  would_you_like_to_create_a: 'هل ترغب في إنشاء ملف',
  Pin: 'دبوس؟',
  enter_pin: 'أدخل رقم التعريف الشخصي',
  enter_password: 'أدخل كلمة المرور',
  use_different_account: 'استخدم حسابًا مختلفًا',
  u_go_pros_inc: 'U\'GO Pros Inc',
  pay: 'يدفع',
  next_payment_schedule: 'جدول الدفع التالي',
  credit: 'الإئتمان',
  debit: 'المطلوب منه',
  continue_: 'استمر',
  schedule: 'جدول',
  payment: 'دفع',
  change_pin: 'تغيير دبوس',
  notifications: 'إشعارات',
  pin: 'دبوس',
  online_status: 'الموجودين',
  email_message: 'رسالة الكترونية',
  add_edit: 'إضافة / تحرير',
  selected_categories: 'فئات مختارة',
  dismiss: 'رفض',
  add_more: 'أضف المزيد',
  project: 'المشروع',
  earning: 'كسب',
  withdraw_status: 'حالة الانسحاب',
  project_leads: 'قادة المشروع',
  offline_mode: 'وضع غير متصل بالشبكة',
  ugo_to: 'ما يصل إلى $',
  pay_dol: 'دفع $',
  are_you_sure_you_want_to_credit: 'هل أنت متأكد أنك تريد إضافة مبلغ $',
  are_you_sure_you_want_to_delete: 'هل أنت متأكد أنك تريد حذف هذا المشروع من القائمة؟',
  logout: 'تسجيل خروج',
  complete: 'اكتمال',
  please_complete_your_profile: 'يرجى استكمال ملف التعريف الخاص بك.',
  ready_for_quotes: 'جاهز لعروض الأسعار',
  estimates_received: 'تم استلام التقديرات',
  pending_for_official_cost: 'في انتظار التكلفة الرسمية',
  project_started: 'بدأ المشروع',
  please_confirm_project: 'الرجاء تأكيد المشروع',
  successfully_completed: 'مكتملة بنجاح',
  before_payment: 'قبل الدفع',
  after_payment: 'بعد الدفع',
  issue_reported: 'تم الإبلاغ عن المشكلة',
  refunded_by_admin: 'تم ردها من قبل المسؤول',
  official_cost_recieved: 'تم استلام التكلفة الرسمية',
  waiting_for_pro_to_start: 'انتظر ل طليعة للبدأ',
  please_wait: 'انتظر من فضلك...',
  waiting_for_pros_response: 'في انتظار رد الايجابيات',
  started_by_pro: 'بدأ بواسطة Pro',
  need_for_rechedule: 'بحاجة لإعادة الجدولة',
  project_canceled: 'تم إلغاء المشروع',
  create_your_pin: 'قم بإنشاء رقم التعريف الشخصي الخاص بك',
  please_select_what_you_want_to_do: 'الرجاء تحديد ما تريد القيام به',
  remove_change_pin: 'إزالة / تغيير رقم التعريف الشخصي؟',
  remove_pin: 'إزالة الدبوس',
  successfully_scheduled: 'مجدول بنجاح',
  project_accepted: 'المشروع مقبول',
  accepted_by_pro: 'مقبول من قبل Pro',
  image_attched: 'الصورة المرفقة',
  video_attached: 'فيديو مرفق',
  valid_till: 'صالح لغاية : ',
  rejected: 'مرفوض',
  confirmed: 'مؤكد',
  are_you_sure_you_want_to_reject: 'هل أنت متأكد من أنك تريد رفض طلب الزيارة في الموقع بواسطة',
  are_you_sure_you_want_confirmed: 'هل أنت متأكد من أنك تريد تأكيد طلب الزيارة في الموقع بحلول',
  attached: '\" مرفق\"',
  cannot_upload_file_to_server: 'لا يمكن تحميل الملف إلى الخادم',
  update_review: 'تحديث المراجعة',
  response_rate_per: '٪ معدل الاستجابة',
  add_debit_card: 'إضافة بطاقة الخصم',
  add_payout_method: 'أضف طريقة الدفع',
  update_video: 'تحديث الفيديو',
  enter_friend_email_user: 'أدخل البريد الإلكتروني للصديق أدناه لإحالة صديقك كمستخدم.',
  enter_friend_email_pro: 'أدخل البريد الإلكتروني للصديق أدناه لإحالة صديقك إلى Pro.',
  credit_dol: 'الاعتمادات: $',
  free: 'حر',
  are_you_sure_you_want_delete: 'هل أنت متأكد أنك تريد حذف المشروع؟',
  are_you_sure_too_cancel: 'هل أنت متأكد أنك تريد إلغاء هذا المشروع؟',
  rating: 'تقييم',
  total_credits: 'إجمالي الائتمان:',
  cities: 'مدن',
  the_amount_you_have_submitted: 'المبلغ الذي قدمته($',
  if_you_agree_to_the_official: ') أقل من التكلفة الرسمية. إذا كنت توافق على التكلفة الرسمية ، فيرجى الضغط على خيار الدفع وإلا رفض أو اتصل بنا إذا كانت هناك أي مشاكل.',
  refund_dol: 'إعادة مال $',
  the_amount_you_have: 'المبلغ الذي قدمته $',
  otherwise_rejected_or_contact_us: '\" أكبر من التكلفة الرسمية. إذا كنت توافق ، فاضغط على خيار REFUND ، وإلا ارفض أو اتصل بنا إذا كانت لديك أي أسئلة.\"',
  if_there_are_any_problems: 'المبلغ الذي قدمته يساوي التكلفة الرسمية للمشروع. إذا كان كل شيء على ما يرام ، يرجى التأكيد أدناه ، وإلا فاتصل بنا إذا كانت هناك أي مشاكل.',
  are_you_sure_you_want_cancel: 'هل أنت متأكد أنك تريد إلغاء المشروع؟',
  if_you_submit_you_will_refund: 'v',
  are_you_sure_you_want_to_confirm: 'هل أنت متأكد أنك تريد تأكيد المشروع؟',
  pro_will_be_paid_for_this: 'سيتم دفع طليعة مقابل هذا.',
  pending: 'قيد الانتظار',
  accepted: 'وافقت',
  referral_code: 'كود الإحالة',
  expiry_col: 'انقضاء:',
  next_payment_schedule_col: '"جدول الدفع التالي: "',
  status_active: 'الحالة: نشط',
  status_cancelled: 'الحالة: ملغاة',
  update_payout: 'تحديث العائد',
  please_add_it_by_pressing: 'لم تقم بإضافة طريقة الدفع حتى الآن. يرجى إضافته بالضغط على زر "إضافة المدفوعات" في الأسفل.',
  onsite_visit_request_sent: 'تم إرسال طلب زيارة الموقع',
  onsite_visit_request_rejected: 'تم رفض طلب زيارة الموقع',
  onsite_visit_scheduled: 'الزيارة في الموقع المجدولة',
  waiting_for_acceptance: 'في انتظار القبول',
  qoute_and_onsite_visit: 'تم إرسال طلب عرض أسعار وزيارة في الموقع',
  qoute_sent: 'أرسل اقتباس',
  waiting_for_official_cost: 'في انتظار التكلفة الرسمية',
  assigned_to_employee: 'مخصص للموظف',
  rejected_by_employee: 'رفضه الموظف',
  job_started: 'بدأت الوظيفة',
  waiting_for_confirmation: 'في انتظار التأكيد',
  official_cost_sent: 'التكلفة الرسمية المرسلة',
  project_awarded: 'منح المشروع',
  in_progress: 'في تقدم',
  closed: 'مغلق',
  bid_amount: 'قيمة المزايدة : $',
  exit: 'خروج',
  enter_old_pin: 'أدخل رقم التعريف الشخصي القديم',
  confirm_pin: 'تأكيد PIN',
  back: 'BACK',
  wrong_pin_keyboard: 'لوحة مفاتيح دبوس خاطئة',
  enter_new_pin: 'أدخل رقم التعريف الشخصي الجديد',
  wrong_pin_locked: 'دبوس خاطئ مغلق',
  user_guide: 'دليل المستخدم',
  job_no: 'وظيفة #',
  project_col: 'المشروع :',
  posted_by_col: '"منشور من طرف: "',
  online_mode: 'وضع على شبكة الإنترنت',
  are_you_sure_accept: 'هل أنت متأكد أنك تريد قبول',
  are_you_sure_want_reject: 'هل أنت متأكد أنك تريد "رفض"',
  assign_to_col: '" يسند إلى:  "',
  status_assigned: 'حالة : مكلف',
  status_rejected: 'الحالة: مرفوضة',
  status_waiting: 'الحالة: في انتظار التأكيد',
  final_amount_col: 'المبلغ النهائي: $',
  issue_detail: 'تفاصيل القضية',
  official_cost_dol: 'التكلفة الرسمية : $',
  project_title_col: '"عنوان المشروع: "',
  photos_attached: 'الصور المرفقة',
  video_attached: 'الفيديو المرفق',
  document_attached: 'المستند المرفق',
  please_confirm_final_amount: 'الرجاء تأكيد المبلغ النهائي $',
  once_you_tap_confirm_you_will: '. بمجرد النقر فوق "تأكيد" لن تتمكن من تغيير التكلفة النهائية',
  employee_note: 'ملاحظة الموظف',
  employee_upload_pics: 'الموظف تحميل الصور',
  official_document: 'وثيقة رسمية',
  are_you_sure_you_want_to_delete_emp: 'هل أنت متأكد أنك تريد حذف هذا الموظف؟',
  employee_since: '"موظف منذ ذلك الحين "',
  are_you_sure_to_assign: 'هل أنت متأكد أنك تريد تعيين المشروع إلى',
  i_am_intrested: 'هل أنت متأكد من أنك تريد دفع 10 $ات أمريكية مقابل الزيارة في الموقع؟ سيتم رد هذه الرسوم فقط إذا قبل العميل مزايدتك. \ n \ n إذا كنت تفضل إرسال تقديرات مجانًا بدون زيارة الموقع ، فانقر فوق إلغاء وحدد "أنا مهتم" في الجزء السفلي من الشاشة.',
  pay_again: 'ادفع مرة أخرى',
  please_recharge_your_account: 'تم رفض طلب الزيارة في الموقع الخاص بك بسبب عدم كفاية الرصيد في حسابك. يرجى إعادة شحن حسابك وحاول مرة أخرى.',
  your_on_site_visit: 'تم رفض طلب الزيارة في الموقع الخاص بك ولن يتم تحصيل 10 $ات منك.',
  budget_dol: 'ميزانية : $',
  please_confirm_if_you_will: 'طلبات فورية يتطلع المستخدمون لبدء المشروع في غضون ساعتين. يرجى "تأكيد" إذا كنت ستكون متاحًا',
  debit_card: 'بطاقة ائتمان',
  resubscribe: 'إعادة الاشتراك',
  project_no: '"المشروع# "',
  i_am_ready: 'أنا على استعداد للتواصل مع محترف',
  edit: 'EDIT',
  language: 'لغة',
  english: 'English',
  spanish: 'Spanish',
  tamil: 'Tamil',
  hindi: 'Hindi',
  arabic: 'Arabic',
  below_are_the_cities: 'Below are the cities you selected, wish to continue?\n\n',
  contractor_notes_n_if_project: 'ملاحظات المقاول \ n (إذا لم يكتمل المشروع ، فاخرج فقط عند الانتهاء ثم استأنف المهمة مرة أخرى لاحقًا)',
  first_name: 'الاسم الاول',
  last_name: 'الكنية',
  password: 'كلمة المرور',
  confirm_password: 'تأكيد كلمة المرور',
  confirm_password_optional: 'تأكيد كلمة المرور (اختياري)',
  verification_code: 'رمز التحقق',
  verify_pin: 'التحقق من الرمز السري',
  new_password: 'كلمة السر الجديدة',
  your_name: 'اسمك',
  contact_person_first_name: 'الاسم الأول لجهة الاتصال',
  contact_person_last_name: 'الاسم الأخير لجهة الاتصال',
  company_name: 'اسم الشركة',
  company_ein_tax_id: 'رقم تعريف الشركة / معرف الضريبة',
  licence_no_optional: 'رقم الترخيص (اختياري)',
  tap_to_create_project: 'انقر لإنشاء مشروع',
  home_auto_or_exclusive_services: 'السيارات, المنزلية أو الخدمات الحصرية',
  friend_pro_referral: 'صديق / محترف الإحالة',
  enter_title: 'أدخل العنوان',
  account_iban: 'Account/ IBAN',
  swift_bic_code: 'Swift/ Bic code',
  new_password_optional: 'كلمة مرور جديدة (اختياري)',
  reach_out_directly_with_this: 'تواصل مباشرة مع هذا المحترف من خلال الدردشة هنا. ما عليك سوى البدء في الكتابة في هذا المربع والتأكيد عند الانتهاء',
  company_phone_number: 'رقم هاتف الشركة',
  paypal_account: 'Paypal Account',
  card_number: 'رقم البطاقة',
  enter_project_description: 'أدخل وصف المشروع',
  please_enter_project_description: 'الرجاء إدخال وصف المشروع.',
  please_provide_information: 'يرجى تقديم معلومات حول المشاريع السابقة وشركتك. قم بتضمين أي أسئلة قد تكون لديكم.',
  hourly_estimate_optional: 'تقدير كل ساعة $ (اختياري)',
  total_min_estimate_best_case_scenario: 'إجمالي الحد الأدنى لتقدير $ (أفضل سيناريو)',
  total_max_estimate_worst_case_scenario: 'إجمالي الحد الأقصى لتقدير $ (أسوأ سيناريو)',
  please_name_your_project: 'الرجاء تسمية مشروعك',
  social_security_number: 'رقم الضمان الاجتماعي',
  years_in_business: 'سنوات في الأعمال',
  old_password_optional: 'كلمة المرور القديمة (اختياري)',
  other_category_optional: 'فئة أخرى (اختياري)',
  wrong_email_password: 'البريد الإلكتروني / كلمة المرور خاطئة',
  please_enter_your_email: 'رجاءا أدخل بريدك الإلكتروني',
  please_enter_your_name: 'من فضلك أدخل إسمك',
  please_enter_your_project_name: 'الرجاء إدخال اسم مشروعك',
  please_enter_discription: 'الرجاء إدخال وصف المشروع',
  please_enter_your_min: 'الرجاء إدخال الحد الأدنى لميزانيتك $',
  please_enter_your_max_budget: 'الرجاء إدخال الحد الأقصى لميزانيتك $',
  please_enter_your_valid_min: 'الرجاء إدخال ميزانيتك الدنيا الصحيحة $ ',
  please_enter_your_max_valid_budget: 'الرجاء إدخال ميزانيتك القصوى الصحيحة $ ',
  min_budget_should_less_than_max: 'الحد الأدنى للميزانية يجب أن يكون أقل من الحد الأقصى للميزانية',
  please_enter_your_budget: 'الرجاء إدخال ميزانيتك $',
  please_enter_your_budget_at_least_1: 'الرجاء إدخال ميزانيتك على الأقل $1',
  please_select_date_before_time: 'يرجى تحديد التاريخ قبل الوقت المحدد',
  no_user_found: 'لم يتم العثور على المستخدم',
  please_enter_your_message: 'أدرج رسالتك من فضلك',
  please_enter_valid_email: 'الرجاء إدخال بريد إلكتروني صحيح',
  please_enter_your_card_no: 'الرجاء إدخال رقم بطاقتك',
  please_enter_cvc: 'الرجاء إدخال رمز التحقق من البطاقة (CVC) الخاص بك',
  please_select_future_date: 'الرجاء تحديد تاريخ في المستقبل',
  pics_not_available: 'الصورة غير متوفرة!',
  document_not_available: 'المستند غير متوفر!',
  video_not_available: 'الفيديو غير متوفر!',
  please_enter_official_cost: 'الرجاء إضافة التكلفة الرسمية.',
  please_add_official_cost: 'الرجاء إضافة التكلفة الرسمية على الأقل',
  please_enter_your_password: 'الرجاء إدخال كلمة المرور الخاصة بك',
  password_dose_not_matched: 'كلمة السر غير متطابقة!',
  please_enter_your_first_name: 'يرجى ادخال الاسم الاول',
  please_enter_your_last_name: 'الرجاء إدخال اسمك الأخير',
  please_enter_your_phone: 'الرجاء إدخال رقم هاتفك',
  please_enter_password: 'من فضلك أدخل رقمك السري',
  password_must_contain_a_minimum: 'يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل',
  pin_successfully_change: 'دبوس التغيير بنجاح',
  please_enter_your_confirm_password: 'الرجاء إدخال تأكيد كلمة المرور الخاصة بك',
  please_enter_your_address: 'الرجاء إدخال عنوانك',
  phone_length_10: 'يجب أن يكون رقم الهاتف 10',
  please_enter_contact_first_name: 'الرجاء إدخال الاسم الأول لجهة الاتصال',
  please_enter_contact_lastname: 'الرجاء إدخال الاسم الأخير لجهة الاتصال',
  password_dose_not_match: 'تأكيد كلمة المرور لا يتطابق',
  please_enter_dob: 'الرجاء إدخال تاريخ الميلاد.',
  please_enter_company_name: 'الرجاء إدخال اسم الشركة',
  please_enter_mobile_number: 'الرجاء إدخال رقم الجوال',
  please_enter_address: 'الرجاء إدخال العنوان',
  please_enter_contractor_notes: 'الرجاء إدخال ملاحظات المقاول',
  please_enter_at_least_one_work_photo: 'يرجى تحميل صورة عمل واحدة على الأقل',
  you_must_be_18: 'يجب أن يكون عمرك 18 عامًا أو أكثر لاستخدام خدماتنا. إذا كانت لديك أية مشكلات ، فيرجى الاتصال بنا على support@ugopros.com',
  please_agree_to_our: 'يرجى الموافقة على الشروط والأحكام الخاصة بنا',
  please_select_your_available: 'يرجى تحديد الوقت المتاح لديك',
  please_enter_issue_detail: 'الرجاء إدخال تفاصيل المشكلة',
  please_add_profile_photo: 'الرجاء إضافة صورة الملف الشخصي',
  please_set_your_loaction: 'الرجاء تحديد موقعك',
  please_select_address: 'الرجاء تحديد العنوان',
  please_select_address_from: 'يرجى تحديد العنوان من القائمة المنسدلة',
  please_add_availability_date: 'الرجاء إضافة تاريخ التوفر',
  please_add_available_time: 'الرجاء إضافة وقت التوفر',
  enter_address_or_single_press_on_map: 'أدخل العنوان أو اضغط على الخريطة',
  type_to_search: 'اكتب للبحث',
  _3rd_option_date: 'تاريخ الخيار الثالث',
  you_cannot_select_past_date: 'لا يمكنك تحديد التاريخ الماضي',
  thank_you_for_uploading_document: 'شكرا لك على تحميل الوثيقة. يرجى انتظار المراجعة حتى يتم تنشيط الحساب.',
  reason: 'سبب',
  enter_amount: 'أدخل المبلغ',
  please_enter_amount: 'الرجاء إدخال المبلغ!',
  home: 'المنزل',
  track_earning: 'تتبع الأرباح',
  manage_employees: 'إدارة الموظفين',
  payout_method: 'طريقة الدفع',
  payments: 'المدفوعات',
  offers: 'عروض',
  special_requests: 'طلبات خاصة',
  first_project_completion: '$20 اكتمال المشروع الأول إذا كان الصديق المشار إليه هو المستخدم المنزلي. \n$50 على الفور بالنسبة لك عند اكتمال المشروع الأول إذا كان الصديق المشار إليه هو مستخدم محترف.',
  confirmation: 'تأكيد',
  refer_a_pro: 'إحالة محترف',
  refer_your_friend: 'ادع صديقك',
  loading_payment_method: 'طرق الدفع ...',
  enabling_instant_payment: 'تمكين طرق الدفع الفوري ...',
  loading_data: 'تحميل البيانات...',
  loading_payment_detail: 'تحميل تفاصيل الدفع ...',
  it_will_take_some_time: 'وسوف يستغرق بعض الوقت....',
  sending: 'إرسال...',
  updating: 'Updating...',
  setting_default_payment_method: 'تحديد طريقة الدفع الافتراضية ...',
  compressing_the_size: 'ضغط حجم الفيديو.',
  uploading_data: 'رفع البيانات',
  your_account_suspended: 'تم تعليق حسابك لانتهاء صلاحية تأمين شركتك. يرجى تحميل معلومات التأمين الجديدة.',
  profile_incomplete: 'الملف الشخصي غير مكتمل',
  please_complete_profile: 'يرجى استكمال ملف التعريف الخاص بك',
  please_enter_your_date_of_birth: 'الرجاء إدخال تاريخ ميلادك لتفعيل حسابك.',
  please_enter_account_holder_name: 'الرجاء إدخال اسم صاحب الحساب الخاص بك.',
  please_enter_your_routing_number: 'الرجاء إدخال رقم التوجيه الخاص بك',
  please_enter_your_account_number: 'الرجاء إدخال رقم حسابك',
  sesson_has_been_expire: 'لقد انتهت جلستك ، يرجى تسجيل الدخول مرة أخرى',
  internet_connection_issue: 'مشكلة في الاتصال بالإنترنت ، يرجى المحاولة مرة أخرى لاحقًا',
  please_check_your_email_for_our: 'يرجى التحقق من بريدك الإلكتروني لاستجابتنا. نحن نقدر ملاحظاتك. يجب أن تتلقى ردًا منا في غضون 24 ساعة.',
  account_has_been_updated: 'تم تحديث الحساب بنجاح. يرجى انتظار المراجعة حتى يتم تنشيط الحساب.',
  project_has_been_completed: 'اكتمل المشروع برجاء انتظار التأكيد من المستخدم.',
  you_have_not_added_a_payout: 'لم تقم بإضافة طريقة الدفع حتى الآن. الرجاء إضافته أولاً.',
  information: 'معلومة!',
  if_profile_needs_updated: 'إذا كان الملف الشخصي يحتاج إلى تحديث فلا تتردد في القيام بذلك.',
  please_add_your_credit: 'الرجاء إضافة بطاقة الائتمان الخاصة بك أولا.',
  for_sercurity_and_payout: 'للأمان وميزات الدفع مزيد من المعلومات المطلوبة.',
  update_app: 'UPDATE APP',
  not_now: 'ليس الآن',
  your_official_price_can_be: 'يمكنك تحديد سعرك الرسمي عند وصولك قبل بدء المشروع. من هنا لا يزال بإمكان العميل رفض الخدمة. يوصى بالبقاء ضمن النطاق الذي تحدده.',
  thank_you_for_your_payment: 'شكرا لتسديدك. الآن يمكنك الذهاب لزيارة الموقع.',
  request_sent_successfully: 'تم إرسال الطلب بنجاح. سيتم إخطارك عندما يقبل العميل طلب الزيارة في الموقع الخاص بك.',
  credit_card_is_valid: 'بطاقة الائتمان غير صالحة',
  are_your_sure_you_want_to_delete: 'هل أنت متأكد أنك تريد حذف هذه البطاقة؟',
  would_you_like_to_auto: 'هل ترغب في التجديد التلقائي لاشتراكك',
  please_select_your_subscription: 'الرجاء تحديد اشتراكك.',
  alert: 'انذار!',
  we_hate_to: 'نحن نكره أن نراك تذهب ، إذا كان لديك أي مشاكل فلا تتردد في التواصل معنا على الرقم 877-733-2425 أو info@ugopros.com إلغاء اشتراكك سيؤدي إلى تعليق حسابك في نهاية دورة الفواتير الخاصة بك.',
  yes_cancel: 'نعم إلغاء',
  no_i_will_stay: 'لا ، سأبقى',
  you_have_not_added: 'لم تقم بإضافة بطاقة الخصم الخاصة بك حتى الآن.',
  please_select_at_least: 'الرجاء تحديد فئة فرعية واحدة على الأقل.',
  you_have_selected_to: 'لقد اخترت تشغيل الدفع التلقائي. تأكيد إذا كنت ترغب في الاستمرار؟',
  turn_off: 'اطفئه',
  are_you_sure_you_want_to_delete_this: 'هل أنت متأكد أنك تريد حذف طريقة الدفع هذه؟',
  if_project_has_not: 'إذا لم يتم تأكيد المشروع في غضون 14 يومًا ، فسيتم تحرير إجمالي المبلغ المقدر إلى حسابك.',
  video_lenght_should_30: 'يجب أن يكون طول الفيديو 30 ثانية كحد أقصى.',
  you_can_not_uodalog_5: 'لا يمكنك تحميل أكثر من 5 صور.',
  please_enter_date_of_birth: 'الرجاء إدخال تاريخ الميلاد لتفعيل حسابك.',
  congratulation_your_account: 'تهنئة! تم تنشيط حسابك الآن.',
  not_availeable: 'غير متوفر. لمزيد من التفاصيل اتصل بنا.',
  please_enter_account_iban: 'الرجاء إدخال الحساب / IBAN',
  please_enter_swift: 'الرجاء إدخال رمز Swift / Bic',
  please_enter_aba: 'الرجاء إدخال ABA / رقم التوجيه',
  please_enter_account_holder: 'الرجاء إدخال اسم صاحب الحساب',
  media_not_available: 'الوسائط غير متوفرة!',
  please_enter_aba_routing: 'الرجاء إدخال ABA / رقم التوجيه',
  please_enter_bank_account_number: 'الرجاء إدخال رقم الحساب المصرفي',
  please_enter_back_name: 'الرجاء إدخال اسم البنك',
  please_add_account: 'الرجاء إضافة نوع الحساب',
  please_enter_bank_address: 'الرجاء إدخال عنوان البنك',
  instant_request: 'طلب فوري',
  free_online_leads: 'العروض المجانية عبر الإنترنت',
  manage_all_your: '• إدارة جميع موظفيك أو فرقك\n',
  here_you_will_see: 'هنا سترى عملاء متوقعين لمشروع جديد حتى تتمكن من إرسال عروض أسعار مجانية.',
  here_you_are_able_to_chat: 'هنا يمكنك الدردشة مع عملائك وإبقائك على نفس الصفحة طوال المشروع.',
  we_are_always_here: 'نحن دائما هنا لاجلك. نحن نقدر أفكارك. هنا يمكنك مشاركة أفكارك أو مخاوفك معنا.',
  here_is_you_able_to_chat: 'هنا يمكنك الدردشة مع عملائك وإبقائك على نفس الصفحة طوال المشروع.',
  we_are_always_here_for_you: 'نحن دائما هنا لاجلك. نحن نقدر أفكارك. هنا يمكنك مشاركة أفكارك أو مخاوفك معنا.',
  here_you_can_make: 'هنا يمكنك أن تجعل نفسك متاحًا للطلبات الأكثر إلحاحًا. يشير خيار الوضع عبر الإنترنت إلى أنك ستكون متاحًا لبدء المشاريع في غضون ساعتين من الطلب.',
  there_is_nothing: 'لا يوجد شيء أكثر جنونًا من الحجز المزدوج. هنا يمكنك الحفاظ على الجدول الزمني الخاص بك حتى تعرف ما الذي ينتظرنا. جدولة مشاريعك بدقة مع هذه الميزة.',
  why_not_grow: 'لماذا لا تنمي عملك ليكون لديك العديد من الموظفين أو الفرق؟ هنا يمكنك إدارة موظفيك حتى تتمكن من تعيين المشاريع التي حصلت عليها!',
  back_button: 'زر العودة',
  loagin_button: 'زر تسجيل الدخول',
  here_is_where_you_see: 'هنا حيث ترى رصيدك المتاح. لا يمكنك كسب أرصدة فحسب ، بل يمكنك أيضًا إضافة أرصدة للدفع مقابل تلك المشاريع الأكثر تكلفة.',
  we_are_here_to_help: 'نحن هنا لمساعدتك في إدارة مشاريعك. سواء كان لديك طلب عاجل أو مشروع بسيط ، فهذا هو المكان الذي تبدأ فيه رحلة البحث عن محترف. سيرسل الكثيرون عرض أسعار ، لكنك تختار من سيكمل المهمة.',
  here_you_will_see_the_status: 'هنا سترى حالة المشاريع التي أدخلتها. سترى ما إذا كان المشروع قد تم إرسال عرض أسعار ، أو ما إذا كان المشروع قد بدأ. نسير معك حتى تشعر بالرضا التام عن مشروعك النهائي.',
  this_is_where_you: 'هذا هو المكان الذي يمكنك فيه التحقق من حالة تلك المشاريع الأكثر إلحاحًا. لذلك إذا كانت هناك حالة طارئة في معظم الحالات ، فلدينا محترفون للمساعدة في غضون ساعتين من الطلبات.',
  here_you_are_able: 'هنا يمكنك الدردشة مع أخصائي الخدمة الخاص بك مما يبقيك على نفس الصفحة طوال اكتمال المشروع.',
  we_are_always_here_for: 'نحن دائما هنا لاجلك. نحن نقدر أفكارك. هنا يمكنك مشاركة أفكارك أو مخاوفك معنا.',
  send_free_qoutes: '• إرسال عروض أسعار مجاني',
  grow_your_business: '• ينمو عمل',
  manage_your_employee: '• إدارة موظفي',
  track_clients: '• تتبع العملاء والوظائف والفواتي',
  utilize_the_free: '• استخدم برنامج CRM المجان',
  connect_directly_with: '• تواصل مباشرة مع المستهلكين حول المشاري',
  select_your_services: '• حدد موقع الخدمة الخاص ب',
  no_annual_fee: '• بدون رسوم سنوية',
  industry_professional: 'الفوائد المهنية للصناعة',
  connect_with_a_vetted_pro: '• تواصل مع محترف تم فحصه في مجتمع',
  recieve_multiple_qoute: '• تلقي عروض أسعار متعددة للمشاري',
  read_reviews_of_pros: '• اقرأ مراجعات الايجابيات وقار',
  manage_all_projects: '• إدارة جميع المشاريع في التطبي',
  communicate_with_your: '• تواصل مع مزود الخدمة الخاص بك',
  send_payment_once_project: '• أرسل الدفع بمجرد اكتمال المشروع',
  refer_a_friend_and: '• قم بإحالة صديق واستلم رصيد الحساب',
  provide_accurate_qoute: '• قدم اقتباسات دقيقة بناء على الصور والدردشة',
  exposure_for_your: '• التعرض لشركتك',
  live_mode_for_instant: '• الوضع المباشر للمشاريع الفورية / العاجلة أو المجدولة',
  choose_the_jobs: '•اختر الوظائف التي تريدها',
  crm_platform_for_great: '• منصة CRM لإرضاء العملاء بشكل كبير',
  professional_chat_funtion: '• وظيفة الدردشة المهنية',
  keep_track_of_all: '• تتبع جميع مشاريعك وفواتيرك',
  free_business_tools: 'أدوات عمل مجانية',
  please_enter_your_project: 'الرجاء إدخال وصف المشروع الخاص بك لمتابعة الدردشة.',
  ok: 'موافق',
  jobs: 'وظائف',
  requests: 'الطلبات',
  got_it: 'فهمتك',

  please_enter_ein: 'الرجاء إدخال رقم تعريف صاحب العمل / معرف الضريبة',
  please_enter_social_number: 'الرجاء إدخال رقم الضمان الاجتماعي.',
  please_enter_correct_ssn: 'الرجاء إدخال رقم الضمان الاجتماعي الصحيح.',
  please_check_the_box: 'يرجى تحديد مربع السندات / التأمين وإظهار دليل على السند / التأمين',
  please_check_the_box_only: 'يرجى تحديد مربع السندات / التأمين',
  please_show_proof_of_bond: 'يرجى إظهار دليل على السند / التأمين',
  please_select_bounded: 'يرجى تحديد Bonded / Insured',
  please_enter_about_business: 'الرجاء إدخال حول الأعمال',
  please_enter_new_password: 'الرجاء إدخال كلمة مرور جديدة',
  please_enter_old_password: 'الرجاء إدخال كلمة المرور القديمة',
  please_enter_your_correct: 'الرجاء إدخال الموقع الصحيح',
  please_add_service_area: 'الرجاء إضافة منطقة الخدمة',
  please_add_categories: 'الرجاء إضافة فئات',
  please_enter_year_of: 'الرجاء إدخال سنوات العمل',
  pleaes_check_the_box_for_ar: 'يرجى تحديد مربع الاعتماد',
  please_check_the_box_nari: 'يرجى تحديد المربع لعضو NARI',
  please_enter_company_ein: 'الرجاء إدخال رقم تعريف الشركة / معرف الضريبة',
  please_add_company_ein_id: 'الرجاء إضافة صورة رقم تعريف الشركة الضريبي / رقم تعريف صاحب العمل للشركة',
  tell_us_about_the_project_using_chat_picture_and_or_videos: 'أخبرنا عن المشروع باستخدام الدردشة و / أو الصورة و / أو مقاطع الفيديو.',
  obtain_multiple_quotes_from_ready_and_available_pros: 'احصل على عروض أسعار متعددة من محترفين جاهزين ومتاحين.',
  book_your_fully_vetted_pro_for_same_day_repair_or_schedule_them_at_your_convenience: 'احجز PRO الذي تم فحصه بالكامل لإصلاحه في نفس اليوم أو جدولة موعده على راحتك.',
  schedule_pay_and_rate_your_pro_while_keeping_track_of_all_your_jobs_with_the_u_go_pros_app: 'قم بجدولة ودفع وتقييم المحترف الخاص بك مع تتبع جميع وظائفك مع تطبيق U\'GO Pros.',
  you_will_be_charged: 'سيتم محاسبتك على أفضل سيناريو وهو $',
  before_pro_starts_job: '! قبل أن يبدأ المحترف وظيفته ، سيتم إخطارك بالتكلفة الفعلية بمجرد تقييمهم. من هنا يمكنك الرفض أو القبول',
  please_enter_title_of_your_event: 'الرجاء إدخال عنوان الحدث الخاص بك.',
  please_enter_detail_of_your_event: 'الرجاء إدخال تفاصيل الحدث الخاص بك.',
  please_select_time_of_your_event: 'الرجاء تحديد وقت الحدث الخاص بك.',
  please_select_date_of_your_event: 'يرجى تحديد تاريخ الحدث الخاص بك.',
  please_enter_the_reason_for_cancel_to_continue: 'الرجاء إدخال سبب الإلغاء للمتابعة.',
  you_havent_enough_credits: 'لم يكن لديك ما يكفي من الاعتمادات.',
  please_select_month_from_list: 'يرجى تحديد الشهر من القائمة',
  please_select_year_from_list: 'يرجى تحديد السنة من القائمة',
  google_play_services_required: 'خدمات Google Play المطلوبة:',
  after_installing_close_and_relaunch_this_app: 'بعد التثبيت ، أغلق هذا التطبيق وأعد تشغيله.',
  no_network_connection_available: 'لا يوجد اتصال شبكة متاح.',
  cancelling_required_permissions_are_not_granted: 'الإلغاء ، لا يتم منح الأذونات المطلوبة',
  connection_problem: 'مشكلة في الاتصال ، يرجى التحقق من الإنترنت الخاص بك والمحاولة مرة أخرى لاحقًا',
  oh_no: 'أوه لا!',
  microphone_permission: 'مطلوب إذن الميكروفون للتسجيل. يرجى السماح في إعدادات التطبيق للحصول على وظائف إضافية.',
  external_storage_permission: 'مطلوب إذن التخزين الخارجي. يرجى السماح في إعدادات التطبيق للحصول على وظائف إضافية.',
  camera_permission: 'مطلوب إذن الكاميرا. يرجى السماح في إعدادات التطبيق للحصول على وظائف إضافية.',
  please_provide_personal_past_experience_and_company_info: 'يرجى تقديم معلومات شخصية وخبرة سابقة ومعلومات عن الشركة',
  please_add_total_minimum_estimate: 'الرجاء إضافة إجمالي الحد الأدنى للتقدير؟ $',
  please_add_total_minimum_estimate_at_least_1: 'الرجاء إضافة تقدير إجمالي الحد الأدنى على الأقل 1 دولار',
  please_add_total_maximum_estimate_at_least_1: 'الرجاء إضافة إجمالي الحد الأقصى التقديري على الأقل 1 دولار',
  please_add_total_maximum_estimate: 'الرجاء إضافة إجمالي الحد الأقصى التقديري؟ $',
  total_minimum_estimate_should_be_less_than_total_maximum_estimate: 'يجب أن يكون إجمالي الحد الأدنى للتقدير أقل من إجمالي الحد الأقصى للتقدير.',
  please_select_your_available_time: 'يرجى تحديد الوقت المتاح لديك',
  permission_deny: 'رفض الإذن',
  mediaActivity_not_available: 'MediaActivity غير متوفر!',
  portfolio_not_found: 'المحفظة غير موجودة',
  select_second_time: 'يرجى تحديد الوقت المتاح الثاني مرة أخرى',
  select_third_time: 'يرجى تحديد الوقت المتاح الثالث مرة أخرى',
  commissioned_base_services: 'للخدمات الأساسية المفوضة. على سبيل المثال: السماسرة والمحامين ووكلاء التأمين وما إلى ذلك.\n\nشكرًا لك على اهتمامك بـ U\'GO Pros. نحن ملتزمون بربطك بمزيد من العملاء. ستضعك الشراكة معنا في دليل للمستهلكين. سيكونون قادرين على إخطارك عبر الدردشة على منصتنا ، أو من خلال اتصالك الشخصي.\n\n تكاليف الخدمة: لا توجد رسوم خفية\n',
  yearly: 'سنوي:     ',
  monthly: 'شهريا:     ',
  these_fees_are_subject: 'تخضع هذه الرسوم للتغيير ، ولكن سيتم إخطارك مسبقًا في حالة حدوث ذلك.\n\n إذا كنت مهتمًا بالشراكة معنا ، فاستمر في عملية التسجيل. إذا كانت لديك أي أسئلة ، فلا تتردد في التواصل معي على الرقم 877-733-2425 أو info@ugopros.com',
  chose_to_resubscribe: 'سعيد للغاية لأنك اخترت إعادة الاشتراك ، يرجى تحديد الطريقة التي تريد أن يتم دفع اشتراكك بها شهريًا أو سنويًا',
  plumber_request: 'طلب سباكة',
  schedule_meeting: 'يرتب موعد للاجتماع',
  error_pdf_docs: 'خطأ ، يُسمح فقط بملف pdf و docs في المستند',
  please_enter_paypall_account: 'الرجاء إدخال حساب Paypal',
  please_enter_verify_code: 'الرجاء إدخال رمز التحقق',
  please_enter_your_new_password: 'الرجاء إدخال كلمة المرور الجديدة الخاصة بك',
  choose_service_distance: 'الرجاء اختيار مسافة منطقة الخدمة',
  unable_to_get_info: 'لم نتمكن من الحصول على المعلومات الضرورية من حسابك. يرجى إعطاء الإذن / تحديث التطبيق وحاول مرة أخرى',
  please_enter_service_location: 'الرجاء إدخال موقع الخدمة',
  no_data_found: 'لاتوجد بيانات',
  add_portfolio_picture: 'الرجاء إضافة صورة المحفظة',
  cannot_access_location: 'الإذن! لا يمكنك الوصول إلى بيانات الموقع',
  information_not_found: 'لم يتم العثور على المعلومات',
  you_cannot_select_past_time: 'لا يمكنك تحديد الوقت الماضي',
  error_occurs: 'حدث خطأ',
  select_special_service: 'الرجاء تحديد خدمة خاصة',
  select_state: 'يرجى تحديد الولاية',
  select_city: 'الرجاء تحديد المدينة',
  enter_your_withdraw_amount: 'الرجاء إدخال مبلغ السحب الخاص بك',
  less_than_total_balance: 'يجب أن يكون المبلغ أقل من إجمالي الرصيد',
  enter_business_description: 'أدخل وصف العمل',
  please_show_proof_of_register_certificate: 'يرجى إظهار دليل على شهادة التسجيل',
  please_show_proof_of_pen_card: 'يرجى إظهار دليل على بطاقة القلم',
  please_show_proof_of_aadhar_card: 'الرجاء إظهار ما يثبت بطاقة AAdhar',
  please_show_proof_of_voter_identity_card: 'يرجى إبراز ما يثبت بطاقة هوية الناخب',
  please_show_proof_of_gst: 'يرجى إظهار دليل على ضريبة السلع والخدمات',
  please_show_proof_of_licese: 'يرجى إظهار دليل على رخصة القيادة',
  please_check_the_box_for_register_certificate_and_show_poof: 'يرجى تحديد المربع الخاص بشهادة التسجيل وإظهار دليل على شهادة التسجيل',
  please_check_the_for_register_certificate: 'يرجى تحديد مربع شهادة التسجيل',
  please_check_the_box_for_pen_card_and_show_poof: 'يرجى تحديد المربع الخاص ببطاقة القلم وإظهار دليل على بطاقة القلم',
  please_check_the_box_only_for_pen_card: 'يرجى تحديد المربع الخاص بـ Pen Card',
  please_check_the_box_for_adhar_card_and_show_poof: 'يرجى تحديد المربع الخاص ببطاقة الأذار وإظهار دليل على بطاقة الأذار',
  please_check_the_box_only_for_adhar_card: 'يرجى تحديد المربع الخاص ببطاقة الأذار',
  please_check_the_box_for_adhar_card: 'الرجاء تحديد المربع الخاص ببطاقة Adhar',
  please_check_the_box_for_voter_identityr_card_and_show_poof: 'يرجى تحديد المربع الخاص ببطاقة هوية الناخب وإظهار دليل على بطاقة القلم',
  please_check_the_box_for_voter_identity_card: 'يرجى تحديد المربع الخاص ببطاقة هوية الناخب',
  please_check_the_box_for_gst_and_show_poof: 'يرجى تحديد مربع ضريبة السلع والخدمات وإظهار دليل على بطاقة القلم',
  please_check_the_box_for_gst: 'يرجى تحديد المربع الخاص بضريبة السلع والخدمات',
  please_check_the_box_for_driving_license_and_show_poof: 'يرجى تحديد مربع رخصة القيادة وإظهار دليل على بطاقة القلم',
  please_check_the_box_for_driving_License: 'يرجى تحديد مربع رخصة القيادة',
  you_must_be_18_: 'يجب أن يكون عمرك 18 عامًا أو أكثر لاستخدام خدماتنا.',
  select_payment_method: 'اختار طريقة الدفع',
  google_payment_is_not_available_in_this_device: 'دفع Google غير متوفر في هذا الجهاز',
  us: 'نحن',
  _5_credit_for_reviews_are_for_jobs_that_are_complete_over_10: 'رصيد 5 دولارات للمراجعات للوظائف التي اكتملت أكثر من 10 دولارات',

  //employee

  login_in: 'تسجيل الدخول',

  mobile: 'متحرك#',

  please_enter_valid_email_address: 'الرجاء إدخال عنوان بريد إلكتروني صالح.',

  minimum_password_length_should_be: 'يجب أن يكون الحد الأدنى لطول كلمة المرور',

  don_t_have_an_account: 'ليس لديك حساب؟',

  agree_to_terms_conditions: 'شروط وأمبير. شروط',
  privacy_policy: 'سياسة الخصوصية',

  google_maps_key: 'AIzaSyBb2sUywacICcNUEmkhGsMUXIArFSoWUfc',

  please_check_network_connection: 'يرجى التحقق من اتصالك بالشبكة',
  add_lead: 'أضف الرصاص',
  write_your_message_here: 'اكتب رسالتك هنا ...',
  lead_detail: 'تفاصيل الرصاص',
  superviser: 'مشرف',
  followed_up: 'تمت المتابعة',
  in_person_schedule: 'من المقرر شخصيا',

  refused: 'رفض',
  requested_callback: 'تم طلب معاودة الاتصال',

  all_employees: 'كل الموظفين',

  manage: 'تدبير',
  employees: 'الموظفين',
  please_enter_user_name: 'الرجاء إدخال اسم المستخدم',

  oops: 'وجه الفتاة!',
  task_list: 'قائمة المهام',
  setting: 'جلسة',



  update_employee: 'تحديث الموظف',

  please_enter_phone_number: 'الرجاء إدخال رقم الهاتف',

  please_enter_country: 'الرجاء إدخال الدولة',
  please_enter_city: 'الرجاء إدخال المدينة',

  please_Enter_body: '\"الرجاء إدخال النص \"',
  please_enter_postal_code: '\"الرجاء إدخال الرمز البريدي \"',

  please_enter_First_name: 'الرجاء إدخال الاسم الأول',
  please_enter_last_name: 'الرجاء إدخال الاسم الأخير',
  plese_select_the_team: 'الرجاء تحديد الفريق',

  this_app_is_designed_for_internal_staff_members_only: 'تم تصميم هذا التطبيق للموظفين',
  follow_ugo_pros_to_utilize_pros_features: 'الداخليين فقط، اتبع U\'GO Pros للاستفادة من ميزات المحترفين',

  signup_as_an_industry_professional: 'الاشتراك كمحترف في الصناعة؟',
  supervisor: 'مشرف',

  user_login: 'تسجيل دخول المستخدم',
  user_name: 'اسم االمستخدم',

  team_id: 'معرف الفريق',

  sale: 'تخفيض السعر',
  street: 'شارع',
  phone_number: 'رقم الهاتف',
  body: 'الجسم',
  postal_code: 'رمز بريدي',

  new_task_s: 'مهمة جديدة',
  current_task: 'المهمة الحالية',

  task_num: 'مهمة #',
  ready_to_start: 'جاهز للبدء',

  start: 'يبدأ',

  end_tracking: 'تتبع النهاية',
  task_detail: 'تفاصيل المهمة',

  upload_images: 'تحميل الصور',
  write_your_feedback_here: 'اكتب ملاحظاتك هنا',

  followed: 'يتبع',
  lead_name: 'اسم العميل المحتمل',

  name_: 'اسم:',
  address_: 'عنوان:',
  country_: 'دولة:',
  state_: 'حالة:',
  city_: 'مدينة:',
  meeting_detail: 'تفاصيل الاجتماع:',

  start_tracking: 'بدء تتبع',
  meet_with_pro_to_get_information: 'اجتمع مع Pro للحصول على معلومات',
  choose_option: 'إختر خيارا',

  permission: 'الإذن',
  allow_to_access_your_location: 'السماح بالوصول إلى موقعك؟',
  allow: 'السماح',
  never: 'مطلقا',

  days_ago: 'أيام مضت',

  assign: 'تعيين',
  update_successfully: 'تم التحديث بنجاح',

  turn_on_location_on_device: 'قم بتشغيل موقعك على الجهاز',

  welcome_to_u_go_employee_staff: 'مرحبًا بكم في U\'GO Employee / Staff',
  account_deletion: 'حذف الحساب',
  delete_my_account: 'احذف حسابي',
  delete_account: 'حذف الحساب',
  importent_note: 'ملاحظة مهمة',
  your_account_should_not_have_any_active_pending_project_n_your_account_should_not_have_any_active_payouts_n_after_successful_deletion_of_your_account_you_will_not_be_able_to_log_in_to_the_account_account_deletion_is_permanent_and_will_not_be_reversible: '• يجب ألا يحتوي حسابك على أي مشروع معلق نشط\n• يجب ألا يحتوي حسابك على أي مدفوعات نشطة\n• بعد حذف حسابك بنجاح ، لن تتمكن من تسجيل الدخول إلى الحساب. حذف الحساب نهائي ولن يمكن التراجع عنه.',
  please_acknowledge_that_you_have_read_the_above_note: 'يرجى الإقرار بأنك قد قرأت الملاحظة أعلاه',
  acknowledged: 'أقر',
  are_you_sure_you_want_to_delete_account: 'هل أنت متأكد أنك تريد حذف الحساب؟',
  please_confirm_your_account_deletion_once_you_confirm_it_you_will_not_be_able_to_use_your_account_and_we_will_delete_all_the_data_associated_with_your_account: 'يرجى تأكيد حذف حسابك ، بمجرد تأكيد ذلك لن تتمكن من استخدام حسابك وسنقوم بحذف جميع البيانات المرتبطة بحسابك.',
  please_acknowledge: 'نرجو الاعتراف',
  delete_accounts: 'حذف الحساب',
  your_profile_is_incomplete_if_you_want_edit_profile_you_can_do_that_by_pressing_edit_profile_button: 'ملف التعريف الخاص بك غير مكتمل إذا كنت تريد تحرير ملف التعريف يمكنك القيام بذلك عن طريق الضغط على زر تحرير ملف التعريف.',
  sorry_you_have_not_yet_been_approved_by_admin: 'عذرا ، لم يتم الموافقة عليك من قبل المشرف.',
  confirm_that_you_are: 'تأكد من أنك تبلغ من العمر 18 عامًا أو أكثر',
  please_check_the_box_confirm_that_you_are: 'يرجى تحديد المربع لتأكيد عمرك 18+',
  resend_link: 'إعادة إرسال الرابط',
  resend_in: 'إعادة إرسال',
  create_pin: 'إنشاء دبوس',
  your_authentication_code: 'رمز المصادقة الخاص بك',
  sign_in_securely: 'تسجيل الدخول بأمان',
  resend_authentication_code: 'أعد إرسال رمز المصادقة',
  two_setup_authentication: 'مصادقة ثنائية الإعداد',
  please_enter_your_authentication_code: 'الرجاء إدخال رمز المصادقة الخاص بك.',
  thanks_for_keeping_your_account_secure: 'شكرا للحفاظ على حسابك آمن.',
  please_check_your_email: 'تفقد بريدك الالكتروني من فضلك',
  enter_old_password: 'أدخل كلمة المرور القديمة',
  please_proceed_to_your_email_for_the_purpose_of_verification_in_order_to_proceed_to_the_next_step: 'يرجى المتابعة إلى بريدك الإلكتروني لغرض التحقق من أجل المتابعة إلى الخطوة التالية.',
  please_enter_social_number_or_ein: 'الرجاء إدخال رقم الضمان الاجتماعي أو رقم تعريف صاحب العمل.',



};
