const POST_GER_USER_EVENT_REQUEST = "POST_GER_USER_EVENT_REQUEST";
const POST_GER_USER_EVENT_REQUEST_SUCCESS ="POST_GER_USER_EVENT_REQUEST_SUCCESS";
const POST_GER_USER_EVENT_REQUEST_FAILURE ="POST_GER_USER_EVENT_REQUEST_FAILURE";
const RESET_GER_USER_EVENT_STATE = "RESET_GER_USER_EVENT_STATE";

const addUserEvent = (formData) => {
  return {
    type: POST_GER_USER_EVENT_REQUEST,
    formData,
  };
};
const resetAddUserEvent = () => {

  return {
    type: RESET_GER_USER_EVENT_STATE,
  }
}


export {
  addUserEvent,
  resetAddUserEvent,
  RESET_GER_USER_EVENT_STATE,
  POST_GER_USER_EVENT_REQUEST,
  POST_GER_USER_EVENT_REQUEST_SUCCESS,
  POST_GER_USER_EVENT_REQUEST_FAILURE,
};
