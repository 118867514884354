const POST_GET_SALES_EMPLOYEES_REQUEST = "POST_GET_SALES_EMPLOYEES_REQUEST";
const POST_GET_SALES_EMPLOYEES_REQUEST_SUCCESS = "POST_GET_SALES_EMPLOYEES_REQUEST_SUCCESS";
const POST_GET_SALES_EMPLOYEES_REQUEST_FAILURE = "POST_GET_SALES_EMPLOYEES_REQUEST_FAILURE";
const RESET_GET_SALES_EMPLOYEES_STATE = "RESET_GET_SALES_EMPLOYEES_STATE";

const getSalesEmployee = (formData) => {
  return {
    type: POST_GET_SALES_EMPLOYEES_REQUEST,
    formData
  };
};

const resetGetSalesEmployee = () => {
  return {
    type: RESET_GET_SALES_EMPLOYEES_STATE,
  };
};
export {
  getSalesEmployee,
  resetGetSalesEmployee,
  RESET_GET_SALES_EMPLOYEES_STATE,
  POST_GET_SALES_EMPLOYEES_REQUEST,
  POST_GET_SALES_EMPLOYEES_REQUEST_SUCCESS,
  POST_GET_SALES_EMPLOYEES_REQUEST_FAILURE,
};
