import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  SafeAreaView,
  Pressable,
  ImageBackground,
} from "react-native";
import BackgroundImage from "../../../assets/images/tutorial_home.png";
import stepOne from "../../../assets/images/s1.png";
import StepTwo from "../../../assets/images/s2.png";
import StepThree from "../../../assets/images/s3.png";
import StepFour from "../../../assets/images/s4.png";
import { useTranslation } from 'react-i18next'


const StepCount = () => {
  const { t } = useTranslation();
  return (
      <ImageBackground
        source={BackgroundImage}
        resizeMode="cover"
        style={{ flex: 1 }}
      >
        <View style={styles.mainContainer}>
          <View style={{ alignSelf: "flex-end" ,marginRight:20}}>
            <View style={styles.rightContainer}>
              <Text style={styles.stepText}>Step</Text>
              <Text
                style={styles.stepTextCounting}
              >
                1
              </Text>
              <Image source={stepOne} style={styles.CenterImage} />
            </View>
            <View style={styles.descriptionContainer}>
              <Text style={styles.descriptionStep}>{t("common:tell_us_about_the_project_using_chat_picture_and_or_videos")}</Text>
            </View>
          </View>

          <View style={{ alignSelf: "flex-start" }}>
            <View style={styles.leftContainer}>
              <Text style={styles.stepText}>Step</Text>
              <Text style={styles.stepTextCounting}>
                2
              </Text>
              <Image source={StepTwo} style={styles.CenterImage} />
            </View>
            <View style={styles.leftDescriptionContainer}>
              <Text style={styles.descriptionStep}>{t("common:obtain_multiple_quotes_from_ready_and_available_pros")}</Text>
            </View>
          </View>
          <View style={{ alignSelf: "flex-end",marginRight:20 }}>
            <View style={styles.rightContainer}>
              <Text style={styles.stepText}>Step</Text>
              <Text
                style={styles.stepTextCounting}
              >
                3
              </Text>
              <Image source={StepThree} style={styles.CenterImage} />
            </View>
            <View style={styles.descriptionContainer}>
              <Text style={styles.descriptionStep}>{t("common:book_your_fully_vetted_pro_for_same_day_repair_or_schedule_them_at_your_convenience")}</Text>
            </View>
          </View>


          <View style={{ alignSelf: "flex-start" }}>
            <View style={styles.leftContainer}>
              <Text style={styles.stepText}>Step</Text>
              <Text style={styles.stepTextCounting}>
                4
              </Text>
              <Image source={StepFour} style={styles.CenterImage} />
            </View>
            <View style={styles.leftDescriptionContainer}>
              <Text style={styles.descriptionStep}>{t("common:schedule_pay_and_rate_your_pro_while_keeping_track_of_all_your_jobs_with_the_u_go_pros_app")}</Text>
            </View>
          </View>

        </View>
      </ImageBackground>
  );
};

export default StepCount;

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
  },
  header: {
    paddingHorizontal: 15,
    height: 80,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  mainContainer: {
    marginTop: 170,
  },
  rightContainer: {
    marginRight: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  leftContainer: {
    marginTop: 10,
    marginLeft: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  stepText: {
    color: "#FFF",
    fontSize: 25,
    fontWeight: "500",
  },
  descriptionStep: {
    color: "#FFF",
    fontSize: 14,
    alignSelf: 'center',
    fontWeight: "300",
    textAlign: 'center',
    fontStyle:"normal"
  },
  descriptionContainer: {
    width: 170,
    alignSelf: "center",

  },
  leftDescriptionContainer: {
    marginTop: 10,
    marginLeft: 60,
    width: 170,
    textAlign: 'center'
  },
  CenterImage: {
    resizeMode: 'contain',
    // height:80,
    // width:90
  },
  stepTextCounting: {
    color: "#FFF",
    fontSize: 25,
    fontWeight: "500",
    fontSize: 35,
    marginRight: 30
  }
});
