import { setItem } from "../../../../Utils/AsyncStore";
import {
  POST_USER_REGISTER_REQUEST,
  POST_USER_REGISTER_REQUEST_SUCCESS,
  POST_USER_REGISTER_REQUEST_FAILURE,
  CHANGE_STATUS
} from "./Action";

const initialState = {
  message: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_REGISTER_REQUEST: {
      console.log(POST_USER_REGISTER_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_USER_REGISTER_REQUEST_SUCCESS: {
      const data = action.payload;
      if (data.success.data?.user_type) {
        data.success.user = data.success.data
        setItem("registerUser", data);
      }
      console.log(POST_USER_REGISTER_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        registered: true,
        data,
        message: data.success.message,
      };
    }
    case POST_USER_REGISTER_REQUEST_FAILURE: {
      console.log(POST_USER_REGISTER_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: error?.message,
        errorData: error
      };
    }
    case CHANGE_STATUS: {
      console.log(CHANGE_STATUS);
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

export { reducer };
