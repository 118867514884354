const POST_UPDATE_LEAD_STATUS_REQUEST = "POST_UPDATE_LEAD_STATUS_REQUEST";
const POST_UPDATE_LEAD_STATUS_REQUEST_SUCCESS = "POST_UPDATE_LEAD_STATUS_REQUEST_SUCCESS";
const POST_UPDATE_LEAD_STATUS_REQUEST_FAILURE = "POST_UPDATE_LEAD_STATUS_REQUEST_FAILURE";
const RESET_UPDATE_LEAD_STATUS_STATE = "RESET_UPDATE_LEAD_STATUS_STATE";

const supervisorUpdateLeadStatus = (formData) => {
  return {
    type: POST_UPDATE_LEAD_STATUS_REQUEST,
    formData
  };
};

const resetSupervisorUpdateLeadStatus = () => {
  return {
    type: RESET_UPDATE_LEAD_STATUS_STATE,
  };
};
export {
  supervisorUpdateLeadStatus,
  resetSupervisorUpdateLeadStatus,
  RESET_UPDATE_LEAD_STATUS_STATE,
  POST_UPDATE_LEAD_STATUS_REQUEST,
  POST_UPDATE_LEAD_STATUS_REQUEST_SUCCESS,
  POST_UPDATE_LEAD_STATUS_REQUEST_FAILURE,
};
