import {
  POST_RESEND_EMAIL_REQUEST,
  POST_RESEND_EMAIL_REQUEST_SUCCESS,
  POST_RESEND_EMAIL_REQUEST_FAILURE,
  RESET_RESEND_EMAIL_STATE
} from "./Action"

const initialState = {
  data: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_RESEND_EMAIL_REQUEST: {
      console.log(POST_RESEND_EMAIL_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_RESEND_EMAIL_REQUEST_SUCCESS: {
      const { success } = action.payload;
      console.log(POST_RESEND_EMAIL_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: success,
        message: success.message,
      };
    }
    case POST_RESEND_EMAIL_REQUEST_FAILURE: {
      console.log(POST_RESEND_EMAIL_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message:error?.message,
        errorData:error
      };
    }
    case RESET_RESEND_EMAIL_STATE: {
      console.log(RESET_RESEND_EMAIL_STATE);

      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
