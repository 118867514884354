import { View, StyleSheet, ActivityIndicator, Modal, Text } from 'react-native'
import React from 'react'

export default function Loading() {
  return (
    <Modal statusBarTranslucent={true} transparent={true} visible={true}>
      <View style={styles.background}>

      </View>
      <View style={styles.loadingPostion}>
        <ActivityIndicator style={styles.loading} size="large" color="#FFF" />
        <Text style={{ color: "#FFF", marginTop: -20 }}>Please wait...</Text>
      </View>

    </Modal>
  )
}

const styles = StyleSheet.create({
  loading: {
    top: 10,
    width: 130,
    height: 110,
    borderRadius: 10,
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  loadingPostion: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.5,
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center'
  }
})