const POST_GET_TASK_REQUEST = "POST_GET_TASK_REQUEST";
const POST_GET_TASK_REQUEST_SUCCESS = "POST_GET_TASK_REQUEST_SUCCESS";
const POST_GET_TASK_REQUEST_FAILURE = "POST_GET_TASK_REQUEST_FAILURE";
const RESET_GET_TASK_STATE = "RESET_GET_TASK_STATE";

const getTask = () => {
  return {
    type: POST_GET_TASK_REQUEST,
  };
};

const resetGetTask = () => {
  return {
    type: RESET_GET_TASK_STATE,
  };
};
export {
  getTask,
  resetGetTask,
  RESET_GET_TASK_STATE,
  POST_GET_TASK_REQUEST,
  POST_GET_TASK_REQUEST_SUCCESS,
  POST_GET_TASK_REQUEST_FAILURE,
};
