const POST_GET_REWARD_AMOUNT_REQUEST = 'POST_GET_REWARD_AMOUNT_REQUEST';
const POST_GET_REWARD_AMOUNT_REQUEST_SUCCESS = 'POST_GET_REWARD_AMOUNT_REQUEST_SUCCESS';
const POST_GET_REWARD_AMOUNT_REQUEST_FAILURE = 'POST_GET_REWARD_AMOUNT_REQUEST_FAILURE';
const RESET_GET_REWARD_AMOUNT_STATE = 'RESET_GET_REWARD_AMOUNT_STATE'

const getRewardAmount = (formData) => {

    return {
        type: POST_GET_REWARD_AMOUNT_REQUEST,
        formData
    }
}
const resetRewardAmount = () => {

    return {
        type: RESET_GET_REWARD_AMOUNT_STATE,
    }
}
export {
    getRewardAmount,
    resetRewardAmount,
    RESET_GET_REWARD_AMOUNT_STATE,
    POST_GET_REWARD_AMOUNT_REQUEST,
    POST_GET_REWARD_AMOUNT_REQUEST_SUCCESS,
    POST_GET_REWARD_AMOUNT_REQUEST_FAILURE,
};