const POST_ALLOWED_COUNTRY_REQUEST = "POST_ALLOWED_COUNTRY_REQUEST";
const POST_ALLOWED_COUNTRY_REQUEST_SUCCESS = "POST_ALLOWED_COUNTRY_REQUEST_SUCCESS";
const POST_ALLOWED_COUNTRY_REQUEST_FAILURE = "POST_ALLOWED_COUNTRY_REQUEST_FAILURE";

const getAllowedCountry = () => {
  return {
    type: POST_ALLOWED_COUNTRY_REQUEST,
    
  };
};


export {
  getAllowedCountry,
  POST_ALLOWED_COUNTRY_REQUEST,
  POST_ALLOWED_COUNTRY_REQUEST_SUCCESS,
  POST_ALLOWED_COUNTRY_REQUEST_FAILURE,
};
