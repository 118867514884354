const POST_V2_RESPONSE_ON_BID_REQUEST = 'POST_V2_RESPONSE_ON_BID_REQUEST';
const POST_V2_RESPONSE_ON_BID_REQUEST_SUCCESS = 'POST_V2_RESPONSE_ON_BID_REQUEST_SUCCESS';
const POST_V2_RESPONSE_ON_BID_REQUEST_FAILURE = 'POST_V2_RESPONSE_ON_BID_REQUEST_FAILURE';
const RESET_V2_RESPONSE_ON_BID_STATE = 'RESET_V2_RESPONSE_ON_BID_STATE'

const responseOnBid = (formData) => {

    return {
        type: POST_V2_RESPONSE_ON_BID_REQUEST,
        formData
    }
}
const resetResponseOnBid = () => {

    return {
        type: RESET_V2_RESPONSE_ON_BID_STATE,
    }
}
export {
    responseOnBid,
    resetResponseOnBid,
    RESET_V2_RESPONSE_ON_BID_STATE,
    POST_V2_RESPONSE_ON_BID_REQUEST,
    POST_V2_RESPONSE_ON_BID_REQUEST_SUCCESS,
    POST_V2_RESPONSE_ON_BID_REQUEST_FAILURE,
};