const POST_CHANE_NOTIFICATION_SETTING_REQUEST = 'POST_CHANE_NOTIFICATION_SETTING_REQUEST';
const POST_CHANE_NOTIFICATION_SETTING_REQUEST_SUCCESS = 'POST_CHANE_NOTIFICATION_SETTING_REQUEST_SUCCESS';
const POST_CHANE_NOTIFICATION_SETTING_REQUEST_FAILURE = 'POST_CHANE_NOTIFICATION_SETTING_REQUEST_FAILURE';
const RESET_CHANE_NOTIFICATION_SETTING_STATE = 'RESET_CHANE_NOTIFICATION_SETTING_STATE'

const changeNotificationSetting = (formData) => {

    return {
        type: POST_CHANE_NOTIFICATION_SETTING_REQUEST,
        formData
    }
}
const resetNotificationSetting = () => {

    return {
        type: RESET_CHANE_NOTIFICATION_SETTING_STATE,

    }
}
export {
    changeNotificationSetting,
    resetNotificationSetting,
    RESET_CHANE_NOTIFICATION_SETTING_STATE,
    POST_CHANE_NOTIFICATION_SETTING_REQUEST,
    POST_CHANE_NOTIFICATION_SETTING_REQUEST_SUCCESS,
    POST_CHANE_NOTIFICATION_SETTING_REQUEST_FAILURE,
};