import {
  POST_ADD_PAY_IN_METHOD_REQUEST,
  POST_ADD_PAY_IN_METHOD_REQUEST_SUCCESS,
  POST_ADD_PAY_IN_METHOD_REQUEST_FAILURE,
  RESET_ADD_PAYOUT_METHOD_STATE,
  SET_PAYMENT_METHOD
} from "./Actions";

const initialState = {
  data: {},
  payment_method: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_ADD_PAY_IN_METHOD_REQUEST: {
      console.log(POST_ADD_PAY_IN_METHOD_REQUEST);

      return {
        ...state,
        error: false,
        loading: true,
        success: false,
      };
    }
    case POST_ADD_PAY_IN_METHOD_REQUEST_SUCCESS: {
      console.log(POST_ADD_PAY_IN_METHOD_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case POST_ADD_PAY_IN_METHOD_REQUEST_FAILURE: {
      console.log(POST_ADD_PAY_IN_METHOD_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: error?.message,
        errorData: error
      };
    }

    case RESET_ADD_PAYOUT_METHOD_STATE: {
      console.log(RESET_ADD_PAYOUT_METHOD_STATE);
      return {
        ...initialState
      };
    }
    case SET_PAYMENT_METHOD: {

      console.log(SET_PAYMENT_METHOD);
      return {
        ...state,
        payment_method: action.paymentMethod
      };
    }
    default:
      return state;
  }
};

export { reducer };
