const BASEURL = "https://ugopros.com/api/";
export const IMAGEURL = "https://ugopros.com/";
export const CHATURL = "wss://ugopros.com:9991";
const SUPPORT_BASE_URL = "http://www.support.ugopros.com/api/";
export const SUPPORT_IMAGEURL = "http://www.support.ugopros.com/";

export default {
  // support Employee 
  EMP_LOGIN: SUPPORT_BASE_URL + "agent/login",
  GET_TASK: SUPPORT_BASE_URL + "agent/get-tasks",
  GET_MY_LEADS: SUPPORT_BASE_URL + "agent/get-my-leads",
  UPDATE_NOTIFICATION_SETTING: SUPPORT_BASE_URL + "agent/update-notification-setting",
  UPDATE_PROFILE: SUPPORT_BASE_URL + "agent/update-profile",
  GET_CHAT_HISTORY: SUPPORT_BASE_URL + "agent/get-chat-history",
  DELETE_PORTAL_USER: SUPPORT_BASE_URL + "agent/portaluser/delete",
  EMPLOYEE_UPDATE_LEAD_STATUS: SUPPORT_BASE_URL + "agent/update-lead-status",
  EMPLOYEE_END_TRACKING: SUPPORT_BASE_URL + "agent/end-tracking",
  EMPLOYEE_START_TASK: SUPPORT_BASE_URL + "agent/start-task",
  EMPLOYEE_END_TASK: SUPPORT_BASE_URL + "agent/end-task",

  // Support Supervisor
  SUPERVISOR_LOGIN: SUPPORT_BASE_URL + "supervisor/login",
  GET_SALES_EMPLOYEES: SUPPORT_BASE_URL + "supervisor/get-sales-employees",
  GET_STREET_EMPLOYEES: SUPPORT_BASE_URL + "supervisor/get-street-employees",
  GET_CITIES: SUPPORT_BASE_URL + "get-cities",
  GET_ALL_EMPLOYEES: SUPPORT_BASE_URL + "supervisor/get-all-employees",
  GET_ALL_LEADS: SUPPORT_BASE_URL + "supervisor/get-all-leads",
  SUPERVISOR_UPDATE_LEAD_STATUS: SUPPORT_BASE_URL + "supervisor/update-lead-status",
  CREATE_NEW_LEAD: SUPPORT_BASE_URL + "supervisor/create-new-lead",
  SUPERVISOR_UPDATE_PROFILE: SUPPORT_BASE_URL + "supervisor/update-profile",
  SUPERVISOR_NOTIFICATION_SETTING: SUPPORT_BASE_URL + "supervisor/update-notification-setting",
  SUPERVISOR_UPDATE_EMPLOYEE: SUPPORT_BASE_URL + "supervisor/update-employee",
  CREATE_EMPLOYEE: SUPPORT_BASE_URL + "supervisor/create-employee",
  GET_BASIC_VALUES: SUPPORT_BASE_URL + "get-basic-values",
  SUPERVISOR_PORTALUSER_DELETE: SUPPORT_BASE_URL + "supervisor/portaluser/delete",
  SUPERVISOR_CHAT_HISTORY: SUPPORT_BASE_URL + "supervisor/get-chat-history",
  SUPERVISOR_MESSAGES_LIST: SUPPORT_BASE_URL + "supervisor/get-messages-list",
  SUPERVISOR_ASSIGN_LEAD: SUPPORT_BASE_URL + "supervisor/assign-lead",

  //Common
  API_BASIC_START: BASEURL + "system/get-basic-start-up-values",
  RE_SEND_AUTHENTICATION_CODE: BASEURL + "resendVerificationCode",
  API_CHANGE_PASSWORD: BASEURL + "users/new-password",
  API_USER_REGISTER: BASEURL + "users/register",
  API_RESEND_EMAIL: BASEURL + "resendEmail",
  API_AUTH_CODE: BASEURL + "users/verify-token",
  API_LOGIN: BASEURL + "login",
  API_GET_CITIES: BASEURL + "get-cities",
  API_CHANGE_LANGUAGE: BASEURL + "users/change-language-settings?token=",
  API_GET_NOTIFICATIONS: BASEURL + "notifications/get-notifications?token=",
  API_CONTACT_US: BASEURL + "contact-request?token=",
  API_LOGOUT_USER: BASEURL + "users/logout?token=",
  API_DELETE_ACCOUNT: BASEURL + "user/delete?token=",
  API_FORGET_PASSWORD: BASEURL + "users/forgot-password",
  API_UPLOAD_MESSAGE_FILE: BASEURL + "upload-message-file?token=",
  API_MESSAGE_READ_STATUS: BASEURL + "set-message-read-status?token=",
  API_GET_CHAT_DETAIL: BASEURL + "chat/get-detail-chat?token=",
  API_REMOVE_PAY_IN_METHOD: BASEURL + "user/remove-pay-in-method?token=",
  API_DELETE_NOTIFICATION: BASEURL + "notifications/delete-notifications?token=",
  API_CHANE_NOTIFICATION_SETTING: BASEURL + "users/change-notification-settings?token=",
  API_GET_PROJECT_DETAIL: BASEURL + "projects/project-detail?token=",
  API_GET_UNREAD_NOTIFICATION_COUNT: BASEURL + "get-unread-count?token=",


  //USer
  API_SOCIAL_LOGIN: BASEURL + "home-user/login-with-socialmedia",
  API_USER_SOCIAL_REGISTER: BASEURL + "home-user/login-with-socialmedia",
  API_PROJECT_DELETE_BY_USER: BASEURL + "projects/delete-by-user?token=",
  API_GET_MY_INSTANT_PROJECTS: BASEURL + "projects/get-my-instant-projects?token=",
  API_GET_OFFER: BASEURL + "offers/get?token=",
  API_REMOVE_PIN: BASEURL + "users/remove-pin?token=",
  API_GET_PAYMENT_HISTORY: BASEURL + "projects/get-payment-history?token=",
  API_GET_SITE_VISIT_REQUESTS: BASEURL + "get-on-site-visit-requests?token=",
  API_REJECT_ON_SITE_VISIT: BASEURL + "reject-on-site-visit?token=",
  API_REJECTS_CANCEL: BASEURL + "projects/cancel?token=",
  API_APPROVE_ON_SITE_VISIT: BASEURL + "approve-on-site-visit?token=",
  API_ACCEPT_REJECT: BASEURL + "projects/accept-reject?token=",
  API_UPDATE_USER: BASEURL + "users/update?token=",
  API_REVIEW_USER: BASEURL + "reviews/review-user?token=",
  API_REFER_FRIENDS: BASEURL + "credits/refer-friend?token=",
  API_CHECK_PRO_IN_AREA: BASEURL + "check-pro-in-area?token=",
  API_GET_REWARD_AMOUNT: BASEURL + "credits/get-reward-amount?token=",
  API_MAKE_PAY_IN_METHOD_DEFAULT: BASEURL + "user/make-pay-in-method-default?token=",
  API_GET_SERVICE_PROFESSIONALS: BASEURL + "get-service-professionals?token=",
  API_GET_MY_PROJECTS: BASEURL + "projects/get-my-projects?token=",
  API_GET_MY_PAY_IN_METHOD: BASEURL + "user/get-my-pay-in-methods?token=",
  API_GET_BIDS: BASEURL + "projects/get-bids?token=",
  API_PIN_STATUS: BASEURL + "users/pin-status?token=",
  API_PROJECTS_NEW: BASEURL + "projects/new?token=",
  API_PROJECT_UPDATE: BASEURL + "project/update?token=",
  API_GET_CHAT: BASEURL + "chat/get-chat?token=",
  API_CANCEL_PROJECT_AFTER_PAYMENT: BASEURL + "v2/cancel-project-after-payment?token=",
  API_ADD_PAY_IN_METHOD: BASEURL + "user/add-pay-in-methods?token=",
  API_ADD_PAY_IN_METHOD_WITH_TOKEN: BASEURL + "user/add-pay-in-methods-with-token?token=",
  API_ADD_CREDITS: BASEURL + "credits/add-credits?token=",
  API_ACCEPT_INSTANT_PROJECT: BASEURL + "projects/accept-instant-project?token=",
  API_GER_USER_EVENT: BASEURL + "add-user-event?token=",
  API_QUICK_QUOTE_RESCHEDULE: BASEURL + "projects/quick-quote-reschedule?token=",
  API_GET_PROJECTS_DETAIL: BASEURL + "get-projects-detail?token=",
  API_CONFIRM_PROJECT_COMPLETION: BASEURL + "v2/confirm-project-completion-on-user-side?token=",
  API_PROJECT_NOT_COMPLETED: BASEURL + "project-not-completed?token=",
  API__V2_ACCEPT_ACTUAL_BID: BASEURL + "v2/accept-actual-price?token=",
  API_RESPONSE_ON_BID: BASEURL + "projects/response-on-bid?token=",
  API_V2_CAPTURE_BID_AMOUNT: BASEURL + "v2/capture-bid-amount?token=",
  API_V2_RESPONSE_ON_BID: BASEURL + "v2/response-on-bid?token=",



  // PRO
  API_REGISTER_PRO_USER: BASEURL + "register-pro-user",
  API_GET_UGO_MAIN_SERVICES: BASEURL + "services/get-category?token=",
  API_GET_UGO_MAIN_SERVICES_WITHOUT: BASEURL + "services/get-category",
  API_UPDATE_PRO_USER: BASEURL + "update-pro-user?token=",
  API_GET_PROJECT: BASEURL + "projects/get?token=",
  API_CHANGE_ONLINE_STATUS: BASEURL + "users/change-online-status?token=",
  API_GET_USER_EMPLOYEE_LIST: BASEURL + "users/get-employees-list?token=",
  API_DELETE_EMPLOYEE: BASEURL + "employees/delete-by-employee?token=",
  API_NEW_EMPLOYEE: BASEURL + "users/add-new-employee?token=",
  API_EMPLOYEE_DLETE: BASEURL + "employees/delete?token=",
  API_UPDATE_EMPLOYEE: BASEURL + "users/update-employee-info?token=",
  API_GET_INVOICES: BASEURL + "projects/get-invoices?token=",
  API_TRACK_EARNING: BASEURL + "track-earnings?token=",
  API_GET_PRO_REVIEWS: BASEURL + "get-pro-reviews?token=",
  API_GET_PRO_CHAT: BASEURL + "chat/get-pro-chat?token=",
  API_CHANGE_INSTANT_PAYOUT_SETTING: BASEURL + "settings/change-instant-payout-settings?token=",
  API_ADD_DEBIT_CARD: BASEURL + "user/add-debit-card?token=",
  API_GET_PAYOUT_METHOD: BASEURL + "user/get-my-payOut-methods?token=",
  API_ADD_PAYOUT_METHODS: BASEURL + "user/add-payOut-methods?token=",
  API_REMOVE_DEBIT_CARD: BASEURL + "user/remove-debit-card?token=",
  API_REVIEW_PRO: BASEURL + "reviews/review-pro?token=",
  API_ON_SITE_VISIT_PAYMENT: BASEURL + "on-site-visit-request?token=",
  API_RETRY_ON_SITE_VISIT_PAYMENT: BASEURL + "retry-on-site-visit-payment?token=",
  API_REJECT_PROJECT: BASEURL + "projects/reject?token=",
  API_BID_ON_PROJECT: BASEURL + "v2/bid-on-project?token=",
  API_SEND_ACTUAL_PRICE: BASEURL + "v2/send-actual-price?token=",
  API_PROJECT_DELETE_BY_PRO: BASEURL + "projects/delete-by-pro?token=",
  API_DELETE_CLOSED_PROJECT_BY_PRO: BASEURL + "projects/delete-closed-project-by-pro?token=",
  API_END_PROJECT_BY_PRO: BASEURL + "v2/end-project-by-pro?token=",
  API_EMPLOYEE_ASSIGN_TOKEN: BASEURL + "employee/assign-project?token=",
  API_START_PROJECT: BASEURL + "v2/start-project?token=",

  // Special Pro
  API_REVIEW_SPECIAL_PRO: BASEURL + "reviews/review-special-pro?token=",
  API_SPECIAL_REQUEST: BASEURL + "get-special-requests?token=",
  API_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE: BASEURL + "get-chat-list-for-specialty-service?token=",
  API_GET_SPECIALITY_SERVICE_CHAT_DETAIL: BASEURL + "get-speciality-service-chat-detail?token=",
  API_SUBSCRIBE_USER: BASEURL + "subscribe-user?token=",
  API_CANCEL_SUBSCRIBE_USER: BASEURL + "cancel-subscription?token=",
  API_RE_SUBSCRIBE_USER: BASEURL + "re-subscribe?token=",
  API_SET_AUTO_PAYMENT: BASEURL + "set-auto-payment?token=",

  API_PRO_DETAIL: BASEURL + "pros-detail?token=",
  API_GET_EARNING: BASEURL + "payment/get-earnings?token=",
  API_GET_PRO_REWARD_AMOUNT: BASEURL + "credits/get-pro-reward-amount?token=",
  API_MAKE_DEBIT_CARD_DEFAULT: BASEURL + "user/make-debit-card-default?token=",


  //Employee
  API_GET_EMPLOYEE: BASEURL + "employee/get-employee-projects?token=",

  API_EMPLOYEE_END_JOB: BASEURL + "v2/employee-end-job?token=",


  API_GET_Allowed_Countries: BASEURL + "allowed-countries",

  API_GET_MEETING_DETAIL: BASEURL + "projects/get-meeting-detail?token=",
  API_PAYMENT_WITHDRAW: BASEURL + "payment/request-withdraw?token=",
  API_GET_PAYMENT_ACCOUNT_INFO: BASEURL + "payment/get-payment-account-info?token=",
  API_SELECT_PAYMENT_METHOD: BASEURL + "payment/select-payment-method?token=",
  API_GET_PAYMENT_METHOD: BASEURL + "payment/get-payment-account-info?token=",
  API_UPDATE_PAYMENT: BASEURL + "payment/update-payment-account-info?token=",
  API_ADD_PAYMENT: BASEURL + "payment/save-payment-account-info?token=",
  API_REMOVE_PAYOUT_METHODS: BASEURL + "user/remove-payOut-methods?token=",
  API_SEND_REQUEST_TO_PROFESSIONAL: BASEURL + "send-request-to-professional?token=",
  API_V2_GET_PAYMENT_INTENT: BASEURL + "v2/get-payment-intent?token=",
  API_GET_WITHDRAW_HISTORY: BASEURL + "payment/get-withdraw-history?token=",

};
