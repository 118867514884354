import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  POST_AUTH_CODE_REQUEST,
  POST_AUTH_CODE_REQUEST_SUCCESS,
  POST_AUTH_CODE_REQUEST_FAILURE,
  AUTH_CODE_RESET_STATE
} from "./Actions";

const initialState = {
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_AUTH_CODE_REQUEST: {
      console.log(POST_AUTH_CODE_REQUEST);

      return {
        ...state,
        error: false,
        loading: true,
        success: false, 
      };
    }
    case POST_AUTH_CODE_REQUEST_SUCCESS: {
      console.log(POST_AUTH_CODE_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success:true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case POST_AUTH_CODE_REQUEST_FAILURE: {
      console.log(POST_AUTH_CODE_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message:error?.message,
        errorData:error
      };
    }
    case AUTH_CODE_RESET_STATE: {
      console.log(AUTH_CODE_RESET_STATE);
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
