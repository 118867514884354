import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  POST_GET_PAYMENT_METHOD_REQUEST,
  POST_GET_PAYMENT_METHOD_REQUEST_SUCCESS,
  POST_GET_PAYMENT_METHOD_REQUEST_FAILURE,
  RESET_GET_PAYMENT_METHOD_STATE
} from "./Actions";

const initialState = {
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_GET_PAYMENT_METHOD_REQUEST: {
      console.log(POST_GET_PAYMENT_METHOD_REQUEST);

      return {
        ...state,
        error: false,
        loading: true,
        success: false,
      };
    }
    case POST_GET_PAYMENT_METHOD_REQUEST_SUCCESS: {
      console.log(POST_GET_PAYMENT_METHOD_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success:true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case POST_GET_PAYMENT_METHOD_REQUEST_FAILURE: {
      console.log(POST_GET_PAYMENT_METHOD_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message:error.message,
        errorData:error
      };
    }

    case RESET_GET_PAYMENT_METHOD_STATE: {
      console.log(RESET_GET_PAYMENT_METHOD_STATE);
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

export { reducer };
