const POST_GET_BASIC_VALUES_REQUEST = "POST_GET_BASIC_VALUES_REQUEST";
const POST_GET_BASIC_VALUES_REQUEST_SUCCESS = "POST_GET_BASIC_VALUES_REQUEST_SUCCESS";
const POST_GET_BASIC_VALUES_REQUEST_FAILURE = "POST_GET_BASIC_VALUES_REQUEST_FAILURE";
const RESET_GET_BASIC_VALUES_STATE = "RESET_GET_BASIC_VALUES_STATE";

const getBasicValues = () => {
  return {
    type: POST_GET_BASIC_VALUES_REQUEST,
  };
};

const resetGetBasicValues = () => {
  return {
    type: RESET_GET_BASIC_VALUES_STATE,
  };
};
export {
  getBasicValues,
  resetGetBasicValues,
  RESET_GET_BASIC_VALUES_STATE,
  POST_GET_BASIC_VALUES_REQUEST,
  POST_GET_BASIC_VALUES_REQUEST_SUCCESS,
  POST_GET_BASIC_VALUES_REQUEST_FAILURE,
};
