const PRO_DETAIL_REQUEST = "PRO_DETAIL_REQUEST";
const PRO_DETAIL_REQUEST_SUCCESS = "PRO_DETAIL_REQUEST_SUCCESS";
const PRO_DETAIL_REQUEST_FAILURE = "PRO_DETAIL_REQUEST_FAILURE";
const RESET_PRO_DETAIL_STATE = "RESET_PRO_DETAIL_STATE";

const proDetail = (formData) => {
  return {
    type: PRO_DETAIL_REQUEST,
    formData
  };
};
const resetProDetail = () => {
  return {
    type: RESET_PRO_DETAIL_STATE,

  };
};
export {
  proDetail,
  resetProDetail,
  RESET_PRO_DETAIL_STATE,
  PRO_DETAIL_REQUEST,
  PRO_DETAIL_REQUEST_SUCCESS,
  PRO_DETAIL_REQUEST_FAILURE,
};
