const POST_UPDATE_USER_REQUEST = "POST_UPDATE_USER_REQUEST";
const POST_UPDATE_USER_REQUEST_SUCCESS = "POST_UPDATE_USER_REQUEST_SUCCESS";
const POST_UPDATE_USER_REQUEST_FAILURE = "POST_UPDATE_USER_REQUEST_FAILURE";
const RESET_UPDATE_USER = "RESET_UPDATE_USER";

const updateUser = (formData) => {
  return {
    type: POST_UPDATE_USER_REQUEST,
    formData
  };
};

const resetUpdateUser = () => {
  return {
    type: RESET_UPDATE_USER,
  };
};
export {
  updateUser,
  resetUpdateUser,
  POST_UPDATE_USER_REQUEST,
  POST_UPDATE_USER_REQUEST_SUCCESS,
  POST_UPDATE_USER_REQUEST_FAILURE,
  RESET_UPDATE_USER
};
