const POST_GET_CHAT_HISTORY_REQUEST = "POST_GET_CHAT_HISTORY_REQUEST";
const POST_GET_CHAT_HISTORY_REQUEST_SUCCESS = "POST_GET_CHAT_HISTORY_REQUEST_SUCCESS";
const POST_GET_CHAT_HISTORY_REQUEST_FAILURE = "POST_GET_CHAT_HISTORY_REQUEST_FAILURE";
const RESET_GET_CHAT_HISTORY_STATE = "RESET_GET_CHAT_HISTORY_STATE";
const ADD_EMPLOYEE_MESSAGES_LIST_CHAT = "ADD_EMPLOYEE_MESSAGES_LIST_CHAT"

const getChatHistory = (formData) => {
  return {
    type: POST_GET_CHAT_HISTORY_REQUEST,
    formData
  };
};

const resetGetChatHistory = () => {
  return {
    type: RESET_GET_CHAT_HISTORY_STATE,
  };
};

const addMessageToList = (message) => {
  return {
    type: ADD_EMPLOYEE_MESSAGES_LIST_CHAT,
    message,
  };
};

export {
  getChatHistory,
  resetGetChatHistory,
  addMessageToList,
  ADD_EMPLOYEE_MESSAGES_LIST_CHAT,
  RESET_GET_CHAT_HISTORY_STATE,
  POST_GET_CHAT_HISTORY_REQUEST,
  POST_GET_CHAT_HISTORY_REQUEST_SUCCESS,
  POST_GET_CHAT_HISTORY_REQUEST_FAILURE,
};
