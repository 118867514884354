const POST_UPDATE_EMPLOYEE_REQUEST = "POST_UPDATE_EMPLOYEE_REQUEST";
const POST_UPDATE_EMPLOYEE_REQUEST_SUCCESS = "POST_UPDATE_EMPLOYEE_REQUEST_SUCCESS";
const POST_UPDATE_EMPLOYEE_REQUEST_FAILURE = "POST_UPDATE_EMPLOYEE_REQUEST_FAILURE";
const RESET_UPDATE_EMPLOYEE_STATE = "RESET_UPDATE_EMPLOYEE_STATE";

const updateEmployee = (formData) => {
  return {
    type: POST_UPDATE_EMPLOYEE_REQUEST,
    formData,
  };
};
const resetUpdateEmployee = () => {
  return {
    type: RESET_UPDATE_EMPLOYEE_STATE,
  };
};
export {
  updateEmployee,
  resetUpdateEmployee,
  RESET_UPDATE_EMPLOYEE_STATE,
  POST_UPDATE_EMPLOYEE_REQUEST,
  POST_UPDATE_EMPLOYEE_REQUEST_SUCCESS,
  POST_UPDATE_EMPLOYEE_REQUEST_FAILURE,
};
