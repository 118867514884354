const POST_AUTH_CODE_REQUEST = 'POST_AUTH_CODE_REQUEST';
const POST_AUTH_CODE_REQUEST_SUCCESS =  'POST_AUTH_CODE_REQUEST_SUCCESS';
const POST_AUTH_CODE_REQUEST_FAILURE =  'POST_AUTH_CODE_REQUEST_FAILURE';
const AUTH_CODE_RESET_STATE =  'AUTH_CODE_RESET_STATE';

const authCode = (formData)=>{

    return {
        type: POST_AUTH_CODE_REQUEST,
        formData
    }
} 

export {
    authCode,
    POST_AUTH_CODE_REQUEST,
    POST_AUTH_CODE_REQUEST_SUCCESS,
    POST_AUTH_CODE_REQUEST_FAILURE,
    AUTH_CODE_RESET_STATE
};