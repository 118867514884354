const POST_RESPONSE_ON_BID_REQUEST = 'POST_RESPONSE_ON_BID_REQUEST';
const POST_RESPONSE_ON_BID_REQUEST_SUCCESS = 'POST_RESPONSE_ON_BID_REQUEST_SUCCESS';
const POST_RESPONSE_ON_BID_REQUEST_FAILURE = 'POST_RESPONSE_ON_BID_REQUEST_FAILURE';
const RESET_RESPONSE_ON_BID_STATE = 'RESET_RESPONSE_ON_BID_STATE'

const projectResponseOnBid = (formData) => {

    return {
        type: POST_RESPONSE_ON_BID_REQUEST,
        formData
    }
}
const resetProjectResponseOnBid = () => {

    return {
        type: RESET_RESPONSE_ON_BID_STATE,

    }
}
export {
    projectResponseOnBid,
    resetProjectResponseOnBid,
    RESET_RESPONSE_ON_BID_STATE,
    POST_RESPONSE_ON_BID_REQUEST,
    POST_RESPONSE_ON_BID_REQUEST_SUCCESS,
    POST_RESPONSE_ON_BID_REQUEST_FAILURE,
};