const POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST = 'POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST';
const POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST_SUCCESS = 'POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST_SUCCESS';
const POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST_FAILURE = 'POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST_FAILURE';
const RESET_CANCEL_PROJECT_AFTER_PAYMENT_STATE = 'RESET_CANCEL_PROJECT_AFTER_PAYMENT_STATE'

const cancelProjectAfterPayment = (formData) => {

    return {
        type: POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST,
        formData
    }
}
const resetCancelProject = () => {

    return {
        type: RESET_CANCEL_PROJECT_AFTER_PAYMENT_STATE,

    }
}
export {
    cancelProjectAfterPayment,
    resetCancelProject,
    RESET_CANCEL_PROJECT_AFTER_PAYMENT_STATE,
    POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST,
    POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST_SUCCESS,
    POST_CANCEL_PROJECT_AFTER_PAYMENT_REQUEST_FAILURE,
};