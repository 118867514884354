const POST_EMPLOYEE_LOGIN_REQUEST = "POST_EMPLOYEE_LOGIN_REQUEST";
const POST_EMPLOYEE_LOGIN_REQUEST_SUCCESS = "POST_EMPLOYEE_LOGIN_REQUEST_SUCCESS";
const POST_EMPLOYEE_LOGIN_REQUEST_FAILURE = "POST_EMPLOYEE_LOGIN_REQUEST_FAILURE";
const RESET_EMPLOYEE_LOGIN_STATE = "RESET_EMPLOYEE_LOGIN_STATE";

const employeeLogin = (formData) => {
  return {
    type: POST_EMPLOYEE_LOGIN_REQUEST,
    formData
  };
};

const resetEmployeeLogin = () => {
  return {
    type: RESET_EMPLOYEE_LOGIN_STATE,
  };
};
export {
  employeeLogin,
  resetEmployeeLogin,
  RESET_EMPLOYEE_LOGIN_STATE,
  POST_EMPLOYEE_LOGIN_REQUEST,
  POST_EMPLOYEE_LOGIN_REQUEST_SUCCESS,
  POST_EMPLOYEE_LOGIN_REQUEST_FAILURE,
};
