const SET_HOLE_POSITION = "SET_HOLE_POSITION";
const RESET_HOLE_POSITION = "RESET_HOLE_POSITION";

const setHolesPositions = (positions) => {
  return {
    type: SET_HOLE_POSITION,
    positions
  }
}
export {
  setHolesPositions,
  SET_HOLE_POSITION,
  RESET_HOLE_POSITION,
};
