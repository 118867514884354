import {
  SET_HOLE_POSITION,
  RESET_HOLE_POSITION,
} from "./Actions";
const initialState = {
  positions: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOLE_POSITION: {
      const { positions } = action
      console.log(SET_HOLE_POSITION);

      return {
        ...state,
        positions
      };
    }
    case RESET_HOLE_POSITION: {
      console.log(RESET_HOLE_POSITION);
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
