const POST_EMPLOYEES_DELETE_REQUEST = "POST_EMPLOYEES_DELETE_REQUEST";
const POST_EMPLOYEES_DELETE_REQUEST_SUCCESS = "POST_EMPLOYEES_DELETE_REQUEST_SUCCESS";
const POST_EMPLOYEES_DELETE_REQUEST_FAILURE = "POST_EMPLOYEES_DELETE_REQUEST_FAILURE";
const RESET_EMPLOYEES_DELETE_STATE = "RESET_EMPLOYEES_DELETE_STATE";

const employeesDelete = (formData) => {
  return {
    type: POST_EMPLOYEES_DELETE_REQUEST,
    formData,
  };
};
const resetEmployeesDelete = () => {
  return {
    type: RESET_EMPLOYEES_DELETE_STATE,
  };
};
export {
  employeesDelete,
  resetEmployeesDelete,
  RESET_EMPLOYEES_DELETE_STATE,
  POST_EMPLOYEES_DELETE_REQUEST,
  POST_EMPLOYEES_DELETE_REQUEST_SUCCESS,
  POST_EMPLOYEES_DELETE_REQUEST_FAILURE,
};
