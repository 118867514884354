const POST_CHANGE_ONLINE_STATUS_REQUEST = 'POST_CHANGE_ONLINE_STATUS_REQUEST';
const POST_CHANGE_ONLINE_STATUS_REQUEST_SUCCESS = 'POST_CHANGE_ONLINE_STATUS_REQUEST_SUCCESS';
const POST_CHANGE_ONLINE_STATUS_REQUEST_FAILURE = 'POST_CHANGE_ONLINE_STATUS_REQUEST_FAILURE';
const RESET_CHANGE_ONLINE_STATUS_STATE = 'RESET_CHANGE_ONLINE_STATUS_STATE'

const changeOnlineStatus = (formData) => {

    return {
        type: POST_CHANGE_ONLINE_STATUS_REQUEST,
        formData
    }
}
const resetChangeOnlineStatus = () => {

    return {
        type: RESET_CHANGE_ONLINE_STATUS_STATE,

    }
}
export {
    changeOnlineStatus,
    resetChangeOnlineStatus,
    RESET_CHANGE_ONLINE_STATUS_STATE,
    POST_CHANGE_ONLINE_STATUS_REQUEST,
    POST_CHANGE_ONLINE_STATUS_REQUEST_SUCCESS,
    POST_CHANGE_ONLINE_STATUS_REQUEST_FAILURE,
};