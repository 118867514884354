const POST_ADD_PAYMENT_REQUEST = 'POST_ADD_PAYMENT_REQUEST';
const POST_ADD_PAYMENT_REQUEST_SUCCESS =  'POST_ADD_PAYMENT_REQUEST_SUCCESS';
const POST_ADD_PAYMENT_REQUEST_FAILURE =  'POST_ADD_PAYMENT_REQUEST_FAILURE';
const RESET_ADD_PAYMENT_STATE ='RESET_ADD_PAYMENT_STATE'

const addPayment = (formData)=>{

    return {
        type: POST_ADD_PAYMENT_REQUEST,
        formData
    }
}

export {
    addPayment,
    RESET_ADD_PAYMENT_STATE,
    POST_ADD_PAYMENT_REQUEST,
    POST_ADD_PAYMENT_REQUEST_SUCCESS,
    POST_ADD_PAYMENT_REQUEST_FAILURE,
};