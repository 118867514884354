const POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST = 'POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST';
const POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST_SUCCESS = 'POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST_SUCCESS';
const POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST_FAILURE = 'POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST_FAILURE';
const RESET_CHAT_FOR_SPECIALITY_STATE = 'RESET_CHAT_FOR_SPECIALITY_STATE'

const getChatForSpeciality = (formData) => {

    return {
        type: POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST,
        formData
    }
}
const resetChatForSpeciality = () => {

    return {
        type: RESET_CHAT_FOR_SPECIALITY_STATE,
    }
}
export {
    getChatForSpeciality,
    resetChatForSpeciality,
    RESET_CHAT_FOR_SPECIALITY_STATE,
    POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST,
    POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST_SUCCESS,
    POST_GET_CHAT_LIST_FOR_SPECIALITY_SERVICE_REQUEST_FAILURE,
};