import * as React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import LanguageSelector from "../Screens/HomeScreens/LanguageSelector";
import HomeScreen from "../Screens/HomeScreens/HomeScreen";
import Signup from "../Screens/HomeScreens/Signup";
import { useInitFirebase } from "../Utils/NotificationService";
import { Platform } from "react-native";

const Stack = createNativeStackNavigator();
const OnBoardStack = ({ languageSelected }) => {
  useInitFirebase()
  return (
    <Stack.Navigator initialRouteName={Platform.OS=='web'? "HomeScreen" :(languageSelected ? "HomeScreen" : "LanguageSelector")} screenOptions={{ animation: "slide_from_right" ,orientation:"portrait"}}>
      <Stack.Screen
        options={{ headerShown: false }}
        name="HomeScreen"
        component={HomeScreen}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="LanguageSelector"
        component={LanguageSelector}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Signup"
        component={Signup}
      />
 

    </Stack.Navigator>
  );
};
export default OnBoardStack;
