const POST_EMPLOYEE_END_TRACKING_REQUEST = "POST_EMPLOYEE_END_TRACKING_REQUEST";
const POST_EMPLOYEE_END_TRACKING_REQUEST_SUCCESS = "POST_EMPLOYEE_END_TRACKING_REQUEST_SUCCESS";
const POST_EMPLOYEE_END_TRACKING_REQUEST_FAILURE = "POST_EMPLOYEE_END_TRACKING_REQUEST_FAILURE";
const RESET_EMPLOYEE_END_TRACKING_STATE = "RESET_EMPLOYEE_END_TRACKING_STATE";

const endTracking = (formData) => {
  return {
    type: POST_EMPLOYEE_END_TRACKING_REQUEST,
    formData
  };
};

const resetEndTracking = () => {
  return {
    type: RESET_EMPLOYEE_END_TRACKING_STATE,
  };
};
export {
  endTracking,
  resetEndTracking,
  RESET_EMPLOYEE_END_TRACKING_STATE,
  POST_EMPLOYEE_END_TRACKING_REQUEST,
  POST_EMPLOYEE_END_TRACKING_REQUEST_SUCCESS,
  POST_EMPLOYEE_END_TRACKING_REQUEST_FAILURE,
};
