const POST_ACCEPT_REJECT_REQUEST = 'POST_ACCEPT_REJECT_REQUEST';
const POST_ACCEPT_REJECT_REQUEST_SUCCESS = 'POST_ACCEPT_REJECT_REQUEST_SUCCESS';
const POST_ACCEPT_REJECT_REQUEST_FAILURE = 'POST_ACCEPT_REJECT_REQUEST_FAILURE';
const RESET_ACCEPT_REJECT_STATE = 'RESET_ACCEPT_REJECT_STATE'

const acceptReject = (formData) => {

    return {
        type: POST_ACCEPT_REJECT_REQUEST,
        formData
    }
}
const resetAcceptReject = () => {

    return {
        type: RESET_ACCEPT_REJECT_STATE,

    }
}
export {
    acceptReject,
    resetAcceptReject,
    RESET_ACCEPT_REJECT_STATE,
    POST_ACCEPT_REJECT_REQUEST,
    POST_ACCEPT_REJECT_REQUEST_SUCCESS,
    POST_ACCEPT_REJECT_REQUEST_FAILURE,
};