import { atob, btoa } from 'js-base64';
import CryptoJS from 'crypto-js';
import {
  Platform,
  StatusBar,
  NativeModules,
  BackHandler
} from "react-native"
import DeviceInfo from 'react-native-device-info'


function projectCreatedTime(date) {
  try {
    let newDate = new Date()
    date = new Date(date)
    const difference = -newDate.getTimezoneOffset() / 60
    date = new Date(date.setHours(date.getHours() + difference))

    // newDate = newDate.setHours(newDate.getHours() + 5)
    const createDate = Math.floor(new Date(date) / 1000);
    const currDate = Math.floor(new Date().getTime() / 1000);

    const diffInSec = currDate - createDate;
    if (diffInSec < 3600) {
      if (Math.floor(diffInSec / 60) > 0) {
        return `${Math.floor(diffInSec / 60)} min`;
      }
      return `0 min`;
    } else if (diffInSec < 3600 * 24) {
      return `${Math.floor(diffInSec / 3600)} hours`;
    } else {
      // const diff = currDate - createDate
      const diff = Math.floor((currDate - createDate) * 1000);
      var Difference_In_Days = Math.floor(diff / (1000 * 3600 * 24))
      return `${Difference_In_Days} days`;
      // return `${diff + 1} days`;
    }
  } catch (error) {
    return "";
  }
}
function chatCreatedTime(date) {
  try {
    let newDate = new Date()
    date = new Date(date)
    const difference = -newDate.getTimezoneOffset() / 60
    date = new Date(date.setHours(date.getHours() + difference))
    const createDate = Math.floor(new Date(date) / 1000);
    const currDate = Math.floor(new Date().getTime() / 1000);

    const diffInSec = currDate - createDate;
    if (diffInSec < 3600) {
      if (Math.floor(diffInSec / 60) > 0) {
        return `${Math.floor(diffInSec / 60)} min`;
      }
      return `Just Sent`;
    } else if (diffInSec < 3600 * 24) {
      return `${Math.floor(diffInSec / 3600)} hours`;
    } else {
      // const diff = currDate - createDate
      const diff = Math.floor((currDate - createDate) * 1000);
      var Difference_In_Days = Math.floor(diff / (1000 * 3600 * 24))
      return `${Difference_In_Days} days`;
      // return `${diff + 1} days`;
    }
  } catch (error) {
    return "";
  }
}
const DateFormate = (date) => {
  const monthNames = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  return (
    date.getFullYear() +
    "-" +
    monthNames[date.getMonth()] +
    "-" +
    date.getDate()
  );
};
function timeToAMPM(hours, minutes) {
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? "0" + Number(hours).toString() : hours;
  minutes = minutes < 10 ? "0" + Number(minutes).toString() : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function ApiTimeFormat(time) {
  let setTime = time.split(":")
  var hours = setTime[0]
  var minutes = setTime[1];
  return timeToAMPM(hours, minutes)
}
export const calculateRating = (reviews) => {
  return reviews?.reduce((accumulator, review) => {
    return accumulator + review.rating;
  }, 0) / reviews?.length
}

function formatAMPM(currDate) {

  let date = new Date(currDate);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  return timeToAMPM(hours, minutes)

}

function formatTimeAMPM(hours, minutes) {
  var ampm = Number(hours) >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = Number(minutes) < 10 ? "0" + Number(minutes) : Number(minutes);
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
const ApiDateFormate = (currDate) => {
  let dateLength = Math.floor(new Date(currDate)).toString().length;
  let date = new Date(currDate);
  if (dateLength === 10) {
    date = new Date(Math.floor(new Date(currDate) * 1000));
  }
  if (dateLength === 13) {
    date = new Date(Math.floor(new Date(currDate / 1000) * 1000));
  }
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    monthNames[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear()
  );
};

function genHexString(len) {
  const hex = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let output = '';
  for (let i = 0; i < len; ++i) {
    output += hex.charAt(Math.floor(Math.random() * hex.length));
  }
  return output;
}
function encrypt(params, key) {
  params = JSON.stringify(params)
  let iv = genHexString(16);
  key = CryptoJS.enc.Utf8.parse(key);// Secret key
  let iv1 = CryptoJS.enc.Utf8.parse(iv);//Vector iv
  let encrypted = CryptoJS.AES.encrypt(params, key, { iv: iv1, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.toString() + ":" + btoa(iv);

}
const getStatusBarHeight = () => {
  if (Platform.OS === 'android') {
    return StatusBar.currentHeight || 0;
  } else if (Platform.OS === 'ios') {
    const { StatusBarManager } = NativeModules;
    return StatusBarManager.HEIGHT || 0;
  } else {
    return 0;
  }
};

const hardwareBackPress = () => {
  // if (Platform.OS == 'web') {
  //   return
  // }
  // const backHandler = BackHandler.addEventListener(
  //   'hardwareBackPress',
  //   () => {
  //     return true;
  //   },
  // );
  // return () => backHandler.remove();
}
function validURL(str) {
  if (!/https/.test(str)) {
    return false;
  }
  if (str.includes(".png") || str.includes(".jpg") || str.includes(".jpeg")) {
    return { photo: true, uri: str };
  }
  if (
    str.includes(".mov") ||
    str.includes(".flv") ||
    str.includes(".wmv") ||
    str.includes(".mp4") ||
    str.includes(".wmv")
  ) {
    let newUrl = str.substring(0, str.lastIndexOf(".")) + ".jpg";

    return { video: true, uri: newUrl };
  }
}
const formatePhNumber = (text) => {
  var cleaned = ("" + text).replace(/\D/g, "");
  let normValue = `${cleaned.substring(0, 3)}${cleaned.length > 3 ? "-" : ""
    }${cleaned.substring(3, 6)}${cleaned.length > 6 ? "-" : ""
    }${cleaned.substring(6, 10)}`;
  return normValue;
}

let app_version = DeviceInfo.getVersion()
export {
  DateFormate,
  formatAMPM,
  formatTimeAMPM,
  ApiDateFormate,
  encrypt,
  getStatusBarHeight,
  projectCreatedTime,
  ApiTimeFormat,
  hardwareBackPress,
  validURL,
  app_version,
  formatePhNumber,
  timeToAMPM,
  chatCreatedTime
};
