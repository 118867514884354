const POST_SUPERVISOR_ASSIGN_LEAD_REQUEST = "POST_SUPERVISOR_ASSIGN_LEAD_REQUEST";
const POST_SUPERVISOR_ASSIGN_LEAD_REQUEST_SUCCESS = "POST_SUPERVISOR_ASSIGN_LEAD_REQUEST_SUCCESS";
const POST_SUPERVISOR_ASSIGN_LEAD_REQUEST_FAILURE = "POST_SUPERVISOR_ASSIGN_LEAD_REQUEST_FAILURE";
const RESET_SUPERVISOR_ASSIGN_LEAD_STATE = "RESET_SUPERVISOR_ASSIGN_LEAD_STATE";

const supervisorAssignLead = (formData) => {
  return {
    type: POST_SUPERVISOR_ASSIGN_LEAD_REQUEST,
    formData
  };
};

const resetSupervisorAssignLead = () => {
  return {
    type: RESET_SUPERVISOR_ASSIGN_LEAD_STATE,
  };
};
export {
  supervisorAssignLead,
  resetSupervisorAssignLead,
  RESET_SUPERVISOR_ASSIGN_LEAD_STATE,
  POST_SUPERVISOR_ASSIGN_LEAD_REQUEST,
  POST_SUPERVISOR_ASSIGN_LEAD_REQUEST_SUCCESS,
  POST_SUPERVISOR_ASSIGN_LEAD_REQUEST_FAILURE,
};
