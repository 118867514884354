import { all } from "redux-saga/effects";
import { handler as basicStartSaga } from "./BasicStart/Saga";
import { handler as proDetailSaga } from "./ProUser/ProDetail/Saga";
import { handler as loginSaga } from "./Login/Saga";
import { handler as resendEmailSaga } from "./ResendEmail/Saga";
import { handler as getServiceCategorySaga } from "./ServiceCategory/Saga";
import { handler as registerProUserSaga } from "./ProUser/Register/Saga";
import { handler as updateProUserSaga } from "./ProUser/UpdateProUser/Saga";
import { handler as contactUsRequestSaga } from "./ContactUs/Saga";
import { handler as forgotPasswordSaga } from "./ForgotPassword/Saga";
import { handler as userRegisterSaga } from "./User/Register/Saga";
import { handler as deleteAccountSaga } from "./DeleteAccount/Saga";
import { handler as logoutUserSaga } from "./LogoutUser/Saga";
import { handler as updateUserSaga } from "./User/UpdateUser/Saga";
import { handler as reviewUserSaga } from "./User/ReviewUser/Saga";
import { handler as reviewProSaga } from "./ProUser/ReviewPro/Saga";
import { handler as reviewSpecialProSaga } from "./ProUser/ReviewSpecialPro/Saga";
import { handler as getNotificationsSaga } from "./GetNotifications/Saga";
import { handler as deleteNotificationsSaga } from "./DeleteNotifications/Saga";
import { handler as changePasswordSaga } from "./ChangePassword/Saga";
import { handler as changeLanguageSaga } from "./ChangeLanguage/Saga";
import { handler as citiesListSaga } from "./CitiesList/Saga";
import { handler as getUserChatSaga } from "./User/GetUserChat/Saga";
import { handler as getProChatSaga } from "./ProUser/GetProChat/Saga";
import { handler as newEmployeeSaga } from "./Employee/AddNewEmployee/Saga";
import { handler as employeeEndJobSaga } from "./Employee/EmployeeEndJob/Saga";
import { handler as deleteEmployeeSaga } from "./Employee/DeleteEmployee/Saga";
import { handler as assignEmployeeSaga } from "./ProUser/AssignEmployee/Saga";
import { handler as employeesDeleteSaga } from "./Employee/EmployeesDelete/Saga";
import { handler as updateEmployeeSaga } from "./Employee/UpdateEmployee/Saga";
import { handler as userEmployeeListSaga } from "./Employee/UserEmployeeList/Saga";
import { handler as getAllowedCountrySaga } from "./GetAllowedCountry/Saga";
import { handler as newProjectSaga } from "./User/NewProject/Saga";
import { handler as updateProjectSaga } from "./User/UpdateProject/Saga";
import { handler as authCodeSaga } from "./AuthCode/Saga";
import { handler as unreadNotificationsSaga } from "./UnreadNotifications/Saga";
import { handler as userSocialRegisterSaga } from "./User/UserSocialRegister/Saga";

import { handler as removePinSaga } from "./RemovePin/Saga";
import { handler as pinStatusSaga } from "./User/PinStatus/Saga";
import { handler as getOfferSaga } from "./User/GetOffer/Saga";
import { handler as getMyInstantProjectSaga } from "./User/GetMyInstantProjects/Saga";
import { handler as projectDeleteByUserSaga } from "./User/ProjectDeleteByUser/Saga";
import { handler as trackEarningSaga } from "./ProUser/TrackEarning/Saga";
import { handler as addCreditsSaga } from "./User/AddCredits/Saga";
import { handler as changeNotificationSettingSaga } from "./User/ChnageNotificationSetting/Saga";
import { handler as deleteClosedProjectSaga } from "./ProUser/DeleteClosedProject/Saga";
import { handler as projectDeleteByProSaga } from "./ProUser/ProjectDeleteByPro/Saga";
import { handler as getWithdrawHistorySaga } from "./GetWithdrawHistory/Saga";
import { handler as getChatForSpecialitySaga } from "./SpecialPro/GetChatForSpeciality/Saga";
import { handler as getInvoicesSaga } from "./ProUser/GetInvoices/Saga";
import { handler as getProRewardAmountSaga } from "./ProUser/GetProRewardAmount/Saga";
import { handler as cancelProjectAfterPaymentSaga } from "./User/CancelProjectAfterPayment/Saga";
import { handler as projectNotCompletedSaga } from "./User/ProjectNotComplete/Saga";
import { handler as confirnProjectCompletionSaga } from "./User/ConfirmProjectCompletion/Saga";
import { handler as acceptActualBidSaga } from "./User/AcceptActualBid/Saga";
import { handler as getServiceProfessionalsSaga } from "./User/GetServiceProfessionals/Saga";
import { handler as getPaymentIntentSaga } from "./GetPaymentIntent/Saga";
import { handler as captureBidAmountSaga } from "./User/CaptureBidAmount/Saga";
import { handler as responseOnBidSaga } from "./User/ResponseOnBid/Saga";
import { handler as getMyProjectsSaga } from "./User/GetMyProjects/Saga";
import { handler as quickQuoteRescheduleSaga } from "./User/QuickQuoteReschedule/Saga";
import { handler as rejectsCancelSaga } from "./User/RejectsCancel/Saga";
import { handler as rejectOnSiteVisitSaga } from "./User/RejectOnSiteVisit/Saga";
import { handler as approveOnSiteVisitSaga } from "./User/ApproveOnSiteVisit/Saga";
import { handler as getSiteVisitSaga } from "./User/GetSiteVisitRequests/Saga";
import { handler as sendRequestToProfessionalSaga } from "./SendRequestToProfessional/Saga";
import { handler as checkProInAreaSaga } from "./User/CheckProInArea/Saga";
import { handler as referFriendsSaga } from "./User/ReferFriends/Saga";
import { handler as makePayInMethodDefaultSaga } from "./MakePayInMethodDefault/Saga";
import { handler as makeDebitCardDefaultSaga } from "./ProUser/MakeDebitCardDefault/Saga";
import { handler as getRewardAmountSaga } from "./User/GetRewardAmount/Saga";
import { handler as getSpecialityServiceChatDetailSaga } from "./SpecialPro/GetSpecialityServiceChatDetail/Saga";
import { handler as getBidsSaga } from "./User/GetBids/Saga";
import { handler as projectResponseOnBidSaga } from "./User/ProjectResponseOnBid/Saga";
import { handler as gerUserEventSaga } from "./GerUserEvent/Saga";
import { handler as getUserProjectDetailSaga } from "./User/GetProjectsDetail/Saga";
import { handler as getProUserProjectDetailSaga } from "./ProUser/GetProjectDetail/Saga";
import { handler as addPayoutMethodsSaga } from "./ProUser/AddPayoutMethods/Saga";
import { handler as autoPaymentSaga } from "./SpecialPro/SetAutoPayment/Saga";
import { handler as removePayoutMethodsSaga } from "./RemovePaymentMethod/Saga";

import { handler as changeInstantPayoutSettingSaga } from "./ProUser/ChangeInstantPayoutSetting/Saga";
import { handler as addDebitCardSaga } from "./ProUser/AddDebitCard/Saga";
import { handler as removePayInMethodSaga } from "./RemovePayInMethod/Saga";
import { handler as removeDebitCardSaga } from "./ProUser/RemoveDebitCard/Saga";
import { handler as sendActualPriceSaga } from "./ProUser/SendActualPrice/Saga";
import { handler as onSiteVisitPaymentSaga } from "./ProUser/OnSiteVIsitPayment/Saga";
import { handler as retryOnSiteVisitPaymentSaga } from "./ProUser/RetryOnSiteVisitPayment/Saga";
import { handler as getMyPayInMethodSaga } from "./User/GetMyPayInMethod/Saga";
import { handler as acceptInstantProjectSaga } from "./User/AcceptInstantProject/Saga";
import { handler as getChatDetailSaga } from "./User/GetChatDetail/Saga";
import { handler as messageReadStatusSaga } from "./MessageReadStatus/Saga";
import { handler as uploadMessageFileSaga } from "./UploadMessageFile/Saga";
import { handler as endProjectByProSaga } from "./ProUser/EndProjectByPro/Saga";
import { handler as addPaymentSaga } from "./AddPayment/Saga";
import { handler as updatePaymentSaga } from "./UpdatePayment/Saga";
import { handler as getPaymentMethodSaga } from "./GetPaymentMethod/Saga";
import { handler as acceptRejectSaga } from "./Employee/AcceptReject/Saga";
import { handler as changeOnlineStatusSaga } from "./ProUser/ChangeOnlineStatus/Saga";
import { handler as startProjectSaga } from "./ProUser/StartProject/Saga";
import { handler as selectPaymentMethodSaga } from "./SelectPaymentMethod/Saga";
import { handler as getUgoMainServicesSaga } from "./GetUgoMainServices/Saga";
import { handler as reSendAuthCodeSaga } from "./ReSendAuthenticationCode/Saga";
import { handler as bidOnProjectSaga } from "./ProUser/BidOnProject/Saga";
import { handler as addPayinMethodSaga } from "./User/AddPayinMethod/Saga";
import { handler as AddPayinMethodWithTokenSaga } from "./User/AddPayinMethodWithToken/Saga";

import { handler as getSpecialRequestSaga } from "./SpecialPro/GetSpecialRequest/Saga";
import { handler as getPaymentHistorySaga } from "./User/GetPaymentHistory/Saga";
import { handler as getProjectSaga } from "./ProUser/GetProject/Saga";
import { handler as getProReviewsSaga } from "./ProUser/GetProReviews/Saga";
import { handler as getPayoutMethodSaga } from "./ProUser/GetPayOutMethod/Saga";
import { handler as employeeProjectsSaga } from "./Employee/GetEmployeeProjects/Saga";
import { handler as rejectProjectSaga } from "./ProUser/RejectProject/Saga";
import { handler as subscribeUserSaga } from "./ProUser/SubscribeUser/Saga";
import { handler as cancelSubscribeUserSaga } from "./SpecialPro/CancelSubscribeUser/Saga";
import { handler as reSubscribeUserSaga } from "./SpecialPro/ReSubscribeUser/Saga";
import { handler as supervisorLoginSaga } from "./SupportUser/Supervisor/SupervisorLogin/Saga";
import { handler as employeeLoginSaga } from "./SupportUser/Employee/EmployeeLogin/Saga";
import { handler as getTaskSaga } from "./SupportUser/Employee/GetTask/Saga";
import { handler as getMyLeadsSaga } from "./SupportUser/Employee/GetMyLeads/Saga";
import { handler as updateProfileSaga } from "./SupportUser/Employee/UpdateProfile/Saga";
import { handler as updateNotificationSaga } from "./SupportUser/Employee/UpdateNotificationSetting/Saga";
import { handler as getChatHistorySaga } from "./SupportUser/Employee/GetChatHistory/Saga";


import { handler as deletePortalUserSaga } from "./SupportUser/Employee/DeletePortalUser/Saga";
import { handler as getSalesEmployeeSaga } from "./SupportUser/Supervisor/GetSaleEmployee/Saga";
import { handler as getStreetEmployeeSaga } from "./SupportUser/Supervisor/GetStreetEmployee/Saga";
import { handler as getAllEmployeesSaga } from "./SupportUser/Supervisor/GetAllEmployees/Saga";
import { handler as getAllLeadsSaga } from "./SupportUser/Supervisor/GetAllLeads/Saga";
import { handler as getCitiesSaga } from "./SupportUser/Supervisor/GetCities/Saga";
import { handler as supervisorUpdateLeadStatusSaga } from "./SupportUser/Supervisor/UpdateLeadStatus/Saga";
import { handler as supervisorUpdateNotificationSaga } from "./SupportUser/Supervisor/SupervisorNotificationSetting/Saga";
import { handler as supervisorUpdateProfileSaga } from "./SupportUser/Supervisor/SupervisorUpdateProfile/Saga";
import { handler as supervisorUpdateEmployeeSaga } from "./SupportUser/Supervisor/UpdateEmployee/Saga";
import { handler as createEmployeeSaga } from "./SupportUser/Supervisor/CreateEmployee/Saga";
import { handler as getBasicValuesSaga } from "./SupportUser/Supervisor/GetBasicValues/Saga";
import { handler as supervisorUserDeleteSaga } from "./SupportUser/Supervisor/SupervisorUserDelete/Saga";
import { handler as createNewLeadSaga } from "./SupportUser/Supervisor/CreateNewLead/Saga";
import { handler as supervisorChatHistorySaga } from "./SupportUser/Supervisor/SupervisorChatHistory/Saga";
import { handler as supervisorMessagesListSaga } from "./SupportUser/Supervisor/SupervisorMessageList/Saga";
import { handler as supervisorAssignLeadSaga } from "./SupportUser/Supervisor/SupervisorAssignLead/Saga";
import { handler as endTrackingSaga } from "./SupportUser/Employee/EndTracking/Saga";
import { handler as startTaskSaga } from "./SupportUser/Employee/StartTask/Saga";
import { handler as employeeUpdateStatusSaga } from "./SupportUser/Employee/EmployeeUpdateStatus/Saga";
import { handler as endTaskSaga } from "./SupportUser/Employee/EndTask/Saga";

export default function* rootSaga() {
  yield all([
    basicStartSaga(),
    proDetailSaga(),
    loginSaga(),
    resendEmailSaga(),
    getServiceCategorySaga(),
    registerProUserSaga(),
    updateProUserSaga(),
    contactUsRequestSaga(),
    forgotPasswordSaga(),
    userRegisterSaga(),
    deleteAccountSaga(),
    logoutUserSaga(),
    updateUserSaga(),
    reviewUserSaga(),
    reviewProSaga(),
    reviewSpecialProSaga(),
    getNotificationsSaga(),
    deleteNotificationsSaga(),
    changePasswordSaga(),
    changeLanguageSaga(),
    citiesListSaga(),
    getUserChatSaga(),
    getProChatSaga(),
    newEmployeeSaga(),
    employeeEndJobSaga(),
    deleteEmployeeSaga(),
    assignEmployeeSaga(),
    employeesDeleteSaga(),
    updateEmployeeSaga(),
    userEmployeeListSaga(),
    getAllowedCountrySaga(),
    newProjectSaga(),
    updateProjectSaga(),
    authCodeSaga(),
    unreadNotificationsSaga(),
    userSocialRegisterSaga(),

    removePinSaga(),
    pinStatusSaga(),
    getOfferSaga(),
    getMyInstantProjectSaga(),
    projectDeleteByUserSaga(),
    trackEarningSaga(),
    addCreditsSaga(),
    changeNotificationSettingSaga(),
    deleteClosedProjectSaga(),
    projectDeleteByProSaga(),
    getWithdrawHistorySaga(),
    getChatForSpecialitySaga(),
    getInvoicesSaga(),
    getProRewardAmountSaga(),
    cancelProjectAfterPaymentSaga(),
    projectNotCompletedSaga(),
    confirnProjectCompletionSaga(),
    acceptActualBidSaga(),
    getServiceProfessionalsSaga(),
    getPaymentIntentSaga(),
    captureBidAmountSaga(),
    responseOnBidSaga(),
    getMyProjectsSaga(),
    quickQuoteRescheduleSaga(),
    rejectsCancelSaga(),
    rejectOnSiteVisitSaga(),
    approveOnSiteVisitSaga(),
    getSiteVisitSaga(),
    sendRequestToProfessionalSaga(),
    checkProInAreaSaga(),
    referFriendsSaga(),
    makePayInMethodDefaultSaga(),
    makeDebitCardDefaultSaga(),
    getRewardAmountSaga(),
    getSpecialityServiceChatDetailSaga(),
    getBidsSaga(),
    projectResponseOnBidSaga(),
    gerUserEventSaga(),
    getProUserProjectDetailSaga(),
    getUserProjectDetailSaga(),
    addPayoutMethodsSaga(),
    autoPaymentSaga(),
    removePayoutMethodsSaga(),

    changeInstantPayoutSettingSaga(),
    addDebitCardSaga(),
    removePayInMethodSaga(),
    removeDebitCardSaga(),
    sendActualPriceSaga(),
    onSiteVisitPaymentSaga(),
    retryOnSiteVisitPaymentSaga(),
    getMyPayInMethodSaga(),
    acceptInstantProjectSaga(),
    getChatDetailSaga(),
    messageReadStatusSaga(),
    uploadMessageFileSaga(),
    endProjectByProSaga(),
    addPaymentSaga(),
    updatePaymentSaga(),
    getPaymentMethodSaga(),
    acceptRejectSaga(),
    changeOnlineStatusSaga(),
    startProjectSaga(),
    selectPaymentMethodSaga(),
    getUgoMainServicesSaga(),
    reSendAuthCodeSaga(),
    bidOnProjectSaga(),
    addPayinMethodSaga(),
    AddPayinMethodWithTokenSaga(),
    getSpecialRequestSaga(),
    getPaymentHistorySaga(),
    getProjectSaga(),
    getProReviewsSaga(),
    getPayoutMethodSaga(),
    employeeProjectsSaga(),
    rejectProjectSaga(),
    subscribeUserSaga(),
    cancelSubscribeUserSaga(),
    reSubscribeUserSaga(),
    supervisorLoginSaga(),
    employeeLoginSaga(),
    getTaskSaga(),
    getMyLeadsSaga(),
    updateProfileSaga(),
    updateNotificationSaga(),
    getChatHistorySaga(),

    deletePortalUserSaga(),
    getSalesEmployeeSaga(),
    getStreetEmployeeSaga(),
    getAllEmployeesSaga(),
    getAllLeadsSaga(),
    getCitiesSaga(),
    supervisorUpdateLeadStatusSaga(),
    supervisorUpdateNotificationSaga(),
    supervisorUpdateProfileSaga(),
    supervisorUpdateEmployeeSaga(),
    createEmployeeSaga(),
    getBasicValuesSaga(),
    supervisorUserDeleteSaga(),
    createNewLeadSaga(),
    supervisorChatHistorySaga(),
    supervisorMessagesListSaga(),
    supervisorAssignLeadSaga(),
    endTrackingSaga(),
    startTaskSaga(),
    employeeUpdateStatusSaga(),
    endTaskSaga(),
  ]);
}
