const POST_SUPERVISOR_MESSAGES_LIST_REQUEST = "POST_SUPERVISOR_MESSAGES_LIST_REQUEST";
const POST_SUPERVISOR_MESSAGES_LIST_REQUEST_SUCCESS = "POST_SUPERVISOR_MESSAGES_LIST_REQUEST_SUCCESS";
const POST_SUPERVISOR_MESSAGES_LIST_REQUEST_FAILURE = "POST_SUPERVISOR_MESSAGES_LIST_REQUEST_FAILURE";
const RESET_SUPERVISOR_MESSAGES_LIST_STATE = "RESET_SUPERVISOR_MESSAGES_LIST_STATE";
const ADD_SUPERVISOR_MESSAGES_LIST_CHAT = "ADD_SUPERVISOR_MESSAGES_LIST_CHAT"
const supervisorMessagesList = (formData) => {
  return {
    type: POST_SUPERVISOR_MESSAGES_LIST_REQUEST,
    formData
  };
};

const resetSupervisorMessagesList = () => {
  return {
    type: RESET_SUPERVISOR_MESSAGES_LIST_STATE,
  };
};
const addMessageToList = (message) => {
  return {
    type: ADD_SUPERVISOR_MESSAGES_LIST_CHAT,
    message,
  };
};
export {
  supervisorMessagesList,
  resetSupervisorMessagesList,
  addMessageToList,
  ADD_SUPERVISOR_MESSAGES_LIST_CHAT,
  RESET_SUPERVISOR_MESSAGES_LIST_STATE,
  POST_SUPERVISOR_MESSAGES_LIST_REQUEST,
  POST_SUPERVISOR_MESSAGES_LIST_REQUEST_SUCCESS,
  POST_SUPERVISOR_MESSAGES_LIST_REQUEST_FAILURE,
};
