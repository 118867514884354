const POST_RE_SEND_AUTHENTICATION_CODE_REQUEST = 'POST_RE_SEND_AUTHENTICATION_CODE_REQUEST';
const POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_SUCCESS = 'POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_SUCCESS';
const POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_FAILURE = 'POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_FAILURE';
const RESET_RE_SEND_AUTHENTICATION_CODE_STATE = 'RESET_RE_SEND_AUTHENTICATION_CODE_STATE'

const reSendAuthCode = (formData) => {

    return {
        type: POST_RE_SEND_AUTHENTICATION_CODE_REQUEST,
        formData
    }
}
const resetReSendAutCode = () => {

    return {
        type: RESET_RE_SEND_AUTHENTICATION_CODE_STATE,
    }
}
export {
    reSendAuthCode,
    resetReSendAutCode,
    RESET_RE_SEND_AUTHENTICATION_CODE_STATE,
    POST_RE_SEND_AUTHENTICATION_CODE_REQUEST,
    POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_SUCCESS,
    POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_FAILURE,
};