const POST_GET_INVOICES_REQUEST = 'POST_GET_INVOICES_REQUEST';
const POST_GET_INVOICES_REQUEST_SUCCESS = 'POST_GET_INVOICES_REQUEST_SUCCESS';
const POST_GET_INVOICES_REQUEST_FAILURE = 'POST_GET_INVOICES_REQUEST_FAILURE';
const RESET_GET_INVOICES_STATE = 'RESET_GET_INVOICES_STATE'

const getInvoices = (formData) => {

    return {
        type: POST_GET_INVOICES_REQUEST,
        formData
    }
}
const resetGetInvoices = () => {

    return {
        type: RESET_GET_INVOICES_STATE,

    }
}
export {
    getInvoices,
    resetGetInvoices,
    RESET_GET_INVOICES_STATE,
    POST_GET_INVOICES_REQUEST,
    POST_GET_INVOICES_REQUEST_SUCCESS,
    POST_GET_INVOICES_REQUEST_FAILURE,
};