const POST_ADD_DEBIT_CARD_REQUEST = 'POST_ADD_DEBIT_CARD_REQUEST';
const POST_ADD_DEBIT_CARD_REQUEST_SUCCESS = 'POST_ADD_DEBIT_CARD_REQUEST_SUCCESS';
const POST_ADD_DEBIT_CARD_REQUEST_FAILURE = 'POST_ADD_DEBIT_CARD_REQUEST_FAILURE';
const RESET_ADD_DEBIT_CARD_STATE = 'RESET_ADD_DEBIT_CARD_STATE'

const addDebitCard = (formData) => {

    return {
        type: POST_ADD_DEBIT_CARD_REQUEST,
        formData
    }
}
const resetAddDebitCard = () => {

    return {
        type: RESET_ADD_DEBIT_CARD_STATE,

    }
}
export {
    addDebitCard,
    resetAddDebitCard,
    RESET_ADD_DEBIT_CARD_STATE,
    POST_ADD_DEBIT_CARD_REQUEST,
    POST_ADD_DEBIT_CARD_REQUEST_SUCCESS,
    POST_ADD_DEBIT_CARD_REQUEST_FAILURE,
};