const POST_REGISTER_PRO_USER_REQUEST = "POST_REGISTER_PRO_USER_REQUEST";
const POST_REGISTER_PRO_USER_REQUEST_SUCCESS = "POST_REGISTER_PRO_USER_REQUEST_SUCCESS";
const POST_REGISTER_PRO_USER_REQUEST_FAILURE = "POST_REGISTER_PRO_USER_REQUEST_FAILURE";
const RESET_REGISTER_STATE = "RESET_REGISTER_STATE";

const registerProUser = (formData) => {
  return {
    type: POST_REGISTER_PRO_USER_REQUEST,
    formData
  };
};

export {
  registerProUser,
  POST_REGISTER_PRO_USER_REQUEST,
  POST_REGISTER_PRO_USER_REQUEST_SUCCESS,
  POST_REGISTER_PRO_USER_REQUEST_FAILURE,
  RESET_REGISTER_STATE
};
