const POST_ADD_CREDITS_REQUEST = 'POST_ADD_CREDITS_REQUEST';
const POST_ADD_CREDITS_REQUEST_SUCCESS = 'POST_ADD_CREDITS_REQUEST_SUCCESS';
const POST_ADD_CREDITS_REQUEST_FAILURE = 'POST_ADD_CREDITS_REQUEST_FAILURE';
const RESET_ADD_CREDITS_STATE = 'RESET_ADD_CREDITS_STATE'

const addCredits = (formData) => {

    return {
        type: POST_ADD_CREDITS_REQUEST,
        formData
    }
}
const resetAddCredits = () => {

    return {
        type: RESET_ADD_CREDITS_STATE,
    }
}
export {
    addCredits,
    resetAddCredits,
    RESET_ADD_CREDITS_STATE,
    POST_ADD_CREDITS_REQUEST,
    POST_ADD_CREDITS_REQUEST_SUCCESS,
    POST_ADD_CREDITS_REQUEST_FAILURE,
};