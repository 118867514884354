const SET_USER = "SET_USER";
const RESET_USER = "RESET_USER";

const setUser = (user) => {
  return {
    type: SET_USER,
    user
  }
}
const resetUser = () => {
  return {
    type: RESET_USER,
  }
}

export {
  setUser,
  resetUser,
  SET_USER,
  RESET_USER,
};
