const POST_TRACK_EARNING_REQUEST = 'POST_TRACK_EARNING_REQUEST';
const POST_TRACK_EARNING_REQUEST_SUCCESS = 'POST_TRACK_EARNING_REQUEST_SUCCESS';
const POST_TRACK_EARNING_REQUEST_FAILURE = 'POST_TRACK_EARNING_REQUEST_FAILURE';
const RESET_TRACK_EARNING_STATE = 'RESET_TRACK_EARNING_STATE'

const trackEarning = (formData) => {

    return {
        type: POST_TRACK_EARNING_REQUEST,
        formData
    }
}
const resetTrackEarning = () => {

    return {
        type: RESET_TRACK_EARNING_STATE,

    }
}
export {
    trackEarning,
    resetTrackEarning,
    RESET_TRACK_EARNING_STATE,
    POST_TRACK_EARNING_REQUEST,
    POST_TRACK_EARNING_REQUEST_SUCCESS,
    POST_TRACK_EARNING_REQUEST_FAILURE,
};