const POST_EMPLOYEE_START_TASK_REQUEST = "POST_EMPLOYEE_START_TASK_REQUEST";
const POST_EMPLOYEE_START_TASK_REQUEST_SUCCESS = "POST_EMPLOYEE_START_TASK_REQUEST_SUCCESS";
const POST_EMPLOYEE_START_TASK_REQUEST_FAILURE = "POST_EMPLOYEE_START_TASK_REQUEST_FAILURE";
const RESET_EMPLOYEE_START_TASK_STATE = "RESET_EMPLOYEE_START_TASK_STATE";

const startTask = (formData) => {
  return {
    type: POST_EMPLOYEE_START_TASK_REQUEST,
    formData
  };
};

const resetStartTask = () => {
  return {
    type: RESET_EMPLOYEE_START_TASK_STATE,
  };
};
export {
  startTask,
  resetStartTask,
  RESET_EMPLOYEE_START_TASK_STATE,
  POST_EMPLOYEE_START_TASK_REQUEST,
  POST_EMPLOYEE_START_TASK_REQUEST_SUCCESS,
  POST_EMPLOYEE_START_TASK_REQUEST_FAILURE,
};
