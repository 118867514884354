import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../URLs";
import { queryApi } from "../../QueryApi";
import {
  POST_REGISTER_PRO_USER_REQUEST,
  POST_REGISTER_PRO_USER_REQUEST_SUCCESS,
  POST_REGISTER_PRO_USER_REQUEST_FAILURE,
} from "./Action";
import { Alert } from "react-native";
function* handler() {
  yield takeEvery(POST_REGISTER_PRO_USER_REQUEST, registerProUser);
}

function* registerProUser(action) {
  try {
    // API call
    // console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.API_REGISTER_PRO_USER,
      method: "POST",
      body: action.formData      
    });
    console.log("HIT", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_REGISTER_PRO_USER_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_REGISTER_PRO_USER_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_REGISTER_PRO_USER_REQUEST_FAILURE,
      payload: { error:{message: err} },
    });
  }
}

export { handler };
