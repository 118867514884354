export default {



  app_name: 'U’GO Pros',
  facebook_app_id: '1464655393624471',
  facebook_client_token: 'f93f25880f86c822b0eb4da7147c18c6',
  fb_login_protocol_scheme: 'fb1464655393624471',
  Continue_with_Facebook: 'फेसबुक के साथ जारी रखें',
  Sign_in_with_Google: 'கூகிளுடன் உள்நுழைக',
  create_account: 'பதிவுபெறுக',
  create_account_: 'பதிவுபெறுக',
  user_privacy: 'தனியுரிமை',
  terms_conditions: 'விதிமுறை',
  forgot_pass: 'கடவுச்சொல்லை மறந்துவிட்டீர்களா?',
  terms: 'விதிமுறைகள் மற்றும் நிபந்தனைகள்',
  validationErrors: 'சரிபார்ப்பு பிழைகள்',
  meeting_note: '<b>குறிப்பு:</b> மதிப்பீட்டை நீங்கள் (பயனர்) ஏற்றுக்கொண்டவுடன், இந்தத் தொகை உங்கள் கட்டண முறையிலிருந்து கோப்பில் எடுக்கப்படும். நீங்கள் எந்த நேரத்திலும் ஒரு திட்டத்தை ரத்து செய்யலாம், ஆனால் ஒரு மதிப்பீடு ஏற்றுக்கொள்ளப்பட்டதும், திட்டத்தை ரத்துசெய்ததும், அந்த மதிப்பீட்டில் 5% கட்டணம் வசூலிக்கப்படும். திட்டத்தின் முடிவில் கட்டணம் இறுதி செலவுக்கு ஏற்ப சரிசெய்யப்படும்.',
  bsb_number: 'வழித்தட எண்r',
  account_number: 'கணக்கு எண்',
  account_name: 'கணக்கின் பெயர்',
  txt_response_rate: 'கடந்த ஆண்டில் நீங்கள் பெற்ற 0 முன்பதிவு கோரிக்கையில் 24 மணி முதல் 0 மணி வரை பதிலளித்தீர்கள்.',
  below_are_the_cities: 'Below are the cities you selected, wish to continue?\n\n',
  sunday_text: 'சூரியன்',
  monday_text: 'திங்கள்',
  tuesday_text: 'செவ்வாய்',
  wednesday_text: 'திருமணம் செய்',
  thursday_text: 'து',
  friday_text: 'வெள்ளி',
  saturday_text: 'Sat',
  no_events: 'நிகழ்வுகள் இல்லை',
  call_failure: 'Failure!',

  invalid_attribute: 'காலெண்டரை உருவாக்குவதில் தோல்வி, \n\n பண்புகளின் தவறான மதிப்பு!, தயவுசெய்து மதிப்பாய்வு &amp; ஆம்ப்; மீண்டும் முயற்சி செய்!',
  your_earnings: 'உங்கள் வருவாய் உடனடியாக உங்களுக்குக் கிடைக்கும். வாராந்திர கட்டணத்திற்காக காத்திருக்க வேண்டிய அவசியமில்லை. \n உங்கள் வருவாயை நீங்கள் விரும்பும் போது பெறுங்கள். ஒரு நாளைக்கு 5 முறை வரை உடனடி ஊதியத்துடன் பணமளிக்கவும். ',
  reviews_detail: 'விவரங்களை மறுபரிசீலனை செய்க',
  oops_enter_payout_method_: 'செலுத்தும் முறை இல்லை. பணம் செலுத்த, செலுத்தும் முறையை உள்ளிடவும். கவலைப்பட வேண்டாம், எங்கள் மூன்றாம் தரப்பு கட்டண செயலாக்கத்தின் மூலம் உங்கள் தகவல் பாதுகாப்பானது.',
  start_your_connection_with: 'இதனுடன் உங்கள் இணைப்பைத் தொடங்கவும்',
  about_business: 'வணிகம் பற்றி',
  done: 'முடிந்தது',
  account_type: 'கணக்கு வகை',
  checking: 'சரிபார்க்கிறது',
  saving: 'சேமிக்கிறது',
  business_checking: 'வணிக சோதனை',
  save: 'SAVE',
  ach_payment: 'ACH PAYMENT',
  set_as_default: 'இயல்புநிலைக்கு அமை',
  feedback: 'பின்னூட்டம்',
  tell_me_how_so_we_can_match_you_with_better_jobs: 'சிறந்த வேலைகளுடன் நாங்கள் உங்களை எவ்வாறு பொருத்த முடியும் என்று என்னிடம் சொல்லுங்கள்.',
  i_am_busy: 'நான் பிஸியாக இருக்கிறேன்',
  wrong_area: 'தவறான பகுதி',
  not_my_type_of_work: 'எனது வகை வேலை அல்ல',
  submit: 'சமர்ப்பிக்கவும்',
  actions: 'நடவடிக்கைகள்',
  accept_amp_schedule: 'ஏற்றுக்கொள் &amp; அட்டவணை',
  reject: 'நிராகரி',
  save_to_another: 'இன்னொருவருக்கு சேமிக்கவும்',
  date: 'தேதி',
  request_details: 'விவரங்களைக் கோருங்கள்',
  description: 'விளக்கம்',
  media: 'மீடியா',
  time: 'நேரம்',
  start_job: 'ஸ்டார்ட் வேலை',
  enter_final_cost_optional: 'இறுதி செலவை உள்ளிடவும் (Optional)',
  upload_work_photo: 'வேலை புகைப்படத்தை பதிவேற்றவும்',
  project_amount: 'திட்ட தொகை :',
  u_go_pros_fee: 'U\'GO நன்மை கட்டணம் :',
  u_go_pros_fee_structure: 'U\'GO Pros கட்டண கட்டுப்பாடு',
  you_will_receive: 'உங்களுக்கு கிடைக்கும் :',
  If_these_times_are_not_convenient: 'இந்த நேரங்கள் உங்களுக்கு அருகிலும் பெருகின்றன, உங்களுக்கு அந்தச் சொந்தத்தோருடன் உங்களுக்கு சுழற்சியான நேரம் கண்டறிய அனுமதி உள்ளதாகவும்.',
  _0: '$ 0',
  _10: '10%',
  submit_when_completed: 'முடிந்ததும் சமர்ப்பிக்கவும்',
  chat: 'சாட்',
  uploaded_pics: 'பதிவேற்றிய படங்கள்',
  view_video: 'வீடியோவைக் காண்க',
  contractor_note: 'ஒப்பந்தக்காரர் குறிப்பு',
  contractor_uploaded_pics: 'ஒப்பந்தக்காரர் பதிவேற்றிய படங்கள்',
  location: 'இடம்',
  assign_job: 'ASSIGN JOB',
  change_employee: 'பணியாளரை மாற்றவும்',
  project_already_assign: "இந்த ஊழியருக்கு ஏற்கனவே திட்டம் ஒதுக்கப்பட்டுள்ளது",
  send_official_cost: 'அதிகாரப்பூர்வ செலவு அனுப்பவும்',
  new_project: 'புதிய திட்டம்',
  add_document: 'ஆவணத்தைச் சேர்க்கவும்',
  max_limit_1: '(அதிகபட்ச வரம்பு 1)',
  next: 'அடுத்தது',
  add_employee: 'பணியாளரைச் சேர்க்கவும்',
  this_information_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'இந்த தகவல் சில நொடிகளில் சேமிக்கப்படும் மற்றும் மேலே உள்ள படிவத்தில் வழங்கப்பட்ட பயனர் மின்னஞ்சலுக்கு அனுப்பப்படும்.',
  show_us_more_about_your_project: 'உங்கள் திட்டத்தைப் பற்றி மேலும் காட்டுங்கள்',
  add_photo: 'புகைப்படம் சேர்க்க',
  max_limit_5: '(அதிகபட்ச வரம்பு 5)',
  add_video: 'வீடியோவைச் சேர்க்கவும்',
  add_work_video: 'வேலை வீடியோவைச் சேர்க்கவும்',
  max_duration_30sec: 'காணொளியின் அதிகபடியான நேரம் 30 வினாடி',
  where_is_the_service_location: 'சேவை இடம் எங்கே?',
  i_m_ready_for_quotes: 'நான் மேற்கோள்களுக்கு தயாராக உள்ளேன்',
  review: 'விமர்சனம்',
  close: 'நெருக்கமான',
  select_any_option: 'எந்த விருப்பத்தையும் தேர்ந்தெடுக்கவும்',
  view_photo: 'புகைப்படத்தைக் காண்க',
  view_photos: ' புகைப்படங்களைக் காண்க',
  already_pay_out: 'ஏற்கனவே பணம் செலுத்துங்கள்',
  _0_usd: '$0 USD',
  expected_earnings: 'எதிர்பார்க்கப்படும் வருவாய்',
  total: 'மொத்தம்',
  _0_project: '0 திட்டம்',
  total_complete_project: 'மொத்த முழுமையான திட்டம்',
  total_active_project: 'மொத்த செயலில் உள்ள திட்டம்',
  _0_response_rate: '0% மறுமொழி விகிதம்',
  response_rate: 'மறுமொழி விகிதம்',
  send: 'அனுப்புக',
  auto_services: 'தன்னியக்க சேவைகள்',
  project_details: 'திட்ட விவரங்கள்',
  hourly_price: 'மணிநேர விலை',
  n_a: 'ந / அ',
  portfolio: 'சேவை',
  default_album: 'இயல்புநிலை ஆல்பம்',
  recent_activities: 'சமீபத்திய செயல்பாடுகள்',
  _15_online_bids_100_response_rate: '15 ஆன்லைன் ஏலங்கள், 100% மறுமொழி விகிதம்.',
  business_detail: 'வணிக விவரம்',
  add_review: 'மதிப்பாய்வைச் சேர்',
  reviews: 'விமர்சனங்கள்',
  select_your_service_category: 'உங்கள் சேவை வகையைத் தேர்ந்தெடுக்கவும்',
  change_password: 'கடவுச்சொல்லை மாற்று',
  change_password_mark: 'கடவுச்சொல்லை மாற்று?கடவுச்சொல்லை மாற்று?',
  please_enter_the_code_you_were_sent_by_email_and_reset_your_new_password: 'மின்னஞ்சல் மூலம் நீங்கள் அனுப்பிய குறியீட்டை உள்ளிட்டு உங்கள் புதிய கடவுச்சொல்லை மீட்டமைக்கவும்.',
  messages: 'செய்திகள்',
  contact_info: 'தொடர்பு தகவல்',
  project_info: 'திட்ட தகவல்',
  category: 'வகை',
  view_document: 'ஆவணத்தைக் காண்க',
  budget_dolar: 'பட்ஜெட் $',
  min_budget: 'குறைந்தபட்ச பட்ஜெட் $',
  max_budget: 'அதிகபட்ச பட்ஜெட் $',
  assigning_detail: 'விரிவாக ஒதுக்குதல்',
  bid_detail: 'ஏலம் விரிவாக',
  notes: 'குறிப்புகள்',
  contact_us: 'எங்களை தொடர்பு கொள்ள',
  send_us_an_email: 'எங்களுக்கு ஒரு மின்னஞ்சல் அனுப்புங்கள்',
  let_us_know_what_you_think_don_t_hesitate_to_send_us_questions_or_comments_we_value_your_thoughts_and_will_respond_in_a_timely_manner: 'நீங்கள் என்ன நினைக்கிறீர்கள் என்பதை எங்களுக்குத் தெரியப்படுத்துங்கள்! எங்களுக்கு கேள்விகள் அல்லது கருத்துகளை அனுப்ப தயங்க வேண்டாம். உங்கள் எண்ணங்களை நாங்கள் மதிக்கிறோம், சரியான நேரத்தில் பதிலளிப்போம்!',
  bonded_insured: 'பிணைக்கப்பட்ட / காப்பீடு செய்யப்பட்ட',
  accredited: 'அங்கீகாரம் பெற்றது',
  nari_member: 'நரி உறுப்பினர்',
  service_area: 'சேவை பகுதி',
  speciality_services: 'சிறப்பு சேவைகள்',
  categories: 'வகைகள்',
  add_utility_bill: 'பயன்பாட்டு மசோதாவைச் சேர்க்கவும்',
  portfolio_optional: 'சேவை (விரும்பினால்)',
  by_creating_account_you_agree_to_our: 'கணக்கை உருவாக்குவதன் மூலம் நீங்கள் எங்கள் ஒப்புக்கொள்கிறீர்கள்',
  register: 'பதிவு',
  create_profile: 'சுயவிவரத்தை உருவாக்கவும்',
  date_of_birth: 'பிறந்த தேதி',
  credits: 'கிரெடிட்கள்',
  u_go_reward_credits: 'U’GO வெகுமதி கிரெடிட்கள்',
  friend_referral: 'நண்பர் பரிந்துரை',
  rate_contractor: 'விகித ஒப்பந்தக்காரர்',
  _20_gift_card: '$20 gift cardGift 20 பரிசு அட்டை',
  _20_instantly_for_your_friend_and_20_for_you_once_their_project_completed: 'உங்கள் நண்பருக்கு உடனடியாக $ 20 மற்றும் அவர்களின் திட்டம் முடிந்ததும் உங்களுக்காக $ 20',
  redeem_credits: 'வரவுகளை மீட்டெடுக்கவும்',
  add: 'கூட்டு',
  default_payment: 'இயல்புநிலை கட்டணம்',
  documents: 'ஆவணங்கள்',
  edit_profile: 'சுயவிவரத்தைத் திருத்து',
  add_view: 'கூட்டு / View',
  service: 'சேவை',
  state: 'நிலை',
  city: 'நகரம்',
  update: 'புதுப்பிப்பு',
  employee: 'பணியாளர்',
  record_not_found: 'பதிவு கிடைக்கவில்லை',
  employee_info: 'பணியாளர் தகவல்',
  mobile_no: 'அலைபேசி எண்',
  email: 'மின்னஞ்சல்',
  address: 'முகவரி',
  delete: 'அழி',
  tell_us_more_about_your_project: 'உங்கள் திட்டத்தைப் பற்றி மேலும் சொல்லுங்கள்',
  project_description: 'திட்ட விளக்கம்',
  what_is_your_budget: 'உங்கள் பட்ஜெட் என்ன?',
  what_is_your_contact_info: 'உங்கள் தொடர்பு தகவல் என்ன?',
  what_is_your_availability: 'உங்கள் கிடைக்கும் தன்மை என்ன?',
  _1st_option_date: '1 வது விருப்ப தேதி',
  _2nd_option_date: '2 வது விருப்ப தேதி',
  estimates: 'மதிப்பீடுகள்',
  your_available_time: 'உங்கள் கிடைக்கக்கூடிய நேரம்',
  best_case_scenario: 'சிறந்த வழக்கு காட்சி',
  worst_case_scenario: 'மோசமான வழக்கு காட்சி',
  exclusive_services: 'எக்ஸ்க்ளூசிவ் சேவைகள்',
  forgot_password: 'கடவுச்சொல்லை மறந்துவிட்டீர்களா',
  forgot_password_mark: 'கடவுச்சொல்லை மறந்துவிட்டீர்களா?',
  enter_your_email_below_to_receive_your_password_reset_instructions: 'உங்கள் கடவுச்சொல் மீட்டமைப்பு வழிமுறைகளைப் பெற உங்கள் மின்னஞ்சலை கீழே உள்ளிடவும்.',
  send_password: 'கடவுச்சொல்லை அனுப்பவும்',
  friends_referral: 'நண்பர்கள் குறிப்பு',
  enter_friend_email_below_to_refer_your_friend: 'உங்கள் நண்பரைக் குறிக்க கீழே நண்பர் மின்னஞ்சலை உள்ளிடவும்.',
  _20_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_home_user_n_n_50_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_pro_user: 'நட்பு 20 குறிப்பிடப்பட்ட நண்பர் வீட்டு பயனராக இருந்தால் உடனடியாக உங்கள் நண்பருக்கு அவர்களின் முதல் திட்ட நிறைவு. \n $ 50 உங்கள் நண்பருக்கு உடனடியாக குறிப்பிடப்பட்ட நண்பர் புரோ பயனராக இருந்தால் அவர்களின் முதல் திட்ட நிறைவு.',
  help: 'உதவி',
  faq: 'அடிக்கடி கேட்கப்படும் கேள்விகள்',
  send_feedback: 'கருத்தினை அனுப்பவும்',
  home_services: 'வீட்டு சேவைகள்',
  status: 'நிலை',
  final_amount: 'இறுதி தொகை',
  note: 'குறிப்பு',
  login: 'உள்நுழைய',
  user: 'பயனர்',
  remember_me: 'என்னை நினைவில் கொள்கிறீர்களா?',
  connect_with_facebook: 'முகநூலுடன் தொடர்பு கொள்ளுங்கள்',
  connect_with_google: 'GOOGLE உடன் இணைக்கவும்',
  pro: 'புரோ',
  map: 'MAP',
  time_availability: 'நேரம் கிடைக்கும்',
  accept: 'ஏற்றுக்கொள்',
  budget: 'பட்ஜெட்',
  click_to_request_on_site_visit: 'தள வருகையை கோர கிளிக் செய்க',
  action: 'செயல்',
  i_am_not_interested: 'எனக்கு விருப்பமில்லை',
  i_am_interested: 'நான் ஆர்வமாக இருக்கிறேன்',
  my_rewards: 'என் வெகுமதிகள்',
  official_cost: 'அதிகாரப்பூர்வ செலவு',
  your_official_price_can_be_set_by_you_when_you_arrive_before_project_starts_from_here_client_can_still_refuse_service_it_is_recommended_to_stay_within_the_range_you_set: 'திட்டம் தொடங்குவதற்கு முன்பு நீங்கள் வரும்போது உங்கள் அதிகாரப்பூர்வ விலையை நீங்கள் நிர்ணயிக்கலாம். இங்கிருந்து கிளையன்ட் இன்னும் சேவையை மறுக்க முடியும். நீங்கள் அமைத்த வரம்பிற்குள் இருக்க பரிந்துரைக்கப்படுகிறது.',
  on_site_visit_requests: 'ஆன்-சைட் வருகை கோரிக்கைகள்',
  pay_with_card: 'அட்டையுடன் செலுத்துங்கள்',
  paypal: 'பேபால்',
  free_transaction: 'இலவச பரிவர்த்தனை',
  no_minimum_transfer: 'குறைந்தபட்ச பரிமாற்றம் இல்லை',
  delete_project: 'திட்டத்தை நீக்கு',
  user_privacy_statement: 'பயனர் தனியுரிமை அறிக்கை',
  credit_card: 'கடன் அட்டை',
  bank_transfer: 'வங்கி பரிமாற்றம்',
  country: 'நாடு',
  usa: 'அமெரிக்கா',
  currency: 'நாணய',
  usd: 'USD',
  add_payout: 'செலுத்துதலைச் சேர்க்கவும்',
  schedules: 'திட்டங்கள்',
  pro_detail: 'புரோ விவரம்',
  payout_detail: 'பணம் செலுத்தும் விவரம்',
  account_info: 'கணக்கு தகவல்',
  instant_payment: 'உடனடி கட்டணம்',
  routing_number: 'வழித்தட எண்',
  auto_renew_your_subscription: 'தானாக உங்கள் சந்தாவை புதுப்பிக்கவும் ',
  profile: 'சுயவிவரம்',
  projects: 'திட்டங்கள்',
  reward: 'வெகுமதி',
  credits_points: 'வரவு புள்ளிகள்',
  enter_Pin: 'பின்னை உள்ளிடவும்',
  view_detail: 'விவரங்களை காண்க',
  cancel_project: 'திட்டத்தை ரத்துசெய்',
  edit_project: 'திருத்தப்படுகின்றன திட்டம்',
  reschedule_project: 'RESCHEDULE PROJECT',
  my_project: 'என் திட்டம்',
  you_have_not_completed_any_project_yet: 'நீங்கள் இதுவரை எந்த திட்டத்தையும் முடிக்கவில்லை',
  speciality_services_pro: 'சிறப்பு சேவைகள் புரோ',
  available_time: 'கிடைக்கும் நேரம்',
  decline: 'தீர்மானிக்கவும்',
  min_budget_normal: 'குறைந்தபட்ச பட்ஜெட்',
  max_budget_normal: 'அதிகபட்ச பட்ஜெட்',
  review_detail: 'விரிவான ஆய்வு',
  use_amount_from_credits: 'வரவுகளிலிருந்து தொகையைப் பயன்படுத்துங்கள்',
  your_availability_time_preference: 'உங்கள் கிடைக்கும் - நேர விருப்பம்',
  specialty_service_partners: 'சிறப்பு சேவை கூட்டாளர்கள்',
  special_pro_detail: 'சிறப்பு புரோ விவரம்',
  email_col: 'மின்னஞ்சல்:',
  mobile_no_col: 'அலைபேசி எண்:',
  service_col: 'சேவை:',
  state_col: 'நிலை:',
  cities_col: 'நகரங்கள்:',
  detail: 'விவரம்',
  request_info: 'தகவல் கோரிக்கை',
  phone: 'தொலைபேசி',
  select_cities_you_offer_your_services: 'உங்கள் சேவைகளை வழங்கும் நகரங்களைத் தேர்ந்தெடுக்கவும்',
  specialty_service: 'சிறப்பு சேவை:',
  connecting_consumers_with: 'உடன் நுகர்வோரை இணைக்கிறது',
  highly_skilled_industry_professionals_in_their_communities: 'அவர்களின் சமூகங்களில் மிகவும் திறமையான தொழில் வல்லுநர்கள்.',
  are_you_looking_to_have_a_project_completed: 'நீங்கள் ஒரு திட்டத்தை முடிக்க விரும்புகிறீர்களா?',
  or: 'அல்லது',
  sign_up_as_an_industry_professional: 'ஒரு தொழில் வல்லுநராக பதிவு செய்யவா?',
  select_all_categories_below: 'கீழே உள்ள அனைத்து வகைகளையும் தேர்ந்தெடுக்கவும்',
  employee_contact_info: 'பணியாளர் தொடர்பு தகவல்',
  name: 'பெயர்',
  company: 'நிறுவனம்',
  min_bid_amount: 'குறைந்தபட்ச ஏல தொகை',
  max_bid_amount: 'அதிகபட்ச ஏல தொகை',
  confirm_project: 'திட்டத்தை உறுதிப்படுத்தவும்',
  send_issue: 'ISSUE ஐ அனுப்பவும்',
  accept_official_cost: 'அதிகாரப்பூர்வ செலவை ஏற்றுக்கொள்',
  subscription: 'சந்தா',
  cancel: 'ரத்துசெய்',
  terms_and_conditions: 'விதிமுறைகள் மற்றும் நிபந்தனைகள்',
  skip: 'ஸ்கிப்',
  signup: 'பதிவுபெறுதல்',
  edit_info: 'தகவலைத் திருத்து',
  this_inform_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'இந்த தகவல் சில நொடிகளில் சேமிக்கப்பட்டு மேலே உள்ள படிவத்தில் வழங்கப்பட்ட பயனர் மின்னஞ்சலுக்கு அனுப்பப்படும்.',
  upload_pictures: 'படங்களை பதிவேற்றுங்கள்',
  upload: 'பதிவேற்று',
  video: 'காணொளி',
  wire: 'வயர்',
  _20_transaction_fee: 'Trans 20 பரிவர்த்தனைக் கட்டணம்.',
  minimum_transaction_250: 'குறைந்தபட்ச பரிவர்த்தனை $ 250',
  account_holder: 'கணக்கு வைத்திருப்பவர்',
  bank_address: 'வங்கி முகவரி',
  withdraw: 'WITHDRAW',
  available_balance: 'வங்கி கணக்கில் மிச்சம் இருக்கும் தொகை',
  withdraw_amount: 'தொகையை திரும்பப் பெறுங்கள்',
  services_offered: 'சேவைகள் வழங்கப்படுகின்றன',
  become_a_pro: 'ஒரு புரோ ஆக',
  add_event: 'நிகழ்வைச் சேர்',
  get_free_quotes: 'இலவச மேற்கோள்களைப் பெறுங்கள்',
  tell_us_about_the_project_you_would_like_completed_and_we_will_connect_you_with_several_industry_professionals_in_your_community_you_can_then_read_through_their_reviews_and_select_the_professional_to_work_on_your_project: 'நீங்கள் முடிக்க விரும்பும் திட்டத்தைப் பற்றி எங்களிடம் கூறுங்கள், உங்கள் சமூகத்தில் உள்ள பல தொழில் வல்லுநர்களுடன் நாங்கள் உங்களை இணைப்போம். நீங்கள் அவர்களின் மதிப்புரைகளைப் படித்து, உங்கள் திட்டத்தில் பணியாற்ற நிபுணரைத் தேர்ந்தெடுக்கலாம்.',
  use_instant_request: 'உடனடி கோரிக்கையைப் பயன்படுத்தவும்',
  in_a_hurry_or_have_an_emergency_project_tell_us_more_about_the_project_and_we_will_quickly_connect_you_to_the_first_available_professional_usually_within_2_hours: 'அவசரமாக அல்லது அவசரகால திட்டம் உள்ளதா? திட்டத்தைப் பற்றி மேலும் எங்களிடம் கூறுங்கள், கிடைக்கக்கூடிய முதல் தொழில்முறை நிபுணருடன் (பொதுவாக 2 மணி நேரத்திற்குள்) விரைவாக உங்களை இணைப்போம்.',
  select_this_option_for_a_directory_list_of_our_specialty_professional_partners_in_your_city_find_your_lawyers_realtors_insurance_agents_doctors_etc: 'உங்கள் நகரத்தில் உள்ள எங்கள் சிறப்பு தொழில்முறை கூட்டாளர்களின் அடைவு பட்டியலுக்கு இந்த விருப்பத்தைத் தேர்ந்தெடுக்கவும். உங்கள் வழக்கறிஞர்கள், ரியல் எஸ்டேட் நிறுவனங்கள், காப்பீட்டு முகவர்கள், மருத்துவர்கள் போன்றவர்களைக் கண்டறியவும்.',
  instant_requests: 'உடனடி கோரிக்கைகள்',
  leads: 'வழிநடத்துகிறது',
  invoices: 'விலைப்பட்டியல்',
  settings: 'அமைப்புகள்',
  downloading: 'பதிவிறக்குகிறது ...',
  select_cities: 'நகரங்களைத் தேர்ந்தெடுக்கவும்',
  are_you_sure_you_want_to_move_back: 'நீங்கள் திரும்பிச் செல்ல விரும்புகிறீர்களா?',
  you_will_lose_your_progress: 'உங்கள் முன்னேற்றத்தை இழப்பீர்கள்.',
  no: 'இல்லை',
  yes: 'ஆம்',
  your_account_is_under_review_please_allow_24_28: 'உங்கள் கணக்கு மதிப்பாய்வு செய்யப்பட்டுள்ளது. உறுதிப்படுத்தல் மின்னஞ்சலைப் பெற 24-28 மணிநேரங்களை அனுமதிக்கவும். எந்த கணக்கு கேள்விகளுடனும் support@ugopros.com ஐ தொடர்பு கொள்ளவும்.',
  payout_detail_confirmation: 'Payout Detail Confirmation',
  please_confirm_all_info_entered_is_correct_we_want_to_make_sure_you_get_paid_for_the_projects_you_complete: 'உள்ளிட்ட அனைத்து தகவல்களும் சரியானவை என்பதை உறுதிப்படுத்தவும். நீங்கள் முடித்த திட்டங்களுக்கு நீங்கள் பணம் பெறுகிறீர்கள் என்பதை உறுதிப்படுத்த விரும்புகிறோம்.',
  rate_user: 'Rate User',
  _20_instantly_for_you_and_your_friend_on_first_project_completion_if_the_referred_friend_is_home_user_n_50_instantly_for_you_on_first_project_completion_if_the_referred_friend_is_pro_user: 'Friend 20 குறிப்பிடப்பட்ட நண்பர் வீட்டு பயனராக இருந்தால், உங்களுக்கும் உங்கள் நண்பருக்கும் முதல் திட்டப்பணி முடிந்தவுடன் உடனடியாக. friend n $ 50 குறிப்பிடப்பட்ட நண்பர் புரோ பயனராக இருந்தால் முதல் திட்டம் முடிந்தவுடன் உடனடியாக உங்களுக்காக.',
  add_credit_for_the_bigger_jobs_pay_now_for_jobs_later: 'பெரிய வேலைகளுக்கு கடன் சேர்க்கவும், பின்னர் வேலைகளுக்கு இப்போது பணம் செலுத்துங்கள்.',
  add_credits: 'கிரெடிட்களைச் சேர்க்கவும்',
  no_default_card: 'இயல்புநிலை அட்டை இல்லை',
  add_payment_method: 'கொள்கை முறையை சேர்க்க',
  payment_method: 'கொள்கை முறை',
  payment_history: 'கொள்கை வரலாறு',
  add_credit_card: 'கடன் அட்டையைச் சேர்க்கவும்',
  add_credit_gift_code: 'கடன் / பரிசுக் குறியீட்டைச் சேர்க்கவும்',
  apply: 'விண்ணப்பிக்கவும்',
  payment_defaults: 'கட்டண இயல்புநிலைகள்',
  set_payment_default: 'இயல்புநிகழ் கொள்கை அமை',
  default_payment_method: 'இயல்புநிகழ் கொள்கை குழு',
  amount: 'தொகை',
  view: 'காண்க',
  revenue_detail: 'வருவாய் விவரம்',
  total_revenue: 'மொத்த வருவாய்',
  total_withdraw: 'மொத்த WITHDRAW',
  payable_balance: 'செலுத்த வேண்டிய இருப்பு',
  pending_withdraw: 'திரும்பப் பெறுவது நிலுவையில் உள்ளது',
  new_: 'புதியது',
  active: 'செயலில்',
  completed: 'நிறைவு',
  notification: 'அறிவிப்பு',
  message: 'செய்தி',
  write_your_message: 'உங்கள் செய்தியை எழுதுங்கள்',
  sign_out: 'வெளியேறு',
  my_projects: 'எனது திட்டங்கள்',
  site_visit_request: 'தள வருகை கோரிக்கை',
  archived: 'முழுமை',
  canceled: 'ரத்துசெய்',
  consumer_benefits: 'நுகர்வோர் நன்மைகள்',
  normal: 'நார்மல்',
  specialty: 'சிறப்பு',
  invoiced: 'விலை விவரம்',
  paid: 'PAID',
  enter_your_issue_detail: 'உங்கள் பிரச்சினை விவரத்தை உள்ளிடவும்',
  add_new_event: 'புதிய நிகழ்வைச் சேர்க்கவும்',
  tap_on_date_to_view_schedule: 'அட்டவணையைப் பார்க்க தேதியில் தட்டவும்.',
  my_earnings: 'எனது வருவாய்',
  withdraw_history: 'வரலாற்றைத் திரும்பப் பெறுங்கள்',
  waiting_for_response: 'பதிலுக்காக காத்திருக்கிறது',
  confirm: 'உறுதிப்படுத்தவும்',
  would_you_like_to_create_a: 'நீங்கள் உருவாக்க விரும்புகிறீர்களா?',
  Pin: 'பின்?',
  enter_pin: 'பின்னை உள்ளிடவும்',
  enter_password: 'கடவுச்சொல்லை உள்ளிடவும்',
  use_different_account: 'மாறுபட்ட கணக்கைப் பயன்படுத்துங்கள்',
  u_go_pros_inc: 'U\'GO Pros Inc',

  pay: 'செலுத்தவும்',
  next_payment_schedule: 'அடுத்த கட்டண அட்டவணை',
  credit: 'கிரெடிட்',
  debit: 'பற்று',
  continue_: 'தொடருங்கள்',
  schedule: 'அட்டவணை',
  payment: 'கட்டணம்',
  change_pin: 'முள் மாற்றவும்',
  notifications: 'அறிவிப்புகள்',
  pin: 'முள்',
  online_status: 'ஆன்லைன் நிலை',
  email_message: 'மின்னஞ்சல் செய்தி',
  add_edit: 'சேர் / திருத்து',
  selected_categories: 'தேர்ந்தெடுக்கப்பட்ட வகைகள்',
  dismiss: 'நிராகரித்தல்',
  add_more: 'மேலும் சேர்க்கவும்',
  project: 'திட்டம்',
  earning: 'சம்பாதித்தல்',
  withdraw_status: 'நிலைகளுடன்',
  project_leads: 'திட்ட லீட்ஸ்',
  offline_mode: 'ஆஃப்லைன் பயன்முறை',
  ugo_to: '$ வரை',
  pay_dol: 'செலுத்துங்கள் $',
  are_you_sure_you_want_to_credit: 'நீங்கள் நிச்சயமாக credit கடன் பெற விரும்புகிறீர்களா?',
  are_you_sure_you_want_to_delete: 'இந்த திட்டத்தை பட்டியலிலிருந்து நீக்க விரும்புகிறீர்களா?',
  logout: 'வெளியேறு',
  complete: 'முழுமை',
  please_complete_your_profile: 'உங்கள் சுயவிவரத்தை முடிக்கவும்.',
  ready_for_quotes: 'மேற்கோள்களுக்கு தயார்',
  estimates_received: 'பெறப்பட்ட மதிப்பீடுகள்',
  pending_for_official_cost: 'அதிகாரப்பூர்வ செலவுக்கு நிலுவையில் உள்ளது',
  project_started: 'திட்டம் தொடங்கியது ',
  please_confirm_project: 'திட்டத்தை உறுதிப்படுத்தவும்',
  successfully_completed: 'வெற்றிகரமாக முடிக்கப்பட்டது',
  before_payment: 'கட்டணம் செலுத்துவதற்கு முன்',
  after_payment: 'கட்டணம் செலுத்திய பிறகு',
  issue_reported: 'வெளியீடு அறிக்கை',
  refunded_by_admin: 'நிர்வாகத்தால் திருப்பித் தரப்பட்டது',
  official_cost_recieved: 'உத்தியோகபூர்வ செலவு பெறப்பட்டது',
  waiting_for_pro_to_start: 'புரோ தொடங்குவதற்கு காத்திருக்கிறது',
  please_wait: 'தயவுசெய்து காத்திருங்கள் ...',
  waiting_for_pros_response: 'நன்மை பதிலுக்காக காத்திருக்கிறது',
  started_by_pro: 'புரோ மூலம் தொடங்கப்பட்டது',
  need_for_rechedule: 'மறுசீரமைப்பு தேவை',
  project_canceled: 'திட்டம் ரத்து செய்யப்பட்டது',
  create_your_pin: 'உங்கள் முள் உருவாக்கவும்',
  please_select_what_you_want_to_do: 'நீங்கள் என்ன செய்ய விரும்புகிறீர்கள் என்பதைத் தேர்ந்தெடுக்கவும்',
  remove_change_pin: 'பின்னை அகற்ற/மாற்றவா?',
  remove_pin: 'முள் அகற்று',
  successfully_scheduled: 'வெற்றிகரமாக திட்டமிடப்பட்டுள்ளது',
  project_accepted: 'திட்டம் ஏற்றுக்கொள்ளப்பட்டது',
  accepted_by_pro: 'புரோவால் ஏற்றுக்கொள்ளப்பட்டது',
  image_attched: 'படம் இணைக்கப்பட்டுள்ளது',
  video_attached: 'வீடியோ இணைக்கப்பட்டுள்ளது',
  valid_till: 'செல்லுபடியாகும் வரை: ',
  rejected: 'நிராகரிக்கப்பட்டது',
  confirmed: 'உறுதி',
  are_you_sure_you_want_to_reject: 'ஆன்-சைட் வருகை கோரிக்கையை நிராகரிக்க விரும்புகிறீர்களா?',
  are_you_sure_you_want_confirmed: 'ஆன்-சைட் வருகை கோரிக்கையை உறுதிப்படுத்த விரும்புகிறீர்களா?',
  attached: 'Attached',
  cannot_upload_file_to_server: 'சேவையகத்தில் கோப்பை பதிவேற்ற முடியாது',
  update_review: 'புதுப்பிப்பைப் புதுப்பிக்கவும்',
  response_rate_per: '% மறுமொழி விகிதம்',
  add_debit_card: 'டெபிட் கார்டைச் சேர்க்கவும்',
  add_payout_method: 'பணம் செலுத்தும் முறையைச் சேர்க்கவும்',
  update_video: 'வீடியோவைப் புதுப்பிக்கவும்',
  enter_friend_email_user: 'உங்கள் நண்பரை பயனர் எனக் குறிப்பிட கீழே நண்பர் மின்னஞ்சலை உள்ளிடவும்.',
  enter_friend_email_pro: 'உங்கள் நண்பரை புரோ எனக் குறிப்பிட கீழே நண்பர் மின்னஞ்சலை உள்ளிடவும்.',
  credit_dol: 'வரவு: $',
  free: 'இலவசம்',
  are_you_sure_you_want_delete: 'திட்டத்தை நீக்க விரும்புகிறீர்களா?',
  are_you_sure_too_cancel: 'இந்த திட்டத்தை ரத்து செய்ய விரும்புகிறீர்களா?',
  rating: 'மதிப்பீடு',
  total_credits: 'மொத்த வரவு:',
  cities: 'நகரங்கள்',
  the_amount_you_have_submitted: 'நீங்கள் சமர்ப்பித்த தொகை($',
  if_you_agree_to_the_official: ') உத்தியோகபூர்வ செலவை விட குறைவாக உள்ளது. உத்தியோகபூர்வ செலவுக்கு நீங்கள் ஒப்புக்கொண்டால், தயவுசெய்து ஊதிய விருப்பத்தை அழுத்தவும், இல்லையெனில் நிராகரிக்கவும் அல்லது ஏதேனும் சிக்கல்கள் இருந்தால் எங்களை தொடர்பு கொள்ளவும்.',
  refund_dol: 'திரும்பப் பெறுங்கள் $',
  the_amount_you_have: 'நீங்கள் சமர்ப்பித்த தொகை $',
  otherwise_rejected_or_contact_us: 'உத்தியோகபூர்வ செலவை விட அதிகமாகும். நீங்கள் ஒப்புக்கொண்டால், REFUND விருப்பத்தை அழுத்தவும், இல்லையெனில் நிராகரிக்கவும் அல்லது உங்களுக்கு ஏதேனும் கேள்விகள் இருந்தால் எங்களை தொடர்பு கொள்ளவும்.',
  if_there_are_any_problems: 'நீங்கள் சமர்ப்பித்த தொகை திட்டத்தின் அதிகாரப்பூர்வ செலவுக்கு சமம். எல்லாம் சரியாக இருந்தால் தயவுசெய்து கீழே உறுதிப்படுத்தவும், இல்லையெனில் ஏதேனும் சிக்கல்கள் இருந்தால் எங்களை தொடர்பு கொள்ளவும்.',
  are_you_sure_you_want_cancel: 'திட்டத்தை ரத்து செய்ய விரும்புகிறீர்களா?',
  if_you_submit_you_will_refund: 'நீங்கள் சமர்ப்பித்தால், உங்கள் பணத்தைத் திரும்பப் பெற 5% செயலாக்க கட்டணம் வசூலிக்கப்படும்.',
  are_you_sure_you_want_to_confirm: 'திட்டத்தை உறுதிப்படுத்த விரும்புகிறீர்களா?',
  pro_will_be_paid_for_this: 'இதற்காக புரோ செலுத்தப்படும்.',
  pending: 'நிலுவையில் உள்ளது',
  accepted: 'ஏற்றுக்கொள்ளப்பட்டது',
  referral_code: 'பரிந்துரை குறியீடு',
  expiry_col: 'காலாவதி:',
  next_payment_schedule_col: '"Next Payment Schedule: "',
  status_active: 'நிலை: செயலில்',
  status_cancelled: 'நிலை: ரத்து செய்யப்பட்டது',
  update_payout: 'செலுத்துதலைப் புதுப்பிக்கவும்',
  please_add_it_by_pressing: 'நீங்கள் இன்னும் பணம் செலுத்தும் முறையைச் சேர்க்கவில்லை. கீழே உள்ள AD பொத்தானை அழுத்துவதன் மூலம் அதைச் சேர்க்கவும்.',
  onsite_visit_request_sent: 'ஆன்-சைட் வருகை கோரிக்கை அனுப்பப்பட்டது',
  onsite_visit_request_rejected: 'ஆன்-சைட் வருகை கோரிக்கை நிராகரிக்கப்பட்டது',
  onsite_visit_scheduled: 'ஆன்-சைட் வருகை திட்டமிடப்பட்டுள்ளது',
  waiting_for_acceptance: 'ஏற்றுக்கொள்ள காத்திருக்கிறது',
  qoute_and_onsite_visit: 'மேற்கோள் மற்றும் ஆன்-சைட் வருகை கோரிக்கை அனுப்பப்பட்டது',
  qoute_sent: 'மேற்கோள் அனுப்பப்பட்டது',
  waiting_for_official_cost: 'அதிகாரப்பூர்வ செலவுக்காக காத்திருக்கிறது',
  assigned_to_employee: 'பணியாளருக்கு ஒதுக்கப்பட்டுள்ளது',
  rejected_by_employee: 'ஊழியரால் நிராகரிக்கப்பட்டது',
  job_started: 'வேலை தொடங்கியது',
  waiting_for_confirmation: 'உறுதிப்படுத்தலுக்காக காத்திருக்கிறது',
  official_cost_sent: 'அதிகாரப்பூர்வ செலவு அனுப்பப்பட்டது',
  project_awarded: 'திட்டம் வழங்கப்பட்டது',
  in_progress: 'முன்னேற்றத்தில் உள்ளது',
  closed: 'மூடப்பட்டது',
  bid_amount: 'ஏல தொகை : $',
  exit: 'வெளியேறு',
  enter_old_pin: 'பழைய பின்னை உள்ளிடவும்',
  confirm_pin: 'உறுதி முள்',
  back: 'மீண்டும்',
  wrong_pin_keyboard: 'தவறான முள் விசைப்பலகை',
  enter_new_pin: 'புதிய பின்னை உள்ளிடவும்',
  wrong_pin_locked: 'தவறான முள் பூட்டப்பட்டது',
  user_guide: 'பயனர் வழிகாட்டி',
  job_no: 'வேலை #',
  project_col: 'திட்டம்:',
  posted_by_col: '"Posted by : "',
  online_mode: 'ஆன்லைன் முறை',
  are_you_sure_accept: 'நீங்கள் நிச்சயமாக ஏற்றுக்கொள்ள விரும்புகிறீர்களா?',
  are_you_sure_want_reject: 'நீங்கள் நிச்சயமாக நிராகரிக்க விரும்புகிறீர்களா?',
  assign_to_col: '" Assign To:  "',
  status_assigned: 'நிலை :ஒதுக்கப்படும்',
  status_rejected: 'நிலை: நிராகரிக்கப்பட்டது',
  status_waiting: 'நிலை: உறுதிப்படுத்தலுக்காக காத்திருக்கிறது',
  final_amount_col: 'இறுதி தொகை: $',
  issue_detail: 'வெளியீடு விவரம்',
  official_cost_dol: 'அதிகாரப்பூர்வ செலவு: $',
  project_title_col: 'திட்டத்தின் தலைப்பு: ',
  photos_attached: 'புகைப்படங்கள் இணைக்கப்பட்டன',
  video_attached: 'காணொளியை இணைக்கப்பட்டது',
  document_attached: 'ஆவணம் இணைக்கப்பட்டது',
  please_confirm_final_amount: 'இறுதி தொகையை உறுதிப்படுத்தவும்',
  once_you_tap_confirm_you_will: '. “உறுதிப்படுத்து” என்பதைத் தட்டினால், இறுதி செலவை மாற்ற முடியாது',
  employee_note: 'பணியாளர் குறிப்பு',
  employee_upload_pics: 'பணியாளர் பதிவேற்றிய படங்கள்',
  official_document: 'அதிகாரப்பூர்வ ஆவணம்',
  are_you_sure_you_want_to_delete_emp: 'இந்த ஊழியரை நீக்க விரும்புகிறீர்களா?',
  employee_since: '"Employee since "',
  are_you_sure_to_assign: 'திட்டத்தை ஒதுக்க விரும்புகிறீர்களா?',
  i_am_intrested: 'ஆன் சைட் விசிட்டிற்கு $ 10 செலுத்த விரும்புகிறீர்களா? வாடிக்கையாளர் உங்கள் முயற்சியை ஏற்றுக்கொண்டால் மட்டுமே அந்தக் கட்டணம் திருப்பித் தரப்படும். visit n site n தளத்தின் வருகை இல்லாமல் இலவசமாக மதிப்பீட்டை அனுப்ப விரும்பினால், ரத்து என்பதைத் தட்டவும், திரையின் அடிப்பகுதியில் “நான் ஆர்வமாக உள்ளேன்” என்பதைத் தேர்ந்தெடுக்கவும்.',
  pay_again: 'மீண்டும் செலுத்துங்கள்',
  please_recharge_your_account: 'உங்கள் கணக்கில் போதுமான இருப்பு இல்லாததால் உங்கள் ஆன்-சைட் வருகை கோரிக்கை நிராகரிக்கப்பட்டது. உங்கள் கணக்கை ரீசார்ஜ் செய்து மீண்டும் முயற்சிக்கவும்.',
  your_on_site_visit: 'உங்கள் ஆன்-சைட் வருகை கோரிக்கை நிராகரிக்கப்பட்டது, உங்களிடம் $ 10 வசூலிக்கப்படாது.',
  budget_dol: 'பட்ஜெட் : $',
  please_confirm_if_you_will: 'INSTANT REQUESTS Users are looking to have project started within 2 hours.  Please “Confirm” if you will be available',
  debit_card: 'டெபிட் கார்டு',
  resubscribe: 'மீண்டும் குழுசேர்',
  project_no: '"Project# "',
  i_am_ready: 'நான் ஒரு புரோவுடன் இணைக்கத் தயாராக உள்ளேன்',
  edit: 'தொகு',
  language: 'மொழி',
  english: 'English',
  spanish: 'Spanish',
  tamil: 'Tamil',
  hindi: 'Hindi',
  arabic: 'Arabic',
  contractor_notes_n_if_project: 'ஒப்பந்தக்காரர் குறிப்புகள் \n (திட்டம் முடிக்கப்படாவிட்டால், நீங்கள் முடிந்ததும் வெளியேறவும், பின்னர் மீண்டும் வேலையைத் தொடங்கவும்)',
  first_name: 'முதல் பெயர்',
  last_name: 'கடைசி பெயர்',
  password: 'கடவுச்சொல்',
  confirm_password: 'கடவுச்சொல்லை உறுதிப்படுத்தவும்',
  confirm_password_optional: 'கடவுச்சொல் உறுதிப்படுத்தவும் (விரும்பியது)',
  verification_code: 'சரிபார்ப்பு குறியீடு',
  verify_pin: 'பின் சரிபார்க்கவும்',
  new_password: 'புதிய கடவுச்சொல்',
  your_name: 'உங்கள் பெயர்',
  contact_person_first_name: 'நபரின் முதல் பெயரைத் தொடர்பு கொள்ளுங்கள்',
  contact_person_last_name: 'நபரின் கடைசி பெயரைத் தொடர்பு கொள்ளுங்கள்',
  company_name: 'நிறுவனத்தின் பெயர்',
  company_ein_tax_id: 'நிறுவனம் EIN / வரி ஐடி',
  licence_no_optional: 'உரிமம் இல்லை (விரும்பினால்)',
  tap_to_create_project: 'திட்டத்தை உருவாக்க தட்டவும்',
  home_auto_or_exclusive_services: 'ஹோம், ஆட்டோ அல்லது எக்ஸ்க்ளூசிவ் சர்வீசஸ்',
  friend_pro_referral: 'நண்பர் / புரோ பரிந்துரை',
  enter_title: 'தலைப்பை உள்ளிடவும்',
  account_iban: 'Account/ IBAN',
  swift_bic_code: 'Swift/ Bic code',
  new_password_optional: 'புதிய கடவுச்சொல் (விரும்பினால்)',
  reach_out_directly_with_this: 'இங்கே அரட்டை அடிப்பதன் மூலம் இந்த சார்புடன் நேரடியாக அணுகவும். இந்த பெட்டியில் தட்டச்சு செய்யத் தொடங்கி, முடிந்ததும் உறுதிப்படுத்தவும்',
  company_phone_number: 'நிறுவனத்தின் தொலைபேசி எண்',
  paypal_account: 'Paypal Account',
  card_number: 'அட்டை எண்',
  enter_project_description: 'திட்ட விளக்கத்தை உள்ளிடவும்',
  please_enter_project_description: 'தயவுசெய்து பயிற்சி விவரம் உள்ளிடவும்.',
  please_provide_information: 'முந்தைய திட்டங்கள் மற்றும் உங்கள் நிறுவனம் பற்றிய தகவல்களை வழங்கவும். உங்களிடம் ஏதேனும் கேள்விகள் இருந்தால் சேர்க்கவும்.',
  hourly_estimate_optional: 'மணிநேர மதிப்பீடு $ (விரும்பினால்)',
  total_min_estimate_best_case_scenario: 'மொத்த குறைந்தபட்ச மதிப்பீடு $ (சிறந்த வழக்கு காட்சி)',
  total_max_estimate_worst_case_scenario: 'மொத்த அதிகபட்ச மதிப்பீடு $ (மோசமான வழக்கு காட்சி)',
  please_name_your_project: 'உங்கள் திட்டத்திற்கு பெயரிடுக',
  social_security_number: 'சமூக பாதுகாப்பு எண்',
  years_in_business: 'வணிகத்தில் ஆண்டுகள்',
  old_password_optional: 'பழைய கடவுச்சொல் (விரும்பினால்)',
  other_category_optional: 'பிற வகை (விரும்பினால்)',
  wrong_email_password: 'தவறான மின்னஞ்சல் / கடவுச்சொல்',
  please_enter_your_email: 'உங்கள் மின்னஞ்சலை உள்ளிடவும்',
  please_enter_your_name: 'உங்களுடைய பெயரை பதிவு செய்யவும்',
  please_enter_your_project_name: 'உங்கள் திட்ட பெயரை உள்ளிடவும்',
  please_enter_discription: 'திட்ட விளக்கத்தை உள்ளிடவும்',
  please_enter_your_min: 'உங்கள் குறைந்தபட்ச பட்ஜெட்டை உள்ளிடவும் $',
  please_enter_your_max_budget: 'உங்கள் அதிகபட்ச பட்ஜெட்டை உள்ளிடவும் $',
  please_enter_your_valid_min: 'உங்கள் செலவு குறைந்த பட்ஜெட் $ ஐ உள்ளிடவும்',
  please_enter_your_max_valid_budget: 'உங்கள் செலவின் அதிகபடியான பட்ஜெட் $ ஐ உள்ளிடவும்',
  min_budget_should_less_than_max: 'குறைந்தபட்ச பட்ஜெட் அதிகபட்ச பட்ஜெட்டை விட குறைவாக இருக்க வேண்டும்',
  please_enter_your_budget: 'உங்கள் பட்ஜெட்டை உள்ளிடவும் $',
  please_enter_your_budget_at_least_1: 'தயவுசெய்து உங்கள் பட்ஜெட்டை உள்ளிடவும் $1',
  please_select_date_before_time: 'நிர்ணயிக்கப்பட்ட நேரத்திற்கு முன் தேதியைத் தேர்ந்தெடுக்கவும்',
  no_user_found: 'பயனர் இல்லை',
  please_enter_your_message: 'உங்கள் செய்தியை உள்ளிடவும்',
  please_enter_valid_email: 'செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும்',
  please_enter_your_card_no: 'உங்கள் அட்டை எண்ணை உள்ளிடவும்',
  please_enter_cvc: 'உங்கள் சி.வி.சியை உள்ளிடவும்',
  please_select_future_date: 'எதிர்கால தேதியைத் தேர்ந்தெடுக்கவும்',
  pics_not_available: 'படம் கிடைக்கவில்லை!',
  document_not_available: 'ஆவணம் கிடைக்கவில்லை!',
  video_not_available: 'வீடியோ கிடைக்கவில்லை!',
  please_enter_official_cost: 'அதிகாரப்பூர்வ செலவைச் சேர்க்கவும்.',
  please_add_official_cost: 'குறைந்தபட்சம் உத்தியோகபூர்வ செலவைச் சேர்க்கவும்',
  please_enter_your_password: 'தயவு செய்து கடவுச்சொல்லை பதிவு செய்க',
  password_dose_not_matched: 'கடவுச்சொல் பொருந்தவில்லை!',
  please_enter_your_first_name: 'உங்கள் முதல் பெயரை உள்ளிடவும்',
  please_enter_your_last_name: 'உங்கள் கடைசி பெயரை உள்ளிடவும்',
  please_enter_your_phone: 'உங்கள் தொலைபேசியை உள்ளிடவும்',
  please_enter_password: 'தயவு செய்து கடவுச்சொல்லை பதிவு செய்க',
  password_must_contain_a_minimum: 'கடவுச்சொல்லில் குறைந்தபட்சம் 8 எழுத்துக்கள் இருக்க வேண்டும்',
  pin_successfully_change: 'முள் வெற்றிகரமாக மாறுகிறது',
  please_enter_your_confirm_password: 'உங்கள் கடவுச்சொல்லை உறுதிப்படுத்தவும்',
  please_enter_your_address: 'உங்கள் முகவரியை உள்ளிடவும்',
  phone_length_10: 'தொலைபேசி # நீளம் 10 ஆக இருக்க வேண்டும்',
  please_enter_contact_first_name: 'தொடர்பு நபரின் முதல் பெயரை உள்ளிடவும்',
  please_enter_contact_lastname: 'தொடர்பு நபரின் கடைசி பெயரை உள்ளிடவும்',
  password_dose_not_match: 'கடவுச்சொல் பொருந்தவில்லை என்பதை உறுதிப்படுத்தவும்',
  please_enter_dob: 'பிறந்த தேதியை உள்ளிடவும்.',
  please_enter_company_name: 'நிறுவனத்தின் பெயரை உள்ளிடவும்',
  please_enter_mobile_number: 'மொபைல் எண் உள்ளிடவும்',
  please_enter_address: 'முகவரியை உள்ளிடவும்',
  please_enter_contractor_notes: 'ஒப்பந்தக்காரர் குறிப்புகளை உள்ளிடவும்',
  please_enter_at_least_one_work_photo: 'தயவுசெய்து ஒரு வேலை புகைப்படத்தையாவது பதிவேற்றவும்',
  you_must_be_18: 'எங்கள் சேவைகளைப் பயன்படுத்த நீங்கள் 18 வயது அல்லது அதற்கு மேற்பட்டவராக இருக்க வேண்டும். உங்களுக்கு ஏதேனும் சிக்கல்கள் இருந்தால் எங்களை தொடர்பு கொள்ளவும் support@ugopros.com',
  please_agree_to_our: 'எங்கள் விதிமுறைகள் மற்றும் நிபந்தனைகளுக்கு உடன்படுங்கள்',
  please_select_your_available: 'சிக்கல் விவரத்தை உள்ளிடவும்',
  please_enter_issue_detail: 'சிக்கல் விவரத்தை உள்ளிடவும்',
  please_add_profile_photo: 'சுயவிவர புகைப்படத்தைச் சேர்க்கவும்',
  please_set_your_loaction: 'உங்கள் இருப்பிடத்தை அமைக்கவும்',
  please_select_address: 'முகவரியைத் தேர்ந்தெடுக்கவும்',
  please_select_address_from: 'கீழ்தோன்றும் பட்டியலில் இருந்து முகவரியைத் தேர்ந்தெடுக்கவும்',
  please_add_availability_date: 'கிடைக்கும் தேதியைச் சேர்க்கவும்',
  please_add_available_time: 'கிடைக்கும் நேரத்தைச் சேர்க்கவும்',
  enter_address_or_single_press_on_map: 'வரைபடத்தில் முகவரி அல்லது ஒற்றை அழுத்தத்தை உள்ளிடவும்',
  type_to_search: 'தேட தட்டச்சு செய்க',
  _3rd_option_date: '3 வது விருப்ப தேதி',
  you_cannot_select_past_date: 'கடந்த தேதியை நீங்கள் தேர்ந்தெடுக்க முடியாது',
  thank_you_for_uploading_document: 'ஆவணத்தைப் பதிவேற்றியதற்கு நன்றி. மதிப்பாய்வு கணக்கு செயல்படுத்தப்படுவதற்கு காத்திருக்கவும்.',
  reason: 'காரணம்',
  enter_amount: 'தொகையை உள்ளிடவும்',
  please_enter_amount: 'தொகையை உள்ளிடவும்!',
  home: 'வீடு',
  track_earning: 'ட்ராக் சம்பாதித்தல்',
  manage_employees: 'பணியாளர்களை நிர்வகிக்கவும்',
  payout_method: 'செலுத்தும் முறை',
  payments: 'கொடுப்பனவுகள்',
  offers: 'சலுகைகள்',
  special_requests: 'சிறப்பு கோரிக்கைகள்',
  first_project_completion: '$20 குறிப்பிடப்பட்ட நண்பர் வீட்டு பயனராக இருந்தால் முதல் திட்ட நிறைவு. \n$50 குறிப்பிடப்பட்ட நண்பர் புரோ பயனர் என்றால் முதல் திட்ட முடித்தவுடன் உடனடியாக உங்களுக்காக.',
  confirmation: 'உறுதிப்படுத்தல்',
  refer_a_pro: 'ஒரு புரோவைப் பார்க்கவும்',
  refer_your_friend: 'உங்கள் நண்பரைப் பார்க்கவும்',
  loading_payment_method: 'கட்டண முறை ஏற்றுகிறது ...',
  enabling_instant_payment: 'உடனடி கட்டண முறைகளை இயக்குகிறது ...',
  loading_data: 'தரவை ஏற்றுகிறது...',
  loading_payment_detail: 'பணம் செலுத்தும் விவரங்களை ஏற்றுகிறது ...',
  it_will_take_some_time: 'இதற்கு சிறிது நேரம் பிடிக்கும்....',
  sending: 'அனுப்புகிறது...',
  updating: 'Updating...',
  setting_default_payment_method: 'இயல்புநிலை கட்டணம் செலுத்தும் முறையை அமைத்தல் ...',
  compressing_the_size: 'வீடியோவின் அளவை அமுக்கி.',
  uploading_data: 'தரவைப் பதிவேற்றுகிறது',
  your_account_suspended: 'உங்கள் நிறுவனத்தின் காப்பீடு காலாவதியானதால் உங்கள் கணக்கு இடைநிறுத்தப்பட்டுள்ளது. புதிய காப்பீட்டு தகவல்களை பதிவேற்றவும்.',
  profile_incomplete: 'சுயவிவரம் முழுமையடையாது',
  please_complete_profile: 'உங்கள் சுயவிவரத்தை முடிக்கவும்',
  please_enter_your_date_of_birth: 'உங்கள் கணக்கை செயல்படுத்த உங்கள் பிறந்த தேதியை உள்ளிடவும்.',
  please_enter_account_holder_name: 'உங்கள் கணக்கு வைத்திருப்பவரின் பெயரை உள்ளிடவும்.',
  please_enter_your_routing_number: 'உங்கள் ரூட்டிங் எண்ணை உள்ளிடவும்',
  please_enter_your_account_number: 'உங்கள் கணக்கு எண்ணை உள்ளிடவும்',
  sesson_has_been_expire: 'உங்கள் அமர்வு முடிந்தது, மீண்டும் உள்நுழைக',
  internet_connection_issue: 'இணைய இணைப்பு சிக்கல். பின்னர் மீண்டும் முயற்சிக்கவும்',
  please_check_your_email_for_our: 'எங்கள் கருத்துக்கு உங்கள் மின்னஞ்சலை சரிபார்க்கவும். உங்கள் கருத்தை நாங்கள் மதிக்கிறோம். 24 மணி நேரத்திற்குள் நீங்கள் எங்களிடமிருந்து கேட்க வேண்டும்।',
  account_has_been_updated: 'கணக்கு வெற்றிகரமாக புதுப்பிக்கப்பட்டது. மதிப்பாய்வுக்காக கணக்கு செயல்படுத்தப்படும் வரை காத்திருக்கவும்।',
  project_has_been_completed: 'திட்டம் முடிந்தது பயனரிடமிருந்து உறுதிப்படுத்த காத்திருக்கவும்ं.',
  you_have_not_added_a_payout: 'கட்டணம் செலுத்தும் முறையை நீங்கள் இன்னும் சேர்க்கவில்லை. முதலில் இதைச் சேர்க்கவும்।',
  information: 'தகவல்!',
  if_profile_needs_updated: 'சுயவிவர புதுப்பிப்பு தேவைப்பட்டால், தயவுசெய்து அவ்வாறு செய்யுங்கள்.',
  please_add_your_credit: 'முதலில் உங்கள் கிரெடிட் கார்டைச் சேர்க்கவும்.',
  for_sercurity_and_payout: 'பாதுகாப்பு மற்றும் கட்டண வசதிகளுக்கு கூடுதல் தகவல்கள் தேவை.',
  update_app: 'பயன்பாட்டைப் புதுப்பிக்கவும்',
  not_now: 'இப்போது இல்லை',
  your_official_price_can_be: 'திட்டம் தொடங்குவதற்கு முன்பு நீங்கள் வரும்போது உங்கள் அதிகாரப்பூர்வ விலையை நீங்கள் நிர்ணயிக்கலாம். இங்கிருந்து கிளையன்ட் இன்னும் சேவையை மறுக்க முடியும். நீங்கள் அமைத்த வரம்பிற்குள் இருக்க பரிந்துரைக்கப்படுகிறது.',
  thank_you_for_your_payment: 'உங்கள் கட்டணத்திற்கு நன்றி. இப்போது நீங்கள் தள வருகைக்கு செல்லலாம்.',
  request_sent_successfully: 'கோரிக்கை வெற்றிகரமாக அனுப்பப்பட்டது. உங்கள் ஆன்-சைட் வருகை கோரிக்கையை வாடிக்கையாளர் ஏற்றுக்கொள்ளும்போது உங்களுக்கு அறிவிக்கப்படும்.',
  credit_card_is_valid: 'கடன் அட்டை தவறானது',
  are_your_sure_you_want_to_delete: 'இந்த அட்டையை நீக்க விரும்புகிறீர்களா?',
  would_you_like_to_auto: 'உங்கள் சந்தாவை தானாக புதுப்பிக்க விரும்புகிறீர்களா?',
  please_select_your_subscription: 'உங்கள் சந்தாவைத் தேர்ந்தெடுக்கவும்.',
  alert: 'எச்சரிக்கை!',
  we_hate_to: 'நீங்கள் ஏதேனும் சிக்கல்களைக் கொண்டிருந்தால் 877-733-2425 என்ற எண்ணில் எங்களை தொடர்பு கொள்ளலாம் அல்லது info@ugopros.com உங்கள் சந்தாவை ரத்துசெய்வது உங்கள் பில்லிங் சுழற்சியின் முடிவில் உங்கள் கணக்கை நிறுத்திவிடும்.',
  yes_cancel: 'ஆம் ரத்துசெய்',
  no_i_will_stay: 'இல்லை, நான் தங்குவேன்',
  you_have_not_added: 'உங்கள் டெபிட் கார்டை இன்னும் சேர்க்கவில்லை.',
  please_select_at_least: 'தயவுசெய்து ஒரு துணை வகையாவது தேர்ந்தெடுக்கவும்.',
  you_have_selected_to: 'தானியங்கு கட்டணத்தை இயக்க நீங்கள் தேர்ந்தெடுத்துள்ளீர்கள். நீங்கள் தொடர விரும்பினால் உறுதிப்படுத்தவும்?',
  turn_off: 'முடக்கு',
  are_you_sure_you_want_to_delete_this: 'இந்த செலுத்தும் முறையை நீக்க விரும்புகிறீர்களா??',
  if_project_has_not: '14 நாட்களுக்குள் திட்டம் உறுதிப்படுத்தப்படாவிட்டால், உங்கள் மதிப்பிடப்பட்ட மொத்தம் உங்கள் கணக்கில் வெளியிடப்படும்.',
  video_lenght_should_30: 'வீடியோ நீளம் அதிகபட்சம் 30 நொடியாக இருக்க வேண்டும்.',
  you_can_not_uodalog_5: 'நீங்கள் 5 க்கும் மேற்பட்ட புகைப்படங்களை பதிவேற்ற முடியாது.',
  please_enter_date_of_birth: 'உங்கள் கணக்கை செயல்படுத்துவதற்கு பிறந்த தேதியை உள்ளிடவும்.',
  congratulation_your_account: 'வாழ்த்துக்கள்! உங்கள் கணக்கு இப்போது செயல்படுத்தப்பட்டுள்ளது..',
  not_availeable: 'கிடைக்கவில்லை. மேலும் விவரங்களுக்கு எங்களை தொடர்பு கொள்ளவும்.',
  please_enter_account_iban: 'கணக்கு / IBAN ஐ உள்ளிடவும்',
  please_enter_swift: 'ஸ்விஃப்ட் / பிக் குறியீட்டை உள்ளிடவும்',
  please_enter_aba: 'ஏபிஏ / ரூட்டிங் எண்ணை உள்ளிடவும்',
  please_enter_account_holder: 'கணக்கு வைத்திருப்பவரின் பெயரை உள்ளிடவும்',
  media_not_available: 'மீடியா கிடைக்கவில்லை!',
  please_enter_aba_routing: 'ஏபிஏ / ரூட்டிங் எண்ணை உள்ளிடவும்',
  please_enter_bank_account_number: 'வங்கி கணக்கு எண்ணை உள்ளிடவும்',
  please_enter_back_name: 'வங்கியின் பெயரை உள்ளிடவும்',
  please_add_account: 'கணக்கு வகையைச் சேர்க்கவும்',
  please_enter_bank_address: 'வங்கி முகவரியை உள்ளிடவும்',
  instant_request: 'உடனடி கோரிக்கை',
  free_online_leads: 'இலவச ஆன்லைன் வழிவகுக்கிறது',
  manage_all_your: '• உங்கள் எல்லா ஊழியர்களையும் அல்லது குழுக்களையும் நிர்வகிக்கவும்\n',
  here_you_will_see: 'இங்கே நீங்கள் புதிய திட்ட வழிவகைகளைக் காண்பீர்கள், எனவே நீங்கள் இலவச மேற்கோள்களை அனுப்பலாம்.',
  here_you_are_able_to_chat: 'இங்கே நீங்கள் உங்கள் வாடிக்கையாளர்களுடன் திட்டப்பணி முழுவதும் ஒரே பக்கத்தில் உங்களை அரட்டை அடிக்க முடியும்.',
  we_are_always_here: 'நாங்கள் உங்களுக்காக எப்போதும் இங்கே இருக்கிறோம். உங்கள் எண்ணங்களை நாங்கள் மதிக்கிறோம். இங்கே நீங்கள் உங்கள் கருத்துக்களை அல்லது கவலைகளை எங்களுடன் பகிர்ந்து கொள்ளலாம்.',
  here_is_you_able_to_chat: 'இங்கே நீங்கள் உங்கள் வாடிக்கையாளர்களுடன் திட்டப்பணி முழுவதும் ஒரே பக்கத்தில் உங்களை அரட்டை அடிக்க முடியும்.',
  we_are_always_here_for_you: 'நாங்கள் உங்களுக்காக எப்போதும் இங்கே இருக்கிறோம். உங்கள் எண்ணங்களை நாங்கள் மதிக்கிறோம். இங்கே நீங்கள் உங்கள் கருத்துக்களை அல்லது கவலைகளை எங்களுடன் பகிர்ந்து கொள்ளலாம்.',
  here_you_can_make: 'இங்கே நீங்கள் மிகவும் அவசர கோரிக்கைகளுக்கு உங்களை கிடைக்கச் செய்யலாம். கோரிக்கையின் 2 மணி நேரத்திற்குள் திட்டங்களைத் தொடங்க நீங்கள் கிடைக்கும் என்று ஆன்லைன் பயன்முறை விருப்பம் கூறுகிறது.',
  there_is_nothing: 'இரட்டை முன்பதிவை விட கிரேசியர் எதுவும் இல்லை. இங்கே நீங்கள் உங்கள் அட்டவணையை பராமரிக்க முடியும், இதன் மூலம் என்னவென்று உங்களுக்குத் தெரியும். இந்த அம்சத்துடன் உங்கள் திட்டங்களை துல்லியமாக திட்டமிடுங்கள்.',
  why_not_grow: 'பல ஊழியர்கள் அல்லது குழுக்கள் இருக்க உங்கள் வணிகத்தை ஏன் வளர்க்கக்கூடாது? இங்கே நீங்கள் உங்கள் ஊழியர்களை நிர்வகிக்க முடியும், இதன் மூலம் உங்களுக்கு வழங்கப்பட்ட திட்டங்களை ஒதுக்கலாம்!',
  back_button: 'பின் பொத்தான்',
  loagin_button: 'உள்நுழை பொத்தான்',
  here_is_where_you_see: 'உங்களுடைய கிடைக்கக்கூடிய கடனை இங்கே காணலாம். நீங்கள் வரவுகளை சம்பாதிப்பது மட்டுமல்லாமல், அதிக விலையுயர்ந்த திட்டங்களுக்கு செலுத்த வரவுகளையும் சேர்க்கலாம்.',
  we_are_here_to_help: 'உங்கள் திட்டங்களை நிர்வகிக்க உங்களுக்கு உதவ நாங்கள் இங்கு வந்துள்ளோம். உங்களிடம் அவசர கோரிக்கை அல்லது எளிய திட்டம் இருந்தாலும், ஒரு தொழில்முறை நிபுணரைக் கண்டுபிடிப்பதற்கான பயணத்தைத் தொடங்குவது இதுதான். பலர் மேற்கோளை அனுப்புவார்கள், ஆனால் யார் பணியை முடிப்பார்கள் என்பதை நீங்கள் தேர்வு செய்கிறீர்கள்.',
  here_you_will_see_the_status: 'நீங்கள் உள்ளிட்ட திட்டங்களின் நிலையை இங்கே காண்பீர்கள். ஒரு திட்டத்திற்கு மேற்கோள் அனுப்பப்பட்டதா, அல்லது திட்டம் தொடங்கப்பட்டதா என்பதை நீங்கள் காண்பீர்கள். உங்கள் முடிக்கப்பட்ட திட்டத்தில் நீங்கள் முழுமையாக திருப்தி அடையும் வரை நாங்கள் உங்களுடன் நடப்போம்.',
  this_is_where_you: 'இந்த அவசர திட்டங்களின் நிலையை நீங்கள் சரிபார்க்க வேண்டும். ஆகவே, பெரும்பாலான சந்தர்ப்பங்களில் அவசரநிலை ஏற்பட்டால், கோரிக்கைகளுக்கு 2 மணி நேரத்திற்குள் உதவ எங்களுக்கு நன்மை கிடைக்கிறது.',
  here_you_are_able: 'இங்கே நீங்கள் உங்கள் சேவை நிபுணருடன் அரட்டை அடிக்க முடியும்.',
  we_are_always_here_for: 'நாங்கள் உங்களுக்காக எப்போதும் இங்கே இருக்கிறோம். உங்கள் எண்ணங்களை நாங்கள் மதிக்கிறோம். இங்கே நீங்கள் உங்கள் கருத்துக்களை அல்லது கவலைகளை எங்களுடன் பகிர்ந்து கொள்ளலாம்.',
  send_free_qoutes: '• இலவச மேற்கோள்களை அனுப்பவும்',
  grow_your_business: '• உங்கள் வணிகத்தை வளர்க்கவும்',
  manage_your_employee: '• உங்கள் ஊழியர்களை நிர்வகிக்கவும்',
  track_clients: '• வாடிக்கையாளர்கள், வேலைகள் மற்றும் விலைப்பட்டியல்களைக் கண்காணிக்கவும்',
  utilize_the_free: '• இலவச சிஆர்எம் மென்பொருளைப் பயன்படுத்துங்கள்',
  connect_directly_with: '• திட்டங்களைப் பற்றி நுகர்வோருடன் நேரடியாக இணைக்கவும்',
  select_your_services: '• உங்கள் சேவை இருப்பிடத்தைத் தேர்ந்தெடுக்கவும்',
  no_annual_fee: '•ஆண்டு கட்டணம் இல்லை',
  industry_professional: 'தொழில் நிபுணத்துவ நன்மைகள்',
  connect_with_a_vetted_pro: '• உங்கள் சமூகத்தில் ஒரு சார்பு சார்புடன் இணைக்கவும்',
  recieve_multiple_qoute: '• திட்டங்களுக்கு பல மேற்கோள்களைப் பெறுக',
  read_reviews_of_pros: '• நன்மை பற்றிய மதிப்புரைகளைப் படித்து ஒப்பிடுக',
  manage_all_projects: '• பயன்பாட்டில் உள்ள அனைத்து திட்டங்களையும் நிர்வகிக்கவும்',
  communicate_with_your: '• உங்கள் சேவை வழங்குநருடன் தொடர்பு கொள்ளுங்கள்',
  send_payment_once_project: '• திட்டம் முடிந்ததும் கட்டணம் அனுப்பவும்',
  refer_a_friend_and: '• நண்பரைப் பார்த்து கணக்கு வரவுகளைப் பெறுங்கள்t',
  provide_accurate_qoute: '•படங்கள் மற்றும் அரட்டையின் அடிப்படையில் துல்லியமான மேற்கோள்களை வழங்கவும்',
  exposure_for_your: '•உங்கள் நிறுவனத்திற்கான வெளிப்பாடு',
  live_mode_for_instant: '• உடனடி / அவசர அல்லது அட்டவணை திட்டங்களுக்கான நேரடி பயன்முறை',
  choose_the_jobs: '• நீங்கள் விரும்பும் வேலைகளைத் தேர்வுசெய்க',
  crm_platform_for_great: '•சிறந்த வாடிக்கையாளர் திருப்திக்கான CRM தளம்',
  professional_chat_funtion: '• தொழில்முறை அரட்டை செயல்பாடு',
  keep_track_of_all: '• உங்கள் திட்டங்கள் மற்றும் விலைப்பட்டியல்களைக் கண்காணிக்கவும்',
  free_business_tools: 'இலவச வணிக கருவிகள்',
  please_enter_your_project: 'அரட்டையுடன் தொடர உங்கள் திட்ட விளக்கத்தை உள்ளிடவும்.',
  ok: 'சரி',
  jobs: 'வேலைகள்',
  requests: 'கோரிக்கைகளை',
  got_it: 'அறிந்துகொண்டேன்',

  please_enter_ein: 'EIN / Tax ID ஐ உள்ளிடவும்',
  please_enter_social_number: 'சமூக பாதுகாப்பு எண்ணை உள்ளிடவும்.',
  please_enter_correct_ssn: 'சரியான சமூக பாதுகாப்பு எண்ணை உள்ளிடவும்.',
  please_check_the_box: 'பாண்ட் / காப்பீட்டுக்கான பெட்டியை சரிபார்த்து, பாண்ட் / காப்பீட்டுக்கான ஆதாரத்தைக் காட்டுங்கள்',
  please_check_the_box_only: 'பாண்ட் / காப்பீட்டுக்கான பெட்டியை சரிபார்க்கவும்',
  please_show_proof_of_bond: 'பாண்ட் / காப்பீட்டுக்கான ஆதாரத்தைக் காட்டுங்கள்',
  please_select_bounded: 'பிணைக்கப்பட்ட / காப்பீடு செய்யப்பட்டதைத் தேர்ந்தெடுக்கவும்',
  please_enter_about_business: 'வணிகத்தைப் பற்றி உள்ளிடவும்',
  please_enter_new_password: 'புதிய கடவுச்சொல்லை உள்ளிடவும்',
  please_enter_old_password: 'பழைய கடவுச்சொல்லை உள்ளிடவும்',
  please_enter_your_correct: 'சரியான இருப்பிடத்தை உள்ளிடவும்',
  please_add_service_area: 'சேவை பகுதியைச் சேர்க்கவும்',
  please_add_categories: 'வகைகளைச் சேர்க்கவும்',
  please_enter_year_of: 'வணிகத்தில் வருடங்களை உள்ளிடவும்',
  pleaes_check_the_box_for_ar: 'அங்கீகாரம் பெற்ற பெட்டியை சரிபார்க்கவும்',
  please_check_the_box_nari: 'நரி உறுப்பினருக்கான பெட்டியை சரிபார்க்கவும்',
  please_enter_company_ein: 'நிறுவனத்தின் EIN / வரி ஐடியை உள்ளிடவும்',
  please_add_company_ein_id: 'நிறுவனத்தின் EIN / வரி ஐடி புகைப்படத்தைச் சேர்க்கவும்',
  tell_us_about_the_project_using_chat_picture_and_or_videos: 'அரட்டை, படம் மற்றும் / அல்லது வீடியோக்களைப் பயன்படுத்தி திட்டத்தைப் பற்றி எங்களிடம் கூறுங்கள்.',
  obtain_multiple_quotes_from_ready_and_available_pros: 'தயாராக மற்றும் கிடைக்கக்கூடிய நன்மைகளிலிருந்து பல மேற்கோள்களைப் பெறுங்கள்.',
  book_your_fully_vetted_pro_for_same_day_repair_or_schedule_them_at_your_convenience: 'ஒரே நாளில் பழுதுபார்ப்பதற்காக உங்கள் முழு சோதனை புரோவை முன்பதிவு செய்யுங்கள் அல்லது அவற்றை உங்கள் வசதிக்கு ஏற்ப திட்டமிடவும்.',
  schedule_pay_and_rate_your_pro_while_keeping_track_of_all_your_jobs_with_the_u_go_pros_app: 'U\'GO Pros பயன்பாட்டில் உங்கள் எல்லா வேலைகளையும் கண்காணிக்கும் போது உங்கள் சார்பு திட்டமிடவும், செலுத்தவும் மற்றும் மதிப்பிடவும்.',
  you_will_be_charged: 'நீங்கள் சிறந்த வழக்கு சூழ்நிலையில் வசூலிக்கப்படும் $',
  before_pro_starts_job: 'சார்பு வேலை தொடங்குவதற்கு முன், அவர்கள் மதிப்பீடு செய்தவுடன் உண்மையான செலவு குறித்து உங்களுக்கு அறிவிக்கப்படும். இங்கிருந்து நீங்கள் மறுக்கலாம் அல்லது ஏற்றுக்கொள்ளலாம்',
  please_enter_title_of_your_event: 'உங்கள் நிகழ்வின் தலைப்பை உள்ளிடவும்',
  please_enter_detail_of_your_event: 'தயவுசெய்து உங்கள் நிகழ்வின் விவரங்களை உள்ளிடவும்.',
  please_select_time_of_your_event: 'தயவுசெய்து உங்கள் நிகழ்வின் நேரத்தைத் தேர்ந்தெடுக்கவும்.',
  please_select_date_of_your_event: 'தயவுசெய்து உங்கள் நிகழ்வின் தேதியை தேர்ந்தெடுக்கவும்.',
  please_enter_the_reason_for_cancel_to_continue: 'தொடர ரத்து செய்வதற்கான காரணத்தை உள்ளிடவும்.',
  you_havent_enough_credits: 'நீங்கள் போதுமான கிரெடிட்கள் இல்லை.',
  please_select_month_from_list: 'தயவுசெய்து பட்டியலில் இருந்து மாதம் தேர்ந்தெடுக்கவும்',
  please_select_year_from_list: 'தயவுசெய்து பட்டியலிலிருந்து வருடத்தைத் தேர்ந்தெடுக்கவும்',
  google_play_services_required: 'Google Play சேவைகள் தேவை: ',
  after_installing_close_and_relaunch_this_app: 'நிறுவிய பின், இந்த பயன்பாட்டை மூடி மீண்டும் தொடங்கவும்.',
  no_network_connection_available: 'பிணைய இணைப்பு எதுவும் கிடைக்கவில்லை.',
  cancelling_required_permissions_are_not_granted: 'ரத்துசெய்கிறது, தேவையான அனுமதிகள் வழங்கப்படவில்லை',
  connection_problem: 'இணைப்பு சிக்கல், தயவுசெய்து உங்கள் இணையத்தை சரிபார்த்து பின்னர் மீண்டும் முயற்சிக்கவும்',
  oh_no: 'ஓ இல்லை! ',
  microphone_permission: 'பதிவு செய்ய மைக்ரோஃபோன் அனுமதி தேவை. கூடுதல் செயல்பாட்டுக்கு பயன்பாட்டு அமைப்புகளில் அனுமதிக்கவும்.',
  external_storage_permission: 'வெளிப்புற சேமிப்பு அனுமதி தேவை. கூடுதல் செயல்பாட்டுக்கு பயன்பாட்டு அமைப்புகளில் அனுமதிக்கவும்.',
  camera_permission: 'கேமரா அனுமதி தேவை. கூடுதல் செயல்பாட்டுக்கு பயன்பாட்டு அமைப்புகளில் அனுமதிக்கவும்.',
  please_provide_personal_past_experience_and_company_info: 'தனிப்பட்ட, கடந்த கால அனுபவம் மற்றும் நிறுவனத்தின் தகவலை வழங்கவும்',
  please_add_total_minimum_estimate: 'மொத்த குறைந்தபட்ச மதிப்பீட்டைச் சேர்க்கவா? $',
  please_add_total_minimum_estimate_at_least_1: 'மொத்த குறைந்தபட்ச மதிப்பீட்டை குறைந்தது $ 1 ஐச் சேர்க்கவும்',
  please_add_total_maximum_estimate_at_least_1: 'மொத்த அதிகபட்ச மதிப்பீட்டை குறைந்தது $ 1 ஐச் சேர்க்கவும்',
  please_add_total_maximum_estimate: 'மொத்த அதிகபட்ச மதிப்பீட்டைச் சேர்க்கவா? $',
  total_minimum_estimate_should_be_less_than_total_maximum_estimate: 'மொத்த குறைந்தபட்ச மதிப்பீடு மொத்த அதிகபட்ச மதிப்பீட்டை விட குறைவாக இருக்க வேண்டும்.',
  please_select_your_available_time: 'உங்கள் கிடைக்கும் நேரத்தைத் தேர்ந்தெடுக்கவும்',
  permission_deny: 'அனுமதி மறுக்க',
  mediaActivity_not_available: 'மீடியா செயல்பாடு கிடைக்கவில்லை!',
  portfolio_not_found: 'போர்ட்ஃபோலியோ கிடைக்கவில்லை',
  select_second_time: 'உங்கள் இரண்டாவது கிடைக்கக்கூடிய நேரத்தை மீண்டும் தேர்ந்தெடுக்கவும்',
  select_third_time: 'உங்கள் மூன்றாவது கிடைக்கக்கூடிய நேரத்தை மீண்டும் தேர்ந்தெடுக்கவும்',
  commissioned_base_services: 'நியமிக்கப்பட்ட அடிப்படை சேவைகளுக்கு. எ.கா: ரியல் எஸ்டேட், வக்கீல்கள், காப்பீட்டு முகவர்கள் போன்றவை.\n\n U\'GO Pros இல் உங்கள் ஆர்வத்திற்கு நன்றி. உங்களை மேலும் வாடிக்கையாளர்களுடன் இணைக்க நாங்கள் அர்ப்பணித்துள்ளோம். எங்களுடன் கூட்டு சேருவது உங்களை நுகர்வோருக்கான கோப்பகத்தில் வைக்கும். எங்கள் மேடையில் அரட்டை வழியாக அல்லது உங்கள் தனிப்பட்ட தொடர்பு மூலம் அவர்கள் உங்களுக்கு அறிவிக்க முடியும்.\n\n சேவை செலவுகள்: மறைக்கப்பட்ட கட்டணம் இல்லை\n',
  yearly: 'ஆண்டு:     ',
  monthly: 'மாதாந்திர:     ',
  these_fees_are_subject: 'இந்த கட்டணங்கள் மாற்றத்திற்கு உட்பட்டவை, ஆனால் இது நடந்தால் உங்களுக்கு முன்கூட்டியே அறிவிக்கப்படும்.\n\n எங்களுடன் கூட்டுசேர நீங்கள் ஆர்வமாக இருந்தால், பதிவுபெறும் செயல்முறையைத் தொடரவும். உங்களிடம் ஏதேனும் கேள்விகள் இருந்தால் 877-733-2425 அல்லது info@ugopros.com ஐ அணுகலாம்',
  chose_to_resubscribe: 'நீங்கள் மீண்டும் குழுசேர தேர்வு செய்ததில் மகிழ்ச்சி, தயவுசெய்து உங்கள் சந்தா மாதாந்திர அல்லது வருடாந்திர கட்டணத்தை எவ்வாறு செலுத்த விரும்புகிறீர்கள் என்பதைத் தேர்ந்தெடுக்கவும்.',
  plumber_request: 'பிளம்பர் கோரிக்கை',
  schedule_meeting: 'திட்டமிடல் சந்திப்பு',
  error_pdf_docs: 'பிழை, ஆவணத்தில் பி.டி.எஃப் மற்றும் டாக்ஸ் கோப்பு மட்டுமே அனுமதிக்கப்படுகின்றன',
  please_enter_paypall_account: 'Paypal கணக்கை உள்ளிடவும்',
  please_enter_verify_code: 'சரிபார்க்கும் குறியீட்டை உள்ளிடவும்',
  please_enter_your_new_password: 'உங்கள் புதிய கடவுச்சொல்லை உள்ளிடவும்',
  choose_service_distance: 'சேவை பகுதி தூரத்தைத் தேர்வுசெய்க',
  unable_to_get_info: 'உங்கள் கணக்கிலிருந்து தேவையான தகவல்களை எங்களால் பெற முடியவில்லை. தயவுசெய்து அனுமதி / புதுப்பிப்பு பயன்பாடு மற்றும் மீண்டும் முயற்சிக்கவும்',
  please_enter_service_location: 'சேவை இருப்பிடத்தை உள்ளிடவும்',
  no_data_found: 'வேறு தகவல்கள் இல்லை',
  add_portfolio_picture: 'போர்ட்ஃபோலியோ படத்தைச் சேர்க்கவும்',
  cannot_access_location: 'அனுமதி! இருப்பிட தரவை நீங்கள் அணுக முடியாது',
  information_not_found: 'தகவல் கிடைக்கவில்லை',
  you_cannot_select_past_time: 'கடந்த காலத்தை நீங்கள் தேர்ந்தெடுக்க முடியாது',
  error_occurs: 'பிழை ஏற்பட்டுவிட்டது',
  select_special_service: 'சிறப்பு சேவையைத் தேர்ந்தெடுக்கவும்',
  select_state: 'மாநிலத்தைத் தேர்ந்தெடுக்கவும்',
  select_city: 'நகரத்தைத் தேர்ந்தெடுக்கவும்',
  enter_your_withdraw_amount: 'உங்கள் திரும்பப் பெறும் தொகையை உள்ளிடவும்',
  less_than_total_balance: 'மொத்த இருப்பு விட தொகை குறைவாக இருக்க வேண்டும்',
  enter_business_description: 'வணிக விளக்கத்தை உள்ளிடவும்',
  please_show_proof_of_register_certificate: 'தயவுசெய்து பதிவுச் சான்றிதழுக்கான ஆதாரத்தைக் காட்டுங்கள்',
  please_show_proof_of_pen_card: 'தயவுசெய்து பேனா அட்டைக்கான ஆதாரத்தைக் காட்டுங்கள்',
  please_show_proof_of_aadhar_card: 'ஆதார் அட்டைக்கான ஆதாரத்தைக் காட்டுங்கள்',
  please_show_proof_of_voter_identity_card: 'தயவுசெய்து வாக்காளர் அடையாள அட்டைக்கான ஆதாரத்தைக் காட்டுங்கள்',
  please_show_proof_of_gst: 'தயவுசெய்து ஜிஎஸ்டி ஆதாரத்தைக் காட்டுங்கள்',
  please_show_proof_of_licese: 'தயவுசெய்து ஓட்டுநர் உரிமத்தின் சான்றைக் காட்டுங்கள்',
  please_check_the_box_for_register_certificate_and_show_poof: 'பதிவுச் சான்றிதழுக்கான பெட்டியை சரிபார்த்து, பதிவுச் சான்றிதழின் சான்றைக் காட்டவும்',
  please_check_the_for_register_certificate: 'பதிவுச் சான்றிதழுக்கான பெட்டியை சரிபார்க்கவும்',
  please_check_the_box_for_pen_card_and_show_poof: 'பென் கார்டிற்கான பெட்டியை சரிபார்த்து, பென் கார்டின் சான்றை காட்டவும்',
  please_check_the_box_only_for_pen_card: 'பென் கார்டிற்கான பெட்டியை சரிபார்க்கவும்',
  please_check_the_box_for_adhar_card_and_show_poof: 'ஆதார் அட்டைக்கான பெட்டியை சரிபார்த்து ஆதார் அட்டைக்கான ஆதாரத்தைக் காட்டவும்',
  please_check_the_box_only_for_adhar_card: 'ஆதார் அட்டைக்கான பெட்டியை சரிபார்க்கவும்',
  please_check_the_box_for_adhar_card: 'ஆதார் அட்டைக்கான பெட்டியை சரிபார்க்கவும்',
  please_check_the_box_for_voter_identityr_card_and_show_poof: 'வாக்காளர் அடையாள அட்டைக்கான பெட்டியை சரிபார்த்து, பென் கார்டின் சான்றை காட்டவும்',
  please_check_the_box_for_voter_identity_card: 'வாக்காளர் அடையாள அட்டைக்கான பெட்டியை சரிபார்க்கவும்',
  please_check_the_box_for_gst_and_show_poof: 'ஜிஎஸ்டிக்கு பெட்டியை சரிபார்த்து, பென் கார்டின் சான்றை காட்டவும்',
  please_check_the_box_for_gst: 'GST க்கான பெட்டியை சரிபார்க்கவும்',
  please_check_the_box_for_driving_license_and_show_poof: 'ஓட்டுநர் உரிமத்திற்கான பெட்டியை சரிபார்த்து, பென் கார்டின் சான்றை காட்டவும்',
  please_check_the_box_for_driving_License: 'ஓட்டுநர் உரிமத்திற்கான பெட்டியை சரிபார்க்கவும்',
  you_must_be_18_: 'எங்கள் சேவைகளைப் பயன்படுத்த உங்களுக்கு 18 வயது அல்லது அதற்கு மேற்பட்ட வயது இருக்க வேண்டும்.',
  select_payment_method: 'கட்டண முறையைத் தேர்ந்தெடுக்கவும்',
  google_payment_is_not_available_in_this_device: 'இந்தச் சாதனத்தில் Google Payment இல்லை',
  us: 'US',
  _5_credit_for_reviews_are_for_jobs_that_are_complete_over_10: 'மதிப்புரைகளுக்கு $5 கிரெடிட் $10க்கு மேல் முடிந்த வேலைகளுக்கு',

  //employee

  login_in: 'உள்நுழைய',

  mobile: 'கைபேசி#',

  please_enter_valid_email_address: 'செல்லுபடியாகும் மின்னஞ்சல் முகவரியை உள்ளிடவும்.',

  minimum_password_length_should_be: 'குறைந்தபட்ச கடவுச்சொல் நீளம் இருக்க வேண்டும்',

  don_t_have_an_account: 'கணக்கு இல்லையா?',

  agree_to_terms_conditions: 'விதிமுறைகள் மற்றும் நிபந்தனைகள்',
  privacy_policy: 'தனியுரிமைக் கொள்கை',

  google_maps_key: 'AIzaSyBb2sUywacICcNUEmkhGsMUXIArFSoWUfc',

  please_check_network_connection: 'உங்கள் பிணைய இணைப்பைச் சரிபார்க்கவும்',
  add_lead: 'லீட் சேர்க்கவும்',
  write_your_message_here: 'உங்கள் செய்தியை இங்கே எழுதுங்கள் ...',
  lead_detail: 'விரிவாக வழிநடத்துங்கள்',
  superviser: 'மேற்பார்வையாளர்',
  followed_up: 'பின்தொடர்ந்தார்',
  in_person_schedule: 'நபர் அட்டவணையில்',

  refused: 'மறுக்கப்பட்டது',
  requested_callback: 'திரும்ப அழைப்பு கோரப்பட்டது',

  all_employees: 'அனைத்து பணியாளர்கள்',

  manage: 'நிர்வகிக்கவும்',
  employees: 'பணியாளர்கள்',
  please_enter_user_name: 'பயனர் பெயரை உள்ளிடவும்',

  oops: 'அச்சச்சோ!',
  task_list: 'பணி பட்டியல்',
  setting: 'அமைத்தல்',

  update_employee: 'பணியாளரைப் புதுப்பிக்கவும்',

  please_enter_phone_number: 'தொலைபேசி எண்ணை உள்ளிடவும்',

  please_enter_country: 'தயவுசெய்து நாட்டை உள்ளிடவும்',
  please_enter_city: 'நகரத்தை உள்ளிடவும்',

  please_Enter_body: '\"உடலை உள்ளிடவும் \"',
  please_enter_postal_code: '\"அஞ்சல் குறியீட்டை உள்ளிடவும் \"',

  please_enter_First_name: 'முதல் பெயரை உள்ளிடவும்',
  please_enter_last_name: 'கடைசி பெயரை உள்ளிடவும்',
  plese_select_the_team: 'அணியைத் தேர்ந்தெடுக்கவும்',

  this_app_is_designed_for_internal_staff_members_only: 'இந்த பயன்பாடு உள் ஊழியர்களுக்காக மட்டுமே வடிவமைக்கப்பட்டுள்ளது',
  follow_ugo_pros_to_utilize_pros_features: 'ப்ரோஸ் அம்சங்களைப் பயன்படுத்த U\'GO ப்ரோஸைப்',
  signup_as_an_industry_professional: 'ஒரு தொழில் வல்லுநராக பதிவுபெறவா?',
  supervisor: 'மேற்பார்வையாளர்',

  user_login: 'பயனர் உள்நுழைவு',
  user_name: ' பயனர் பெயர் ',

  team_id: 'அணி ஐடி',

  sale: 'விற்பனை',
  street: 'ஸ்ட்ரீட்',
  phone_number: 'தொலைபேசி எண்',
  body: 'உடல்',
  postal_code: 'அஞ்சல் குறியீடு',

  new_task_s: 'புதிய பணி',
  current_task: 'தற்போதைய பணி',

  task_num: 'பணி # ',
  ready_to_start: 'தொடங்கத் தயார்',

  start: 'தொடங்கு',

  end_tracking: 'டிராக்கிங் முடிக்க',
  task_detail: 'பணி விவரம்',

  upload_images: 'படங்களை பதிவேற்றுங்கள்',
  write_your_feedback_here: 'உங்கள் கருத்தை இங்கே எழுதுங்கள்',

  followed: 'பின்பற்றப்பட்டது',
  lead_name: 'முன்னணி பெயர்',

  name_: 'பெயர்:',
  address_: 'முகவரி:',
  country_: 'நாடு:',
  state_: 'நிலை:',
  city_: 'நகரம்:',
  meeting_detail: 'கூட்ட விவரம்:',

  start_tracking: 'டிராக்கிங் தொடங்குங்கள்',
  meet_with_pro_to_get_information: 'தகவலைப் பெற புரோவுடன் சந்திக்கவும்',
  choose_option: 'விருப்பத்தைத் தேர்வுசெய்க',

  permission: 'அனுமதி',
  allow_to_access_your_location: 'உங்கள் இருப்பிடத்தை அணுக அனுமதிக்கவா?',
  allow: 'அனுமதி',
  never: 'எப்போதும் இல்லை',

  days_ago: 'நாட்களுக்கு முன்பு',

  assign: 'ஒதுக்க',
  update_successfully: 'வெற்றிகரமாக புதுப்பிக்கவும்',

  turn_on_location_on_device: 'சாதனத்தில் உங்கள் இருப்பிடத்தை இயக்கவும்',

  welcome_to_u_go_employee_staff: 'U\'GO ஊழியர் / பணியாளர்களுக்கு வருக',
  account_deletion: 'கணக்கு நீக்கம்',
  delete_my_account: 'எனது கணக்கை நீக்கு',
  delete_account: 'கணக்கை நீக்குக',
  importent_note: 'முக்கியமான குறிப்பு',
  your_account_should_not_have_any_active_pending_project_n_your_account_should_not_have_any_active_payouts_n_after_successful_deletion_of_your_account_you_will_not_be_able_to_log_in_to_the_account_account_deletion_is_permanent_and_will_not_be_reversible: '• உங்கள் கணக்கில் செயலில் எந்த திட்டமும் நிலுவையில் இருக்கக்கூடாது\n• உங்கள் கணக்கில் செயலில் பணம் செலுத்துதல்கள் இருக்கக்கூடாது\n• உங்கள் கணக்கை வெற்றிகரமாக நீக்கிய பிறகு, உங்களால் கணக்கில் உள்நுழைய முடியாது. கணக்கு நீக்கம் நிரந்தரமானது மற்றும் திரும்பப்பெற முடியாது.',
  please_acknowledge_that_you_have_read_the_above_note: 'மேலே உள்ள குறிப்பை நீங்கள் படித்திருப்பதை ஒப்புக்கொள்ளவும்',
  acknowledged: 'ஒப்புக்கொள்ளப்பட்டது',
  are_you_sure_you_want_to_delete_account: 'கணக்கை நிச்சயமாக நீக்க விரும்புகிறீர்களா?',
  please_confirm_your_account_deletion_once_you_confirm_it_you_will_not_be_able_to_use_your_account_and_we_will_delete_all_the_data_associated_with_your_account: 'உங்கள் கணக்கு நீக்குதலை உறுதிப்படுத்தவும், அதை உறுதிசெய்தவுடன் உங்கள் கணக்கைப் பயன்படுத்த முடியாது, மேலும் உங்கள் கணக்குடன் தொடர்புடைய எல்லா தரவையும் நாங்கள் நீக்குவோம்.',
  please_acknowledge: 'தயவுகூர்ந்து ஒப்புதல் அளியுங்கள்',
  delete_accounts: 'கணக்கை நீக்குக',
  your_profile_is_incomplete_if_you_want_edit_profile_you_can_do_that_by_pressing_edit_profile_button: 'உங்கள் சுயவிவரம் முழுமையடையவில்லை, சுயவிவரத்தைத் திருத்த விரும்பினால், சுயவிவரத்தைத் திருத்து பொத்தானை அழுத்துவதன் மூலம் அதைச் செய்யலாம்.',
  sorry_you_have_not_yet_been_approved_by_admin: 'மன்னிக்கவும், நீங்கள் இன்னும் நிர்வாகியால் அங்கீகரிக்கப்படவில்லை.',
  confirm_that_you_are: 'நீங்கள் 18+ என்பதை உறுதிப்படுத்தவும்',
  please_check_the_box_confirm_that_you_are: 'நீங்கள் 18 வயதுக்கு மேற்பட்டவர் என்பதை உறுதிப்படுத்த பெட்டியை சரிபார்க்கவும்',
  resend_link: 'இணைப்பை மீண்டும் அனுப்பு',
  resend_in: 'மீண்டும் அனுப்பு',
  create_pin: 'பின்னை உருவாக்கவும்',
  your_authentication_code: 'உங்கள் அங்கீகாரக் குறியீடு',
  please_enter_your_authentication_code: 'உங்கள் அங்கீகார குறியீடுக்கு சொல்லுக.',
  sign_in_securely: 'பாதுகாப்பாக உள்நுழையவும்',
  resend_authentication_code: 'அங்கீகாரக் குறியீட்டை மீண்டும் அனுப்பவும்',
  two_setup_authentication: 'இரண்டு-அமைவு அங்கீகாரம்',
  thanks_for_keeping_your_account_secure: 'உங்கள் கணக்கைப் பாதுகாப்பாக வைத்திருந்ததற்கு நன்றி.',
  please_check_your_email: 'தயவுசெய்து உங்கள் மின்னஞ்சலைச் சரிபார்க்கவும்',
  enter_old_password: 'பழைய கடவுச்சொல்லை உள்ளிடவும்',
  please_proceed_to_your_email_for_the_purpose_of_verification_in_order_to_proceed_to_the_next_step: 'அடுத்த கட்டத்திற்குச் செல்ல, சரிபார்க்கும் நோக்கத்திற்காக உங்கள் மின்னஞ்சலுக்குச் செல்லவும்.',
  please_enter_social_number_or_ein: 'சமூக பாதுகாப்பு அல்லது EIN எண்ணை உள்ளிடவும்.',

};
