const POST_REFER_FRIENDS_REQUEST = 'POST_REFER_FRIENDS_REQUEST';
const POST_REFER_FRIENDS_REQUEST_SUCCESS = 'POST_REFER_FRIENDS_REQUEST_SUCCESS';
const POST_REFER_FRIENDS_REQUEST_FAILURE = 'POST_REFER_FRIENDS_REQUEST_FAILURE';
const RESET_REFER_FRIENDS_STATE = 'RESET_REFER_FRIENDS_STATE'

const referFriends = (formData) => {

    return {
        type: POST_REFER_FRIENDS_REQUEST,
        formData
    }
}
const resetReferFriends = () => {

    return {
        type: RESET_REFER_FRIENDS_STATE,

    }
}

export {
    referFriends,
    resetReferFriends,
    RESET_REFER_FRIENDS_STATE,
    POST_REFER_FRIENDS_REQUEST,
    POST_REFER_FRIENDS_REQUEST_SUCCESS,
    POST_REFER_FRIENDS_REQUEST_FAILURE,
};