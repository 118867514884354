import { setItem } from "../../../Utils/AsyncStore";
import {
  POST_BASIC_START_REQUEST,
  POST_BASIC_START_REQUEST_SUCCESS,
  POST_BASIC_START_REQUEST_FAILURE,
  SET_LANGUAGE,
  SET_BASIC_START,
  RESET_BASIC_START_STATE
} from "./Action"

const initialState = {
  data: {}
};

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case POST_BASIC_START_REQUEST: {

      console.log(POST_BASIC_START_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_BASIC_START_REQUEST_SUCCESS: {
      console.log(POST_BASIC_START_REQUEST_SUCCESS);
      setItem("startUp", action.payload)
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case POST_BASIC_START_REQUEST_FAILURE: {
      console.log(POST_BASIC_START_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: error?.message,
        errorData: error
      };
    }
    case RESET_BASIC_START_STATE: {
      console.log(RESET_BASIC_START_STATE);
      return {
        ...initialState
      };
    }
    case SET_LANGUAGE: {
      let language = action.language
      return {
        ...state,
        language
      }
    }
    case SET_BASIC_START: {
      return {
        ...state,
        data: action.payload,
      }
    }
    default:
      return state;
  }
};

export { reducer };
