const POST_PIN_STATUS_REQUEST = 'POST_PIN_STATUS_REQUEST';
const POST_PIN_STATUS_REQUEST_SUCCESS = 'POST_PIN_STATUS_REQUEST_SUCCESS';
const POST_PIN_STATUS_REQUEST_FAILURE = 'POST_PIN_STATUS_REQUEST_FAILURE';
const RESET_PIN_STATUS_STATE = 'RESET_PIN_STATUS_STATE'

const pinStatus = (formData) => {

    return {
        type: POST_PIN_STATUS_REQUEST,
        formData
    }
}
const resetPinStatus = () => {

    return {
        type: RESET_PIN_STATUS_STATE,
    }
}
export {
    pinStatus,
    resetPinStatus,
    RESET_PIN_STATUS_STATE,
    POST_PIN_STATUS_REQUEST,
    POST_PIN_STATUS_REQUEST_SUCCESS,
    POST_PIN_STATUS_REQUEST_FAILURE,
};