const POST_DELETE_NOTIFICATIONS_REQUEST = "POST_DELETE_NOTIFICATIONS_REQUEST";
const POST_DELETE_NOTIFICATIONS_REQUEST_SUCCESS = "POST_DELETE_NOTIFICATIONS_REQUEST_SUCCESS";
const POST_DELETE_NOTIFICATIONS_REQUEST_FAILURE = "POST_DELETE_NOTIFICATIONS_REQUEST_FAILURE";
const RESET_DELETE_NOTIFICATIONS_STATE = "RESET_DELETE_NOTIFICATIONS_STATE";

const deleteNotifications = (formData) => {
  return {
    type: POST_DELETE_NOTIFICATIONS_REQUEST,
    formData
  };
};

const resetDeleteNotifications = () => {
  return {
    type: RESET_DELETE_NOTIFICATIONS_STATE,
  };
};
export {
  deleteNotifications,
  resetDeleteNotifications,
  RESET_DELETE_NOTIFICATIONS_STATE,
  POST_DELETE_NOTIFICATIONS_REQUEST,
  POST_DELETE_NOTIFICATIONS_REQUEST_SUCCESS,
  POST_DELETE_NOTIFICATIONS_REQUEST_FAILURE,
};
