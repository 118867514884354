const POST_CHECK_PRO_IN_AREA_REQUEST = 'POST_CHECK_PRO_IN_AREA_REQUEST';
const POST_CHECK_PRO_IN_AREA_REQUEST_SUCCESS = 'POST_CHECK_PRO_IN_AREA_REQUEST_SUCCESS';
const POST_CHECK_PRO_IN_AREA_REQUEST_FAILURE = 'POST_CHECK_PRO_IN_AREA_REQUEST_FAILURE';
const RESET_CHECK_PRO_IN_AREA_STATE = 'RESET_CHECK_PRO_IN_AREA_STATE'

const checkProInArea = (formData) => {

    return {
        type: POST_CHECK_PRO_IN_AREA_REQUEST,
        formData
    }
}
const resetCheckProInArea = () => {

    return {
        type: RESET_CHECK_PRO_IN_AREA_STATE,
    }
}

export {
    checkProInArea,
    resetCheckProInArea,
    RESET_CHECK_PRO_IN_AREA_STATE,
    POST_CHECK_PRO_IN_AREA_REQUEST,
    POST_CHECK_PRO_IN_AREA_REQUEST_SUCCESS,
    POST_CHECK_PRO_IN_AREA_REQUEST_FAILURE,
};