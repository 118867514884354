import { takeEvery, put, call } from 'redux-saga/effects';
import BASEURL from '../URLs'
import { queryApi } from '../QueryApi'
import {
  POST_RE_SEND_AUTHENTICATION_CODE_REQUEST,
  POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_SUCCESS,
  POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_FAILURE,
} from './Actions';
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_RE_SEND_AUTHENTICATION_CODE_REQUEST, reSendAuthCode);
}


function* reSendAuthCode(action) {
  try {
    // API call
    console.log(action.formData)
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.RE_SEND_AUTHENTICATION_CODE,
      method: 'POST',
      body: action.formData
    });
    //console.log('HIT',apiResponse)
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_SUCCESS,
        payload: apiResponse
      });

    } else {
      yield put({
        type: POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_FAILURE,
        payload: apiResponse.error
      });
    }


  } catch (err) {
    console.log(err);
    //alert(err)
    yield put({
      type: POST_RE_SEND_AUTHENTICATION_CODE_REQUEST_FAILURE,
      payload: { error: { message: err } }
    });
  }
}

export { handler };