const POST_CREATE_NEW_LEAD_REQUEST = "POST_CREATE_NEW_LEAD_REQUEST";
const POST_CREATE_NEW_LEAD_REQUEST_SUCCESS = "POST_CREATE_NEW_LEAD_REQUEST_SUCCESS";
const POST_CREATE_NEW_LEAD_REQUEST_FAILURE = "POST_CREATE_NEW_LEAD_REQUEST_FAILURE";
const RESET_CREATE_NEW_LEAD_STATE = "RESET_CREATE_NEW_LEAD_STATE";

const createNewLead = (formData) => {
  return {
    type: POST_CREATE_NEW_LEAD_REQUEST,
    formData
  };
};

const resetCreateNewLead = () => {
  return {
    type: RESET_CREATE_NEW_LEAD_STATE,
  };
};
export {
  createNewLead,
  resetCreateNewLead,
  RESET_CREATE_NEW_LEAD_STATE,
  POST_CREATE_NEW_LEAD_REQUEST,
  POST_CREATE_NEW_LEAD_REQUEST_SUCCESS,
  POST_CREATE_NEW_LEAD_REQUEST_FAILURE,
};
