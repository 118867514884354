const POST_GET_MY_PROJECTS_REQUEST = 'POST_GET_MY_PROJECTS_REQUEST';
const POST_GET_MY_PROJECTS_REQUEST_SUCCESS = 'POST_GET_MY_PROJECTS_REQUEST_SUCCESS';
const POST_GET_MY_PROJECTS_REQUEST_FAILURE = 'POST_GET_MY_PROJECTS_REQUEST_FAILURE';
const RESET_GET_MY_PROJECTS_STATE = 'RESET_GET_MY_PROJECTS_STATE'

const getMyProjects = (formData) => {

    return {
        type: POST_GET_MY_PROJECTS_REQUEST,
        formData
    }
}
const resetMyProjects = () => {

    return {
        type: RESET_GET_MY_PROJECTS_STATE,
    }
}
export {
    getMyProjects,
    resetMyProjects,
    RESET_GET_MY_PROJECTS_STATE,
    POST_GET_MY_PROJECTS_REQUEST,
    POST_GET_MY_PROJECTS_REQUEST_SUCCESS,
    POST_GET_MY_PROJECTS_REQUEST_FAILURE,
};