import { takeEvery, put, call } from "redux-saga/effects";
import BASEURL from "../../URLs";
import { queryApi } from "../../QueryApi";
import {
  POST_DELETE_EMPLOYEE_REQUEST,
  POST_DELETE_EMPLOYEE_REQUEST_SUCCESS,
  POST_DELETE_EMPLOYEE_REQUEST_FAILURE,
} from "./Action";

function* handler() {
  yield takeEvery(POST_DELETE_EMPLOYEE_REQUEST, deleteEmployee);
}

function* deleteEmployee(action) {
  try {
    // API call
    console.log(action.formData);
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.API_DELETE_EMPLOYEE,
      method: "POST",
      body: action.formData,
    });
    console.log("HIT", apiResponse);
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_DELETE_EMPLOYEE_REQUEST_SUCCESS,
        payload: apiResponse,
      });
    } else {
      yield put({
        type: POST_DELETE_EMPLOYEE_REQUEST_FAILURE,
        payload: apiResponse.error,
      });
    }
  } catch (err) {
    console.log(err);
    // Handle error
    yield put({
      type: POST_DELETE_EMPLOYEE_REQUEST_FAILURE,
      payload: { error:{message: err} },
    });
  }
}

export { handler };
