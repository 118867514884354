const POST_GET_WITHDRAW_HISTORY_REQUEST = 'POST_GET_WITHDRAW_HISTORY_REQUEST';
const POST_GET_WITHDRAW_HISTORY_REQUEST_SUCCESS =  'POST_GET_WITHDRAW_HISTORY_REQUEST_SUCCESS';
const POST_GET_WITHDRAW_HISTORY_REQUEST_FAILURE =  'POST_GET_WITHDRAW_HISTORY_REQUEST_FAILURE';
const RESET_GET_WITHDRAW_HISTORY_STATE ='RESET_GET_WITHDRAW_HISTORY_STATE'

const getWithdrawHistory = (formData)=>{

    return {
        type: POST_GET_WITHDRAW_HISTORY_REQUEST,
        formData
    }
}

export {
    getWithdrawHistory,
    RESET_GET_WITHDRAW_HISTORY_STATE,
    POST_GET_WITHDRAW_HISTORY_REQUEST,
    POST_GET_WITHDRAW_HISTORY_REQUEST_SUCCESS,
    POST_GET_WITHDRAW_HISTORY_REQUEST_FAILURE,
};