const POST_GET_PRO_REVIEWS_REQUEST = "POST_GET_PRO_REVIEWS_REQUEST";
const POST_GET_PRO_REVIEWS_REQUEST_SUCCESS = "POST_GET_PRO_REVIEWS_REQUEST_SUCCESS";
const POST_GET_PRO_REVIEWS_REQUEST_FAILURE = "POST_GET_PRO_REVIEWS_REQUEST_FAILURE";
const RESET_GET_PRO_REVIEWS_STATE = "RESET_GET_PRO_REVIEWS_STATE";

const getProReviews = (formData) => {
  return {
    type: POST_GET_PRO_REVIEWS_REQUEST,
    formData
  };
};
const resetGetProReview = () => {
  return {
    type: RESET_GET_PRO_REVIEWS_STATE,

  };
};
export {
  getProReviews,
  resetGetProReview,
  RESET_GET_PRO_REVIEWS_STATE,
  POST_GET_PRO_REVIEWS_REQUEST,
  POST_GET_PRO_REVIEWS_REQUEST_SUCCESS,
  POST_GET_PRO_REVIEWS_REQUEST_FAILURE,
};
