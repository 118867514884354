const POST_SUPERVISOR_CHAT_HISTORY_REQUEST = "POST_SUPERVISOR_CHAT_HISTORY_REQUEST";
const POST_SUPERVISOR_CHAT_HISTORY_REQUEST_SUCCESS = "POST_SUPERVISOR_CHAT_HISTORY_REQUEST_SUCCESS";
const POST_SUPERVISOR_CHAT_HISTORY_REQUEST_FAILURE = "POST_SUPERVISOR_CHAT_HISTORY_REQUEST_FAILURE";
const RESET_SUPERVISOR_CHAT_HISTORY_STATE = "RESET_SUPERVISOR_CHAT_HISTORY_STATE";

const supervisorChatHistory = () => {
  return {
    type: POST_SUPERVISOR_CHAT_HISTORY_REQUEST,
    
  };
};

const resetSupervisorChatHistory = () => {
  return {
    type: RESET_SUPERVISOR_CHAT_HISTORY_STATE,
  };
};
export {
  supervisorChatHistory,
  resetSupervisorChatHistory,
  RESET_SUPERVISOR_CHAT_HISTORY_STATE,
  POST_SUPERVISOR_CHAT_HISTORY_REQUEST,
  POST_SUPERVISOR_CHAT_HISTORY_REQUEST_SUCCESS,
  POST_SUPERVISOR_CHAT_HISTORY_REQUEST_FAILURE,
};
