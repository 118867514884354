const POST_GET_PAYMENT_METHOD_REQUEST = 'POST_GET_PAYMENT_METHOD_REQUEST';
const POST_GET_PAYMENT_METHOD_REQUEST_SUCCESS =  'POST_GET_PAYMENT_METHOD_REQUEST_SUCCESS';
const POST_GET_PAYMENT_METHOD_REQUEST_FAILURE =  'POST_GET_PAYMENT_METHOD_REQUEST_FAILURE';
const RESET_GET_PAYMENT_METHOD_STATE ='RESET_GET_PAYMENT_METHOD_STATE'

const getPaymentMethod = (formData)=>{

    return {
        type: POST_GET_PAYMENT_METHOD_REQUEST,
        formData
    }
}

export {
    getPaymentMethod,
    RESET_GET_PAYMENT_METHOD_STATE,
    POST_GET_PAYMENT_METHOD_REQUEST,
    POST_GET_PAYMENT_METHOD_REQUEST_SUCCESS,
    POST_GET_PAYMENT_METHOD_REQUEST_FAILURE,
};