const POST_USER_SOCIAL_REGISTER_REQUEST = 'POST_USER_SOCIAL_REGISTER_REQUEST';
const POST_USER_SOCIAL_REGISTER_REQUEST_SUCCESS = 'POST_USER_SOCIAL_REGISTER_REQUEST_SUCCESS';
const POST_USER_SOCIAL_REGISTER_REQUEST_FAILURE = 'POST_USER_SOCIAL_REGISTER_REQUEST_FAILURE';
const RESET_SOCIAL_REGISTER_STATE = 'RESET_SOCIAL_REGISTER_STATE'

const userSocialRegister = (formData) => {

    return {
        type: POST_USER_SOCIAL_REGISTER_REQUEST,
        formData
    }
}

export {
    userSocialRegister,
    RESET_SOCIAL_REGISTER_STATE,
    POST_USER_SOCIAL_REGISTER_REQUEST,
    POST_USER_SOCIAL_REGISTER_REQUEST_SUCCESS,
    POST_USER_SOCIAL_REGISTER_REQUEST_FAILURE,
};