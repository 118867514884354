const POST_NEW_PROJECTS_REQUEST = "POST_NEW_PROJECTS_REQUEST";
const POST_NEW_PROJECTS_REQUEST_SUCCESS = "POST_NEW_PROJECTS_REQUEST_SUCCESS";
const POST_NEW_PROJECTS_REQUEST_FAILURE = "POST_NEW_PROJECTS_REQUEST_FAILURE";
const RESET_NEW_PROJECTS_STATE = "RESET_NEW_PROJECTS_STATE";
const SET_IS_QUICK_QUOTE = "SET_IS_QUICK_QUOTE";

const newProject = (formData) => {
  return {
    type: POST_NEW_PROJECTS_REQUEST,
    formData
  };
};
const resetNewProject = () => {
  return {
    type: RESET_NEW_PROJECTS_STATE,
  };
};
const setIsQuickQuote = (quickQuote) => {
  return {
    type: SET_IS_QUICK_QUOTE,
    quickQuote
  };
};


export {
  newProject,
  resetNewProject,
  setIsQuickQuote,
  POST_NEW_PROJECTS_REQUEST,
  POST_NEW_PROJECTS_REQUEST_SUCCESS,
  POST_NEW_PROJECTS_REQUEST_FAILURE,
  RESET_NEW_PROJECTS_STATE,
  SET_IS_QUICK_QUOTE
};
