const POST_RE_SUBSCRIBE_USER_REQUEST = 'POST_RE_SUBSCRIBE_USER_REQUEST';
const POST_RE_SUBSCRIBE_USER_REQUEST_SUCCESS = 'POST_RE_SUBSCRIBE_USER_REQUEST_SUCCESS';
const POST_RE_SUBSCRIBE_USER_REQUEST_FAILURE = 'POST_RE_SUBSCRIBE_USER_REQUEST_FAILURE';
const RESET_RE_SUBSCRIBE_USER_STATE = 'RESET_RE_SUBSCRIBE_USER_STATE'

const reSubscribeUser = (formData) => {

    return {
        type: POST_RE_SUBSCRIBE_USER_REQUEST,
        formData
    }
}
const resetReSubscribeUser = () => {

    return {
        type: RESET_RE_SUBSCRIBE_USER_STATE,

    }
}
export {
    reSubscribeUser,
    resetReSubscribeUser,
    RESET_RE_SUBSCRIBE_USER_STATE,
    POST_RE_SUBSCRIBE_USER_REQUEST,
    POST_RE_SUBSCRIBE_USER_REQUEST_SUCCESS,
    POST_RE_SUBSCRIBE_USER_REQUEST_FAILURE,
};