const POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST = 'POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST';
const POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST_SUCCESS = 'POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST_SUCCESS';
const POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST_FAILURE = 'POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST_FAILURE';
const RESET_CHANGE_INSTANT_PAYOUT_SETTING_STATE = 'RESET_CHANGE_INSTANT_PAYOUT_SETTING_STATE'

const changeInstantPayoutSetting = (formData) => {

    return {
        type: POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST,
        formData
    }
}
const resetChangeInstant = () => {

    return {
        type: RESET_CHANGE_INSTANT_PAYOUT_SETTING_STATE,
    }
}
export {
    changeInstantPayoutSetting,
    resetChangeInstant,
    RESET_CHANGE_INSTANT_PAYOUT_SETTING_STATE,
    POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST,
    POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST_SUCCESS,
    POST_CHANGE_INSTANT_PAYOUT_SETTING_REQUEST_FAILURE,
};