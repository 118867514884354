export default {
    app_name: 'U’GO Pros',
    version_col: "Version: ",
    facebook_app_id: '1464655393624471',
    facebook_client_token: 'f93f25880f86c822b0eb4da7147c18c6',
    fb_login_protocol_scheme: 'fb1464655393624471',
    Continue_with_Facebook: 'Continue with Facebook',
    Sign_in_with_Google: 'Sign in with Google',
    create_account: 'CREATE ACCOUNT',
    create_account_: 'CREATE ACCOUNT NOW',
    user_privacy: 'USER PRIVACY STATEMENT',
    terms_conditions: 'TERMS AND CONDITIONS',
    forgot_pass: 'FORGOT PASSWORD?',
    terms: 'TERMS AND CONDITIONS',
    validationErrors: 'Validation Errors',
    meeting_note: 'Note: Once estimate is accepted by you (user), this amount would then be taken from your payment method on file. You could cancel a project at any time, but once an estimate is accepted and you cancel project you will be charged 5% of that estimate for processing fees. At the end of the project the payment would then be adjusted according to the final cost.',
    bsb_number: 'Routing Number',
    account_number: 'Account Number',
    account_name: 'Account Name',
    txt_response_rate: 'You\'ve responded within 24 hours to 0 of the 0 booking request you received in the past year.',
    below_are_the_cities: 'Below are the cities you selected, wish to continue?\n\n',
    sunday_text: 'Sun',
    monday_text: 'Mon',
    tuesday_text: 'Tue',
    wednesday_text: 'Wed',
    thursday_text: 'Thu',
    friday_text: 'Fri',
    saturday_text: 'Sat',
    no_events: 'No events',
    call_failure: 'Failure!',
    Pay_through_Stripe: "Pay through Stripe",
    Pay_through_Google_Pay: "Pay through Google Pay",
    invalid_attribute: 'Failed to create Calendar,\n\n invalid value of Attributes!,\n Please review &amp; try again!',
    your_earnings: 'Your earnings are instantly available to you. No need to wait for a weekly payment.\n\nGet your earnings when you want them. Cash out with Instant Pay up to 5 times per day.\n ',
    reviews_detail: 'REVIEWS DETAIL',
    oops_enter_payout_method_: 'Payout method missing. Enter payout method, to get paid. Don\'t worry, your information is safe through our third party payment processing.',
    start_your_connection_with: 'Start Your Connection With',
    about_business: 'About Business',
    done: 'DONE',
    account_type: 'ACCOUNT TYPE',
    checking: 'Checking',
    saving: 'Saving',
    business_checking: 'Business Checking',
    save: 'SAVE',
    ach_payment: 'ACH PAYMENT',
    set_as_default: 'Set as default',
    feedback: 'FEEDBACK',
    tell_me_how_so_we_can_match_you_with_better_jobs: 'TELL ME HOW SO WE CAN MATCH YOU WITH BETTER JOBS.',
    i_am_busy: 'I am busy',
    wrong_area: 'Wrong area',
    not_my_type_of_work: 'Not my type of work',
    submit: 'SUBMIT',
    actions: 'ACTIONS',
    accept_amp_schedule: '<![CDATA[Accept & Schedule]]>',
    reject: 'REJECT',
    save_to_another: 'Save to Another',
    date: 'Date',
    request_details: 'REQUEST DETAILS',
    description: 'Description',
    media: 'Media',
    time: 'Time',
    start_job: 'START JOB',
    enter_final_cost_optional: 'Enter Final Cost (Optional)',
    upload_work_photo: 'Upload Work Photo',
    project_amount: 'Project Amount :',
    project_preview: "Project Preview",
    u_go_pros_fee: 'U\'GO Pros Fee :',
    u_go_pros_fee_structure: 'U\'GO Pros Fee Structure',
    you_will_receive: 'You will receive :',
    If_these_times_are_not_convenient: 'If these times are not convenient for you, feel free to chat with the user to find a better time that works for both of you.',
    _0: '$0',
    _10: '10%',
    submit_when_completed: 'SUBMIT WHEN COMPLETED',
    chat: 'CHAT',
    chat_with_pro: 'CHAT WITH PRO',
    chat_with_user: "CHAT WITH USER",
    uploaded_pics: 'Uploaded Pics',
    view_video: 'View Video',
    contractor_note: 'Contractor Note',
    contractor_uploaded_pics: 'Contractor Uploaded Pics',
    contractor_uploaded_photos: 'Contractor Uploaded Photos',
    contractor_uploaded_video: "Contractor Uploaded Video",
    location: 'Location',
    assign_job: 'ASSIGN JOB',
    change_employee: 'CHANGE EMPLOYEE',
    project_already_assign: "Project already assign to this employee",
    send_official_cost: 'SEND OFFICIAL COST',
    new_project: 'NEW PROJECT',
    add_document: 'Add Document',
    max_limit_1: '(Max limit 1)',
    next: 'NEXT',
    add_employee: 'ADD EMPLOYEE',
    this_information_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'This information will be save in few seconds and send to the user email provided in above form.',
    show_us_more_about_your_project: 'Show us more about your project',
    add_photo: 'Add Photo',
    max_limit_5: '(Max limit 5)',
    add_video: 'Add Video',
    add_work_video: 'Add Work Video',
    max_duration_30sec: 'Max video duration 30 sec',
    where_is_the_service_location: 'WHERE IS THE SERVICE LOCATION?',
    i_m_ready_for_quotes: 'I\'M READY FOR QUOTES',
    review: 'REVIEW',
    close: 'CLOSE',
    select_any_option: 'SELECT ANY OPTION',
    view_photo: 'VIEW PHOTO',
    view_photos: 'View Photos',
    already_pay_out: 'Already Pay Out',
    _0_usd: '$0 USD',
    expected_earnings: 'Expected Earnings',
    total: 'Total',
    _0_project: '0 Project',
    total_complete_project: 'Total Complete Project',
    total_active_project: 'Total Active Project',
    _0_response_rate: '0% Response Rate',
    response_rate: 'Response Rate',
    send: 'SEND',
    auto_services: 'AUTO SERVICES',
    project_details: 'PROJECT DETAILS',
    hourly_price: 'Hourly Price',
    n_a: 'N/A',
    portfolio: 'Portfolio',
    default_album: 'Default Album',
    recent_activities: 'Recent Activities',
    _15_online_bids_100_response_rate: '15 online bids, 100% response rate.',
    business_detail: 'Business Detail',
    add_review: 'Add Review',
    reviews: 'Reviews',
    select_your_service_category: 'Select your service category',
    change_password: 'CHANGE PASSWORD',
    change_password_mark: 'CHANGE PASSWORD?',
    please_enter_the_code_you_were_sent_by_email_and_reset_your_new_password: 'Please enter the code you were sent by email and reset your new password.',
    messages: 'MESSAGES',
    contact_info: 'Contact Info',
    project_info: 'Project Info',
    category: 'Category',
    view_document: 'View Document',
    budget_dolar: 'Budget $',
    min_budget: 'Min Budget $',
    max_budget: 'Max Budget $',
    assigning_detail: 'Assigning Detail',
    bid_detail: 'Bid Detail',
    notes: 'NOTES',
    contact_us: 'Contact Us',
    send_us_an_email: 'SEND US AN EMAIL',
    let_us_know_what_you_think_don_t_hesitate_to_send_us_questions_or_comments_we_value_your_thoughts_and_will_respond_in_a_timely_manner: 'Let us know what you think! Don\'t hesitate to send us questions or comments. We value your thoughts, and will respond in a timely manner!',
    bonded_insured: 'Bonded/Insured',
    accredited: 'Accredited',
    nari_member: 'NARI member',
    service_area: 'Service area',
    speciality_services: 'Speciality Services',
    categories: 'Categories',
    add_utility_bill: 'Add Utility Bill',
    portfolio_optional: 'Portfolio (Optional)',
    by_creating_account_you_agree_to_our: 'By creating account you agree to our',
    register: 'REGISTER',
    create_profile: 'CREATE PROFILE',
    date_of_birth: 'Date of birth',
    credits: 'CREDITS',
    u_go_reward_credits: 'U’GO REWARD CREDITS',
    friend_referral: 'Friend Referral',
    rate_contractor: 'Rate Contractor',
    _20_gift_card: '$20 gift card',
    _20_instantly_for_your_friend_and_20_for_you_once_their_project_completed: '$20 instantly for your friend and $20 for you once their project completed',
    redeem_credits: 'Redeem Credits',
    add: 'ADD',
    default_payment: 'Default Payment',
    documents: 'DOCUMENTS',
    edit_profile: 'Edit Profile',
    add_view: 'Add / View',
    service: 'Service',
    state: 'State',
    city: 'City',
    update: 'UPDATE',
    employee: 'EMPLOYEE',
    record_not_found: 'Record not found',
    not_found: 'Not found',
    employee_info: 'EMPLOYEE INFO',
    mobile_no: 'Mobile No',
    email: 'Email',
    address: 'Address',
    delete: 'Delete',
    tell_us_more_about_your_project: 'Tell us more about your project',
    project_description: 'Project Description',
    what_is_your_budget: 'What is your budget?',
    what_is_your_contact_info: 'WHAT IS YOUR CONTACT INFO?',
    what_is_your_availability: 'What is your availability?',
    _1st_option_date: '1st Option Date',
    _2nd_option_date: '2nd Option Date',
    estimates: 'ESTIMATES',
    your_available_time: 'YOUR AVAILABLE TIME',
    best_case_scenario: 'BEST CASE SCENARIO',
    worst_case_scenario: 'WORST CASE SCENARIO',
    exclusive_services: 'EXCLUSIVE SERVICES',
    forgot_password: 'FORGOT PASSWORD',
    forgot_password_mark: 'FORGOT PASSWORD?',
    enter_your_email_below_to_receive_your_password_reset_instructions: 'Enter your email below to receive your password reset instructions.',
    send_password: 'SEND PASSWORD',
    friends_referral: 'FRIENDS REFERRAL',
    enter_friend_email_below_to_refer_your_friend: 'Enter friend email below to refer your friend.',
    _20_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_home_user_n_n_50_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_pro_user: '$20 instantly for your friend their first project completion if the referred friend is Home User.\n$50 instantly for your friend their first project completion if the referred friend is Pro User.',
    help: 'Help',
    faq: 'FAQ',
    send_feedback: 'SEND FEEDBACK',
    home_services: 'HOME SERVICES',
    status: 'Status',
    final_amount: 'Final Amount',
    note: 'Note',
    login: 'LOGIN',
    user: 'USER',
    remember_me: 'Remember me?',
    connect_with_facebook: 'CONNECT WITH FACEBOOK',
    connect_with_google: 'CONNECT WITH GOOGLE',
    pro: 'PRO',
    map: 'MAP',
    time_availability: 'Time Availability',
    accept: 'ACCEPT',
    budget: 'Budget',
    click_to_request_on_site_visit: 'Click to request on site visit',
    action: 'Action',
    i_am_not_interested: 'I am not interested',
    i_am_interested: 'I AM INTERESTED',
    my_rewards: 'MY REWARDS',
    official_cost: 'OFFICIAL COST',
    your_official_price_can_be_set_by_you_when_you_arrive_before_project_starts_from_here_client_can_still_refuse_service_it_is_recommended_to_stay_within_the_range_you_set: 'Your official price can be set by you when you arrive before project starts. From here client can still refuse service. It is recommended to stay within the range you set.',
    on_site_visit_requests: 'ON-SITE VISIT REQUESTS',
    pay_with_card: 'PAY WITH CARD',
    paypal: 'PAYPAL',
    free_transaction: 'Free Transaction',
    no_minimum_transfer: 'No Minimum Transfer',
    delete_project: 'DELETE PROJECT',
    user_privacy_statement: 'USER PRIVACY STATEMENT',
    credit_card: 'Credit Card',
    bank_transfer: 'Bank Transfer',
    country: 'Country',
    usa: 'USA',
    currency: 'Currency',
    usd: 'USD',
    add_payout: 'Add Payout',
    schedules: 'SCHEDULES',
    pro_detail: 'PRO DETAIL',
    payout_detail: 'Payout Detail',
    account_info: 'Account Info',
    instant_payment: 'Instant Payment',
    routing_number: 'Routing Number',
    auto_renew_your_subscription: 'Auto renew your subscription',
    profile: 'PROFILE',
    projects: 'Projects',
    reward: 'REWARD',
    credits_points: 'Credits Points',
    enter_Pin: 'Enter Pin',
    view_detail: 'View Detail',
    cancel_project: 'CANCEL PROJECT',
    edit_project: 'EDIT PROJECT',
    reschedule_project: 'RESCHEDULE PROJECT',
    my_project: 'MY PROJECT',
    you_have_not_completed_any_project_yet: 'You have not completed any project yet',
    speciality_services_pro: 'Speciality Services Pro',
    available_time: 'Available Time',
    decline: 'DECLINE',
    min_budget_normal: 'Min Budget',
    max_budget_normal: 'Max Budget',
    review_detail: 'Review Detail',
    use_amount_from_credits: 'Use amount from credits',
    your_availability_time_preference: 'YOUR AVAILABILITY - Time Preference',
    specialty_service_partners: 'Specialty Service Partners',
    special_pro_detail: 'SPECIAL PRO DETAIL',
    email_col: 'Email:',
    mobile_no_col: 'Mobile No:',
    service_col: 'Service:',
    state_col: 'State:',
    cities_col: 'Cities:',
    detail: 'DETAIL',
    request_info: 'Request Info',
    phone: 'Phone',
    select_cities_you_offer_your_services: 'Select cities you offer your services',
    specialty_service: 'Specialty Service',
    connecting_consumers_with: 'Connecting consumers with',
    highly_skilled_industry_professionals_in_their_communities: 'highly skilled industry professionals in their communities.',
    are_you_looking_to_have_a_project_completed: 'Are you looking to have a project completed',
    or: 'or',
    sign_up_as_an_industry_professional: 'sign up as an industry professional?',
    select_all_categories_below: 'Select all categories below',
    employee_contact_info: 'Employee Contact Info',
    name: 'Name',
    company: 'Company',
    min_bid_amount: 'Min Bid Amount',
    max_bid_amount: 'Max Bid Amount',
    confirm_project: 'Confirm Project',
    send_issue: 'SEND ISSUE',
    accept_official_cost: 'ACCEPT OFFICIAL COST',
    reject_official_cost: 'REJECT OFFICIAL COST',
    subscription: 'SUBSCRIPTION',
    cancel: 'CANCEL',
    terms_and_conditions: 'TERMS AND CONDITIONS',
    skip: 'SKIP',
    signup: 'Signup',
    edit_info: 'EDIT INFO',
    this_inform_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'This inform will be save in few seconds and send to the user email provided in above form.',
    upload_pictures: 'UPLOAD PICTURES',
    upload: 'UPLOAD',
    video: 'VIDEO',
    wire: 'WIRE',
    _20_transaction_fee: '$20 Transaction fee.',
    minimum_transaction_250: 'Minimum transaction $250',
    account_holder: 'Account Holder',
    bank_address: 'Bank Address',
    withdraw: 'WITHDRAW',
    available_balance: 'AVAILABLE BALANCE',
    withdraw_amount: 'Withdraw Amount',
    services_offered: 'SERVICES OFFERED',
    become_a_pro: 'BECOME A PRO',
    add_event: 'Add Event',
    get_free_quotes: 'GET FREE QUOTES',
    tell_us_about_the_project_you_would_like_completed_and_we_will_connect_you_with_several_industry_professionals_in_your_community_you_can_then_read_through_their_reviews_and_select_the_professional_to_work_on_your_project: 'Tell us about the project you would like completed and we will connect you with several industry professionals in your community. You can then read through their reviews and select the professional to work on your project.',
    use_instant_request: 'USE INSTANT REQUEST',
    in_a_hurry_or_have_an_emergency_project_tell_us_more_about_the_project_and_we_will_quickly_connect_you_to_the_first_available_professional_usually_within_2_hours: 'In a hurry or have an emergency project? Tell us more about the project and we will quickly connect you to the first available professional (usually within 2 hours).',
    select_this_option_for_a_directory_list_of_our_specialty_professional_partners_in_your_city_find_your_lawyers_realtors_insurance_agents_doctors_etc: 'Select this option for a directory list of our specialty professional partners in your city. Find your Lawyers, Realtors, Insurance agents, Doctors etc.',
    instant_requests: 'INSTANT REQUESTS',
    leads: 'LEADS',
    invoices: 'INVOICES',
    settings: 'SETTINGS',
    downloading: 'Downloading...',
    select_cities: 'Select Cities',
    are_you_sure_you_want_to_move_back: 'Are you sure you want to move back.',
    you_will_lose_your_progress: 'You will lose your progress.',
    no: 'NO',
    yes: 'YES',
    your_account_is_under_review_please_allow_24_28: 'Your account is under review. Please allow 24-28 hours to receive a confirmation email. Contact support@ugopros.com with any account questions.',
    payout_detail_confirmation: 'Payout Detail Confirmation',
    please_confirm_all_info_entered_is_correct_we_want_to_make_sure_you_get_paid_for_the_projects_you_complete: 'Please confirm all info entered is correct. We want to make sure you get paid for the projects you complete.',
    rate_user: 'Rate User',
    _20_instantly_for_you_and_your_friend_on_first_project_completion_if_the_referred_friend_is_home_user_n_50_instantly_for_you_on_first_project_completion_if_the_referred_friend_is_pro_user: '$20 instantly for you and your friend on first project completion if the referred friend is Home User. \n$50 instantly for you on first project completion if the referred friend is Pro User.',
    add_credit_for_the_bigger_jobs_pay_now_for_jobs_later: 'Add credit for the bigger jobs, pay now for jobs later.',
    add_credits: 'ADD CREDITS',
    no_default_card: 'NO Default Card',
    add_payment_method: 'Add Payment Method',
    payment_method: 'Payment Method',
    payment_history: 'Payment History',
    add_credit_card: 'Add Credit Card',
    add_credit_gift_code: 'Add Credit/Gift Code',
    apply: 'APPLY',
    payment_defaults: 'Payment Defaults',
    set_payment_default: 'Set Default Payment',
    default_payment_method: 'Default Payment Method',
    amount: 'Amount',
    view: 'View',
    revenue_detail: 'REVENUE DETAIL',
    total_revenue: 'Total Revenue',
    total_withdraw: 'Total WITHDRAW',
    payable_balance: 'Payable Balance',
    pending_withdraw: 'Pending Withdraw',
    new_: 'NEW',
    active: 'ACTIVE',
    completed: 'COMPLETED',
    notification: 'Notification',
    message: 'Message',
    write_your_message: 'Write your message',
    sign_out: 'Sign Out',
    my_projects: 'MY PROJECTS',
    site_visit_request: 'SITE VISIT REQUEST',
    archived: 'ARCHIVED',
    canceled: 'CANCELED',
    consumer_benefits: 'Consumer Benefits',
    normal: 'NORMAL',
    specialty: 'SPECIALTY',
    invoiced: 'INVOICED',
    paid: 'PAID',
    enter_your_issue_detail: 'Enter your issue detail',
    add_new_event: 'Add new event',
    tap_on_date_to_view_schedule: 'Tap on date to view schedule.',
    my_earnings: 'My Earnings',
    withdraw_history: 'Withdraw History',
    waiting_for_response: 'Waiting for response',
    confirm: 'CONFIRM',
    would_you_like_to_create_a: 'Would you like to create a',
    Pin: 'pin?',
    enter_pin: 'ENTER PIN',
    enter_password: 'ENTER PASSWORD',
    use_different_account: 'USE DIFFERENT ACCOUNT',
    u_go_pros_inc: 'U\'GO Pros Inc',
    pay: 'PAY',
    next_payment_schedule: 'Next Payment Schedule',
    credit: 'CREDIT',
    debit: 'DEBIT',
    continue_: 'CONTINUE',
    schedule: 'SCHEDULE',
    payment: 'PAYMENT',
    filter: 'Filter',
    end_date: 'End Date',
    change_pin: 'Change Pin',
    notifications: 'Notifications',
    pin: 'Pin',
    online_status: 'Online Status',
    email_message: 'Email Message',
    add_edit: 'ADD/EDIT',
    selected_categories: 'SELECTED CATEGORIES',
    dismiss: 'DISMISS',
    add_more: 'ADD MORE',
    project: 'PROJECT',
    earning: 'EARNING',
    withdraw_status: 'WITHDRAW STATUS',
    project_leads: 'PROJECT LEADS',
    offline_mode: 'OFFLINE MODE',
    ugo_to: 'Up to $',
    pay_dol: 'Pay $',
    are_you_sure_you_want_to_credit: 'Are you sure you want to credit $',
    are_you_sure_you_want_to_delete: 'Are you sure you want to delete this project from list?',
    logout: 'Logout',
    complete: 'Complete',
    please_complete_your_profile: 'Please complete your profile.',
    ready_for_quotes: 'Ready for Quotes',
    estimates_received: 'Estimates Received',
    pending_for_official_cost: 'Pending for Official Cost',
    project_started: 'Project Started',
    please_confirm_project: 'Please Confirm Project',
    successfully_completed: 'Successfully Completed',
    before_payment: 'Before Payment',
    after_payment: 'After Payment',
    issue_reported: 'Issue Reported',
    refunded_by_admin: 'Refunded By Admin',
    refund: 'REFUND',
    official_cost_recieved: 'Official Cost Received',
    waiting_for_pro_to_start: 'Waiting for Pro to Start',
    please_wait: 'Please Wait...',
    waiting_for_pros_response: 'Waiting For Pros Response',
    started_by_pro: 'Started By Pro',
    need_for_rechedule: 'Need For Reschedule',
    project_canceled: 'Project Canceled',
    create_your_pin: 'Create Your Pin',
    please_select_what_you_want_to_do: 'Please select what you want to do',
    remove_change_pin: 'Remove/Change the Pin?',
    remove_pin: 'Remove Pin',
    successfully_scheduled: 'Successfully Scheduled',
    project_accepted: 'Project Accepted',
    accepted_by_pro: 'Accepted By Pro',
    image_attched: 'Image Attached',
    video_attached: 'Video Attached',
    valid_till: 'Valid Till : ',
    rejected: 'Rejected',
    confirmed: 'Confirmed',
    are_you_sure_you_want_to_reject: 'Are you sure you want reject On-Site Visit request by',
    are_you_sure_you_want_confirmed: 'Are you sure you want confirmed On-Site Visit request by',
    attached: '\" Attached\"',
    cannot_upload_file_to_server: 'Cannot upload file to server',
    update_review: 'UPDATE REVIEW',
    response_rate_per: '% Response rate',
    add_debit_card: 'Add Debit Card',
    add_payout_method: 'Add Payout Method',
    update_video: 'Update Video',
    enter_friend_email_user: 'Enter friend email below to refer your friend as User.',
    enter_friend_email_pro: 'Enter friend email below to refer your friend as Pro.',
    credit_dol: 'Credits: $',
    free: 'Free',
    are_you_sure_you_want_delete: 'Are you sure you want to Delete Project?',
    are_you_sure_too_cancel: 'Are you sure you want to cancel this project?',
    rating: 'Rating',
    total_credits: 'Total Credits:',
    cities: 'Cities',
    the_amount_you_have_submitted: 'The amount you have submitted ($',
    if_you_agree_to_the_official: ') is less than the official cost. If you agree to the official cost please press pay option otherwise reject OR contact us if there are any problems.',
    refund_dol: 'REFUND $',
    the_amount_you_have: 'The amount you have submitted $',
    otherwise_rejected_or_contact_us: '\" is greater than the official cost. If you agree press the REFUND option, otherwise reject OR contact us if you have any questions.\"',
    if_there_are_any_problems: 'The amount you have submitted is equal to the official cost of project. If everything is ok please confirm below, otherwise contact us if there are any problems.',
    are_you_sure_you_want_cancel: 'Are you sure you want to Cancel Project?',
    if_you_submit_you_will_refund: 'If you submit you will be charged a 5% processing fee from your refund.',
    are_you_sure_you_want_to_confirm: 'Are you sure you want to Confirm Project?',
    pro_will_be_paid_for_this: 'Pro will be paid for this.',
    pending: 'Pending',
    accepted: 'Accepted',
    referral_code: 'Referral Code',
    expiry_col: 'Expiry:',
    next_payment_schedule_col: 'Next Payment Schedule: ',
    status_active: 'Status: Active',
    status_cancelled: 'Status: Cancelled',
    update_payout: 'Update Payout',
    please_add_it_by_pressing: 'You have not added a payout method yet. Please add it by pressing \'ADD PAYOUT\' button in bottom.',
    onsite_visit_request_sent: 'On-Site Visit Request Sent',
    onsite_visit_request_rejected: 'On-Site Visit Request Rejected',
    onsite_visit_scheduled: 'On-Site Visit Scheduled',
    waiting_for_acceptance: 'Waiting for Acceptance',
    qoute_and_onsite_visit: 'Quote and On-Site visit request sent',
    qoute_sent: 'Quote Sent',
    waiting_for_official_cost: 'Waiting for Official Cost',
    assigned_to_employee: 'Assigned to Employee',
    rejected_by_employee: 'Rejected by Employee',
    job_started: 'Job Started',
    waiting_for_confirmation: 'Waiting for Confirmation',
    official_cost_sent: 'Official Cost Sent',
    project_awarded: 'Project Awarded',
    in_progress: 'In Progress',
    closed: 'Closed',
    bid_amount: 'Bid Amount : $',
    exit: 'EXIT',
    enter_old_pin: 'Enter Old Pin',
    confirm_pin: 'Confirm Pin',
    back: 'BACK',
    wrong_pin_keyboard: 'Wrong PIN. Keypad Locked',
    enter_new_pin: 'Enter New Pin',
    wrong_pin_locked: 'Wrong PIN. Keypad Locked',
    user_guide: 'USER GUIDE',
    job_no: 'Job #',
    project_col: 'Project :',
    posted_by_col: 'Posted by : ',
    online_mode: 'ONLINE MODE',
    are_you_sure_accept: 'Are you sure you want Accept',
    are_you_sure_want_reject: 'Are you sure you want Reject',
    assign_to_col: 'Assign to: ',
    status_assigned: 'Status : Assigned',
    status_rejected: 'Status : Rejected',
    status_waiting: 'Status : Waiting for Confirmation',
    final_amount_col: 'Final Amount : $',
    issue_detail: 'Issue Detail',
    official_cost_dol: 'Official Cost : $',
    project_title_col: 'Project Title: ',
    photos_attached: 'Photos Attached',
    video_attached: 'Video Attached',
    document_attached: 'Document Attached',
    please_confirm_final_amount: 'Please confirm final amount of $',
    once_you_tap_confirm_you_will: '. Once you tap “confirm” you will be unable change final cost',
    employee_note: 'Employee Note',
    employee_upload_pics: 'Employee Uploaded Pics',
    official_document: 'Official Document',
    are_you_sure_you_want_to_delete_emp: 'Are you sure you want to delete this employee?',
    employee_since: 'Employee since ',
    are_you_sure_to_assign: 'Are you sure you want Assign Project to',
    i_am_intrested: 'Are you sure you want to pay $10 towards On Site Visit? That fee will be refunded only if the client accepts your bid. \n\nIf you would prefer to send estimate for free without the on site visit then tap cancel and select “I AM INTERESTED” on bottom of screen.',
    pay_again: 'Pay Again',
    please_recharge_your_account: 'Your On-Site Visit request was rejected due to insufficient balance in your account. Please recharge your account and try it again.',
    your_on_site_visit: 'Your On-Site Visit request was rejected and you will not be charged $10.',
    budget_dol: 'Budget : $',
    please_confirm_if_you_will: 'INSTANT REQUESTS Users are looking to have project started within 2 hours.  Please “Confirm” if you will be available',
    debit_card: 'Debit Card',
    resubscribe: 'Resubscribe',
    project_no: 'Project# ',
    i_am_ready: 'I\'M READY TO CONNECT WITH A PRO',
    edit: 'EDIT',
    language: 'Language',
    english: 'English',
    spanish: 'Spanish',
    tamil: 'Tamil',
    hindi: 'Hindi',
    arabic: 'Arabic',
    contractor_notes_n_if_project: 'Contractor Notes\n(If project is not completed just exit out when you\'re done then resume job again later)',
    first_name: 'First Name',
    last_name: 'Last Name',
    password: 'Password',
    confirm_password: 'Confirm Password',
    confirm_password_optional: 'Confirm Password (Optional)',
    verification_code: 'VERIFICATION CODE',
    verify_pin: 'Verify Pin',
    new_password: 'NEW PASSWORD',
    your_name: 'Your Name',
    contact_person_first_name: 'Contact Person First Name',
    contact_person_last_name: 'Contact Person Last Name',
    company_name: 'Company Name',
    company_ein_tax_id: 'Company EIN / Tax ID',
    licence_no_optional: 'Licence No (Optional)',
    tap_to_create_project: 'TAP to Create Project',
    home_auto_or_exclusive_services: 'HOME, AUTO OR EXCLUSIVE SERVICES',
    friend_pro_referral: 'Friend/Pro Referral',
    enter_title: 'Enter Title',
    account_iban: 'Account/ IBAN',
    swift_bic_code: 'Swift/ Bic code',
    new_password_optional: 'New Password (Optional)',
    reach_out_directly_with_this: 'Reach out directly with this pro by chatting here. Just start typing in this box and confirm when done',
    company_phone_number: 'Company Phone Number',
    paypal_account: 'Paypal Account',
    card_number: 'Card Number',
    enter_project_description: 'Enter Project Description',
    please_enter_project_description: 'Please Enter Project Description',
    please_provide_information: 'Please provide information about previous projects and your company. Include any questions you may have.',
    hourly_estimate_optional: 'Hourly Estimate $ (Optional)',
    total_min_estimate_best_case_scenario: 'Total Min Estimate $ (Best Case Scenario)',
    total_max_estimate_worst_case_scenario: 'Total Max Estimate $ (Worst Case Scenario)',
    please_name_your_project: 'Please name your project',
    social_security_number: 'Social Security Number',
    years_in_business: 'Years in business',
    old_password_optional: 'Old Password (Optional)',
    other_category_optional: 'Other Category (Optional)',
    wrong_email_password: 'Wrong Email/Password',
    please_enter_your_email: 'Please Enter Your Email',
    please_enter_your_name: 'Please Enter Your Name',
    please_enter_your_project_name: 'Please Enter Your Project Name',
    please_enter_discription: 'Please Enter Project Description',
    please_enter_your_min: 'Please Enter Your Min Budget $',
    please_enter_your_max_budget: 'Please Enter Your Max Budget $',
    please_enter_your_valid_min: 'Please Enter Your Valid Min Budget $',
    please_enter_your_max_valid_budget: 'Please Enter Your Valid Max Budget $',
    min_budget_should_less_than_max: 'Min Budget Must Less Than Max Budget',
    please_enter_your_budget: 'Please Enter Your Budget $',
    please_enter_your_budget_at_least_1: 'Please Enter Your Budget At least $1',
    please_select_date_before_time: 'Please select date before set time',
    no_user_found: 'No User Found',
    please_enter_your_message: 'Please Enter Your Message',
    please_enter_valid_email: 'Please Enter Valid Email',
    please_enter_your_card_no: 'Please Enter Your Card Number',
    please_enter_cvc: 'Please Enter Your CVC',
    please_select_future_date: 'Please select future date',
    pics_not_available: 'Pics Not Available!',
    document_not_available: 'Document Not Available!',
    video_not_available: 'Video Not Available!',
    please_enter_official_cost: 'Please add official cost.',
    please_add_official_cost: 'Please add official cost at least',
    please_enter_your_password: 'Please enter you password',
    password_dose_not_matched: 'Password dose not match!',
    please_enter_your_first_name: 'Please Enter Your First Name',
    please_enter_your_last_name: 'Please Enter Your Last Name',
    please_enter_your_phone: 'Please Enter Your Phone',
    please_enter_password: 'Please Enter Your Password',
    password_must_contain_a_minimum: 'Password must contain a minimum of 8 characters',
    pin_successfully_change: 'Pin Successfully Change',
    please_enter_your_confirm_password: 'Please Enter Your Confirm Password',
    please_enter_your_address: 'Please Enter Your Address',
    phone_length_10: 'Phone# length should be 10',
    please_enter_contact_first_name: 'Please Enter Contact Person First Name',
    please_enter_contact_lastname: 'Please Enter Contact Person Last Name',
    password_dose_not_match: 'Confirm Password does not match',
    please_enter_dob: 'Please enter date of birth.',
    please_enter_company_name: 'Please Enter Company Name',
    please_enter_mobile_number: 'Please Enter Mobile No',
    please_enter_address: 'Please Enter Address',
    please_enter_contractor_notes: 'Please Enter Contractor Notes',
    please_enter_at_least_one_work_photo: 'Please upload at least one work photo',
    you_must_be_18: 'You must be 18 yrs of age or older to use our services.  If you have any issues please contact us at support@ugopros.com',
    please_agree_to_our: 'Please Agree to our TERMS AND CONDITIONS',
    please_select_your_available: 'Please Select Your Available Time',
    please_enter_issue_detail: 'Please enter issue detail',
    please_add_profile_photo: 'Please Add Profile Photo',
    please_set_your_loaction: 'Please Set Your Location',
    please_select_address: 'Please Select Address',
    please_select_address_from: 'Please Select Address from drop down list',
    please_add_availability_date: 'Please Add Availability Date',
    please_add_available_time: 'Please Add Availability Time',
    enter_address_or_single_press_on_map: 'Enter Address or Single Press on Map',
    type_to_search: 'Type to Search',
    _3rd_option_date: '3rd Option Date',
    you_cannot_select_past_date: 'You cannot select past date',
    thank_you_for_uploading_document: 'Thank you for uploading document. Please wait for review to have account activated.',
    reason: 'Reason',
    enter_amount: 'Enter Amount',
    please_enter_amount: 'Please enter amount!',
    home: 'HOME',
    track_earning: 'TRACK EARNING',
    manage_employees: 'MANAGE EMPLOYEES',
    payout_method: 'PAYOUT METHOD',
    payments: 'PAYMENTS',
    offers: 'OFFERS',
    special_requests: 'SPECIAL REQUESTS',
    first_project_completion: '$20 Frst project completion if the referred friend is Home User. \n$50 instantly for you on first project completion if the referred friend is Pro User.',
    confirmation: 'Confirmation',
    refer_a_pro: 'Refer a Pro',
    refer_your_friend: 'Refer your Friend',
    loading_payment_method: 'Loading Payment Methods ...',
    enabling_instant_payment: 'Enabling Instant Payment Methods ...',
    loading_data: 'Loading Data...',
    loading_payment_detail: 'Loading Payout Details ...',
    it_will_take_some_time: 'It will take some time....',
    sending: 'Sending...',
    updating: 'Updating...',
    setting_default_payment_method: 'Setting Default Payment Method ...',
    compressing_the_size: 'Compressing the size of video.',
    uploading_data: 'Uploading data',
    your_account_suspended: 'Your account has been suspended because your company insurance has expired. Please upload new insurance information.',
    profile_incomplete: 'Profile Incomplete',
    please_complete_profile: 'Please complete your profile',
    please_enter_your_date_of_birth: 'Please enter your date of birth for activation of your account.',
    please_enter_account_holder_name: 'Please enter your account holder name.',
    please_enter_your_routing_number: 'Please enter your routing number',
    please_enter_your_account_number: 'Please enter your Account Number',
    sesson_has_been_expire: 'Your Session has been Expired please Sign in again',
    internet_connection_issue: 'Internet connection Problem.Please try again later',
    please_check_your_email_for_our: 'Please check your email for our response. We value your feedback. You should hear back from us within 24hrs.',
    account_has_been_updated: 'Account has been updated successfully. Please wait for review to have account activated.',
    project_has_been_completed: 'Project has been completed please wait for confirmation from user.',
    you_have_not_added_a_payout: 'You have not added a payout method yet. Please add it first.',
    information: 'Information!',
    if_profile_needs_updated: 'If profile needs updated please feel free to do so.',
    please_add_your_credit: 'Please add your credit card first.',
    for_sercurity_and_payout: 'For security and payout features more information is required.',
    update_app: 'UPDATE APP',
    not_now: 'NOT NOW',
    your_official_price_can_be: 'Your official price can be set by you when you arrive before project starts. From here client can still refuse service. It is recommended to stay within the range you set.',
    thank_you_for_your_payment: 'Thank you for your payment. Now you can go for on site visit.',
    request_sent_successfully: 'Request sent successfully. You will be notified when client accepts your On-Site Visit request.',
    credit_card_is_valid: 'Credit Card Is Invalid',
    are_your_sure_you_want_to_delete: 'Are you sure you want to delete this card?',
    would_you_like_to_auto: 'Would you like to auto renew your subscription',
    please_select_your_subscription: 'Please select your subscription.',
    alert: 'Alert!',
    we_hate_to: 'We hate to see you go, if you have any issues feel free to reach out to us at 877-733-2425 or info@ugopros.com cancelling your subscription will suspend your account at the end of your billing cycle.',
    yes_cancel: 'YES cancel',
    no_i_will_stay: 'NO I\'ll stay',
    you_have_not_added: 'You have\'t added your debit card yet.',
    please_select_at_least: 'Please select at least one sub category.',
    you_have_selected_to: 'You have selected to turn on auto payment. Confirm if you wish to continue?',
    turn_off: 'TURN OFF',
    are_you_sure_you_want_to_delete_this: 'Are you sure you want to delete this payout method?',
    if_project_has_not: 'If project hasn\'t been confirmed within 14 days your ESTIMATED total will be released to your account.',
    video_lenght_should_30: 'Video length should be Max 30 Sec.',
    you_can_not_uodalog_5: 'You can\'t upload more than 5 photo.',
    please_enter_date_of_birth: 'Please enter date of birth for activation of your account.',
    congratulation_your_account: 'Congratulation! You account is activated now..',
    not_availeable: 'Not available. For more detail contact us.',
    please_enter_account_iban: 'Please Enter Account/ IBAN',
    please_enter_swift: 'Please Enter Swift/ Bic code',
    please_enter_aba: 'Please Enter ABA/ Routing Number',
    please_enter_account_holder: 'Please Enter Account Holder Name',
    media_not_available: 'MediaActivity Not Available!',
    please_enter_aba_routing: 'Please Enter ABA/Routing Number',
    please_enter_bank_account_number: 'Please Enter Bank Account Number',
    please_enter_back_name: 'Please Enter Bank Name',
    please_add_account: 'Please Add Account Type',
    please_enter_bank_address: 'Please Enter Bank Address',
    instant_request: 'INSTANT REQUEST',
    free_online_leads: 'Free Online Leads',
    manage_all_your: '• Manage all your employees or Teams\n',
    here_you_will_see: 'Here you will see new project leads so you can send free quotes.',
    here_you_are_able_to_chat: 'Here you are able to chat with your clients keeping you on the same page throughout the project.',
    we_are_always_here: 'We are always here for you. We value your thoughts. Here you can share your ideas or concerns with us.',
    here_is_you_able_to_chat: 'Here you are able to chat with your clients keeping you on the same page throughout the project.',
    we_are_always_here_for_you: 'We are always here for you. We value your thoughts. Here you can share your ideas or concerns with us.',
    here_you_can_make: 'Here you can make yourself available for the more urgent requests. Online mode option says that you will be available to start projects within 2 hours of the request.',
    there_is_nothing: 'There\'s nothing crazier than double booking. Here you can maintain your schedule so you know what\'s ahead. Schedule your projects accurately with this feature.',
    why_not_grow: 'Why not grow your business to have many employees or teams? Here you can manage your staff so you can assign the projects you are awarded!',
    back_button: 'Back button',
    loagin_button: 'Login Button',
    here_is_where_you_see: 'Here is where you see your available credit. Not only can you EARN credits, but you could also ADD credits to pay towards those more costly projects.',
    we_are_here_to_help: 'We are here to help you manage your projects. Whether you have a urgent request or a simple project, this is where you begin the journey to find a professional. Many will send a quote, but you choose who will complete the task.',
    here_you_will_see_the_status: 'Here you will see the status of the projects you have entered. You will see if a project had a quote sent, or if project was started. We walk with you until you are completely satisfied with your finished project.',
    this_is_where_you: 'This is where you would check the status on those more urgent projects. So if there is an emergency in most cases we have Pros available to help within 2hrs of requests.',
    here_you_are_able: 'Here you are able to chat with your service professional keeping you on the same page throughout the project completion.',
    we_are_always_here_for: 'We are always here for you. We value your thoughts. Here you can share your ideas or concerns with us.',
    send_free_qoutes: '• Send free quotes',
    grow_your_business: '• Grow your business',
    manage_your_employee: '• Manage your employees',
    track_clients: '• Track clients, jobs, and invoices',
    utilize_the_free: '• Utilize the free CRM software',
    connect_directly_with: '• Connect directly with consumers about projects',
    select_your_services: '• Select your service location',
    no_annual_fee: '• No annual fee',
    industry_professional: 'Industry Professional Benefits',
    connect_with_a_vetted_pro: '• Connect with a vetted pro in your community',
    recieve_multiple_qoute: '• Receive multiple quotes for projects',
    read_reviews_of_pros: '• Read reviews of pros and compare',
    manage_all_projects: '• Manage all projects in the app',
    communicate_with_your: '• Communicate with your service provider',
    send_payment_once_project: '• Send payment once project is completed',
    refer_a_friend_and: '• Refer a friend and receive account credit',
    provide_accurate_qoute: '• Provide accurate quotes based on pictures and chat',
    exposure_for_your: '• Exposure for your company',
    live_mode_for_instant: '• Live mode for instant/ urgent or schedule projects',
    choose_the_jobs: '• Choose the jobs you want',
    crm_platform_for_great: '• CRM platform for great customer satisfaction',
    professional_chat_funtion: '• Professional chat function',
    keep_track_of_all: '• Keep track of all your projects and invoices',
    free_business_tools: 'Free Business Tools',
    please_enter_your_project: 'Please enter your project description to continue with chat.',
    ok: 'OK',
    jobs: 'Jobs',
    requests: 'Requests',
    got_it: 'Got it',
    please_enter_ein: 'Please Enter EIN/Tax ID',
    please_enter_social_number: 'Please Enter Social Security Number.',
    please_enter_correct_ssn: 'Please enter correct Social Security Number.',
    please_check_the_box: 'Please check the box for Bond/insurance and show proof of Bond/insurance',
    please_check_the_box_only: 'Please check the box for Bond/insurance',
    please_show_proof_of_bond: 'Please show proof of Bond/insurance',
    please_select_bounded: 'Please Select Bonded/Insured',
    please_enter_about_business: 'Please Enter About Business',
    please_enter_about_business: 'Please Enter About Business',
    please_add_identity_document: 'please add Identity Document',
    please_enter_old_password: 'Please Enter Old password',
    please_enter_your_correct: 'Please Enter Correct Location',
    please_add_service_area: 'Please Add Service Area',
    please_add_categories: 'Please Add Categories',
    please_enter_year_of: 'Please Enter Years in business',
    pleaes_check_the_box_for_ar: 'Please check the box for Accredited',
    please_show_proof_of_Accredited: 'Please show proof of Accredited',

    please_check_the_box_nari: 'Please check the box for NARI member',
    please_show_proof_of_nari: 'Please show proof of NARI member',

    please_enter_company_ein: 'Please Enter Company EIN / Tax ID',
    please_add_company_ein_id: 'Please Add Company EIN/Tax ID Photo',
    tell_us_about_the_project_using_chat_picture_and_or_videos: 'Tell us about the project using chat,picture and/or videos.',
    obtain_multiple_quotes_from_ready_and_available_pros: 'Obtain multiple quotes from ready and available pros.',
    book_your_fully_vetted_pro_for_same_day_repair_or_schedule_them_at_your_convenience: 'Book your fully vetted PRO for same day repair or schedule them at your convenience.',
    schedule_pay_and_rate_your_pro_while_keeping_track_of_all_your_jobs_with_the_u_go_pros_app: 'Schedule,pay,and rate your pro while keeping track of all your jobs with the U\'GO Pros app.',
    you_will_be_charged: 'You will be charged the best case scenario of $',
    before_pro_starts_job: '! Before pro starts job, you will be notified of the actual cost once they evaluate. From here you can refuse or accept',
    please_enter_title_of_your_event: 'Please enter title of your event.',
    please_enter_detail_of_your_event: 'Please enter detail of your event.',
    please_select_time_of_your_event: 'Please select time of your event.',
    please_select_date_of_your_event: 'Please select date of your event.',
    please_enter_the_reason_for_cancel_to_continue: 'Please enter the reason for cancel to continue.',
    you_havent_enough_credits: 'You Haven\'t Enough Credits.',
    please_select_month_from_list: 'Please Select Month From List',
    please_select_year_from_list: 'Please Select Year From List',
    google_play_services_required: 'Google Play Services required: ',
    after_installing_close_and_relaunch_this_app: 'after installing, close and relaunch this app.',
    no_network_connection_available: 'No network connection available.',
    cancelling_required_permissions_are_not_granted: 'Cancelling, required permissions are not granted',
    connection_problem: 'Connection Problem,Please check Your Internet And try again later',
    oh_no: 'Oh no! ',
    microphone_permission: 'Microphone permission needed for recording. Please allow in App Settings for additional functionality.',
    external_storage_permission: 'External Storage permission needed. Please allow in App Settings for additional functionality.',
    camera_permission: 'Camera permission needed. Please allow in App Settings for additional functionality.',
    please_provide_personal_past_experience_and_company_info: 'Please provide personal,past experience and company info',
    please_add_total_minimum_estimate: 'Please add total minimum estimate? $',
    please_add_total_minimum_estimate_at_least_1: 'Please add total minimum estimate at least $1',
    please_add_total_maximum_estimate_at_least_1: 'Please add total maximum estimate at least $1',
    please_add_total_maximum_estimate: 'Please add total maximum estimate? $',
    total_minimum_estimate_should_be_less_than_total_maximum_estimate: 'Total minimum estimate should be less than total maximum estimate.',
    please_select_your_available_time: 'Please Select Your Available Time',
    permission_deny: 'Permission Deny',
    mediaActivity_not_available: 'MediaActivity Not Available!',
    portfolio_not_found: 'Portfolio not found',
    select_second_time: 'Please Select Your Second Available Time Again',
    select_third_time: 'Please Select Your Third Available Time Again',
    commissioned_base_services: `For commissioned base services. Ex: Realtors, lawyers, insurance agents etc.\n\nThank you for your interest in U\'GO Pros. We are dedicated to connecting you to more clients.Partnering with us will put you on a directory for consumers.They would be able to notify you via chat on our platform, or through your personal contact.\n\nService costs: No hidden fees`,
    yearly: 'Yearly ',
    monthly: 'Monthly ',
    these_fees_are_subject: `\nThese fees are subject to change, but you will be notified in advance if this happens.\n\nIf you are interested in partnering with us continue with the sign up process.If you have any questions feel free to reach out at 877-733-2425 or info@ugopros.com`,
    chose_to_resubscribe: 'So glad you chose to resubscribe, please select how you would like your subscription paid monthly or yearly.',
    plumber_request: 'PLUMBER REQUEST',
    schedule_meeting: 'SCHEDULE MEETING',
    error_pdf_docs: 'Error, only pdf and docs file are allowed in document',
    please_enter_paypall_account: 'Please Enter Paypal Account',
    please_enter_service_location: 'Please Enter Service Location',
    please_enter_verify_code: 'Please enter verify code',
    please_enter_your_new_password: 'Please enter your New Password',
    choose_service_distance: 'Please Choose Service Area Distance',
    unable_to_get_info: 'We were unable to get necessary information from your account. Please give permission/refresh app and try again',
    no_data_found: 'No Data Found',
    add_portfolio_picture: 'Please add Portfolio picture',
    cannot_access_location: 'Permission! You cannot access location data',
    information_not_found: 'Information Not Found',
    you_cannot_select_past_time: 'You cannot select past time',
    error_occurs: 'Error occurs',
    select_special_service: 'Please Select Special Service',
    select_state: 'Please Select State',
    select_city: 'Please Select City',
    enter_your_withdraw_amount: 'Please Enter Your Withdraw Amount',
    less_than_total_balance: 'Amount should be less than Total Balance',
    enter_business_description: 'Enter Business Description',
    registration_certificate: 'Registration Certificate',
    pan_card: 'Pan Card',
    aadhar_card: "Aadhar Card",
    voter_iden_card: 'Voter Identity Card',
    gst: "GST",
    driving_license: 'Driving License',
    please_show_proof_of_register_certificate: 'Please show proof of Register Certificate',
    please_show_proof_of_pen_card: 'Please show proof of Pen Card',
    please_show_proof_of_aadhar_card: 'Please show proof of AAdhar Card',
    please_show_proof_of_voter_identity_card: 'Please show proof of Voter Identity Card',
    please_show_proof_of_gst: 'Please show proof of GST',
    please_show_proof_of_licese: 'Please show proof of Driving License',
    please_check_the_box_for_register_certificate_and_show_poof: 'Please check the box for Register Certificate and show proof of Register Certificate',
    please_check_the_for_register_certificate: 'Please check the box for Register Certificate',
    please_check_the_box_for_pen_card_and_show_poof: 'Please check the box for Pen Card and show proof of Pen Card',
    please_check_the_box_only_for_pen_card: 'Please check the box for Pen Card',
    please_check_the_box_for_adhar_card_and_show_poof: 'Please check the box for adhar Card and show proof of adhar Card',
    please_check_the_box_only_for_adhar_card: 'Please check the box for adhar Card',
    please_check_the_box_for_adhar_card: 'Please check the box for Adhar Card',
    please_check_the_box_for_voter_identityr_card_and_show_poof: 'Please check the box for Voter Identity Card and show proof of Pen Card',
    please_check_the_box_for_voter_identity_card: 'Please check the box for Voter Identity Card',
    please_check_the_box_for_gst_and_show_poof: 'Please check the box for GST and show proof of Pen Card',
    please_check_the_box_for_gst: 'Please check the box for GST',
    please_check_the_box_for_driving_license_and_show_poof: 'Please check the box for Driving License and show proof of Pen Card',
    please_check_the_box_for_driving_License: 'Please check the box for Driving License',
    you_must_be_18_: 'You must be 18 yrs of age or older to use our services.',
    select_payment_method: 'Select Payment Method',
    google_payment_is_not_available_in_this_device: 'Google Payment is not available in this device',
    us: 'US',
    _5_credit_for_reviews_are_for_jobs_that_are_complete_over_10: '$5 credit for reviews are for Jobs that are complete over $10',

    //employee

    login_in: 'LOGIN',

    mobile: 'Mobile#',

    please_enter_valid_email_address: 'Please enter valid email address.',

    minimum_password_length_should_be: 'Minimum password length should be',

    don_t_have_an_account: 'Don\'t have an account?',

    agree_to_terms_conditions: 'Terms &amp; Conditions',
    privacy_policy: 'Privacy Policy',

    google_maps_key: 'AIzaSyBb2sUywacICcNUEmkhGsMUXIArFSoWUfc',

    please_check_network_connection: 'Please check your network connection',
    add_lead: 'ADD LEAD',
    write_your_message_here: 'write your message here ...',
    lead_detail: 'LEAD DETAIL',
    superviser: 'SUPERVISOR',
    followed_up: 'Followed Up',
    in_person_schedule: 'In Person Scheduled',

    refused: 'Refused',
    requested_callback: 'Requested Call Back',

    all_employees: 'ALL EMPLOYEES',

    manage: 'MANAGE',
    employees: 'EMPLOYEES',
    please_enter_user_name: 'Please enter user name',

    oops: 'Oops!',
    task_list: 'TASK LIST',
    setting: 'Setting',

    update_employee: 'UPDATE EMPLOYEE',

    please_enter_phone_number: 'Please Enter Phone number',

    please_enter_country: 'Please Enter Country',
    please_enter_city: 'Please Enter City',

    please_Enter_body: '\"Please Enter body \"',
    please_enter_postal_code: '\"Please Enter postal code \"',

    please_enter_First_name: 'Please Enter First Name',
    please_enter_last_name: 'Please enter Last Name',
    plese_select_the_team: 'Please Select the team',

    this_app_is_designed_for_internal_staff_members_only: "This app is designed for internal staff members only.",
    follow_ugo_pros_to_utilize_pros_features: "Follow U\'GO Pros to utilize Pros features",
    signup_as_an_industry_professional: 'Signup as an industry professional?',
    supervisor: 'SUPERVISOR',

    user_login: 'USER LOGIN',
    user_name: 'User Name',

    team_id: 'Team Id',

    sale: 'SALE',
    street: 'STREET',
    phone_number: 'Phone Number',
    body: 'Body',
    postal_code: 'Postal Code',

    new_task_s: 'NEW TASK\'s',
    current_task: 'CURRENT TASK',

    task_num: 'Task #',
    ready_to_start: 'Ready to start',

    start: 'Start',

    end_tracking: 'END TRACKING',
    task_detail: 'TASK DETAIL',

    upload_images: 'UPLOAD IMAGES',
    write_your_feedback_here: 'Write your feedback here',

    followed: 'FOLLOWED',
    lead_name: 'Lead Name',

    name_: 'Name:',
    address_: 'Address:',
    country_: 'Country:',
    state_: 'State:',
    city_: 'City:',
    meeting_detail: 'Meeting Detail:',

    start_tracking: 'START TRACKING',
    meet_with_pro_to_get_information: 'Meet with Pro to get Information',
    choose_option: 'Choose option',

    permission: 'Permission',
    allow_to_access_your_location: 'Allow to access your location?',
    allow: 'ALLOW',
    never: 'NEVER',

    days_ago: 'days ago',

    assign: 'Assign',
    update_successfully: 'Update Successfully',

    turn_on_location_on_device: 'turn on your location on device',

    welcome_to_u_go_employee_staff: 'Welcome to U\'GO Employee/Staff',
    account_deletion: 'ACCOUNT DELETION',
    delete_my_account: 'Delete My Account',
    delete_account: 'DELETE ACCOUNT',
    importent_note: 'Important Note',
    your_account_should_not_have_any_active_pending_project_n_your_account_should_not_have_any_active_payouts_n_after_successful_deletion_of_your_account_you_will_not_be_able_to_log_in_to_the_account_account_deletion_is_permanent_and_will_not_be_reversible: '• Your account should not have any active pending project\n• Your account should not have any active payouts\n• After successful deletion of your account, you will not be able to log in to the account. Account deletion is permanent and will not be reversible',
    please_acknowledge_that_you_have_read_the_above_note: 'Please acknowledge that you have read the above note',
    acknowledged: 'Acknowledged',
    are_you_sure_you_want_to_delete_account: 'Are you sure you want to Delete Account?',
    please_confirm_your_account_deletion_once_you_confirm_it_you_will_not_be_able_to_use_your_account_and_we_will_delete_all_the_data_associated_with_your_account: 'Please confirm your account deletion, once you confirm it you will not be able to use your account and we will delete all the data associated with your account.',
    please_acknowledge: 'Please Acknowledge to delete account',
    delete_accounts: 'Delete Account',
    your_profile_is_incomplete_if_you_want_edit_profile_you_can_do_that_by_pressing_edit_profile_button: 'Your Profile is incomplete If you want edit profile you can do that by pressing Edit Profile button.',
    sorry_you_have_not_yet_been_approved_by_admin: 'Sorry, you have not yet been approved by admin.',
    confirm_that_you_are: 'Confirm that you are 18+',
    please_check_the_box_confirm_that_you_are: 'Please check the box for confirm that you are 18+',
    resend_link: 'Resend Link',
    resend_in: 'Resend in',
    create_pin: 'Create Pin',
    your_authentication_code: 'Your Authentication Code',
    please_enter_your_authentication_code: 'Please Enter Your Authentication Code',
    sign_in_securely: 'Sign In Securely',
    resend_authentication_code: 'Resend Authentication code',
    two_setup_authentication: 'Two-setup Authentication',
    thanks_for_keeping_your_account_secure: 'Thanks for keeping your account secure.',
    please_check_your_email: 'Please Check your email',
    enter_old_password: 'Enter Old Password',
    please_proceed_to_your_email_for_the_purpose_of_verification_in_order_to_proceed_to_the_next_step: 'Please proceed to your email for the purpose of verification in order to proceed to the next step.',
    please_enter_social_number_or_ein: 'Please Enter Social Security OR EIN Number.',

};
