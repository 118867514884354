const POST_UNREAD_NOTIFICATIONS_REQUEST = "POST_UNREAD_NOTIFICATIONS_REQUEST";
const POST_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS =
  "POST_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS";
const POST_UNREAD_NOTIFICATIONS_REQUEST_FAILURE =
  "POST_UNREAD_NOTIFICATIONS_REQUEST_FAILURE";
const RESET_UNREAD_NOTIFICATIONS_STATE = "RESET_UNREAD_NOTIFICATIONS_STATE";
const unreadNotifications = (formData) => {
  return {
    type: POST_UNREAD_NOTIFICATIONS_REQUEST,
    formData,
  };
};

const resetUnreadNotifications = () => {
  return {
    type: RESET_UNREAD_NOTIFICATIONS_STATE,
  };
};

export {
  unreadNotifications,
  resetUnreadNotifications,
  RESET_UNREAD_NOTIFICATIONS_STATE,
  POST_UNREAD_NOTIFICATIONS_REQUEST,
  POST_UNREAD_NOTIFICATIONS_REQUEST_SUCCESS,
  POST_UNREAD_NOTIFICATIONS_REQUEST_FAILURE,
};
