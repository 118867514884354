const POST_CITIES_LIST_REQUEST = "POST_CITIES_LIST_REQUEST";
const POST_CITIES_LIST_REQUEST_SUCCESS = "POST_CITIES_LIST_REQUEST_SUCCESS";
const POST_CITIES_LIST_REQUEST_FAILURE = "POST_CITIES_LIST_REQUEST_FAILURE";
const RESET_CITIES_LIST_STATE = "RESET_CITIES_LIST_STATE";

const citiesList = (formData) => {
  return {
    type: POST_CITIES_LIST_REQUEST,
    formData
  };
};
const resetCitiesList = () => {
  return {
    type: RESET_CITIES_LIST_STATE,
  };
};
export {
  citiesList,
  resetCitiesList,
  RESET_CITIES_LIST_STATE,
  POST_CITIES_LIST_REQUEST,
  POST_CITIES_LIST_REQUEST_SUCCESS,
  POST_CITIES_LIST_REQUEST_FAILURE,
};
