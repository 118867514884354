const POST_REMOVE_PAYOUT_METHODS_REQUEST = 'POST_REMOVE_PAYOUT_METHODS_REQUEST';
const POST_REMOVE_PAYOUT_METHODS_REQUEST_SUCCESS =  'POST_REMOVE_PAYOUT_METHODS_REQUEST_SUCCESS';
const POST_REMOVE_PAYOUT_METHODS_REQUEST_FAILURE =  'POST_REMOVE_PAYOUT_METHODS_REQUEST_FAILURE';
const RESET_REMOVE_PAYOUT_METHODS_STATE ='RESET_REMOVE_PAYOUT_METHODS_STATE'

const removePayoutMethods = (formData)=>{

    return {
        type: POST_REMOVE_PAYOUT_METHODS_REQUEST,
        formData
    }
}

export {
    removePayoutMethods,
    RESET_REMOVE_PAYOUT_METHODS_STATE,
    POST_REMOVE_PAYOUT_METHODS_REQUEST,
    POST_REMOVE_PAYOUT_METHODS_REQUEST_SUCCESS,
    POST_REMOVE_PAYOUT_METHODS_REQUEST_FAILURE,
};