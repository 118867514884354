import { combineReducers } from "redux";
import { reducer as basicStartReducer } from "./BasicStart/Reducer";
import { reducer as proDetailReducer } from "./ProUser/ProDetail/Reducer";
import { reducer as loginReducer } from "./Login/Reducer";
import { reducer as resendEmailReducer } from "./ResendEmail/Reducer";
import { reducer as getServiceCategoryReducer } from "./ServiceCategory/Reducer";
import { reducer as registerProUserReducer } from "./ProUser/Register/Reducer";
import { reducer as updateProUserReducer } from "./ProUser/UpdateProUser/Reducer";
import { reducer as contactUsRequestReducer } from "./ContactUs/Reducer";
import { reducer as forgotPasswordReducer } from "./ForgotPassword/Reducer";
import { reducer as userRegisterReducer } from "./User/Register/Reducer";
import { reducer as deleteAccountReducer } from "./DeleteAccount/Reducer";
import { reducer as logoutUserReducer } from "./LogoutUser/Reducer";
import { reducer as updateUserReducer } from "./User/UpdateUser/Reducer";
import { reducer as reviewUserReducer } from "./User/ReviewUser/Reducer";
import { reducer as reviewProReducer } from "./ProUser/ReviewPro/Reducer";
import { reducer as reviewSpecialProReducer } from "./ProUser/ReviewSpecialPro/Reducer";
import { reducer as getNotificationsReducer } from "./GetNotifications/Reducer";
import { reducer as deleteNotificationsReducer } from "./DeleteNotifications/Reducer";
import { reducer as changePasswordReducer } from "./ChangePassword/Reducer";
import { reducer as changeLanguageReducer } from "./ChangeLanguage/Reducer";
import { reducer as citiesListReducer } from "./CitiesList/Reducer";
import { reducer as getUserChatReducer } from "./User/GetUserChat/Reducer";
import { reducer as getProChatReducer } from "./ProUser/GetProChat/Reducer";
import { reducer as newEmployeeReducer } from "./Employee/AddNewEmployee/Reducer";
import { reducer as employeeEndJobReducer } from "./Employee/EmployeeEndJob/Reducer";
import { reducer as deleteEmployeeReducer } from "./Employee/DeleteEmployee/Reducer";
import { reducer as assignEmployeeReducer } from "./ProUser/AssignEmployee/Reducer";
import { reducer as employeesDeleteReducer } from "./Employee/EmployeesDelete/Reducer";
import { reducer as updateEmployeeReducer } from "./Employee/UpdateEmployee/Reducer";
import { reducer as userEmployeeListReducer } from "./Employee/UserEmployeeList/Reducer";
import { reducer as getAllowedCountryReducer } from "./GetAllowedCountry/Reducer";
import { reducer as newProjectReducer } from "./User/NewProject/Reducer";
import { reducer as updateProjectReducer } from "./User/UpdateProject/Reducer";
import { reducer as authCodeReducer } from "./AuthCode/Reducer";
import { reducer as unreadNotificationsReducer } from "./UnreadNotifications/Reducer";
import { reducer as userDataReducer } from "./User/UserDetails/Reducer";
import { reducer as currentStackReducer } from "./CurrentStack/Reducer";
import { reducer as userSocialRegisterReducer } from "./User/UserSocialRegister/Reducer";
import { reducer as getHolesPositionReducer } from "./GetHolesPosition/Reducer";

import { reducer as removePinReducer } from "./RemovePin/Reducer";
import { reducer as pinStatusReducer } from "./User/PinStatus/Reducer";
import { reducer as getOfferReducer } from "./User/GetOffer/Reducer";
import { reducer as getMyInstantProjectReducer } from "./User/GetMyInstantProjects/Reducer";
import { reducer as projectDeleteByUserReducer } from "./User/ProjectDeleteByUser/Reducer";
import { reducer as trackEarningReducer } from "./ProUser/TrackEarning/Reducer";
import { reducer as addCreditsReducer } from "./User/AddCredits/Reducer";
import { reducer as changeNotificationSettingReducer } from "./User/ChnageNotificationSetting/Reducer";
import { reducer as deleteClosedProjectReducer } from "./ProUser/DeleteClosedProject/Reducer";
import { reducer as projectDeleteByProReducer } from "./ProUser/ProjectDeleteByPro/Reducer";
import { reducer as getWithdrawHistoryReducer } from "./GetWithdrawHistory/Reducer";
import { reducer as getChatForSpecialityReducer } from "./SpecialPro/GetChatForSpeciality/Reducer";
import { reducer as getInvoicesReducer } from "./ProUser/GetInvoices/Reducer";
import { reducer as getProRewardAmountReducer } from "./ProUser/GetProRewardAmount/Reducer";
import { reducer as cancelProjectAfterPaymentReducer } from "./User/CancelProjectAfterPayment/Reducer";
import { reducer as projectNotCompletedReducer } from "./User/ProjectNotComplete/Reducer";
import { reducer as confirnProjectCompletionReducer } from "./User/ConfirmProjectCompletion/Reducer";
import { reducer as acceptActualBidReducer } from "./User/AcceptActualBid/Reducer";
import { reducer as getServiceProfessionalsReducer } from "./User/GetServiceProfessionals/Reducer";
import { reducer as getPaymentIntentReducer } from "./GetPaymentIntent/Reducer";
import { reducer as captureBidAmountReducer } from "./User/CaptureBidAmount/Reducer";
import { reducer as responseOnBidReducer } from "./User/ResponseOnBid/Reducer";
import { reducer as getMyProjectsReducer } from "./User/GetMyProjects/Reducer";
import { reducer as quickQuoteRescheduleReducer } from "./User/QuickQuoteReschedule/Reducer";
import { reducer as rejectsCancelReducer } from "./User/RejectsCancel/Reducer";
import { reducer as rejectOnSiteVisitReducer } from "./User/RejectOnSiteVisit/Reducer";
import { reducer as approveOnSiteVisitReducer } from "./User/ApproveOnSiteVisit/Reducer";
import { reducer as getSiteVisitReducer } from "./User/GetSiteVisitRequests/Reducer";
import { reducer as sendRequestToProfessionalReducer } from "./SendRequestToProfessional/Reducer";
import { reducer as checkProInAreaReducer } from "./User/CheckProInArea/Reducer";
import { reducer as referFriendsReducer } from "./User/ReferFriends/Reducer";
import { reducer as makePayInMethodDefaultReducer } from "./MakePayInMethodDefault/Reducer";
import { reducer as makeDebitCardDefaultReducer } from "./ProUser/MakeDebitCardDefault/Reducer";
import { reducer as getRewardAmountReducer } from "./User/GetRewardAmount/Reducer";
import { reducer as getSpecialityServiceChatDetailReducer } from "./SpecialPro/GetSpecialityServiceChatDetail/Reducer";
import { reducer as projectResponseOnBidReducer } from "./User/ProjectResponseOnBid/Reducer";
import { reducer as getBidsReducer } from "./User/GetBids/Reducer";
import { reducer as gerUserEventReducer } from "./GerUserEvent/Reducer";
import { reducer as getUserProjectDetailReducer } from "./User/GetProjectsDetail/Reducer";
import { reducer as getProUserProjectDetailReducer } from "./ProUser/GetProjectDetail/Reducer";
import { reducer as addPayoutMethodsReducer } from "./ProUser/AddPayoutMethods/Reducer";
import { reducer as autoPaymentReducer } from "./SpecialPro/SetAutoPayment/Reducer";
import { reducer as removePayoutMethodsReducer } from "./RemovePaymentMethod/Reducer";

import { reducer as changeInstantPayoutSettingReducer } from "./ProUser/ChangeInstantPayoutSetting/Reducer";
import { reducer as addDebitCardReducer } from "./ProUser/AddDebitCard/Reducer";
import { reducer as removePayInMethodReducer } from "./RemovePayInMethod/Reducer";
import { reducer as removeDebitCardReducer } from "./ProUser/RemoveDebitCard/Reducer";
import { reducer as sendActualPriceReducer } from "./ProUser/SendActualPrice/Reducer";
import { reducer as onSiteVisitPaymentReducer } from "./ProUser/OnSiteVIsitPayment/Reducer";
import { reducer as retryOnSiteVisitPaymentReducer } from "./ProUser/RetryOnSiteVisitPayment/Reducer";
import { reducer as getMyPayInMethodReducer } from "./User/GetMyPayInMethod/Reducer";
import { reducer as acceptInstantProjectReducer } from "./User/AcceptInstantProject/Reducer";
import { reducer as getChatDetailReducer } from "./User/GetChatDetail/Reducer";
import { reducer as messageReadStatusReducer } from "./MessageReadStatus/Reducer";
import { reducer as uploadMessageFileReducer } from "./UploadMessageFile/Reducer";
import { reducer as endProjectByProReducer } from "./ProUser/EndProjectByPro/Reducer";
import { reducer as addPaymentReducer } from "./AddPayment/Reducer";
import { reducer as updatePaymentReducer } from "./UpdatePayment/Reducer";
import { reducer as getPaymentMethodReducer } from "./GetPaymentMethod/Reducer";
import { reducer as acceptRejectReducer } from "./Employee/AcceptReject/Reducer";
import { reducer as changeOnlineStatusReducer } from "./ProUser/ChangeOnlineStatus/Reducer";
import { reducer as startProjectReducer } from "./ProUser/StartProject/Reducer";
import { reducer as selectPaymentMethodReducer } from "./SelectPaymentMethod/Reducer";
import { reducer as getUgoMainServicesReducer } from "./GetUgoMainServices/Reducer";
import { reducer as reSendAuthCodeReducer } from "./ReSendAuthenticationCode/Reducer";
import { reducer as bidOnProjectReducer } from "./ProUser/BidOnProject/Reducer";
import { reducer as addPayinMethodReducer } from "./User/AddPayinMethod/Reducer";
import { reducer as AddPayinMethodWithTokenReducer } from "./User/AddPayinMethodWithToken/Reducer";

import { reducer as getSpecialRequestReducer } from "./SpecialPro/GetSpecialRequest/Reducer";
import { reducer as getPaymentHistoryReducer } from "./User/GetPaymentHistory/Reducer";
import { reducer as getProjectReducer } from "./ProUser/GetProject/Reducer";
import { reducer as getProReviewsReducer } from "./ProUser/GetProReviews/Reducer";
import { reducer as getPayoutMethodReducer } from "./ProUser/GetPayOutMethod/Reducer";
import { reducer as employeeProjectsReducer } from "./Employee/GetEmployeeProjects/Reducer";
import { reducer as rejectProjectReducer } from "./ProUser/RejectProject/Reducer";
import { reducer as subscribeUserReducer } from "./ProUser/SubscribeUser/Reducer";
import { reducer as cancelSubscribeUserReducer } from "./SpecialPro/CancelSubscribeUser/Reducer";
import { reducer as reSubscribeUserReducer } from "./SpecialPro/ReSubscribeUser/Reducer";
import { reducer as employeeLoginReducer } from "./SupportUser/Employee/EmployeeLogin/Reducer";
import { reducer as getTaskReducer } from "./SupportUser/Employee/GetTask/Reducer";
import { reducer as getMyLeadsReducer } from "./SupportUser/Employee/GetMyLeads/Reducer";
import { reducer as updateProfileReducer } from "./SupportUser/Employee/UpdateProfile/Reducer";
import { reducer as updateNotificationReducer } from "./SupportUser/Employee/UpdateNotificationSetting/Reducer";
import { reducer as getChatHistoryReducer } from "./SupportUser/Employee/GetChatHistory/Reducer";

import { reducer as deletePortalUserReducer } from "./SupportUser/Employee/DeletePortalUser/Reducer";

import { reducer as supervisorLoginReducer } from "./SupportUser/Supervisor/SupervisorLogin/Reducer";
import { reducer as getSalesEmployeeReducer } from "./SupportUser/Supervisor/GetSaleEmployee/Reducer";
import { reducer as getStreetEmployeeReducer } from "./SupportUser/Supervisor/GetStreetEmployee/Reducer";
import { reducer as getAllEmployeeReducer } from "./SupportUser/Supervisor/GetAllEmployees/Reducer";
import { reducer as getAllLeadsReducer } from "./SupportUser/Supervisor/GetAllLeads/Reducer";
import { reducer as getCitiesReducer } from "./SupportUser/Supervisor/GetCities/Reducer";
import { reducer as supervisorUpdateLeadStatusReducer } from "./SupportUser/Supervisor/UpdateLeadStatus/Reducer";
import { reducer as supervisorUpdateNotificationReducer } from "./SupportUser/Supervisor/SupervisorNotificationSetting/Reducer";
import { reducer as supervisorUpdateProfileReducer } from "./SupportUser/Supervisor/SupervisorUpdateProfile/Reducer";
import { reducer as supervisorUpdateEmployeeReducer } from "./SupportUser/Supervisor/UpdateEmployee/Reducer";
import { reducer as createEmployeeReducer } from "./SupportUser/Supervisor/CreateEmployee/Reducer";
import { reducer as getBasicValuesReducer } from "./SupportUser/Supervisor/GetBasicValues/Reducer";
import { reducer as supervisorUserDeleteReducer } from "./SupportUser/Supervisor/SupervisorUserDelete/Reducer";
import { reducer as createNewLeadReducer } from "./SupportUser/Supervisor/CreateNewLead/Reducer";
import { reducer as supervisorChatHistoryReducer } from "./SupportUser/Supervisor/SupervisorChatHistory/Reducer";
import { reducer as supervisorMessagesListReducer } from "./SupportUser/Supervisor/SupervisorMessageList/Reducer";
import { reducer as supervisorAssignLeadReducer } from "./SupportUser/Supervisor/SupervisorAssignLead/Reducer";
import { reducer as endTrackingReducer } from "./SupportUser/Employee/EndTracking/Reducer";
import { reducer as startTaskReducer } from "./SupportUser/Employee/StartTask/Reducer";
import { reducer as employeeUpdateStatusReducer } from "./SupportUser/Employee/EmployeeUpdateStatus/Reducer";
import { reducer as endTaskReducer } from "./SupportUser/Employee/EndTask/Reducer";


const reducer = combineReducers({
  basicStart: basicStartReducer,
  login: loginReducer,
  resendEmail: resendEmailReducer,
  userData: userDataReducer,
  currentStack: currentStackReducer,
  getHolesPosition: getHolesPositionReducer,
  proDetail: proDetailReducer,
  getServiceCategory: getServiceCategoryReducer,
  registerProUser: registerProUserReducer,
  updateProUser: updateProUserReducer,
  contactUsRequest: contactUsRequestReducer,
  forgotPassword: forgotPasswordReducer,
  userRegister: userRegisterReducer,
  deleteAccount: deleteAccountReducer,
  logoutUser: logoutUserReducer,
  updateUser: updateUserReducer,
  reviewUser: reviewUserReducer,
  reviewPro: reviewProReducer,
  reviewSpecialPro: reviewSpecialProReducer,
  getNotifications: getNotificationsReducer,
  deleteNotifications: deleteNotificationsReducer,
  changePassword: changePasswordReducer,
  changeLanguage: changeLanguageReducer,
  citiesList: citiesListReducer,
  getUserChat: getUserChatReducer,
  getProChat: getProChatReducer,
  newEmployee: newEmployeeReducer,
  employeeEndJob: employeeEndJobReducer,
  deleteEmployee: deleteEmployeeReducer,
  assignEmployee: assignEmployeeReducer,
  employeesDelete: employeesDeleteReducer,
  updateEmployee: updateEmployeeReducer,
  userEmployeeList: userEmployeeListReducer,
  getAllowedCountry: getAllowedCountryReducer,
  newProject: newProjectReducer,
  updateProject: updateProjectReducer,
  authCode: authCodeReducer,
  unreadNotifications: unreadNotificationsReducer,
  userSocialRegister: userSocialRegisterReducer,

  removePin: removePinReducer,
  pinStatus: pinStatusReducer,
  getOffer: getOfferReducer,
  getMyInstantProject: getMyInstantProjectReducer,
  projectDeleteByUser: projectDeleteByUserReducer,
  trackEarning: trackEarningReducer,
  addCredits: addCreditsReducer,
  changeNotificationSetting: changeNotificationSettingReducer,
  deleteClosedProject: deleteClosedProjectReducer,
  projectDeleteByPro: projectDeleteByProReducer,
  getWithdrawHistory: getWithdrawHistoryReducer,
  getChatForSpeciality: getChatForSpecialityReducer,
  getInvoices: getInvoicesReducer,
  getProRewardAmount: getProRewardAmountReducer,
  cancelProjectAfterPayment: cancelProjectAfterPaymentReducer,
  projectNotCompleted: projectNotCompletedReducer,
  confirnProjectCompletion: confirnProjectCompletionReducer,
  acceptActualBid: acceptActualBidReducer,
  getServiceProfessionals: getServiceProfessionalsReducer,
  getPaymentIntent: getPaymentIntentReducer,
  captureBidAmount: captureBidAmountReducer,
  responseOnBid: responseOnBidReducer,
  getMyProjects: getMyProjectsReducer,
  quickQuoteReschedule: quickQuoteRescheduleReducer,
  rejectsCancel: rejectsCancelReducer,
  rejectOnSiteVisit: rejectOnSiteVisitReducer,
  approveOnSiteVisit: approveOnSiteVisitReducer,
  getSiteVisit: getSiteVisitReducer,
  sendRequestToProfessional: sendRequestToProfessionalReducer,
  checkProInArea: checkProInAreaReducer,
  referFriends: referFriendsReducer,
  makePayInMethodDefault: makePayInMethodDefaultReducer,
  makeDebitCardDefault: makeDebitCardDefaultReducer,
  getRewardAmount: getRewardAmountReducer,
  getSpecialityServiceChatDetail: getSpecialityServiceChatDetailReducer,
  getBids: getBidsReducer,
  projectResponseOnBid: projectResponseOnBidReducer,
  gerUserEvent: gerUserEventReducer,
  getUserProjectDetail: getUserProjectDetailReducer,
  getProUserProjectDetail: getProUserProjectDetailReducer,
  addPayoutMethods: addPayoutMethodsReducer,
  autoPayment: autoPaymentReducer,
  removePayoutMethods: removePayoutMethodsReducer,

  changeInstantPayoutSetting: changeInstantPayoutSettingReducer,
  addDebitCard: addDebitCardReducer,
  removePayInMethod: removePayInMethodReducer,
  removeDebitCard: removeDebitCardReducer,
  sendActualPrice: sendActualPriceReducer,
  onSiteVisitPayment: onSiteVisitPaymentReducer,
  retryOnSiteVisitPayment: retryOnSiteVisitPaymentReducer,
  getMyPayInMethod: getMyPayInMethodReducer,
  acceptInstantProject: acceptInstantProjectReducer,
  getChatDetail: getChatDetailReducer,
  messageReadStatus: messageReadStatusReducer,
  uploadMessageFile: uploadMessageFileReducer,
  endProjectByPro: endProjectByProReducer,
  addPayment: addPaymentReducer,
  updatePayment: updatePaymentReducer,
  getPaymentMethod: getPaymentMethodReducer,
  acceptReject: acceptRejectReducer,
  changeOnlineStatus: changeOnlineStatusReducer,
  startProject: startProjectReducer,
  selectPaymentMethod: selectPaymentMethodReducer,
  getUgoMainServices: getUgoMainServicesReducer,
  reSendAuthCode: reSendAuthCodeReducer,
  bidOnProject: bidOnProjectReducer,
  addPayinMethod: addPayinMethodReducer,
  AddPayinMethodWithToken: AddPayinMethodWithTokenReducer,
  getSpecialRequest: getSpecialRequestReducer,
  getPaymentHistory: getPaymentHistoryReducer,
  getProject: getProjectReducer,
  getProReviews: getProReviewsReducer,
  getPayoutMethod: getPayoutMethodReducer,
  employeeProjects: employeeProjectsReducer,
  rejectProject: rejectProjectReducer,
  subscribeUser: subscribeUserReducer,
  cancelSubscribeUser: cancelSubscribeUserReducer,
  reSubscribeUser: reSubscribeUserReducer,
  supervisorLogin: supervisorLoginReducer,
  employeeLogin: employeeLoginReducer,
  getTask: getTaskReducer,
  getMyLeads: getMyLeadsReducer,
  updateProfile: updateProfileReducer,
  updateNotification: updateNotificationReducer,
  getChatHistory: getChatHistoryReducer,

  deletePortalUser: deletePortalUserReducer,
  getSalesEmployee: getSalesEmployeeReducer,
  getStreetEmployee: getStreetEmployeeReducer,
  getAllEmployee: getAllEmployeeReducer,
  getAllLeads: getAllLeadsReducer,
  getCities: getCitiesReducer,
  supervisorUpdateLeadStatus: supervisorUpdateLeadStatusReducer,
  supervisorUpdateNotification: supervisorUpdateNotificationReducer,
  supervisorUpdateProfile: supervisorUpdateProfileReducer,
  supervisorUpdateEmployee: supervisorUpdateEmployeeReducer,
  createEmployee: createEmployeeReducer,
  getBasicValues: getBasicValuesReducer,
  supervisorUserDelete: supervisorUserDeleteReducer,
  createNewLead: createNewLeadReducer,
  supervisorChatHistory: supervisorChatHistoryReducer,
  supervisorMessagesList: supervisorMessagesListReducer,
  supervisorAssignLead: supervisorAssignLeadReducer,
  endTracking: endTrackingReducer,
  startTask: startTaskReducer,
  employeeUpdateStatus: employeeUpdateStatusReducer,
  endTask: endTaskReducer,
});

export { reducer };
