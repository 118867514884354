const POST_DELETE_PORTAL_USER_REQUEST = "POST_DELETE_PORTAL_USER_REQUEST";
const POST_DELETE_PORTAL_USER_REQUEST_SUCCESS = "POST_DELETE_PORTAL_USER_REQUEST_SUCCESS";
const POST_DELETE_PORTAL_USER_REQUEST_FAILURE = "POST_DELETE_PORTAL_USER_REQUEST_FAILURE";
const RESET_DELETE_PORTAL_USER_STATE = "RESET_DELETE_PORTAL_USER_STATE";

const deletePortalUser = (formData) => {
  return {
    type: POST_DELETE_PORTAL_USER_REQUEST,
    formData
  };
};

const resetDeletePortalUser = () => {
  return {
    type: RESET_DELETE_PORTAL_USER_STATE,
  };
};
export {
  deletePortalUser,
  resetDeletePortalUser,
  RESET_DELETE_PORTAL_USER_STATE,
  POST_DELETE_PORTAL_USER_REQUEST,
  POST_DELETE_PORTAL_USER_REQUEST_SUCCESS,
  POST_DELETE_PORTAL_USER_REQUEST_FAILURE,
};
