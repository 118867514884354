const POST_GET_MY_PAY_IN_METHOD_REQUEST = 'POST_GET_MY_PAY_IN_METHOD_REQUEST';
const POST_GET_MY_PAY_IN_METHOD_REQUEST_SUCCESS = 'POST_GET_MY_PAY_IN_METHOD_REQUEST_SUCCESS';
const POST_GET_MY_PAY_IN_METHOD_REQUEST_FAILURE = 'POST_GET_MY_PAY_IN_METHOD_REQUEST_FAILURE';
const RESET_GET_MY_PAY_IN_METHOD_STATE = 'RESET_GET_MY_PAY_IN_METHOD_STATE'

const getMyPayInMethod = (formData) => {

    return {
        type: POST_GET_MY_PAY_IN_METHOD_REQUEST,
        formData
    }
}
const resetMyPayInMethod = () => {

    return {
        type: RESET_GET_MY_PAY_IN_METHOD_STATE,
    }
}

export {
    getMyPayInMethod,
    resetMyPayInMethod,
    RESET_GET_MY_PAY_IN_METHOD_STATE,
    POST_GET_MY_PAY_IN_METHOD_REQUEST,
    POST_GET_MY_PAY_IN_METHOD_REQUEST_SUCCESS,
    POST_GET_MY_PAY_IN_METHOD_REQUEST_FAILURE,
};