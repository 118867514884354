const POST_ACCEPT_ACTUAL_BID_REQUEST = 'POST_ACCEPT_ACTUAL_BID_REQUEST';
const POST_ACCEPT_ACTUAL_BID_REQUEST_SUCCESS = 'POST_ACCEPT_ACTUAL_BID_REQUEST_SUCCESS';
const POST_ACCEPT_ACTUAL_BID_REQUEST_FAILURE = 'POST_ACCEPT_ACTUAL_BID_REQUEST_FAILURE';
const RESET_ACCEPT_ACTUAL_BID_STATE = 'RESET_ACCEPT_ACTUAL_BID_STATE'

const acceptActualBid = (formData) => {

    return {
        type: POST_ACCEPT_ACTUAL_BID_REQUEST,
        formData
    }
}
const resetAcceptActualBid = () => {

    return {
        type: RESET_ACCEPT_ACTUAL_BID_STATE,

    }
}
export {
    acceptActualBid,
    resetAcceptActualBid,
    RESET_ACCEPT_ACTUAL_BID_STATE,
    POST_ACCEPT_ACTUAL_BID_REQUEST,
    POST_ACCEPT_ACTUAL_BID_REQUEST_SUCCESS,
    POST_ACCEPT_ACTUAL_BID_REQUEST_FAILURE,
};