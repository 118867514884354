import React, { useState, useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import OnBoardStack from "./OnBoardStack";
// import AppStack from "./AppStack";
import { useSelector } from "react-redux";
import { getItem } from "../Utils/AsyncStore";
import { SheetProvider } from "react-native-actions-sheet";
import { registerSheet } from "react-native-actions-sheet";
import ActionSheetModel from "../Components/ActionSheet";
import { View } from "react-native";
registerSheet("ActionSheetModel", ActionSheetModel);

const RootStack = ({ languageSelected }) => {
  const currentStack = useSelector((state) => state.currentStack.stack);
  const [langSelected, setLangSelected] = useState(languageSelected);
  useEffect(() => {
    getItem("languageSelected").then((val) => setLangSelected(val));
  }, [currentStack]);
  return (
    <SheetProvider>
      <NavigationContainer theme={{ colors: { background: "#1F1E22" } }}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#e4eae6",
            marginVertical: "auto",
          }}
        >
          <View
            style={{
              flex: 1,
              maxWidth: 600,
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            {currentStack === "OnBoardStack" ? (
              <OnBoardStack languageSelected={langSelected} />
            ) : (
              <OnBoardStack />
            )}
          </View>
        </View>
      </NavigationContainer>
    </SheetProvider>
  );
};
export default RootStack;
