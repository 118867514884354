// import { PermissionsAndroid, Platform, Alert } from "react-native";
// import messaging from "@react-native-firebase/messaging";
// import React from "react";
// import notifee, { AndroidImportance, EventType } from "@notifee/react-native";
// import { useNavigation } from "@react-navigation/native";
// import { useDispatch, useSelector } from "react-redux";
// import { getProjectDetail, resetGetProjectDetail } from "../Redux/Models/ProUser/GetProjectDetail/Actions";
// import NavigateToProDetail from "./NavigateToProDetail";
// import { encrypt } from "./HelperFunctions";
// import { PRO_PROJECT_DETAIL_SCREEN, PRO_REQUEST_DETAIL_SCREEN } from "./SetProStatus";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAajYK6cNDAnBFPSjSxsLSDuf60lE3jkGc",
  authDomain: "ugopros-new-1520505842754.firebaseapp.com",
  databaseURL: "https://ugopros-new-1520505842754.firebaseio.com",
  projectId: "ugopros-new-1520505842754",
  storageBucket: "ugopros-new-1520505842754.appspot.com",
  messagingSenderId: "42648397778",
  appId: "1:42648397778:web:65799e04cf5b31cd7bca0b",
};

export const firebase = initializeApp(firebaseConfig);

export const deviceToken = async () => {
  const app = getMessaging(firebase);
  let token = await getToken(app);
  return token;
};

export const getPermission = async () => {
  const app = getMessaging(firebase);
  let token = await getToken(app);
  return token;
};

export const showNotification = async (remoteMessage) => {
  remoteMessage = await JSON.parse(remoteMessage.data.message);
  const channelId = await notifee.createChannel({
    id: "default1",
    name: "Default Channel",
    importance: AndroidImportance.HIGH,
    sound: "default",
  });
  await notifee.displayNotification({
    title: remoteMessage.title,
    body: remoteMessage.body,
    data: remoteMessage,
    android: {
      channelId,
      // pressAction is needed if you want the notification to open the app when pressed
      pressAction: {
        id: "default",
      },
    },
  });
};

export const useInitFirebase = () => {
  const msg = getMessaging(firebase);
  console.log(getToken(msg));

  // const navigation = useNavigation();
  // const dispatch = useDispatch();
  // const getProjectDetailResp = useSelector((state) => state.getProUserProjectDetail);
  // const userData = useSelector((state) => state.userData);

  // // messaging().onNotificationOpenedApp((remoteMessage) => {
  // //   console.log("Notification caused app to open from background state:", remoteMessage);
  // //   // navigation.navigate(remoteMessage.data.type);
  // // });

  // // // Check whether an initial notification is available
  // // messaging()
  // //   .getInitialNotification()
  // //   .then((remoteMessage) => {
  // //     if (remoteMessage) {
  // //       console.log("Notification caused app to open from quit state:", remoteMessage);
  // //       // setInitialRoute(remoteMessage.data.type); // e.g. "Settings"
  // //     }
  // //   });

  // React.useEffect(() => {
  //   let unsubscribeNotifee = notifee.onForegroundEvent(({ type, detail }) => {
  //     if (type == EventType.PRESS && userData?.user?.id) {
  //       if(userData.user.user_type ==1){
  //         navigation.navigate("ProjectDetails",{project_id:detail.notification.data.project_id})
  //         return
  //       }
  //       if(PRO_PROJECT_DETAIL_SCREEN.includes(detail.notification.data.type)){
  //         navigation.navigate("ProProjectDetail",{project_id:detail.notification.data.project_id})
  //         return
  //       }
  //       if(PRO_REQUEST_DETAIL_SCREEN.includes(detail.notification.data.type)){
  //         navigation.navigate("RequestDetails",{project_id:detail.notification.data.project_id})
  //       }
  //     }
  //   });
  //   const unsubscribe = messaging().onMessage(async (remoteMessage) => {
  //     console.log(remoteMessage);
  //     await showNotification(remoteMessage);
  //   });
  //   return () => {
  //     unsubscribe();
  //     unsubscribeNotifee();
  //   };
  // }, [userData]);
  // React.useEffect(() => {
  //   if (getProjectDetailResp.success) {
  //     NavigateToProDetail(getProjectDetailResp.data.success.details, navigation);
  //     dispatch(resetGetProjectDetail());
  //     return;
  //   }
  // }, [getProjectDetailResp]);
};
