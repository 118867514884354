import {
  POST_UPDATE_EMPLOYEE_REQUEST,
  POST_UPDATE_EMPLOYEE_REQUEST_SUCCESS,
  POST_UPDATE_EMPLOYEE_REQUEST_FAILURE,
  RESET_UPDATE_EMPLOYEE_STATE
} from "./Action"

const initialState = {
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_UPDATE_EMPLOYEE_REQUEST: {
      console.log(POST_UPDATE_EMPLOYEE_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_UPDATE_EMPLOYEE_REQUEST_SUCCESS: {
      console.log(POST_UPDATE_EMPLOYEE_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case POST_UPDATE_EMPLOYEE_REQUEST_FAILURE: {
      console.log(POST_UPDATE_EMPLOYEE_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: error?.message,
        errorData: error
      };
    }
    case RESET_UPDATE_EMPLOYEE_STATE: {
      console.log(RESET_UPDATE_EMPLOYEE_STATE);

      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
