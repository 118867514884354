const POST_ON_SITE_VISIT_PAYMENT_REQUEST = 'POST_ON_SITE_VISIT_PAYMENT_REQUEST';
const POST_ON_SITE_VISIT_PAYMENT_REQUEST_SUCCESS = 'POST_ON_SITE_VISIT_PAYMENT_REQUEST_SUCCESS';
const POST_ON_SITE_VISIT_PAYMENT_REQUEST_FAILURE = 'POST_ON_SITE_VISIT_PAYMENT_REQUEST_FAILURE';
const RESET_ON_SITE_VISIT_PAYMENT_STATE = 'RESET_ON_SITE_VISIT_PAYMENT_STATE'

const onSiteVisitPayment = (formData) => {

    return {
        type: POST_ON_SITE_VISIT_PAYMENT_REQUEST,
        formData
    }
}
const resetOnSiteVisitPayment = () => {

    return {
        type: RESET_ON_SITE_VISIT_PAYMENT_STATE,

    }
}
export {
    onSiteVisitPayment,
    resetOnSiteVisitPayment,
    RESET_ON_SITE_VISIT_PAYMENT_STATE,
    POST_ON_SITE_VISIT_PAYMENT_REQUEST,
    POST_ON_SITE_VISIT_PAYMENT_REQUEST_SUCCESS,
    POST_ON_SITE_VISIT_PAYMENT_REQUEST_FAILURE,
};