const POST_PROJECT_DELETE_BY_PRO_REQUEST = 'POST_PROJECT_DELETE_BY_PRO_REQUEST';
const POST_PROJECT_DELETE_BY_PRO_REQUEST_SUCCESS = 'POST_PROJECT_DELETE_BY_PRO_REQUEST_SUCCESS';
const POST_PROJECT_DELETE_BY_PRO_REQUEST_FAILURE = 'POST_PROJECT_DELETE_BY_PRO_REQUEST_FAILURE';
const RESET_PROJECT_DELETE_BY_PRO_STATE = 'RESET_PROJECT_DELETE_BY_PRO_STATE'

const projectDeleteByPro = (formData) => {

    return {
        type: POST_PROJECT_DELETE_BY_PRO_REQUEST,
        formData
    }
}
const resetProjectDeleteByPro = () => {

    return {
        type: RESET_PROJECT_DELETE_BY_PRO_STATE,

    }
}
export {
    projectDeleteByPro,
    resetProjectDeleteByPro,
    RESET_PROJECT_DELETE_BY_PRO_STATE,
    POST_PROJECT_DELETE_BY_PRO_REQUEST,
    POST_PROJECT_DELETE_BY_PRO_REQUEST_SUCCESS,
    POST_PROJECT_DELETE_BY_PRO_REQUEST_FAILURE,
};