const POST_USER_EMPLOYEE_LIST_REQUEST = "POST_USER_EMPLOYEE_LIST_REQUEST";
const POST_USER_EMPLOYEE_LIST_REQUEST_SUCCESS = "POST_USER_EMPLOYEE_LIST_REQUEST_SUCCESS";
const POST_USER_EMPLOYEE_LIST_REQUEST_FAILURE = "POST_USER_EMPLOYEE_LIST_REQUEST_FAILURE";
const RESET_USER_EMPLOYEE_LIST_STATE = "RESET_USER_EMPLOYEE_LIST_STATE";

const userEmployeeList = (formData) => {
  return {
    type: POST_USER_EMPLOYEE_LIST_REQUEST,
    formData,
  };
};
const resetUserEmployeeList = () => {
  return {
    type: RESET_USER_EMPLOYEE_LIST_STATE,
  };
};
export {
  userEmployeeList,
  resetUserEmployeeList,
  RESET_USER_EMPLOYEE_LIST_STATE,
  POST_USER_EMPLOYEE_LIST_REQUEST,
  POST_USER_EMPLOYEE_LIST_REQUEST_SUCCESS,
  POST_USER_EMPLOYEE_LIST_REQUEST_FAILURE,
};
