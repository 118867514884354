const POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST = "POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST";
const POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST_SUCCESS = "POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST_SUCCESS";
const POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST_FAILURE = "POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST_FAILURE";
const RESET_SUPERVISOR_PORTALUSER_DELETE_STATE = "RESET_SUPERVISOR_PORTALUSER_DELETE_STATE";

const supervisorUserDelete = (formData) => {
  return {
    type: POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST,
    formData
  };
};

const resetSupervisorUserDelete = () => {
  return {
    type: RESET_SUPERVISOR_PORTALUSER_DELETE_STATE,
  };
};
export {
  supervisorUserDelete,
  resetSupervisorUserDelete,
  RESET_SUPERVISOR_PORTALUSER_DELETE_STATE,
  POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST,
  POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST_SUCCESS,
  POST_SUPERVISOR_PORTALUSER_DELETE_REQUEST_FAILURE,
};
