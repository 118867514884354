const POST_ADD_PAYOUT_METHOD_REQUEST = 'POST_ADD_PAYOUT_METHOD_REQUEST';
const POST_ADD_PAYOUT_METHOD_REQUEST_SUCCESS = 'POST_ADD_PAYOUT_METHOD_REQUEST_SUCCESS';
const POST_ADD_PAYOUT_METHOD_REQUEST_FAILURE = 'POST_ADD_PAYOUT_METHOD_REQUEST_FAILURE';
const RESET_ADD_PAYOUT_METHOD_STATE = 'RESET_ADD_PAYOUT_METHOD_STATE'

const addPayoutMethods = (formData) => {

    return {
        type: POST_ADD_PAYOUT_METHOD_REQUEST,
        formData
    }
}
const resetAddPayoutMethods = () => {

    return {
        type: RESET_ADD_PAYOUT_METHOD_STATE,
    }
}

export {
    addPayoutMethods,
    resetAddPayoutMethods,
    RESET_ADD_PAYOUT_METHOD_STATE,
    POST_ADD_PAYOUT_METHOD_REQUEST,
    POST_ADD_PAYOUT_METHOD_REQUEST_SUCCESS,
    POST_ADD_PAYOUT_METHOD_REQUEST_FAILURE,
};