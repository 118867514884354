//import {createStore, applyMiddleware} from 'redux';
import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import {reducer} from './Models/RootReducer';
import RootSaga from './Models/RootSaga';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
    reducer:reducer,
    middleware:()=>[sagaMiddleware]
});

sagaMiddleware.run(RootSaga);

export {store};
