const POST_GET_PROJECT_DETAIL_REQUEST = 'POST_GET_PROJECT_DETAIL_REQUEST';
const POST_GET_PROJECT_DETAIL_REQUEST_SUCCESS = 'POST_GET_PROJECT_DETAIL_REQUEST_SUCCESS';
const POST_GET_PROJECT_DETAIL_REQUEST_FAILURE = 'POST_GET_PROJECT_DETAIL_REQUEST_FAILURE';
const RESET_GET_PROJECT_DETAIL_STATE = 'RESET_GET_PROJECT_DETAIL_STATE'

const getProjectDetail = (formData) => {

    return {
        type: POST_GET_PROJECT_DETAIL_REQUEST,
        formData
    }
}
const resetGetProjectDetail = (formData) => {

    return {
        type: RESET_GET_PROJECT_DETAIL_STATE,
        formData
    }
}

export {
    getProjectDetail,
    resetGetProjectDetail,
    RESET_GET_PROJECT_DETAIL_STATE,
    POST_GET_PROJECT_DETAIL_REQUEST,
    POST_GET_PROJECT_DETAIL_REQUEST_SUCCESS,
    POST_GET_PROJECT_DETAIL_REQUEST_FAILURE,
};