const POST_SELECT_PAYMENT_METHOD_REQUEST = 'POST_SELECT_PAYMENT_METHOD_REQUEST';
const POST_SELECT_PAYMENT_METHOD_REQUEST_SUCCESS =  'POST_SELECT_PAYMENT_METHOD_REQUEST_SUCCESS';
const POST_SELECT_PAYMENT_METHOD_REQUEST_FAILURE =  'POST_SELECT_PAYMENT_METHOD_REQUEST_FAILURE';
const RESET_SELECT_PAYMENT_METHOD_STATE ='RESET_SELECT_PAYMENT_METHOD_STATE'

const selectPaymentMethod = (formData)=>{

    return {
        type: POST_SELECT_PAYMENT_METHOD_REQUEST,
        formData
    }
}

export {
    selectPaymentMethod,
    RESET_SELECT_PAYMENT_METHOD_STATE,
    POST_SELECT_PAYMENT_METHOD_REQUEST,
    POST_SELECT_PAYMENT_METHOD_REQUEST_SUCCESS,
    POST_SELECT_PAYMENT_METHOD_REQUEST_FAILURE,
};