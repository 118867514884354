import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  SafeAreaView,
  Pressable,
  ImageBackground,
} from "react-native";
import BackgroundImage from "../../../assets/images/bg_home.png";
import HeaderLogo from "../../../assets/images/tutorial_logo.png";
import CenterImage from "../../../assets/images/s2_logo.png";
import { useTranslation } from 'react-i18next'

const FreeBusiness = () => {
  const { t } = useTranslation();
  return (
      <ImageBackground
        source={BackgroundImage}
        resizeMode="cover"
        style={{ flex: 1 }}
      >
        <View style={styles.header}>
          <Image
            source={HeaderLogo}
            resizeMode="contain"
            style={styles.headerLogo}
          />
        </View>
        <Image
          source={CenterImage}
          resizeMode="contain"
          style={styles.centerImage}
        />
        <Text style={styles.industryText}>{t("common:free_business_tools")}</Text>
        <View style={styles.pointsContainer}>
          <Text style={styles.pointText}>{t("common:manage_all_your")}</Text>
          <Text style={styles.pointText}>{t("common:crm_platform_for_great")}</Text>
          <Text style={styles.pointText}>{t("common:professional_chat_funtion")}</Text>
          <Text style={styles.pointText}>{t("common:keep_track_of_all")}</Text>
        </View>
      </ImageBackground>
  )
}

export default FreeBusiness

const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
  },
  header: {
    paddingHorizontal: 15,
    height: 80,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 40
  },
  headerLogo: {
    height: 50,
    width: 150,
  },
  skipText: {
    fontSize: 17,
    color: "#FFF",
    fontWeight: "600",
  },

  centerImage: {
    marginVertical: 20,
    alignSelf: "center",
    height: 110,
    width: 110,
  },
  industryText: {
    alignSelf: "center",
    fontSize: 22,
    color: "#FFFFFF",
    fontWeight: "bold",
    textAlign:"center",
    textTransform:"uppercase"
  },
  pointsContainer: {
    marginTop: 15,
    backgroundColor: "#760001",
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  pointText: {
    color: "#FFF",
    fontWeight: "300",
    fontSize: 14,
    paddingVertical: 5
  },
})