const POST_UPDATE_PRO_USER_REQUEST = "POST_UPDATE_PRO_USER_REQUEST";
const POST_UPDATE_PRO_USER_REQUEST_SUCCESS = "POST_UPDATE_PRO_USER_REQUEST_SUCCESS";
const POST_UPDATE_PRO_USER_REQUEST_FAILURE = "POST_UPDATE_PRO_USER_REQUEST_FAILURE";
const RESET_UPDATE_PRO_USER_STATE = "RESET_UPDATE_PRO_USER_STATE";

const updateProUser = (formData) => {
  return {
    type: POST_UPDATE_PRO_USER_REQUEST,
    formData
  };
};
const resetUpdatePro = () => {
  return {
    type: RESET_UPDATE_PRO_USER_STATE,
    
  };
};

export {
  updateProUser,
  resetUpdatePro,
  POST_UPDATE_PRO_USER_REQUEST,
  POST_UPDATE_PRO_USER_REQUEST_SUCCESS,
  POST_UPDATE_PRO_USER_REQUEST_FAILURE,
  RESET_UPDATE_PRO_USER_STATE
};
