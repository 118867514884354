import {
  POST_CITIES_LIST_REQUEST,
  POST_CITIES_LIST_REQUEST_SUCCESS,
  POST_CITIES_LIST_REQUEST_FAILURE,
  RESET_CITIES_LIST_STATE
} from "./Action"

const initialState = {
  message: null,
  data: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CITIES_LIST_REQUEST: {
      console.log(POST_CITIES_LIST_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_CITIES_LIST_REQUEST_SUCCESS: {
      const { success } = action.payload;
      console.log(POST_CITIES_LIST_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: success,
        message: success.message,
      };
    }
    case POST_CITIES_LIST_REQUEST_FAILURE: {
      console.log(POST_CITIES_LIST_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        message: error?.message,
        errorData: error
      };
    }
    case RESET_CITIES_LIST_STATE: {
      console.log(RESET_CITIES_LIST_STATE);
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
