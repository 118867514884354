const POST_BASIC_START_REQUEST = "POST_BASIC_START_REQUEST";
const POST_BASIC_START_REQUEST_SUCCESS = "POST_BASIC_START_REQUEST_SUCCESS";
const POST_BASIC_START_REQUEST_FAILURE = "POST_BASIC_START_REQUEST_FAILURE";
const RESET_BASIC_START_STATE = "RESET_BASIC_START_STATE";
const SET_LANGUAGE = "SET_LANGUAGE"
const SET_BASIC_START = "SET_BASIC_START"
const basicStart = (formData) => {
  return {
    type: POST_BASIC_START_REQUEST,
    formData

  };
};
const resetBasicStart = () => {
  return {
    type: RESET_BASIC_START_STATE,
  };
};
const setLang = (language) => {
  return {
    type: SET_LANGUAGE,
    language

  };
};
const setBasicStart = (payload) => {
  return {
    type: SET_BASIC_START,
    payload

  };
};
export {
  basicStart,
  setLang,
  setBasicStart,
  resetBasicStart,
  RESET_BASIC_START_STATE,
  POST_BASIC_START_REQUEST,
  POST_BASIC_START_REQUEST_SUCCESS,
  POST_BASIC_START_REQUEST_FAILURE,
  SET_LANGUAGE,
  SET_BASIC_START
};
