import React, { useState, useEffect } from "react";
import { StyleSheet, Text, View, Image, Pressable, ImageBackground, I18nManager } from "react-native";
import LogoImage from "../../assets/images/ugo.png";
import Background from "../../assets/images/bg.png";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SplashScreen from "expo-splash-screen";
import RadioButton from "../../Components/RadioButton";
import { useSafeAreaInsets } from "react-native-safe-area-context";
const LanguageSelector = ({ navigation }) => {
  const { i18n } = useTranslation();
  const [language, setlanguage] = useState("en");
  const insets = useSafeAreaInsets()
  useEffect(() => {
    I18nManager.forceRTL(language === "ar");
    i18n.changeLanguage(language);
    AsyncStorage.setItem("user-language", language);
  }, [language]);

  return (
    <>
      <ImageBackground onLoad={async () => await SplashScreen.hideAsync()} style={styles.imageContainer} source={Background}>
        <View style={styles.logoContainer}>
          <Image source={LogoImage} style={styles.logoImage} />
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.chooseLanguageText}>CHOOSE THE LANGUAGE</Text>
          <View style={styles.languageSelector}>
            <Pressable style={styles.selectBtn} onPress={() => setlanguage("en")}>
              <Text style={styles.languageText}>English</Text>
              <RadioButton checked={language === "en"} />
            </Pressable>
            <Pressable style={styles.selectBtn} onPress={() => setlanguage("es")}>
              <Text style={styles.languageText}>Spanish</Text>
              <RadioButton checked={language === "es"} />
            </Pressable>
            <Pressable style={styles.selectBtn} onPress={() => setlanguage("ar")}>
              <Text style={styles.languageText}>Arabic</Text>
              <RadioButton checked={language === "ar"} />
            </Pressable>
            <Pressable style={styles.selectBtn} onPress={() => setlanguage("ta")}>
              <Text style={styles.languageText}>Tamil</Text>
              <RadioButton checked={language === "ta"} />
            </Pressable>
            <Pressable style={styles.selectBtn} onPress={() => setlanguage("hi")}>
              <Text style={styles.languageText}>Hindi</Text>
              <RadioButton checked={language === "hi"} />
            </Pressable>
          </View>
          <Pressable onPress={() => navigation.navigate("Signup")} style={styles.nextBtn}>
            <Text style={styles.nextText}>Next</Text>
          </Pressable>
        </View>
      </ImageBackground>
      <View style={{ height: insets.bottom, backgroundColor: "#EB2C3B" }}></View>

    </>
  );
};
export default LanguageSelector;
const styles = StyleSheet.create({
  topContainer: {
    flex: 1,
  },
  imageContainer: {
    flex: 1,
    resizeMode: "cover",
  },
  logoContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    flex: 2,
    marginHorizontal: 24,
  },
  logoImage: {
    height: 100,
    width: 300,
    resizeMode: "contain",
  },
  chooseLanguageText: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#ffffff",
    fontWeight: "700",
  },
  languageSelector: {
    flex: 1,
    marginTop: 10,
  },
  selectBtn: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
    height: 50,
  },
  languageText: {
    color: "#ffffff",
    fontWeight: "500",
  },
  nextBtn: {
    justifyContent: "flex-end",
    backgroundColor: "#EB2C3B",
    padding: 15,
    alignItems: "center",
    marginBottom: 20,
    borderRadius: 5,
  },
  nextText: {
    color: "#ffffff",
    fontSize: 14,
    fontWeight: "500",
  },
});
