const POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST = 'POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST';
const POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST_SUCCESS =  'POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST_SUCCESS';
const POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST_FAILURE =  'POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST_FAILURE';
const RESET_SEND_REQUEST_TO_PROFESSIONAL_STATE ='RESET_SEND_REQUEST_TO_PROFESSIONAL_STATE'

const sendRequestToProfessional = (formData)=>{

    return {
        type: POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST,
        formData
    }
}
const resetSendRequestToProfessional = ()=>{

    return {
        type: RESET_SEND_REQUEST_TO_PROFESSIONAL_STATE,
        
    }
}
export {
    sendRequestToProfessional,
    resetSendRequestToProfessional,
    RESET_SEND_REQUEST_TO_PROFESSIONAL_STATE,
    POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST,
    POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST_SUCCESS,
    POST_SEND_REQUEST_TO_PROFESSIONAL_REQUEST_FAILURE,
};