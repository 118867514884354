const POST_FORGOT_PASSWORD_REQUEST = "POST_FORGOT_PASSWORD_REQUEST";
const POST_FORGOT_PASSWORD_REQUEST_SUCCESS = "POST_FORGOT_PASSWORD_REQUEST_SUCCESS";
const POST_FORGOT_PASSWORD_REQUEST_FAILURE = "POST_FORGOT_PASSWORD_REQUEST_FAILURE";
const FORGOT_PASSWORD_RESET_STATE = "FORGOT_PASSWORD_RESET_STATE";

const forgotPassword = (formData) => {
  return {
    type: POST_FORGOT_PASSWORD_REQUEST,
    formData
  };
};


export {
  forgotPassword,
  POST_FORGOT_PASSWORD_REQUEST,
  POST_FORGOT_PASSWORD_REQUEST_SUCCESS,
  POST_FORGOT_PASSWORD_REQUEST_FAILURE,
  FORGOT_PASSWORD_RESET_STATE
};
