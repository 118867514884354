const POST_NEW_EMPLOYEE_REQUEST = "POST_NEW_EMPLOYEE_REQUEST";
const POST_NEW_EMPLOYEE_REQUEST_SUCCESS = "POST_NEW_EMPLOYEE_REQUEST_SUCCESS";
const POST_NEW_EMPLOYEE_REQUEST_FAILURE = "POST_NEW_EMPLOYEE_REQUEST_FAILURE";
const RESET_NEW_EMPLOYEE_STATE = "RESET_NEW_EMPLOYEE_STATE";

const newEmployee = (formData) => {
  return {
    type: POST_NEW_EMPLOYEE_REQUEST,
    formData,
  };
};
const resetNewEmployee = () => {
  return {
    type: RESET_NEW_EMPLOYEE_STATE,
  };
};
export {
  newEmployee,
  resetNewEmployee,
  RESET_NEW_EMPLOYEE_STATE,
  POST_NEW_EMPLOYEE_REQUEST,
  POST_NEW_EMPLOYEE_REQUEST_SUCCESS,
  POST_NEW_EMPLOYEE_REQUEST_FAILURE,
};
