const POST_REMOVE_PAY_IN_METHOD_REQUEST = 'POST_REMOVE_PAY_IN_METHOD_REQUEST';
const POST_REMOVE_PAY_IN_METHOD_REQUEST_SUCCESS = 'POST_REMOVE_PAY_IN_METHOD_REQUEST_SUCCESS';
const POST_REMOVE_PAY_IN_METHOD_REQUEST_FAILURE = 'POST_REMOVE_PAY_IN_METHOD_REQUEST_FAILURE';
const RESET_REMOVE_PAY_IN_METHOD_STATE = 'RESET_REMOVE_PAY_IN_METHOD_STATE'

const removePayInMethod = (formData) => {

    return {
        type: POST_REMOVE_PAY_IN_METHOD_REQUEST,
        formData
    }
}
const resetRemovePayInMethod = () => {

    return {
        type: RESET_REMOVE_PAY_IN_METHOD_STATE,
    }
}
export {
    removePayInMethod,
    resetRemovePayInMethod,
    RESET_REMOVE_PAY_IN_METHOD_STATE,
    POST_REMOVE_PAY_IN_METHOD_REQUEST,
    POST_REMOVE_PAY_IN_METHOD_REQUEST_SUCCESS,
    POST_REMOVE_PAY_IN_METHOD_REQUEST_FAILURE,
};