const POST_GET_BIDS_REQUEST = 'POST_GET_BIDS_REQUEST';
const POST_GET_BIDS_REQUEST_SUCCESS = 'POST_GET_BIDS_REQUEST_SUCCESS';
const POST_GET_BIDS_REQUEST_FAILURE = 'POST_GET_BIDS_REQUEST_FAILURE';
const RESET_GET_BIDS_STATE = 'RESET_GET_BIDS_STATE'

const getBids = (formData) => {

    return {
        type: POST_GET_BIDS_REQUEST,
        formData
    }
}
const resetGetBids = () => {

    return {
        type: RESET_GET_BIDS_STATE,
    }
}
export {
    getBids,
    resetGetBids,
    RESET_GET_BIDS_STATE,
    POST_GET_BIDS_REQUEST,
    POST_GET_BIDS_REQUEST_SUCCESS,
    POST_GET_BIDS_REQUEST_FAILURE,
};