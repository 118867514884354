export default {


  app_name: 'U’GO Pros',
  facebook_app_id: '1464655393624471',
  facebook_client_token: 'f93f25880f86c822b0eb4da7147c18c6',
  fb_login_protocol_scheme: 'fb1464655393624471',
  Continue_with_Facebook: 'Continuar con Facebook',
  Sign_in_with_Google: 'Iniciar sesión con Google',
  create_account: 'CREAR CUENTA AHORA',
  create_account_: 'CREAR CUENTA AHORA',
  user_privacy: 'USER PRIVACY STATEMENT',
  terms_conditions: 'DECLARACIÓN DE PRIVACIDAD DEL USUARIO',
  forgot_pass: '¿HAS OLVIDADO TU CONTRASEÑA?',
  terms: 'TERMS AND CONDITIONS',
  validationErrors: 'Errores de validación',
  meeting_note: '<b>Nota:</b> Una vez que usted (usuario) acepte la estimación, esta cantidad se deducirá de su método de pago registrado. Puede cancelar un proyecto en cualquier momento, pero una vez que se acepta un presupuesto y cancela el proyecto, se le cobrará el 5% de ese presupuesto por las tarifas de procesamiento. Al final del proyecto, el pago se ajustaría de acuerdo con el costo final.',
  bsb_number: 'Routing Number',
  account_number: 'Número de cuenta',
  account_name: 'Nombre de la cuenta',
  txt_response_rate: 'Ha respondido dentro de las 24 horas a 0 de la solicitud de reserva 0 que recibió el año pasado.',
  below_are_the_cities: 'Below are the cities you selected, wish to continue?\n\n',
  sunday_text: 'sol',
  monday_text: 'Lun',
  tuesday_text: 'mar',
  wednesday_text: 'casarse',
  thursday_text: 'Jue',
  friday_text: 'Vie',
  saturday_text: 'Se sentó',
  no_events: 'No hay eventos',
  call_failure: 'Failure!',

  invalid_attribute: 'Error al crear el calendario, \ n \ n valor de atributos no válido, \ n revise &amp; amp; ¡intentar otra vez!',
  your_earnings: 'Sus ganancias están disponibles instantáneamente para usted. No es necesario esperar un pago semanal. \ N \ nObtenga sus ganancias cuando las desee. Cobre con Instant Pay hasta 5 veces al día. \ N',
  reviews_detail: 'RESEÑAS DETAIL',
  oops_enter_payout_method_: 'Falta el método de pago. Ingrese el método de pago para recibir el pago. No se preocupe, su información está segura a través de nuestro procesamiento de pagos de terceros.',
  start_your_connection_with: 'Inicie su conexión con',
  about_business: 'Acerca del negocio',
  done: 'Hecha',
  account_type: 'TIPO DE CUENTA',
  checking: 'Checking',
  saving: 'Ahorro',
  business_checking: 'Cuenta corriente comercial',
  save: 'SALVAR',
  ach_payment: 'PAGO ACH',
  set_as_default: 'Establecer por defecto',
  feedback: 'REALIMENTACIÓN',
  tell_me_how_so_we_can_match_you_with_better_jobs: 'CUÉNTAME CÓMO PODEMOS EMPAREJARTE CON MEJORES TRABAJOS.',
  i_am_busy: 'Estoy ocupada',
  wrong_area: 'Área incorrecta',
  not_my_type_of_work: 'No es mi tipo de trabajo',
  submit: 'ENVIAR',
  actions: 'COMPORTAMIENTO',
  accept_amp_schedule: 'Aceptar y programar',
  reject: 'Rechazar',
  save_to_another: 'Guardar en otro',
  date: 'Fecha',
  request_details: 'PEDIR DETALLES',
  description: 'Descripción',
  media: 'Medios de comunicación',
  time: 'Hora',
  start_job: 'INICIAR TRABAJO',
  enter_final_cost_optional: 'Ingrese el costo final (Optional)',
  upload_work_photo: 'Subir foto de trabajo',
  project_amount: 'Monto del proyecto :',
  u_go_pros_fee: 'Tarifa de profesionales de U\'GO:',
  u_go_pros_fee_structure: ' Estructura de tarifas de U\'GO Pros',
  you_will_receive: 'Usted recibirá :',
  If_these_times_are_not_convenient: 'Si estos horarios no son convenientes para ti, no dudes en chatear con el usuario para encontrar un mejor momento que funcione para ambos.',
  _0: '$ 0',
  _10: '10%',
  submit_when_completed: 'ENVIAR CUANDO ESTÉ COMPLETADO',
  chat: 'CHAT',
  uploaded_pics: 'Fotos subidas',
  view_video: 'Ver video',
  contractor_note: 'Nota del contratista',
  contractor_uploaded_pics: 'Fotos subidas por el contratista',
  location: 'Localización',
  assign_job: 'ASIGNAR TRABAJO',
  change_employee: 'CAMBIAR EMPLEADA',
  project_already_assign: "Proyecto ya asignado a este empleado",
  send_official_cost: 'ENVIAR COSTO OFICIAL',
  new_project: 'NUEVO PROYECTO',
  add_document: 'Agregar documento',
  max_limit_1: '(Límite máximo 1)',
  next: 'PRÓXIMA',
  add_employee: 'AÑADIR EMPLEADA',
  this_information_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'Esta información se guardará en unos segundos y se enviará al correo electrónico del usuario proporcionado en el formulario anterior.',
  show_us_more_about_your_project: ' Muéstrenos más sobre su proyecto',
  add_photo: 'Añadir foto',
  max_limit_5: '(Límite máximo 5)',
  add_video: 'Añadir video',
  add_work_video: 'Agregar vídeo de trabajo',
  max_duration_30sec: 'Max video duration 30 sec',
  where_is_the_service_location: '¿DÓNDE ESTÁ LA UBICACIÓN DEL SERVICIO?',
  i_m_ready_for_quotes: 'Estoy lista para citas',
  review: 'REVISIÓN',
  close: 'CERRAR',
  select_any_option: 'SELECCIONA CUALQUIER OPCIÓN',
  view_photo: 'VER FOTO',
  view_photos: 'Ver Fotos',
  already_pay_out: 'Ya pague',
  _0_usd: '$ 0 USD',
  expected_earnings: 'Ganancias esperadas',
  total: 'Total',
  _0_project: '0 Proyecto',
  total_complete_project: 'Proyecto completo total',
  total_active_project: 'Proyecto activo total',
  _0_response_rate: 'Tasa de respuesta del 0%',
  response_rate: 'Tasa de respuesta',
  send: 'ENVIAR',
  auto_services: 'SERVICIOS DE AUTO',
  project_details: 'DETALLES DEL PROYECTO',
  hourly_price: 'Precio por hora',
  n_a: 'N/A',
  portfolio: 'portafolio',
  default_album: 'Álbum predeterminado',
  recent_activities: 'Actividades recientes',
  _15_online_bids_100_response_rate: '15 ofertas en línea, tasa de respuesta del 100%.',
  business_detail: 'Detalle del negocio',
  add_review: 'Add Review',
  reviews: 'Reseñas',
  select_your_service_category: 'Seleccione su categoría de servicio',
  change_password: 'CAMBIAR LA CONTRASEÑA',
  change_password_mark: '¿CAMBIAR LA CONTRASEÑA?',
  please_enter_the_code_you_were_sent_by_email_and_reset_your_new_password: 'Please enter the code you were sent by email and reset your new password.',
  messages: 'MENSAJES',
  contact_info: 'Datos de contacto',
  project_info: 'Información del proyecto',
  category: 'Categoría',
  view_document: 'Ver documento',
  budget_dolar: 'Presupuesto $',
  min_budget: 'Presupuesto mínimo $',
  max_budget: 'Presupuesto máximo $',
  assigning_detail: 'Asignación de detalles',
  bid_detail: 'Detalle de la oferta',
  notes: 'NOTAS',
  contact_us: 'Contacta con nosotras',
  send_us_an_email: 'SEND US AN EMAIL',
  let_us_know_what_you_think_don_t_hesitate_to_send_us_questions_or_comments_we_value_your_thoughts_and_will_respond_in_a_timely_manner: '¡Háganos saber lo que piensas! No dude en enviarnos preguntas o comentarios. Valoramos sus pensamientos y responderemos de manera oportuna.',
  bonded_insured: 'Consolidado / asegurado',
  accredited: 'Autorizada',
  nari_member: 'Miembro de NARI',
  service_area: 'Área de servicio',
  speciality_services: 'Servicios especiales',
  categories: 'Categorías',
  add_utility_bill: 'Agregar factura de servicios públicos',
  portfolio_optional: 'Portafolio (opcional)',
  by_creating_account_you_agree_to_our: 'Al crear una cuenta, acepta nuestra',
  register: 'REGISTRARSE',
  create_profile: 'CREAR PERFIL',
  date_of_birth: 'Fecha de nacimiento',
  credits: 'CREDITOS',
  u_go_reward_credits: 'CRÉDITOS DE RECOMPENSA U\'GO',
  friend_referral: 'Recomendación de un amigo',
  rate_contractor: 'Contratista de tarifas',
  _20_gift_card: 'Tarjeta de regalo de $ 20',
  _20_instantly_for_your_friend_and_20_for_you_once_their_project_completed: '$ 20 instantáneamente para su amigo y $ 20 para usted una vez que se complete su proyecto',
  redeem_credits: 'Redeem Credits',
  add: 'AGREGAR',
  default_payment: 'Pago predeterminado',
  documents: 'DOCUMENTOS',
  edit_profile: 'Editar perfil',
  add_view: 'Agregar / Ver',
  service: 'Servicio',
  state: 'Expresar',
  city: 'Ciudad',
  update: 'ACTUALIZAR',
  employee: 'EMPLEADA',
  record_not_found: 'Registro no encontrado',
  employee_info: 'INFORMACIÓN DEL EMPLEADO',
  mobile_no: 'NO MÓVILES',
  email: 'EMAIL',
  address: 'DIRECCIÓN',
  delete: 'Borrar',
  tell_us_more_about_your_project: 'Cuéntanos más sobre tu proyecto',
  project_description: 'Descripción del Proyecto',
  what_is_your_budget: '¿Cual es tu presupuesto?',
  what_is_your_contact_info: '¿CUÁL ES SU INFORMACIÓN DE CONTACTO?',
  what_is_your_availability: '¿Cuál es su disponibilidad?',
  _1st_option_date: 'Fecha de la primera opción',
  _2nd_option_date: 'Fecha de la segunda opción',
  estimates: 'ESTIMADAS',
  your_available_time: 'TU HORA DISPONIBLE',
  best_case_scenario: 'EN EL MEJOR DE LOS CASOS',
  worst_case_scenario: 'PEOR DE LOS CASOS',
  exclusive_services: 'SERVICIOS EXCLUSIVOS',
  forgot_password: 'OLVIDÓ LA CONTRASEÑA ',
  forgot_password_mark: '¿HAS OLVIDADO TU CONTRASEÑA?',
  enter_your_email_below_to_receive_your_password_reset_instructions: 'Ingrese su correo electrónico a continuación para recibir las instrucciones para restablecer su contraseña.',
  send_password: 'ENVIAR CONTRASEÑA',
  friends_referral: 'REFERENCIA DE AMIGAS',
  enter_friend_email_below_to_refer_your_friend: 'Introduzca el correo electrónico de un amigo a continuación para referir a su amiga.',
  _20_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_home_user_n_n_50_instantly_for_your_friend_their_first_project_completion_if_the_referred_friend_is_pro_user: '$ 20 instantáneamente para su amigo la primera finalización del proyecto si el amigo recomendado es un usuario doméstico. \ N \ n $ 50 instantáneamente para su amigo la primera finalización del proyecto si el amigo recomendado es un usuario profesional.',
  help: 'Ayuda',
  faq: 'FAQ',
  send_feedback: 'ENVIAR COMENTARIOS',
  home_services: 'SERVICIOS A DOMICILIO',
  status: 'Estado',
  final_amount: 'Cantidad final',
  note: 'Nota',
  login: 'ACCESO',
  user: 'USUARIA',
  remember_me: '¿Recuérdame?',
  connect_with_facebook: 'CONÉCTATE CON FACEBOOK',
  connect_with_google: 'CONECTARSE CON GOOGLE',
  pro: 'PRO',
  map: 'MAPA',
  time_availability: 'Disponibilidad de tiempo',
  accept: 'ACEPTAR',
  budget: 'Presupuesto',
  click_to_request_on_site_visit: 'Haga clic para solicitar una visita al sitio',
  action: 'Acción',
  i_am_not_interested: 'No estoy interesada',
  i_am_interested: 'Estoy interesada',
  my_rewards: 'MI RECOMPENSA',
  official_cost: 'COSTE OFICIAL',
  your_official_price_can_be_set_by_you_when_you_arrive_before_project_starts_from_here_client_can_still_refuse_service_it_is_recommended_to_stay_within_the_range_you_set: 'Usted puede establecer su precio oficial cuando llegue antes de que comience el proyecto. Desde aquí, el cliente aún puede rechazar el servicio. Se recomienda permanecer dentro del rango establecido.',
  on_site_visit_requests: 'SOLICITUDES DE VISITA IN SITU',
  pay_with_card: 'PAGAR CON TARJETA',
  paypal: 'PAYPAL',
  free_transaction: 'Transacción gratuita',
  no_minimum_transfer: 'Sin transferencia mínima',
  delete_project: 'BORRAR PROYECTO',
  user_privacy_statement: 'DECLARACIÓN DE PRIVACIDAD DEL USUARIO',
  credit_card: 'Tarjeta de crédito',
  bank_transfer: 'Transferencia bancaria',
  country: 'País',
  usa: 'EE.UU',
  currency: 'Divisa',
  usd: 'USD',
  add_payout: 'Agregar pago',
  schedules: 'HORARIOS',
  pro_detail: 'DETALLE PRO',
  payout_detail: 'Detalle de pago',
  account_info: 'Informacion de cuenta',
  instant_payment: 'Pago instantáneo',
  routing_number: 'Número de ruta',
  auto_renew_your_subscription: 'Renovar automáticamente su suscripción',
  profile: 'PROFILE',
  projects: 'Proyectos',
  reward: 'RECOMPENSA',
  credits_points: 'Puntos de créditos',
  enter_Pin: 'Ingrese su PIN',
  view_detail: 'Ver Detalle',
  cancel_project: 'CANCELAR PROYECTO',
  edit_project: 'EDITAR PROYECTO',
  reschedule_project: 'PROYECTO DE REPROGRAMACIÓN',
  my_project: 'MI PROYECTO',
  you_have_not_completed_any_project_yet: 'Aún no has completado ningún proyecto',
  speciality_services_pro: 'Servicios especializados Pro',
  available_time: 'Tiempo disponible',
  decline: 'DISMINUCIÓN',
  min_budget_normal: 'Presupuesto mínimo',
  max_budget_normal: 'Presupuesto máximo',
  review_detail: 'Detalle de revisión',
  use_amount_from_credits: 'Usar cantidad de créditos',
  your_availability_time_preference: 'SU DISPONIBILIDAD - Preferencia de tiempo',
  specialty_service_partners: 'Socios de servicios especializados',
  special_pro_detail: 'DETALLE ESPECIAL PRO',
  email_col: 'Correo electrónico:',
  mobile_no_col: 'No móviles:',
  service_col: 'Servicio:',
  state_col: 'Expresar:',
  cities_col: 'Ciudades:',
  detail: 'DETALLE',
  request_info: 'Solicitar información',
  phone: 'Teléfono',
  select_cities_you_offer_your_services: 'Seleccione las ciudades en las que ofrece sus servicios',
  specialty_service: 'Servicio Especializado:',
  connecting_consumers_with: 'Conectando a las consumidoras con',
  highly_skilled_industry_professionals_in_their_communities: 'profesionales de la industria altamente calificados en sus comunidades.',
  are_you_looking_to_have_a_project_completed: 'Are you looking to have a project completed',
  or: 'o',
  sign_up_as_an_industry_professional: 's¿Iniciar como profesional de la industria?',
  select_all_categories_below: 'Seleccione todas las categorías a continuación',
  employee_contact_info: 'Información de contacto del empleado',
  name: 'Nombre',
  company: 'Compañía',
  min_bid_amount: 'Cantidad mínima de oferta',
  max_bid_amount: 'Cantidad máxima de oferta',
  confirm_project: 'Confirmar proyecto',
  send_issue: 'ENVIAR NÚMERO',
  accept_official_cost: 'ACEPTAR COSTO OFICIAL',
  subscription: 'SUSCRIPCIÓN',
  cancel: 'CANCELAR',
  terms_and_conditions: 'TÉRMINOS Y CONDICIONES',
  skip: 'SALTAR',
  signup: 'Inscribirse',
  edit_info: 'EDITAR INFORMACIÓN',
  this_inform_will_be_save_in_few_seconds_and_send_to_the_user_email_provided_in_above_form: 'Este informe se guardará en unos segundos y se enviará al correo electrónico del usuario proporcionado en el formulario anterior.',
  upload_pictures: 'SUBIR FOTOS',
  upload: 'SUBIR',
  video: 'VIDEO',
  wire: 'CABLE',
  _20_transaction_fee: 'Tarifa de transacción de $ 20.',
  minimum_transaction_250: 'Transacción mínima $ 250',
  account_holder: 'Titular de la cuenta',
  bank_address: 'Dirección del banco',
  withdraw: 'RETIRAR',
  available_balance: 'SALDO DISPONIBLE',
  withdraw_amount: 'Cantidad de retiro',
  services_offered: 'SERVICIOS OFRECIDOS',
  become_a_pro: 'Conviértete en una profesional',
  add_event: 'Añadir evento',
  get_free_quotes: 'OBTENER COTIZACIONES GRATIS',
  tell_us_about_the_project_you_would_like_completed_and_we_will_connect_you_with_several_industry_professionals_in_your_community_you_can_then_read_through_their_reviews_and_select_the_professional_to_work_on_your_project: 'Cuéntenos sobre el proyecto que le gustaría completar y lo conectaremos con varios profesionales de la industria en su comunidad. Luego puede leer sus reseñas y seleccionar al profesional para trabajar en su proyecto.',
  use_instant_request: 'USAR SOLICITUD INSTANTÁNEA',
  in_a_hurry_or_have_an_emergency_project_tell_us_more_about_the_project_and_we_will_quickly_connect_you_to_the_first_available_professional_usually_within_2_hours: '¿Tienes prisa o tienes un proyecto de emergencia? Cuéntenos más sobre el proyecto y lo conectaremos rápidamente con el primer profesional disponible (generalmente dentro de 2 horas).',
  select_this_option_for_a_directory_list_of_our_specialty_professional_partners_in_your_city_find_your_lawyers_realtors_insurance_agents_doctors_etc: 'Seleccione esta opción para obtener una lista de directorio de nuestros socios profesionales especializados en su ciudad. Encuentre sus abogados, agentes inmobiliarios, agentes de seguros, médicos, etc.',
  instant_requests: 'SOLICITUDES INSTANTÁNEAS',
  leads: 'GUÍAS',
  invoices: 'FACTURAS',
  settings: 'AJUSTES',
  downloading: 'Descargando ...',
  select_cities: 'Selegir ciudades',
  are_you_sure_you_want_to_move_back: '¿Estás seguro de que quieres volver?',
  you_will_lose_your_progress: 'Perderás tu progreso.',
  no: 'NO',
  yes: 'SÍ',
  your_account_is_under_review_please_allow_24_28: 'Su cuenta está en revisión. Espere entre 24 y 28 horas para recibir un correo electrónico de confirmación. Póngase en contacto con support@ugopros.com con cualquier pregunta sobre la cuenta.',
  payout_detail_confirmation: 'Confirmación de detalles de pago',
  please_confirm_all_info_entered_is_correct_we_want_to_make_sure_you_get_paid_for_the_projects_you_complete: 'Confirme que toda la información ingresada sea correcta. Queremos asegurarnos de que le paguen por los proyectos que complete.',
  rate_user: 'Calificar usuario',
  _20_instantly_for_you_and_your_friend_on_first_project_completion_if_the_referred_friend_is_home_user_n_50_instantly_for_you_on_first_project_completion_if_the_referred_friend_is_pro_user: '$ 20 instantáneamente para usted y su amigo al completar el primer proyecto si el amigo referido es un usuario doméstico. \ n $ 50 instantáneamente para ti al completar el primer proyecto si el amigo recomendado es un usuario profesional.',
  add_credit_for_the_bigger_jobs_pay_now_for_jobs_later: 'Agregue crédito por los trabajos más grandes, pague ahora por los trabajos más tarde.',
  add_credits: 'AÑADIR CRÉDITOS',
  no_default_card: 'SIN tarjeta predeterminada',
  add_payment_method: 'Añadir método de pago',
  payment_method: 'Método de Pago',
  payment_history: 'Historial de Pagos',
  add_credit_card: 'Add Credit Card',
  add_credit_gift_code: 'Agregar crédito / código de regalo',
  apply: 'APLICAR',
  payment_defaults: 'Incumplimientos de pago',
  set_payment_default: 'Establecer Método de Pago Predeterminado',
  default_payment_method: 'Método de Pago Predeterminado',
  amount: 'Cantidad',
  view: 'Vista',
  revenue_detail: 'DETALLE DE INGRESOS',
  total_revenue: 'Los ingresos totales',
  total_withdraw: 'Total RETIRO',
  payable_balance: 'Saldo a pagar',
  pending_withdraw: 'A la espera de Retiro',
  new_: 'NUEVA',
  active: 'ACTIVA',
  completed: 'TERMINADA',
  notification: 'Notificación',
  message: 'Mensaje',
  write_your_message: 'escribe tu mensaje',
  sign_out: 'Desconectar',
  my_projects: 'MIS PROYECTOS',
  site_visit_request: 'SOLICITUD DE VISITA AL SITIO',
  archived: 'ARCHIVADA',
  canceled: 'CANCELADA',
  consumer_benefits: 'Beneficios para el consumidor',
  normal: 'NORMAL',
  specialty: 'ESPECIALIDADESPECIALIDAD',
  invoiced: 'FACTURADA',
  paid: 'PAGADA',
  enter_your_issue_detail: 'Ingrese el detalle de su problema',
  add_new_event: 'Agregar nuevo evento',
  tap_on_date_to_view_schedule: 'Toque la fecha para ver el horario.',
  my_earnings: 'Mis Ganancias',
  withdraw_history: 'Retirar historial',
  waiting_for_response: 'Esperando una respuesta',
  confirm: 'CONFIRMAR',
  would_you_like_to_create_a: '¿Le gustaría crear un',
  Pin: 'Alfiler?',
  enter_pin: 'INGRESE SU PIN',
  enter_password: 'INTRODUCIR LA CONTRASEÑA',
  use_different_account: 'USE UNA CUENTA DIFERENTE',
  u_go_pros_inc: 'U\'GO Pros Inc',

  pay: 'PAGAR',
  next_payment_schedule: 'Siguiente programa de pago',
  credit: 'CRÉDITO',
  debit: 'DÉBITO',
  continue_: 'SEGUIR',
  schedule: 'CALENDARIO',
  payment: 'PAGO',
  change_pin: 'Cambiar PIN',
  notifications: 'Notificaciones',
  pin: 'Alfiler',
  online_status: 'Estado en línea',
  email_message: 'Mensaje de correo electrónico',
  add_edit: 'AÑADIR / EDITAR',
  selected_categories: 'CATEGORÍAS SELECCIONADAS',
  dismiss: 'DESPEDIR',
  add_more: 'AÑADIR MÁS',
  project: 'PROYECTO',
  earning: 'GANADORA',
  withdraw_status: 'ESTADO DE RETIRO',
  project_leads: 'LÍDERES DEL PROYECTO',
  offline_mode: 'MODO OFFLINE',
  ugo_to: 'Hasta $',
  pay_dol: 'Pagar $',
  are_you_sure_you_want_to_credit: '¿Está seguro de que desea acreditar $',
  are_you_sure_you_want_to_delete: '¿Está seguro de que desea eliminar este proyecto de la lista?',
  logout: 'Cerrar sesión',
  complete: 'Completa',
  please_complete_your_profile: 'Por favor complete su perfil.',
  ready_for_quotes: 'Lista para citas',
  estimates_received: 'Estimaciones recibidas',
  pending_for_official_cost: 'Pendiente de costo oficial',
  project_started: 'Proyecto iniciado',
  please_confirm_project: 'Confirme el proyecto',
  successfully_completed: 'Completado con éxito',
  before_payment: 'Antes del pago',
  after_payment: 'Después del pago',
  issue_reported: 'Problema informado',
  refunded_by_admin: 'Reembolsado por el administrador',
  official_cost_recieved: 'Costo oficial recibido',
  waiting_for_pro_to_start: 'Esperando que comience Pro',
  please_wait: 'Espere por favor...',
  waiting_for_pros_response: 'Esperando la respuesta de los profesionales',
  started_by_pro: 'Iniciado por Pro',
  need_for_rechedule: 'Necesidad de reprogramar',
  project_canceled: 'Proyecto cancelado',
  create_your_pin: 'Crea tu pin',
  please_select_what_you_want_to_do: 'Seleccione lo que quiere hacer',
  remove_change_pin: '¿Quitar/Cambiar el Pin?',
  remove_pin: 'Quitar Pin',
  successfully_scheduled: 'Programado exitosamente',
  project_accepted: 'Proyecto aceptado',
  accepted_by_pro: 'Aceptado por Pro',
  image_attched: 'Imagen adjunta',
  video_attached: 'Video adjunto',
  valid_till: 'Válida hasta : ',
  rejected: 'Rechazada',
  confirmed: 'Confirmada',
  are_you_sure_you_want_to_reject: '¿Está seguro de que desea rechazar la solicitud de visita in situ antes de',
  are_you_sure_you_want_confirmed: '¿Está seguro de que desea una solicitud de visita in situ confirmada por',
  attached: '\" Attached\"',
  cannot_upload_file_to_server: 'No se puede cargar el archivo al servidor',
  update_review: 'ACTUALIZAR REVISIÓN',
  response_rate_per: '% Tasa de respuesta',
  add_debit_card: 'Add Debit Card',
  add_payout_method: 'Agregar método de pago',
  update_video: 'Actualizar video',
  enter_friend_email_user: 'Ingrese el correo electrónico de amigo a continuación para recomendar a su amigo como Usuario.',
  enter_friend_email_pro: 'Ingrese el correo electrónico de un amigo a continuación para recomendar a su amigo como Pro.',
  credit_dol: 'Créditos: $',
  free: 'Libre',
  are_you_sure_you_want_delete: '¿Estás segura de que quieres eliminar el proyecto?',
  are_you_sure_too_cancel: '¿Estás seguro de que deseas cancelar este proyecto?',
  rating: 'Clasificación',
  total_credits: 'Creditos totales:',
  cities: 'Ciudades',
  the_amount_you_have_submitted: 'La cantidad que ha enviado($',
  if_you_agree_to_the_official: ') es menor que el costo oficial. Si acepta el costo oficial, presione la opción de pago; de lo contrario, rechace O comuníquese con nosotros si hay algún problema.',
  refund_dol: 'REEMBOLSO $',
  the_amount_you_have: 'La cantidad que ha enviado $',
  otherwise_rejected_or_contact_us: '\" es mayor que el costo oficial. Si está de acuerdo presione la opción REEMBOLSO, de lo contrario rechace O contáctenos si tiene alguna pregunta.\"',
  if_there_are_any_problems: 'La cantidad que ha enviado es igual al costo oficial del proyecto. Si todo está bien, confirme a continuación, de lo contrario, contáctenos si hay algún problema.',
  are_you_sure_you_want_cancel: '¿Estás segura de que quieres cancelar proyecto?',
  if_you_submit_you_will_refund: 'Si envía, se le cobrará una tarifa de procesamiento del 5% de su reembolso.',
  are_you_sure_you_want_to_confirm: '¿Está seguro de que desea confirmar el proyecto?',
  pro_will_be_paid_for_this: 'Pro se pagará por esto.',
  pending: 'Pendiente',
  accepted: 'Aceptada',
  referral_code: 'código de referencia',
  expiry_col: 'Expiración:',
  next_payment_schedule_col: '"Siguiente programa de pago: "',
  status_active: 'Estado: Active',
  status_cancelled: 'Estatutos: Cancelled',
  update_payout: 'Actualizar pago',
  please_add_it_by_pressing: 'Aún no ha agregado un método de pago. Agréguelo presionando el botón en la parte inferior.',
  onsite_visit_request_sent: 'Solicitud de visita in situ enviada',
  onsite_visit_request_rejected: 'Solicitud de visita in situ rechazada',
  onsite_visit_scheduled: 'Visita in situ programada',
  waiting_for_acceptance: 'Esperando aceptación',
  qoute_and_onsite_visit: 'Solicitud de cotización y visita in situ enviada',
  qoute_sent: 'Cotización enviada',
  waiting_for_official_cost: 'Esperando costo oficial',
  assigned_to_employee: 'Asignada a la empleada',
  rejected_by_employee: 'Rechazado por empleado',
  job_started: 'Trabajo iniciado',
  waiting_for_confirmation: 'Esperando confirmación',
  official_cost_sent: 'Costo oficial enviado',
  project_awarded: 'Proyecto adjudicado',
  in_progress: 'En progreso',
  closed: 'Cerrada',
  bid_amount: 'Importe de la oferta : $',
  exit: 'SALIDA',
  enter_old_pin: 'ingrese el pin antiguo',
  confirm_pin: 'confirmar PIN',
  back: 'ESPALDA',
  wrong_pin_keyboard: 'teclado de clavija incorrecta',
  enter_new_pin: 'ingrese un nuevo pin',
  wrong_pin_locked: 'PIN incorrecto bloqueado',
  user_guide: 'GUÍA DEL USUARIO',
  job_no: 'Trabajo #',
  project_col: 'Proyecto :',
  posted_by_col: '"publicado por : "',
  online_mode: 'MODO EN LÍNEA',
  are_you_sure_accept: 'Estas segura que quieres aceptar',
  are_you_sure_want_reject: '¿Estás seguro de que quieres rechazar?',
  assign_to_col: '" Asignar a:  "',
  status_assigned: 'Estado: asignado',
  status_rejected: 'Estado: Rechazado',
  status_waiting: 'Estado: esperando confirmación',
  final_amount_col: 'Monto final: $',
  issue_detail: 'Detalle del problema',
  official_cost_dol: 'Costo oficial: $',
  project_title_col: '"Título del Proyecto: "',
  photos_attached: 'Fotos adjuntas',
  video_attached: 'Video adjunto',
  document_attached: 'Documento adjunto',
  please_confirm_final_amount: 'Confirme el monto final de $',
  once_you_tap_confirm_you_will: '. Una vez que toque "confirmar", no podrá cambiar el costo final',
  employee_note: 'Nota del empleado',
  employee_upload_pics: 'Empleada fotos subidas',
  official_document: 'Documento oficial',
  are_you_sure_you_want_to_delete_emp: '¿Está seguro de que desea eliminar a este empleado?',
  employee_since: '"Employee since "',
  are_you_sure_to_assign: '¿Está seguro de que desea Asignar proyecto a',
  i_am_intrested: '¿Está seguro de que desea pagar $ 10 para la visita in situ? Esa tarifa se reembolsará solo si el cliente acepta su oferta. \ n \ nSi prefiere enviar un presupuesto gratis sin la visita al sitio, toque cancelar y seleccione "ME INTERESA" en la parte inferior de la pantalla.',
  pay_again: 'Pagar de nuevo',
  please_recharge_your_account: 'Su solicitud de visita in situ fue rechazada debido a un saldo insuficiente en su cuenta. Recargue su cuenta y vuelva a intentarlo.',
  your_on_site_visit: 'Su solicitud de visita in situ fue rechazada y no se le cobrará $ 10.',
  budget_dol: 'Presupuesto: $',
  please_confirm_if_you_will: 'SOLICITUDES INSTANTÁNEAS Los usuarios esperan que el proyecto comience en 2 horas. "Confirme" si estará disponible',
  debit_card: 'Tarjeta de débito',
  resubscribe: 'Suscribirse de nuevo',
  project_no: '"Project# "',
  i_am_ready: 'ESTOY LISTO PARA CONECTARME CON UN PRO',
  edit: 'EDITAR',
  language: 'Idioma',
  english: 'English',
  spanish: 'Spanish',
  tamil: 'Tamil',
  hindi: 'Hindi',
  arabic: 'Arabic',
  contractor_notes_n_if_project: 'Notas del contratista \ n (si el proyecto no se completa, simplemente salga cuando haya terminado y luego reanude el trabajo más tarde)',
  first_name: 'Nombre de pila',
  last_name: 'Nom de famille',
  password: 'Contraseña',
  confirm_password: 'Confirmez le mot de passe',
  confirm_password_optional: 'Confirmar Contraseña (Opcional)',
  verification_code: 'CÓDIGO DE VERIFICACIÓN',
  verify_pin: 'Verificar PIN',
  new_password: 'NUEVA CONTRASEÑA',
  your_name: 'Tu nombre',
  contact_person_first_name: 'Nombre de la persona de contacto',
  contact_person_last_name: 'Apellido de la persona de contacto',
  company_name: 'nombre de empresa',
  company_ein_tax_id: 'Número de identificación fiscal / EIN de la empresa',
  licence_no_optional: 'Licencia No (Opcional)',
  tap_to_create_project: 'TOCA para crear proyecto',
  home_auto_or_exclusive_services: 'SERVICIOS A DOMICILIO, AUTO O EXCLUSIVOS',
  friend_pro_referral: 'Amigo / Pro Referencia',
  enter_title: 'Ingrese el título',
  account_iban: 'Account/ IBAN',
  swift_bic_code: 'Swift/ Bic code',
  new_password_optional: 'Nueva contraseña (opcional)',
  reach_out_directly_with_this: 'Comuníquese directamente con este profesional chateando aquí. Simplemente comience a escribir en este cuadro y confirme cuando haya terminado',
  company_phone_number: 'Número de teléfono de la empresa',
  paypal_account: 'Paypal Account',
  card_number: 'Número de tarjeta',
  enter_project_description: 'Ingrese la descripción del proyecto',
  please_enter_project_description: 'Por favor, ingrese la descripción del proyecto.',
  please_provide_information: 'Proporcione información sobre proyectos anteriores y su empresa. Incluya cualquier pregunta que pueda tener.',
  hourly_estimate_optional: 'Estimación por hora $ (opcional)',
  total_min_estimate_best_case_scenario: 'Estimación mínima total $ (mejor escenario)',
  total_max_estimate_worst_case_scenario: 'Estimación máxima total $ (peor escenario)',
  please_name_your_project: 'Por favor nombre su proyecto',
  social_security_number: 'Número de seguridad social',
  years_in_business: 'Años en negocios',
  old_password_optional: 'Contraseña anterior (opcional)',
  other_category_optional: 'Otra categoría (opcional)',
  wrong_email_password: 'Correo electrónico / contraseña incorrectos',
  please_enter_your_email: 'Por favor introduzca su correo electrónico',
  please_enter_your_name: 'Por favor, escriba su nombre',
  please_enter_your_project_name: 'Ingrese el nombre de su proyecto',
  please_enter_discription: 'Ingrese la descripción del proyecto',
  please_enter_your_min: 'Ingrese su presupuesto mínimo $',
  please_enter_your_max_budget: 'Ingrese su presupuesto máximo $',
  please_enter_your_valid_min: 'Por favor, ingrese su presupuesto mínimo válido $',
  please_enter_your_max_valid_budget: 'Por favor, ingrese su presupuesto máximo válido $',
  min_budget_should_less_than_max: 'El presupuesto mínimo debe ser inferior al presupuesto máximo',
  please_enter_your_budget: 'Ingrese su presupuesto $',
  please_enter_your_budget_at_least_1: 'Ingrese su presupuesto al menos $1',
  please_select_date_before_time: 'Seleccione la fecha anterior a la hora establecida',
  no_user_found: 'Usuario no encontrado',
  please_enter_your_message: 'Por favor ingrese su mensaje',
  please_enter_valid_email: 'Por favor introduzca un correo electrónico válido',
  please_enter_your_card_no: 'Ingrese su número de tarjeta',
  please_enter_cvc: 'Ingrese su CVC',
  please_select_future_date: 'Seleccione una fecha futura',
  pics_not_available: '¡Imagen no disponible!',
  document_not_available: '¡Documento no disponible!',
  video_not_available: '¡Video no disponible!',
  please_enter_official_cost: 'Agregue el costo oficial.',
  please_add_official_cost: 'Agregue el costo oficial al menos',
  please_enter_your_password: 'Por favor, introduzca su contraseña',
  password_dose_not_matched: '¡Las contraseñas no coinciden!',
  please_enter_your_first_name: 'Por favor, introduzca su nombre de pila',
  please_enter_your_last_name: 'Por favor ingrese su apellido',
  please_enter_your_phone: 'Ingrese su teléfono',
  please_enter_password: 'Por favor, introduzca su contraseña',
  password_must_contain_a_minimum: 'La contraseña debe contener un mínimo de 8 caracteres',
  pin_successfully_change: 'cambio de pin con éxito',
  please_enter_your_confirm_password: 'Ingrese su contraseña de confirmación',
  please_enter_your_address: 'Por favor ingrese su direccion',
  phone_length_10: 'La longitud del número de teléfono debe ser 10',
  please_enter_contact_first_name: 'Ingrese el nombre de la persona de contacto',
  please_enter_contact_lastname: 'Ingrese el apellido de la persona de contacto',
  password_dose_not_match: 'Confirmar contraseña no coincide',
  please_enter_dob: 'Ingrese la fecha de nacimiento.',
  please_enter_company_name: 'Ingrese el nombre de la empresa',
  please_enter_mobile_number: 'Introduzca el número de móvil',
  please_enter_address: 'Por favor ingrese la dirección',
  please_enter_contractor_notes: 'Introduzca las notas del contratista',
  please_enter_at_least_one_work_photo: 'Sube al menos una foto del trabajo.',
  you_must_be_18: 'Debe tener 18 años o más para utilizar nuestros servicios. Si tiene algún problema, contáctenos en support@ugopros.com',
  please_agree_to_our: 'Acepte nuestros TÉRMINOS Y CONDICIONES',
  please_select_your_available: 'Seleccione su tiempo disponible',
  please_enter_issue_detail: 'Ingrese los detalles del problema',
  please_add_profile_photo: 'Por favor agregue foto de perfil',
  please_set_your_loaction: 'Establezca su ubicación',
  please_select_address: 'Por favor seleccione Dirección',
  please_select_address_from: 'Seleccione la dirección de la lista desplegable',
  please_add_availability_date: 'Por favor agregue la fecha de disponibilidad',
  please_add_available_time: 'Agregue el tiempo de disponibilidad',
  enter_address_or_single_press_on_map: 'Ingrese la dirección o presione una vez en el mapa',
  type_to_search: 'Escribe para buscar',
  _3rd_option_date: 'Fecha de la tercera opción',
  you_cannot_select_past_date: 'No puede seleccionar una fecha pasada',
  thank_you_for_uploading_document: 'Gracias por subir el documento. Espere a que la revisión tenga la cuenta activada.',
  reason: 'Razón',
  enter_amount: 'Ingrese la cantidad',
  please_enter_amount: 'Por favor ingrese la cantidad!',
  home: 'HOGAR',
  track_earning: 'PISTA DE GANANCIAS',
  manage_employees: 'ADMINISTRAR EMPLEADOS',
  payout_method: 'MÉTODO DE PAGO',
  payments: 'PAYMENTS',
  offers: 'OFERTAS',
  special_requests: 'SOLICITUDES ESPECIALES',
  first_project_completion: '$20 Finalización del primer proyecto si el amigo referido es un usuario doméstico. \n$50 instantáneamente para usted al completar el primer proyecto si el amigo recomendado es un usuario profesional.',
  confirmation: 'Confirmación',
  refer_a_pro: 'Recomendar a un profesional',
  refer_your_friend: 'Recomienda a tu amigo',
  loading_payment_method: 'Cargando métodos de pago ...',
  enabling_instant_payment: 'Habilitación de métodos de pago instantáneos ...',
  loading_data: 'Cargando datos...',
  loading_payment_detail: 'Cargando detalles de pago ...',
  it_will_take_some_time: 'Tomará un poco de tiempo....',
  sending: 'Enviando...',
  updating: 'Actualizando...',
  setting_default_payment_method: 'Configuración del método de pago predeterminado ...',
  compressing_the_size: 'Comprimir el tamaño del video.',
  uploading_data: 'Subiendo datos',
  your_account_suspended: 'Su cuenta ha sido suspendida porque el seguro de su empresa ha expirado. Cargue la nueva información del seguro.',
  profile_incomplete: 'Perfil incompleto',
  please_complete_profile: 'Por favor complete su perfil',
  please_enter_your_date_of_birth: 'Ingrese su fecha de nacimiento para activar su cuenta.',
  please_enter_account_holder_name: 'Ingrese el nombre del titular de su cuenta.',
  please_enter_your_routing_number: 'Ingrese su número de ruta',
  please_enter_your_account_number: 'Ingrese su número de cuenta',
  sesson_has_been_expire: 'Su sesión ha caducado, inicie sesión de nuevo.',
  internet_connection_issue: 'Problema de conexión a Internet. Vuelva a intentarlo más tarde.',
  please_check_your_email_for_our: 'Por favor revise su correo electrónico para obtener nuestra respuesta. Valoramos sus comentarios. Debería tener noticias nuestras dentro de las 24 horas siguientes.',
  account_has_been_updated: 'La cuenta se ha actualizado correctamente. Espere la revisión para activar la cuenta.',
  project_has_been_completed: 'El proyecto se ha completado, espere la confirmación del usuario.',
  you_have_not_added_a_payout: 'Aún no ha agregado un método de pago. Añádalo primero.',
  information: 'Información!',
  if_profile_needs_updated: 'Si es necesario actualizar el perfil, no dude en hacerlo.',
  please_add_your_credit: 'Primero agregue su tarjeta de crédito.',
  for_sercurity_and_payout: 'Para las funciones de seguridad y pago, se requiere más información.',
  update_app: 'UPDATE APP',
  not_now: 'AHORA NO',
  your_official_price_can_be: 'Usted puede establecer su precio oficial cuando llegue antes de que comience el proyecto. Desde aquí, el cliente aún puede rechazar el servicio. Se recomienda permanecer dentro del rango establecido.',
  thank_you_for_your_payment: 'Gracias por tu pago. Ahora puede realizar una visita in situ.',
  request_sent_successfully: 'Solicitud enviada con éxito. Se le notificará cuando el cliente acepte su solicitud de visita in situ.',
  credit_card_is_valid: 'La tarjeta de crédito no es válida',
  are_your_sure_you_want_to_delete: '¿Estás segura de que quieres eliminar esta tarjeta?',
  would_you_like_to_auto: '¿Le gustaría renovar automáticamente su suscripción?',
  please_select_your_subscription: 'Seleccione su suscripción.',
  alert: 'Alerta!',
  we_hate_to: 'Odiamos que se vaya, si tiene algún problema, no dude en comunicarse con nosotros al 877-733-2425 o info@ugopros.com cancelar su suscripción suspenderá su cuenta al final de su ciclo de facturación.',
  yes_cancel: 'SI cancelar',
  no_i_will_stay: 'NO, me quedaré',
  you_have_not_added: 'Aún no agregaste tu tarjeta de débito.',
  please_select_at_least: 'Seleccione al menos una subcategoría.',
  you_have_selected_to: 'Ha seleccionado activar el pago automático. ¿Confirmar si desea continuar?',
  turn_off: 'APAGAR',
  are_you_sure_you_want_to_delete_this: '¿Está seguro de que desea eliminar este método de pago?',
  if_project_has_not: 'Si el proyecto no ha sido confirmado dentro de los 14 días, su total ESTIMADO será entregado a su cuenta.',
  video_lenght_should_30: 'La duración del video debe ser de 30 segundos como máximo.',
  you_can_not_uodalog_5: 'No puedes subir más de 5 fotos.',
  please_enter_date_of_birth: 'Ingrese la fecha de nacimiento para la activación de su cuenta.',
  congratulation_your_account: 'Enhorabuena Su cuenta está activada ahora.',
  not_availeable: 'No disponible. Para más detalles contáctenos.',
  please_enter_account_iban: 'Ingrese la cuenta / IBAN',
  please_enter_swift: 'Ingrese el código Swift / Bic',
  please_enter_aba: 'Ingrese ABA / Número de ruta',
  please_enter_account_holder: 'Ingrese el nombre del titular de la cuenta',
  media_not_available: 'Medios no disponibles!',
  please_enter_aba_routing: 'Ingrese ABA / Número de ruta',
  please_enter_bank_account_number: 'Ingrese el número de cuenta bancaria',
  please_enter_back_name: 'Ingrese el nombre del banco',
  please_add_account: 'Por favor agregue el tipo de cuenta',
  please_enter_bank_address: 'Ingrese la dirección del banco',
  instant_request: 'SOLICITUD INSTANTÁNEA',
  free_online_leads: 'Clientes potenciales en línea gratuitos',
  manage_all_your: '•Administra todas tus empleados o equipos\n',
  here_you_will_see: 'Aquí verá nuevos clientes potenciales de proyectos para que pueda enviar cotizaciones gratuitas.',
  here_you_are_able_to_chat: 'Aquí puedes chatear con tus clientes manteniéndote en la misma página durante todo el proyecto.',
  we_are_always_here: 'Estamos siempre aquí para usted. Valoramos sus pensamientos. Aquí puede compartir sus ideas o inquietudes con nosotros.',
  here_is_you_able_to_chat: 'Aquí puedes chatear con tus clientes manteniéndote en la misma página durante todo el proyecto.',
  we_are_always_here_for_you: 'Estamos siempre aquí para usted. Valoramos sus pensamientos. Aquí puede compartir sus ideas o inquietudes con nosotros.',
  here_you_can_make: 'Aquí puede estar disponible para las solicitudes más urgentes. La opción de modo en línea dice que estará disponible para iniciar proyectos dentro de las 2 horas posteriores a la solicitud.',
  there_is_nothing: 'No hay nada más loco que hacer una doble reserva. Aquí puede mantener su horario para saber lo que está por venir. Programe sus proyectos con precisión con esta función.',
  why_not_grow: '¿Por qué no hacer crecer su negocio para tener muchos empleados o equipos? ¡Aquí puede administrar su personal para que pueda asignar los proyectos que se le otorgan!',
  back_button: 'Botón de retroceso',
  loagin_button: 'Botón de inicio de sesión',
  here_is_where_you_see: 'Aquí es donde puede ver su crédito disponible. No solo puede GANAR créditos, sino que también puede AGREGAR créditos para pagar esos proyectos más costosos.',
  we_are_here_to_help: 'Estamos aquí para ayudarlo a administrar sus proyectos. Ya sea que tenga una solicitud urgente o un proyecto simple, aquí es donde comienza el viaje para encontrar un profesional. Muchos enviarán una cotización, pero tú eliges quién completará la tarea.',
  here_you_will_see_the_status: 'Aquí verá el estado de los proyectos que ha ingresado. Verá si un proyecto recibió una cotización enviada o si el proyecto se inició. Caminamos contigo hasta que estés completamente satisfecho con tu proyecto terminado.',
  this_is_where_you: 'Aquí es donde comprobaría el estado de los proyectos más urgentes. Entonces, si hay una emergencia en la mayoría de los casos, tenemos profesionales disponibles para ayudar dentro de las 2 horas posteriores a la solicitud.',
  here_you_are_able: 'Aquí puede charlar con su profesional de servicio y mantenerlo en sintonía durante la finalización del proyecto.',
  we_are_always_here_for: 'Estamos siempre aquí para usted. Valoramos sus pensamientos. Aquí puede compartir sus ideas o inquietudes con nosotros.',
  send_free_qoutes: '• Envíe cotizaciones gratuitas',
  grow_your_business: '•Haga crecer su negocio',
  manage_your_employee: '•Administra a tus empleadas',
  track_clients: '• Seguimiento de clientes, trabajos y facturas',
  utilize_the_free: '•Utilice el software CRM gratuito',
  connect_directly_with: '• Conéctese directamente con los consumidores sobre proyectos',
  select_your_services: '• Seleccione su ubicación de servicio',
  no_annual_fee: '• Sin cuota anual',
  industry_professional: 'Beneficios profesionales de la industria',
  connect_with_a_vetted_pro: '• Conéctese con un profesional investigado en su comunidad',
  recieve_multiple_qoute: '• Reciba múltiples cotizaciones para proyectos',
  read_reviews_of_pros: '• Leer reseñas de profesionales y comparar',
  manage_all_projects: '•Administra todos los proyectos en la aplicación.',
  communicate_with_your: '• Comunicarse con su proveedor de servicios',
  send_payment_once_project: '• Envíe el pago una vez que se complete el proyecto',
  refer_a_friend_and: '•Recomienda a una amiga y recibe crédito de la cuenta',
  provide_accurate_qoute: '• Proporcione cotizaciones precisas basadas en imágenes y chat',
  exposure_for_your: '• Exposición para su empresa',
  live_mode_for_instant: '• Modo en vivo para proyectos instantáneos / urgentes o programados',
  choose_the_jobs: '• Elija los trabajos que desee',
  crm_platform_for_great: '• Plataforma CRM para una gran satisfacción del cliente',
  professional_chat_funtion: '•Función de chat profesional',
  keep_track_of_all: '• Realice un seguimiento de todos sus proyectos y facturas',
  free_business_tools: 'Herramientas comerciales gratuitas',
  please_enter_your_project: 'Ingrese la descripción de su proyecto para continuar con el chat.',
  ok: 'OK',
  jobs: 'Trabajos',
  requests: 'Peticiones',
  got_it: 'Entendido',

  please_enter_ein: 'Ingrese EIN / identificación fiscal',
  please_enter_social_number: 'Ingrese el número de seguro social.',
  please_enter_correct_ssn: 'Ingrese el número de seguro social correcto.',
  please_check_the_box: 'Marque la casilla de Fianza / seguro y muestre prueba de Fianza / seguro',
  please_check_the_box_only: 'Marque la casilla de Fianza / seguro',
  please_show_proof_of_bond: 'Por favor muestre comprobante de fianza / seguro',
  please_select_bounded: 'Seleccione Asegurado / En condiciones de servidumbre',
  please_enter_about_business: 'Ingrese Acerca del negocio',
  please_enter_new_password: 'Por favor ingrese nueva contraseña',
  please_enter_old_password: 'Ingrese la contraseña anterior',
  please_enter_your_correct: 'Ingrese la ubicación correcta',
  please_add_service_area: 'Agregue un área de servicio',
  please_add_categories: 'Por favor agregue categorías',
  please_enter_year_of: 'Ingrese años en el negocio',
  pleaes_check_the_box_for_ar: 'Marque la casilla de Acreditado',
  please_check_the_box_nari: 'Marque la casilla para miembro de NARI',
  please_enter_company_ein: 'Ingrese el EIN / número de identificación fiscal de la empresa',
  please_add_company_ein_id: 'Por favor agregue el EIN de la empresa / foto de identificación fiscal',
  tell_us_about_the_project_using_chat_picture_and_or_videos: 'Cuéntanos sobre el proyecto mediante chat, imágenes y / o videos..',
  obtain_multiple_quotes_from_ready_and_available_pros: 'Obtenga múltiples cotizaciones de profesionales listos y disponibles.',
  book_your_fully_vetted_pro_for_same_day_repair_or_schedule_them_at_your_convenience: 'Reserve su PRO completamente examinado para repararlo el mismo día o prográmelo a su conveniencia.',
  schedule_pay_and_rate_your_pro_while_keeping_track_of_all_your_jobs_with_the_u_go_pros_app: 'Programe, pague y califique a su profesional mientras realiza un seguimiento de todos sus trabajos con la aplicación U\'GO Pros.',
  you_will_be_charged: 'Se le cobrará el mejor de los casos de $',
  before_pro_starts_job: '! Antes de que el profesional comience a trabajar, se le notificará el costo real una vez que lo evalúen. Desde aquí puedes rechazar o aceptar',
  please_enter_title_of_your_event: 'Ingrese el título de su evento.',
  please_enter_detail_of_your_event: 'Por favor ingrese los detalles de su evento.',
  please_select_time_of_your_event: 'Seleccione la hora de su evento.',
  please_select_date_of_your_event: 'Seleccione la fecha de su evento.',
  please_enter_the_reason_for_cancel_to_continue: 'Ingrese el motivo de la cancelación para continuar.',
  you_havent_enough_credits: 'No tienes suficientes créditos.',
  please_select_month_from_list: 'Seleccione el mes de la lista',
  please_select_year_from_list: 'Seleccione el año de la lista',
  google_play_services_required: 'Se requieren los servicios de Google Play: ',
  after_installing_close_and_relaunch_this_app: 'después de la instalación, cierre y reinicie esta aplicación.',
  no_network_connection_available: 'No hay conexión de red disponible.',
  cancelling_required_permissions_are_not_granted: 'Cancelando, no se otorgan los permisos requeridos',
  connection_problem: 'Problema de conexión, compruebe su Internet y vuelva a intentarlo más tarde',
  oh_no: '¡Oh, no! ',
  microphone_permission: 'Se necesita permiso de micrófono para grabar. Permita la configuración de la aplicación para funciones adicionales.',
  external_storage_permission: 'Se necesita permiso de almacenamiento externo. Permita la configuración de la aplicación para funciones adicionales.',
  camera_permission: 'Se necesita permiso de la cámara. Permita la configuración de la aplicación para funciones adicionales.',
  please_provide_personal_past_experience_and_company_info: 'Proporcione información personal, experiencia pasada e información de la empresa',
  please_add_total_minimum_estimate: 'Por favor, agregue la estimación mínima total. PS',
  please_add_total_minimum_estimate_at_least_1: 'Agregue la estimación mínima total al menos $ 1',
  please_add_total_maximum_estimate_at_least_1: 'Agregue la estimación máxima total al menos $ 1',
  please_add_total_maximum_estimate: 'Por favor agregue la estimación máxima total? $',
  total_minimum_estimate_should_be_less_than_total_maximum_estimate: 'La estimación mínima total debe ser menor que la estimación máxima total.',
  please_select_your_available_time: 'Seleccione su tiempo disponible',
  permission_deny: 'Permiso denegado',
  mediaActivity_not_available: 'MediaActivity no disponible!',
  portfolio_not_found: 'Cartera no encontrada',
  select_second_time: 'Seleccione su segundo tiempo disponible nuevamente',
  select_third_time: 'Seleccione su tercera vez disponible nuevamente',
  commissioned_base_services: 'Para servicios básicos encargados. Ej: Agentes inmobiliarios, abogados, agentes de seguros, etc.\n\n Gracias por su interés en U\'GO Pros. Estamos dedicados a conectarlo con más clientes. Asociarse con nosotros lo colocará en un directorio para consumidores. Podrían notificarle a través del chat en nuestra plataforma, oa través de su contacto personal.\n\n Costos de servicio: sin cargos ocultos\n',
  yearly: 'Anual:     ',
  monthly: 'Mensual:     ',
  these_fees_are_subject: 'Estas tarifas están sujetas a cambios, pero se le notificará con anticipación si esto sucede.\n\n Si está interesado en asociarse con nosotros, continúe con el proceso de registro. Si tiene alguna pregunta, no dude en comunicarse al 877-733-2425 o info@ugopros.com',
  chose_to_resubscribe: 'Me alegro de que haya decidido volver a suscribirse, seleccione cómo desea que se pague su suscripción mensual o anualmente.',
  plumber_request: 'SOLICITUD DE FONTANERO',
  schedule_meeting: 'PROGRAMAR REUNIÓN',
  error_pdf_docs: 'Error, solo se permiten archivos pdf y docs en el documento',
  please_enter_paypall_account: 'Ingrese la cuenta de Paypal',
  please_enter_verify_code: 'Ingrese el código de verificación',
  please_enter_your_new_password: 'Ingrese su nueva contraseña',
  choose_service_distance: 'Elija la distancia del área de servicio',
  unable_to_get_info: 'No pudimos obtener la información necesaria de su cuenta. Da permiso / actualiza la aplicación e inténtalo de nuevo',
  please_enter_service_location: 'Ingrese la ubicación del servicio',
  no_data_found: 'Datos no encontrados',
  add_portfolio_picture: 'Por favor agregue la imagen de la cartera',
  cannot_access_location: '¡Permiso! No puede acceder a los datos de ubicación',
  information_not_found: 'Información no encontrada',
  you_cannot_select_past_time: 'No puedes seleccionar tiempo pasado',
  error_occurs: 'Ocurrió un error',
  select_special_service: 'Seleccione Servicio especial',
  select_state: 'Seleccione el estado',
  select_city: 'Seleccione la ciudad',
  enter_your_withdraw_amount: 'Ingrese su monto de retiro',
  less_than_total_balance: 'La cantidad debe ser menor que el saldo total',
  enter_business_description: 'Ingrese la descripción de la empresa',
  please_show_proof_of_register_certificate: 'Muestre el comprobante de certificado de registro',
  please_show_proof_of_pen_card: 'Por favor muestre comprobante de tarjeta de bolígrafo',
  please_show_proof_of_aadhar_card: 'Por favor, muestre un comprobante de la tarjeta AAdhar',
  please_show_proof_of_voter_identity_card: 'Muestre un comprobante de la tarjeta de identidad de votante',
  please_show_proof_of_gst: 'Muestra prueba de GST',
  please_show_proof_of_licese: 'Por favor muestre comprobante de licencia de conducir.',
  please_check_the_box_for_register_certificate_and_show_poof: 'Please check the box for Register Certificate and show proof of Register Certificate',
  please_check_the_for_register_certificate: 'Marque la casilla de Certificado de registro',
  please_check_the_box_for_pen_card_and_show_poof: 'Por favor, marque la casilla de la tarjeta de la pluma y muestre el comprobante de la tarjeta de la pluma.',
  please_check_the_box_only_for_pen_card: 'Por favor, marque la casilla de Tarjeta de bolígrafo',
  please_check_the_box_for_adhar_card_and_show_poof: 'Marque la casilla de la tarjeta adhar y muestre un comprobante de la tarjeta adhar',
  please_check_the_box_only_for_adhar_card: 'Marque la casilla para la tarjeta adhar',
  please_check_the_box_for_adhar_card: 'Marque la casilla de la tarjeta Adhar',
  please_check_the_box_for_voter_identityr_card_and_show_poof: 'Marque la casilla correspondiente a la Tarjeta de identidad de votante y muestre un comprobante de la Tarjeta de lápiz',
  please_check_the_box_for_voter_identity_card: 'Marque la casilla correspondiente a la Tarjeta de identidad de votante',
  please_check_the_box_for_gst_and_show_poof: 'Marque la casilla correspondiente al GST y muestre el comprobante de la tarjeta de bolígrafo',
  please_check_the_box_for_gst: 'Marque la casilla de GST',
  please_check_the_box_for_driving_license_and_show_poof: 'Marque la casilla correspondiente al permiso de conducir y muestre el comprobante de la tarjeta del bolígrafo.',
  please_check_the_box_for_driving_License: 'Marque la casilla correspondiente al permiso de conducir.',
  you_must_be_18_: 'Debe tener 18 años o más para utilizar nuestros servicios.',
  select_payment_method: 'Seleccionar método de pago',
  google_payment_is_not_available_in_this_device: 'Google Payment no está disponible en este dispositivo',
  us: 'US',
  _5_credit_for_reviews_are_for_jobs_that_are_complete_over_10: 'El crédito de $ 5 por revisiones es para trabajos que se completaron en más de $ 10',

  //employee

  login_in: 'ACCESO',

  mobile: 'Móvil#',

  please_enter_valid_email_address: 'Por favor ingrese una dirección de correo electrónico válida.',

  minimum_password_length_should_be: 'La longitud mínima de la contraseña debe ser',

  don_t_have_an_account: '¿No tienes una cuenta?',

  agree_to_terms_conditions: 'Términos y condiciones',
  privacy_policy: 'Política de privacidad',

  google_maps_key: 'AIzaSyBb2sUywacICcNUEmkhGsMUXIArFSoWUfc',

  please_check_network_connection: 'Comprueba tu conexión de red',
  add_lead: 'AÑADIR PLOMO',
  write_your_message_here: 'escriba su mensaje aquí ...',
  lead_detail: 'DETALLE DE PLOMO',
  superviser: 'SUPERVISORA',
  followed_up: 'Seguimiento',
  in_person_schedule: 'En persona programada',

  refused: 'Rechazado',
  requested_callback: 'Devolución de llamada solicitada',

  all_employees: 'TODOS LOS EMPLEADOS',

  manage: 'ADMINISTRAR',
  employees: 'EMPLEADOS',
  please_enter_user_name: 'Por favor ingrese el nombre de usuario',

  oops: '¡UPS!',
  task_list: 'LISTA DE TAREAS',
  setting: 'Configuración',

  update_employee: 'ACTUALIZAR EMPLEADO',

  please_enter_phone_number: 'Por favor ingrese el número de teléfono',

  please_enter_country: 'Por favor ingrese el país',
  please_enter_city: 'Por favor ingrese la ciudad',

  please_Enter_body: '\ "Introduzca el cuerpo \"',
  please_enter_postal_code: '\ "Introduzca el código postal \"',

  please_enter_First_name: 'Por favor ingrese su nombre',
  please_enter_last_name: 'Por favor ingrese su apellido',
  plese_select_the_team: 'Seleccione el equipo',

  this_app_is_designed_for_internal_staff_members_only: 'Cette application est conçue pour les membres du personnel interne uniquement.',
  follow_ugo_pros_to_utilize_pros_features: 'Suivez U\'GO Pro pour utiliser les fonctionnalités Pros',

  signup_as_an_industry_professional: '¿Registrarse como profesional de la industria?',
  supervisor: 'SUPERVISORA',

  user_login: 'INICIO DE SESIÓN DE USUARIO',
  user_name: 'nombre de usuario',

  team_id: 'ID de equipo',

  sale: 'VENTA',
  street: 'CALLE',
  phone_number: 'Número de teléfono',
  body: 'Cuerpo',
  postal_code: 'Código postal',

  new_task_s: 'NUEVA TAREA',
  current_task: 'TAREA ACTUAL',

  task_num: 'Tarea #',
  ready_to_start: 'Listo para comenzar',

  start: 'Comienzo',

  end_tracking: 'SEGUIMIENTO FINAL',
  task_detail: 'DETALLE DE LA TAREA',

  upload_images: 'SUBIR IMÁGENES',
  write_your_feedback_here: 'Escriba sus comentarios aquí',

  followed: 'SEGUIDA',
  lead_name: 'Nombre de cliente potencial',

  name_: 'Nombre:',
  address_: 'Dirección:',
  country_: 'País:',
  state_: 'Expresar:',
  city_: 'Ciudad:',
  meeting_detail: 'Detalle de la reunión:',

  start_tracking: 'INICIAR SEGUIMIENTO',
  meet_with_pro_to_get_information: 'Reúnase con Pro para obtener información',
  choose_option: 'Elige una opción',

  permission: 'Permiso',
  allow_to_access_your_location: '¿Permitir acceder a su ubicación?',
  allow: 'PERMITIR',
  never: 'NUNCA',

  days_ago: 'hace días',

  assign: 'Asignar',
  update_successfully: 'Actualizar correctamente',

  turn_on_location_on_device: 'enciende tu ubicación en el dispositivo',

  welcome_to_u_go_employee_staff: 'Bienvenido a U\'GO Empleada / Personal',
  account_deletion: 'ELIMINACIÓN DE CUENTA',
  delete_my_account: 'Borrar mi cuenta',
  delete_account: 'BORRAR CUENTA',
  importent_note: 'Nota IMPORTANTE',
  your_account_should_not_have_any_active_pending_project_n_your_account_should_not_have_any_active_payouts_n_after_successful_deletion_of_your_account_you_will_not_be_able_to_log_in_to_the_account_account_deletion_is_permanent_and_will_not_be_reversible: '• Tu cuenta no debe tener ningún proyecto pendiente activo\n• Su cuenta no debe tener ningún pago activo\n• Después de eliminar con éxito su cuenta, no podrá iniciar sesión en la cuenta. La eliminación de la cuenta es permanente y no será reversible.',
  please_acknowledge_that_you_have_read_the_above_note: 'Por favor reconozca que ha leído la nota anterior',
  acknowledged: 'Admitida',
  are_you_sure_you_want_to_delete_account: '¿Estás segura de que quieres eliminar la cuenta?',
  please_confirm_your_account_deletion_once_you_confirm_it_you_will_not_be_able_to_use_your_account_and_we_will_delete_all_the_data_associated_with_your_account: 'Confirme la eliminación de su cuenta, una vez que la confirme, no podrá usar su cuenta y eliminaremos todos los datos asociados con su cuenta.',
  please_acknowledge: 'Por favor, mencione',
  delete_accounts: 'Borrar cuenta',
  your_profile_is_incomplete_if_you_want_edit_profile_you_can_do_that_by_pressing_edit_profile_button: 'Su perfil está incompleto. Si desea editar el perfil, puede hacerlo presionando el botón Editar perfil.',
  sorry_you_have_not_yet_been_approved_by_admin: 'Lo sentimos, aún no ha sido aprobado por el administrador.',
  confirm_that_you_are: 'Confirma que eres mayor de 18 años',
  please_check_the_box_confirm_that_you_are: 'Marque la casilla para confirmar que tiene más de 18 años.',
  resend_link: 'Reenviar enlace',
  resend_in: 'Reenviar',
  create_pin: 'Crear pin',
  your_authentication_code: 'Su código de autenticación',
  please_enter_your_authentication_code: 'Por favor, ingrese su código de autenticación.',
  sign_in_securely: 'Vuelva a enviar el código de verificación',
  resend_authentication_code: 'Reenviar código de autenticación',
  two_setup_authentication: 'Autenticación de dos configuraciones',
  thanks_for_keeping_your_account_secure: 'Gracias por mantener tu cuenta segura.',
  please_check_your_email: 'Por favor revise su correo electrónico',
  enter_old_password: 'Ingrese la contraseña anterior',
  please_proceed_to_your_email_for_the_purpose_of_verification_in_order_to_proceed_to_the_next_step: 'Proceda a su correo electrónico con el propósito de verificación para continuar con el siguiente paso.',
  please_enter_social_number_or_ein: 'Ingrese el número de Seguro Social O EIN.',

};
