const POST_SPECIAL_REQUEST = 'POST_SPECIAL_REQUEST';
const POST_SPECIAL_REQUEST_SUCCESS = 'POST_SPECIAL_REQUEST_SUCCESS';
const POST_SPECIAL_REQUEST_FAILURE = 'POST_SPECIAL_REQUEST_FAILURE';
const RESET_SPECIAL_REQUEST_STATE = 'RESET_SPECIAL_REQUEST_STATE'

const getSpecialRequest = (formData) => {

    return {
        type: POST_SPECIAL_REQUEST,
        formData
    }
}
const resetSpecialRequest = () => {

    return {
        type: RESET_SPECIAL_REQUEST_STATE,
    }
}
export {
    getSpecialRequest,
    resetSpecialRequest,
    RESET_SPECIAL_REQUEST_STATE,
    POST_SPECIAL_REQUEST,
    POST_SPECIAL_REQUEST_SUCCESS,
    POST_SPECIAL_REQUEST_FAILURE,
};