import {
  POST_EDIT_PROJECT_REQUEST,
  POST_EDIT_PROJECT_REQUEST_SUCCESS,
  POST_EDIT_PROJECT_REQUEST_FAILURE,
  RESET_EDIT_PROJECT_STATE,
  SET_IS_QUICK_QUOTE
} from "./Action"

const initialState = {
  data: {},
  is_quick_quote: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_EDIT_PROJECT_REQUEST: {
      console.log(POST_EDIT_PROJECT_REQUEST);

      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case POST_EDIT_PROJECT_REQUEST_SUCCESS: {
      console.log(POST_EDIT_PROJECT_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
        message: action.payload.success?.message,
      };
    }
    case POST_EDIT_PROJECT_REQUEST_FAILURE: {
      console.log(POST_EDIT_PROJECT_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: error?.message,
        errorData: error
      };
    }
    case RESET_EDIT_PROJECT_STATE: {
      console.log(RESET_EDIT_PROJECT_STATE);
      return {
        ...initialState,
      };
    }
    case SET_IS_QUICK_QUOTE: {

      console.log(SET_IS_QUICK_QUOTE);
      return {
        ...state,
        is_quick_quote: action.quickQuote
      };
    }

    default:
      return state;
  }
};

export { reducer };
