const POST_END_PROJECT_BY_PRO_REQUEST = 'POST_END_PROJECT_BY_PRO_REQUEST';
const POST_END_PROJECT_BY_PRO_REQUEST_SUCCESS = 'POST_END_PROJECT_BY_PRO_REQUEST_SUCCESS';
const POST_END_PROJECT_BY_PRO_REQUEST_FAILURE = 'POST_END_PROJECT_BY_PRO_REQUEST_FAILURE';
const RESET_END_PROJECT_BY_PRO_STATE = 'RESET_END_PROJECT_BY_PRO_STATE'

const endProjectByPro = (formData) => {

    return {
        type: POST_END_PROJECT_BY_PRO_REQUEST,
        formData
    }
}
const resetEndProjectByPro = () => {

    return {
        type: RESET_END_PROJECT_BY_PRO_STATE,
    }
}
export {
    endProjectByPro,
    resetEndProjectByPro,
    RESET_END_PROJECT_BY_PRO_STATE,
    POST_END_PROJECT_BY_PRO_REQUEST,
    POST_END_PROJECT_BY_PRO_REQUEST_SUCCESS,
    POST_END_PROJECT_BY_PRO_REQUEST_FAILURE,
};