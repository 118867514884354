import { takeEvery, put, call } from 'redux-saga/effects';
import BASEURL from '../../URLs'
import { queryApi } from '../../QueryApi'
import {
  POST_REJECT_ON_SITE_VISIT_REQUEST,
  POST_REJECT_ON_SITE_VISIT_REQUEST_SUCCESS,
  POST_REJECT_ON_SITE_VISIT_REQUEST_FAILURE,
} from './Actions';
//import {queryApi} from '../query-api';

function* handler() {
  yield takeEvery(POST_REJECT_ON_SITE_VISIT_REQUEST, rejectOnSiteVisit);
}


function* rejectOnSiteVisit(action) {
  try {
    // API call
    console.log(action.formData)
    const apiResponse = yield call(queryApi, {
      endpoint: BASEURL.API_REJECT_ON_SITE_VISIT,
      method: 'POST',
      body: action.formData
    });
    //console.log('HIT',apiResponse)
    if (!("error" in apiResponse)) {
      yield put({
        type: POST_REJECT_ON_SITE_VISIT_REQUEST_SUCCESS,
        payload: apiResponse
      });

    } else {
      yield put({
        type: POST_REJECT_ON_SITE_VISIT_REQUEST_FAILURE,
        payload: apiResponse.error
      });
    }


  } catch (err) {
    console.log(err);
    //alert(err)
    yield put({
      type: POST_REJECT_ON_SITE_VISIT_REQUEST_FAILURE,
      payload: { error:{message: err} }
    });
  }
}

export { handler };