import {
  SET_CURRENT_STACK,
  RESET_CURRENT_STACK,
} from "./Actions";
const initialState = {
  stack: "OnBoardStack",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_STACK: {
      const { stack } = action
      console.log(SET_CURRENT_STACK);

      return {
        ...state,
        stack
      };
    }
    case RESET_CURRENT_STACK: {
      console.log(RESET_CURRENT_STACK);
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export { reducer };
