const POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST = "POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST";
const POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST_SUCCESS = "POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST_SUCCESS";
const POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST_FAILURE = "POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST_FAILURE";
const RESET_EMPLOYEE_UPDATE_LEAD_STATUS_STATE = "RESET_EMPLOYEE_UPDATE_LEAD_STATUS_STATE";

const employeeUpdateStatus = (formData) => {
  return {
    type: POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST,
    formData
  };
};

const resetEmployeeUpdateStatus = () => {
  return {
    type: RESET_EMPLOYEE_UPDATE_LEAD_STATUS_STATE,
  };
};
export {
  employeeUpdateStatus,
  resetEmployeeUpdateStatus,
  RESET_EMPLOYEE_UPDATE_LEAD_STATUS_STATE,
  POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST,
  POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST_SUCCESS,
  POST_EMPLOYEE_UPDATE_LEAD_STATUS_REQUEST_FAILURE,
};
