const POST_BID_ON_PROJECT_REQUEST = 'POST_BID_ON_PROJECT_REQUEST';
const POST_BID_ON_PROJECT_REQUEST_SUCCESS = 'POST_BID_ON_PROJECT_REQUEST_SUCCESS';
const POST_BID_ON_PROJECT_REQUEST_FAILURE = 'POST_BID_ON_PROJECT_REQUEST_FAILURE';
const RESET_BID_ON_PROJECT_STATE = 'RESET_BID_ON_PROJECT_STATE'

const bidOnProject = (formData) => {

    return {
        type: POST_BID_ON_PROJECT_REQUEST,
        formData
    }
}
const resetBidOnProject = () => {

    return {
        type: RESET_BID_ON_PROJECT_STATE,
    }
}
export {
    bidOnProject,
    resetBidOnProject,
    RESET_BID_ON_PROJECT_STATE,
    POST_BID_ON_PROJECT_REQUEST,
    POST_BID_ON_PROJECT_REQUEST_SUCCESS,
    POST_BID_ON_PROJECT_REQUEST_FAILURE,
};