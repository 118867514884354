const POST_GET_OFFER_REQUEST = 'POST_GET_OFFER_REQUEST';
const POST_GET_OFFER_REQUEST_SUCCESS =  'POST_GET_OFFER_REQUEST_SUCCESS';
const POST_GET_OFFER_REQUEST_FAILURE =  'POST_GET_OFFER_REQUEST_FAILURE';
const RESET_GET_OFFER_STATE ='RESET_GET_OFFER_STATE'

const getOffer = (formData)=>{

    return {
        type: POST_GET_OFFER_REQUEST,
        formData
    }
}

export {
    getOffer,
    RESET_GET_OFFER_STATE,
    POST_GET_OFFER_REQUEST,
    POST_GET_OFFER_REQUEST_SUCCESS,
    POST_GET_OFFER_REQUEST_FAILURE,
};