import { setItem } from "../../../Utils/AsyncStore";
import {
  POST_USER_LOGIN_REQUEST,
  POST_USER_LOGIN_REQUEST_SUCCESS,
  POST_USER_LOGIN_REQUEST_FAILURE,
  RESET_LOGIN_STATE
} from "./Actions";

const initialState = {
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_USER_LOGIN_REQUEST: {
      console.log(POST_USER_LOGIN_REQUEST);

      return {
        ...state,
        error: false,
        loading: true,
        success: false,
      };
    }
    case POST_USER_LOGIN_REQUEST_SUCCESS: {
      const data = action.payload;
      if (data.success.data?.user_type) {
        data.success.user = data.success.data
        setItem("registerUser", data);
      }
      console.log(POST_USER_LOGIN_REQUEST_SUCCESS);
      return {
        ...state,
        loading: false,
        success: true,
        data,
        message: data.success?.message,
      };
    }
    case POST_USER_LOGIN_REQUEST_FAILURE: {
      console.log(POST_USER_LOGIN_REQUEST_FAILURE);

      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        message: error?.message,
        errorData: error
      };
    }

    case RESET_LOGIN_STATE: {
      console.log(RESET_LOGIN_STATE);
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

export { reducer };
