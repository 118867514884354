import React, { useRef } from "react";
import { Pressable, Text, View } from "react-native";
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
  SheetManager
} from "react-native-actions-sheet";

function ActionSheetModel({ sheetId, payload }) {
  const actionSheetRef = useRef(null);
  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      elevation={0}
      containerStyle={{
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        paddingHorizontal: 10,
        paddingBottom: 5,
        backgroundColor: 'transprent'
      }}
      indicatorStyle={{
        width: 100,
        display: 'none'
      }}
      gestureEnabled={true}
    >
      <View
        style={{
          borderRadius: 15,
          //   flexDirection: "row",
          backgroundColor: 'white',
          width: "100%",
        }}
      >
        <View style={{ borderBottomColor: '#e4e3f3', borderBottomWidth: 1, padding: 10, justifyContent: 'center', alignItems: 'center' }}><Text style={{ color: "#8b8b8c" }}>Select Option</Text></View>
        <Pressable style={{ borderBottomColor: '#e4e3f3', borderBottomWidth: 1, padding: 15, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
          SheetManager.hide(sheetId, {
            payload: "camera",
          });
        }}><Text style={{ color: '#2596be', fontSize: 18 }}>Take {payload?.value =="document" || payload?.value =="mixed"  ? "Photo": payload?.value.charAt(0).toUpperCase() + payload?.value.slice(1)}</Text></Pressable>
        { payload?.value =="mixed" && <Pressable style={{ borderBottomColor: '#e4e3f3', borderBottomWidth: 1, padding: 15, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
          SheetManager.hide(sheetId, {
            payload: "mixed",
          });
        }}><Text style={{ color: '#2596be', fontSize: 18 }}>Take Video</Text></Pressable>
       }
        <Pressable onPress={() => {
          SheetManager.hide(sheetId, {
            payload: payload?.value =="document"?"document":"library",
          });
        }} style={{ padding: 15, justifyContent: 'center', alignItems: 'center', }}><Text style={{ color: '#2596be', fontSize: 18 }}>Choose from Library...</Text></Pressable>
        {/* <View style={{
          width:50,
          height:50,
          backgroundColor:'#f7f7f7',
          borderRadius:100,
          marginRight:10
        }} />

        <View style={{flexGrow:1}}>
          <View style={{ width:"100%",height:20,backgroundColor:"#f7f7f7",borderRadius:10,marginBottom:10 }}/>
          <View style={{ width:"80%",height:20,backgroundColor:"#f7f7f7",borderRadius:10 }}/>
        </View> */}
      </View>
      <View style={{ height: 10 }} />

      <Pressable onPress={() => {
        SheetManager.hide(sheetId);
      }} style={{ backgroundColor: 'white', padding: 20, alignItems: 'center', borderRadius: 15 }}><Text style={{ color: 'red', fontSize: 18 }}>Cancel</Text></Pressable>
    </ActionSheet>
  );
}

export default ActionSheetModel;
