const POST_USER_CHAT_REQUEST = "POST_USER_CHAT_REQUEST";
const POST_USER_CHAT_REQUEST_SUCCESS = "POST_USER_CHAT_REQUEST_SUCCESS";
const POST_USER_CHAT_REQUEST_FAILURE = "POST_USER_CHAT_REQUEST_FAILURE";
const RESET_USER_CHAT_STATE = "RESET_USER_CHAT_STATE";

const getUserChat = (formData) => {
  return {
    type: POST_USER_CHAT_REQUEST,
    formData,
  };
};
const resetUserChat = () => {
  return {
    type: RESET_USER_CHAT_STATE,
  };
};
export {
  getUserChat,
  resetUserChat,
  RESET_USER_CHAT_STATE,
  POST_USER_CHAT_REQUEST,
  POST_USER_CHAT_REQUEST_SUCCESS,
  POST_USER_CHAT_REQUEST_FAILURE,
};
