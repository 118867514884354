const POST_APPROVE_ON_SITE_VISIT_REQUEST = 'POST_APPROVE_ON_SITE_VISIT_REQUEST';
const POST_APPROVE_ON_SITE_VISIT_REQUEST_SUCCESS = 'POST_APPROVE_ON_SITE_VISIT_REQUEST_SUCCESS';
const POST_APPROVE_ON_SITE_VISIT_REQUEST_FAILURE = 'POST_APPROVE_ON_SITE_VISIT_REQUEST_FAILURE';
const RESET_APPROVE_ON_SITE_VISIT_STATE = 'RESET_APPROVE_ON_SITE_VISIT_STATE'

const approveOnSiteVisit = (formData) => {

    return {
        type: POST_APPROVE_ON_SITE_VISIT_REQUEST,
        formData
    }
}
const resetApproveOnSiteVisit = () => {

    return {
        type: RESET_APPROVE_ON_SITE_VISIT_STATE,
    }
}
export {
    approveOnSiteVisit,
    resetApproveOnSiteVisit,
    RESET_APPROVE_ON_SITE_VISIT_STATE,
    POST_APPROVE_ON_SITE_VISIT_REQUEST,
    POST_APPROVE_ON_SITE_VISIT_REQUEST_SUCCESS,
    POST_APPROVE_ON_SITE_VISIT_REQUEST_FAILURE,
};