const POST_GET_PROJECTS_DETAIL_REQUEST = 'POST_GET_PROJECTS_DETAIL_REQUEST';
const POST_GET_PROJECTS_DETAIL_REQUEST_SUCCESS = 'POST_GET_PROJECTS_DETAIL_REQUEST_SUCCESS';
const POST_GET_PROJECTS_DETAIL_REQUEST_FAILURE = 'POST_GET_PROJECTS_DETAIL_REQUEST_FAILURE';
const RESET_GET_PROJECTS_DETAIL_STATE = 'RESET_GET_PROJECTS_DETAIL_STATE'

const getProjectsDetail = (formData) => {

    return {
        type: POST_GET_PROJECTS_DETAIL_REQUEST,
        formData
    }
}
const resetProjectsDetail = () => {

    return {
        type: RESET_GET_PROJECTS_DETAIL_STATE,
    }
}
export {
    getProjectsDetail,
    resetProjectsDetail,
    RESET_GET_PROJECTS_DETAIL_STATE,
    POST_GET_PROJECTS_DETAIL_REQUEST,
    POST_GET_PROJECTS_DETAIL_REQUEST_SUCCESS,
    POST_GET_PROJECTS_DETAIL_REQUEST_FAILURE,
};