const POST_GET_STREET_EMPLOYEES_REQUEST = "POST_GET_STREET_EMPLOYEES_REQUEST";
const POST_GET_STREET_EMPLOYEES_REQUEST_SUCCESS = "POST_GET_STREET_EMPLOYEES_REQUEST_SUCCESS";
const POST_GET_STREET_EMPLOYEES_REQUEST_FAILURE = "POST_GET_STREET_EMPLOYEES_REQUEST_FAILURE";
const RESET_GET_STREET_EMPLOYEES_STATE = "RESET_GET_STREET_EMPLOYEES_STATE";

const getStreetEmployee = (formData) => {
  return {
    type: POST_GET_STREET_EMPLOYEES_REQUEST,
    formData
  };
};

const resetGetStreetEmployee = () => {
  return {
    type: RESET_GET_STREET_EMPLOYEES_STATE,
  };
};
export {
  getStreetEmployee,
  resetGetStreetEmployee,
  RESET_GET_STREET_EMPLOYEES_STATE,
  POST_GET_STREET_EMPLOYEES_REQUEST,
  POST_GET_STREET_EMPLOYEES_REQUEST_SUCCESS,
  POST_GET_STREET_EMPLOYEES_REQUEST_FAILURE,
};
