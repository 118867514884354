const POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST = "POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST";
const POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST_SUCCESS = "POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST_SUCCESS";
const POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST_FAILURE = "POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST_FAILURE";
const RESET_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_STATE = "RESET_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_STATE";

const supervisorUpdateNotification = (formData) => {
  return {
    type: POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST,
    formData
  };
};

const resetSupervisorUpdateNotification = () => {
  return {
    type: RESET_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_STATE,
  };
};
export {
  supervisorUpdateNotification,
  resetSupervisorUpdateNotification,
  RESET_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_STATE,
  POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST,
  POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST_SUCCESS,
  POST_SUPERVISOR_UPDATE_NOTIFICATION_SETTING_REQUEST_FAILURE,
};
