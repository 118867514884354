const POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST = "POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST";
const POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST_SUCCESS = "POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST_SUCCESS";
const POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST_FAILURE = "POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST_FAILURE";
const RESET_SUPERVISOR_UPDATE_EMPLOYEE_STATE = "RESET_SUPERVISOR_UPDATE_EMPLOYEE_STATE";

const supervisorUpdateEmployee = (formData) => {
  return {
    type: POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST,
    formData
  };
};

const resetSupervisorUpdateEmployee = () => {
  return {
    type: RESET_SUPERVISOR_UPDATE_EMPLOYEE_STATE,
  };
};
export {
  supervisorUpdateEmployee,
  resetSupervisorUpdateEmployee,
  RESET_SUPERVISOR_UPDATE_EMPLOYEE_STATE,
  POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST,
  POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST_SUCCESS,
  POST_SUPERVISOR_UPDATE_EMPLOYEE_REQUEST_FAILURE,
};
