const POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST = 'POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST';
const POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST_SUCCESS = 'POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST_SUCCESS';
const POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST_FAILURE = 'POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST_FAILURE';
const RESET_MAKE_PAY_IN_METHOD_DEFAULT_STATE = 'RESET_MAKE_PAY_IN_METHOD_DEFAULT_STATE'

const makePayInMethodDefault = (formData) => {

    return {
        type: POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST,
        formData
    }
}
const resetPayInMethodDefault = () => {

    return {
        type: RESET_MAKE_PAY_IN_METHOD_DEFAULT_STATE,

    }
}
export {
    makePayInMethodDefault,
    resetPayInMethodDefault,
    RESET_MAKE_PAY_IN_METHOD_DEFAULT_STATE,
    POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST,
    POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST_SUCCESS,
    POST_MAKE_PAY_IN_METHOD_DEFAULT_REQUEST_FAILURE,
};