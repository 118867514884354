const POST_GET_CHAT_DETAIL_REQUEST = "POST_GET_CHAT_DETAIL_REQUEST";
const POST_GET_CHAT_DETAIL_REQUEST_SUCCESS =
  "POST_GET_CHAT_DETAIL_REQUEST_SUCCESS";
const POST_GET_CHAT_DETAIL_REQUEST_FAILURE =
  "POST_GET_CHAT_DETAIL_REQUEST_FAILURE";
const RESET_GET_CHAT_DETAIL_STATE = "RESET_GET_CHAT_DETAIL_STATE";
const ADD_MESSAGE_TO_CHAT = "ADD_MESSAGE_TO_CHAT";

const getChatDetail = (formData) => {
  return {
    type: POST_GET_CHAT_DETAIL_REQUEST,
    formData,
  };
};
const resetChatDetail = () => {
  return {
    type: RESET_GET_CHAT_DETAIL_STATE,
  };
};
const addMessageToList = (message) => {
  return {
    type: ADD_MESSAGE_TO_CHAT,
    message,
  };
};
export {
  getChatDetail,
  resetChatDetail,
  addMessageToList,
  RESET_GET_CHAT_DETAIL_STATE,
  POST_GET_CHAT_DETAIL_REQUEST,
  POST_GET_CHAT_DETAIL_REQUEST_SUCCESS,
  POST_GET_CHAT_DETAIL_REQUEST_FAILURE,
  ADD_MESSAGE_TO_CHAT,
};
