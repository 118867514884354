import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const RadioButton = ({ checked }) => {

    return (
        <View style={[{
            height: 24,
            width: 24,
            borderRadius: 12,
            borderWidth: 2,
            borderColor: '#FFF',
            alignItems: 'center',
            justifyContent: 'center',
        },]}>
            {
                checked ?
                    <View style={{
                        height: 12,
                        width: 12,
                        borderRadius: 6,
                        backgroundColor: '#FFF',
                    }} />
                    : null
            }
        </View>
    );
}

export default RadioButton
