const POST_PROJECT_DELETE_BY_USER_REQUEST = 'POST_PROJECT_DELETE_BY_USER_REQUEST';
const POST_PROJECT_DELETE_BY_USER_REQUEST_SUCCESS = 'POST_PROJECT_DELETE_BY_USER_REQUEST_SUCCESS';
const POST_PROJECT_DELETE_BY_USER_REQUEST_FAILURE = 'POST_PROJECT_DELETE_BY_USER_REQUEST_FAILURE';
const RESET_PROJECT_DELETE_BY_USER_STATE = 'RESET_PROJECT_DELETE_BY_USER_STATE'

const projectDeleteByUser = (formData) => {

    return {
        type: POST_PROJECT_DELETE_BY_USER_REQUEST,
        formData
    }
};
const resetProjectDeleteByUser = () => {

    return {
        type: RESET_PROJECT_DELETE_BY_USER_STATE,
    }
}

export {
    projectDeleteByUser,
    resetProjectDeleteByUser,
    RESET_PROJECT_DELETE_BY_USER_STATE,
    POST_PROJECT_DELETE_BY_USER_REQUEST,
    POST_PROJECT_DELETE_BY_USER_REQUEST_SUCCESS,
    POST_PROJECT_DELETE_BY_USER_REQUEST_FAILURE,
};